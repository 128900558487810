import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n.js';
import moment from 'moment';
import auth from './auth';
import axios from 'axios';
import _ from 'lodash';
import frendsApi from './frendsApi.js';
import api from "@/api";
import dayjs from 'dayjs';
import ApiCacheService from '@/api-cache.service';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    projectsViewSearchText: '',
    projectsViewScrollY: 0,
    projectsViewProjectCount: 0,
    projectViewScrollY: 0,
    deliveryLotViewScrollY: 0,
    systemData: {},
    mainMenuOpen: false,
    installationGroups: [],
    projects: [],
    ownProjects: [],
    tasks: [],
    selectedTaskId: '',
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {},
    },
    previousRouteName: null,
    fallBackRouteCounter: 0,
    auth: auth,
    projectDTO: {},
    countries: [],
    reclamationTypes: [],
    calendarScrollY: null,
    tasksView: {
      tasksFromDate: dayjs().subtract(3, 'months').toDate().toISOString().substring(0, 10),
      scrollY: 0,
      scrollYItemsCount: 0,
    },
    reclamationsView: {
      reclamationRows: [],
      scrollY: null,
      project: null,
      reclamationFilters: {
        searchPhrase: '',
        selectedProject: null,
        selectedDeliveryLots: [],
        selectedApartments: [],
        selectedStatuses: [],
        selectedTypes: [],
        selectedRekltoimeraNimet: [],
        filterByVuositarkastus: false,
      },
    },
    tyomaarayksetView: {
      tyomaaraykset: [],
      scrollY: null,
      project: null,
      tyomaarayksetFilters: {
        searchPhrase: '',
        selectedProject: null,
        selectedDeliveryLots: null,
        selectedInstallationGroup: null,
        own: false,
        underWork: true,
      },
    },
    tyomaaraysStatukset: null,
  },
  mutations: {
    setCountries(state, val) {

      state.countries = _.sortBy(val, 'jarjestys');
    },
    setTasksFromDate(state, val) {
      state.tasksView.tasksFromDate = val;
    },
    setTasksVisibleItemIndex(state, val) {
      state.tasksView.visibleItemIndex = val;
    },
    setProjectsViewScrollY(state, val) {
      state.projectsViewScrollY = val;
    },
    setProjectViewScrollY(state, val) {
      state.projectViewScrollY = val;
    },
    setDeliveryLotViewScrollY(state, val) {
      state.deliveryLotViewScrollY = val;
    },
    setProjectsViewSearchText(state, val) {
      state.projectsViewSearchText = val;
    },
    setSystemData(state, val) {
      state.systemData = val;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setFallBackRouteCounter(state, val) {
      state.fallBackRouteCounter = val;
    },
    setInstallationGroups(state, val) {
      const sortedGroups = _.sortBy(val, ['name']);
      const groupSelectionsString = localStorage.getItem("installation-group-selections");
      let groupSelections = null;
      if (groupSelectionsString != null) {
        groupSelections = JSON.parse(groupSelectionsString);
      }
      sortedGroups.forEach(v => {
        if (groupSelections != null) {
          const groupSelection = groupSelections.find(gs => gs.id === v.id);
          if (groupSelection != null) {
            v.selected = groupSelection.selected;
          } else {
            v.selected = true
          }
        } else {
          v.selected = true
        }
      });
      state.installationGroups = sortedGroups;
    },
    setOwnProjects(state, val) {
      state.ownProjects = val;
    },
    setProjects(state, val) {
      state.projects = val;
    },
    setTasks(state, val) {
      state.tasks = val.sort((a, b) => {
        const am = moment(a.scheduledEnd);
        const bm = moment(b.scheduledEnd);
        if (am.isBefore(bm)) {
          return -1;
        }

        if (am.isAfter(bm)) {
          return 1;
        }

        return 0;
      });
    },
    updateTask(state, val) {
      const taskIndex = state.tasks.findIndex(t => t.activityId === val.activityId);
      if (taskIndex > -1) {
        val.deliveryLot = state.tasks[taskIndex].deliveryLot;
        val.project = state.tasks[taskIndex].project;
        state.tasks.splice(taskIndex, 1, val);
      }
    },
    setProjectDTO(state, val) {
      state.projectDTO = val;
    },
    setReclamationTypes(state, val) {
      state.reclamationTypes = val;
    },
    setTyomaaraysStatukset(state, val) {
      state.tyomaaraysStatukset = val;
    },
  },
  actions: {
    resetTyomaarayksetFilters({ state }) {
      const filter = state.tyomaarayksetView.reclamationFilters;
      filter.selectedProject = null;
      filter.selectedDeliveryLots = [];
    },
    resetReclamationFilters({ state }) {
      const filter = state.reclamationsView.reclamationFilters;
        filter.selectedProject = null;
      filter.selectedTypes = [];
      filter.selectedApartments = [];
      filter.selectedDeliveryLots = [];
      filter.selectedStatuses = [];
      filter.selectedRekltoimeraNimet = [];
      filter.filterByVuositarkastus = false;
    },
    selectInstallationGroup(context, group) {
      ApiCacheService.clear();
      context.state.tasks = []
      context.state.calendarScrollY = null;

      group.selected = !group.selected;
      const selections = context.state.installationGroups.map(ig => { return { id: ig.id, selected: ig.selected }});
      localStorage.setItem("installation-group-selections", JSON.stringify(selections));
    },
    async reloadInstallationGroups(context) {
      try {
        const groupResponse = await axios.get('/api/User/groups');
        context.commit('setInstallationGroups', groupResponse.data);
      } catch (err) {
        console.log(err);
        context.dispatch('showNotification',
          {
            color: 'error',
            message: i18n.t('installation_groups.failed_to_fetch_installation_groups'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              context.dispatch('getInstallationGroups')
            }
          });
      }
    },
    async reloadSystemData(context, payload) {
      try {
        const systemData = await api.getSystemData();
        context.commit('setSystemData', systemData);

        const countries = await frendsApi.getMaat(payload.kielinro);
        context.commit('setCountries', countries);
      } catch (err) {
        console.log(err);
      }
    },
    async reloadProjects(context) {
      const installationGroupIds = context.state.installationGroups.filter(g => g.selected).map(group => group.id);

      if (installationGroupIds.length === 0 && !(context.state.auth.role === 'PROJECT_CUSTOMER')) {
        context.commit('setProjects', []);
        ApiCacheService.setAllProjects(installationGroupIds, []);
        return;
      }

      try {
        const allProjects = await api.getProjects(installationGroupIds);
        let projects = [];
        // Alla poistetaan duplikaattiprojektit, joita haku voi palauttaa
        allProjects.forEach(project => {
          if (projects.findIndex(p => p.id === project.id) > -1) {
            return;
          }
          projects.push(project);
        });

        projects = _.orderBy(projects, 'deliveryStartDate')
        ApiCacheService.setAllProjects(installationGroupIds, projects);
        context.commit('setProjects', projects);
      } catch (error) {
        console.log(error);
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('projects.error_loading_projects'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => { context.dispatch('reloadProjects') }
        });
      }
    },
    async reloadOwnProjects(context) {
      try {
        const allProjects = await api.getProjects([], true);
        let projects = [];
        allProjects.forEach(project => {
          if (projects.findIndex(p => p.id === project.id) > -1) {
            return;
          }
          projects.push(project);
        });

        projects = _.orderBy(projects, 'deliveryStartDate')
        ApiCacheService.setOwnProjects(context.state.auth.user.id, projects);
        context.commit('setOwnProjects', projects);
      } catch (error) {
        console.log(error);
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('projects.error_loading_projects'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => { context.dispatch('reloadOwnProjects') }
        });
      }
    },
    async reloadTasksForUser(context) {
      const installationGroupIds = context.state.installationGroups.filter(g => g.selected).map(group => group.id);

      if (installationGroupIds.length === 0) {
        context.commit('setTasks', []);
        return;
      }

      try {
        const params = { from_date: context.state.tasksView.tasksFromDate };
        const response = await axios.post('/api/User/tasks', { installationGroupIds }, { params });
        const data = response.data;
        data.map(t => {
          t.done = false;
          if (t.statecode === 1 && t.statuscode === 5) {
            t.done = true;
          }
        });
        context.commit('setTasks', data);
      } catch (error) {
        console.log(error);
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('tasks.failed_to_fetch_tasks'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => { context.dispatch('reloadTasksForUser') }
        });
      }
    },
    async setTaskCompleted(context, activityId) {
      try {
        const response =  await axios.post(`/api/WorkTask/${activityId}/complete`);
        context.commit('updateTask', response.data);
      } catch (error) {
        console.log(error);
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('tasks.failed_to_change_task_status'),
          timeOut: 4000,
          showButton: false,
        });
      }
    },
    async reloadProjectDTO(context, projectId) {
      try {
        const projectResponse = await axios.get(`/api/Project/${projectId}/dto`);
        context.commit('setProjectDTO', projectResponse.data);
      } catch (error) {
        context.dispatch('showNotification',
          {
            color: 'error',
            message: i18n.t('projects.error_loading_project'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              context.dispatch('reloadProjectDTO', projectId);
            }
          });
        console.log(error);
      }
    },
    async reloadReclamationTypes(context, payload) {
      try {
        const reclamationTypes = await frendsApi.getReklamaatioTyypit(payload.kielinro);
        context.commit('setReclamationTypes', reclamationTypes);
      } catch (err) {
        console.log(err);
        context.dispatch('showNotification',
          {
            color: 'error',
            message: i18n.t('reclamations.failed_to_fetch_reclamation_types'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              context.dispatch('reloadReclamationTypes')
            }
          });
      }
    },
    async reloadTyomaaraysStatukset(context, payload) {
      try {
        const statukset = await frendsApi.getTyomaaraysStatukset(payload.kielinro);
        context.commit('setTyomaaraysStatukset', statukset);
      } catch (err) {
        console.log(err);
        context.dispatch('showNotification',
          {
            color: 'error',
            message: i18n.t('tyomaaraykset.failed_to_fetch_statuses'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              context.dispatch('reloadTyomaaraysStatukset');
            }
          });
      }
    },
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
  },
});

export default store;
