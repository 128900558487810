<template>
  <v-app id="app">
    <v-snackbar v-model="snackbar" top multi-line :timeout="timeOut" :color="color">

      {{ text }}
      <template v-if="showButton">
        <v-btn outline flat @click="() => { callback(); snackbar = false;}">
          {{ buttonText }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn icon @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-model="menuOpenState"
      :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
      app
      fixed
      dark
    >
      <v-toolbar class="primary darken-1">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar @click.stop="toggleMiniMenu">
              <img src="/img/icons/icon-192x192.png" />
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title class="subheading font-weight-bold">{{ $t('title') }} {{ env }}</v-list-tile-title>
              <v-list-tile-sub-title class="caption">{{ userFullName }}</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn
                icon
                @click.stop="toggleMiniMenu"
              >
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action>

          </v-list-tile>
        </v-list>
      </v-toolbar>

      <v-list :dense="$vuetify.breakpoint.mdAndDown" data-cy="navigation-drawer">
        <v-list-tile
          v-for="(page, index) in filteredPrimaryPages"
          :key="index+'primary'"
          :to="{ name: page.link }"
          :data-cy="page.dataCy"
        >
          <v-list-tile-action>
            <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content class="white--text">
            <v-badge color="red" v-if="page.items">
              <template v-slot:badge>
                {{ page.items }}
              </template>
              <v-list-tile-title>{{ page.title }}</v-list-tile-title>
            </v-badge>
            <v-list-tile-title v-else>{{ page.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>

        <hb-language-selector/>

        <v-divider></v-divider>

        <v-list-tile
          v-for="(page, index) in filteredSecondaryPages"
          :key="index+'secondary'"
          :to="{ name: page.link }"
          :data-cy="page.dataCy"
        >
          <v-list-tile-action>
            <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content class="white--text">
            <v-list-tile-title>{{ page.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="logout" data-cy="logout">
          <v-list-tile-action>
            <v-icon color="grey lighten-1">logout</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t('logout') }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
       </v-list>

      <div v-if="showHash" class="grey--text text--darken-1" style="position: absolute; left: 20px; right: 20px; bottom: 10px;">
        Revision: #{{ hash }}
      </div>
    </v-navigation-drawer>

    <v-bottom-nav
      v-if="$vuetify.breakpoint.mdAndDown"
      :active.sync="bottomNavSelected"
      :value="showBottomNav"
      fixed
      data-cy="bottom_nav"
      app
      color="white"
    >
      <template v-for="(page, index) in filteredBottomNavPages">
        <v-btn :key="index" color="primary" flat :value="page.link" :to="page.link" :data-cy="page.dataCy">
          <span style="font-size: 11px;">{{ page.title }}</span>
          <v-badge color="red" v-if="page.items">
            <template v-slot:badge>
              {{ page.items }}
            </template>
            <v-icon>{{ page.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ page.icon }}</v-icon>
        </v-btn>
      </template>
    </v-bottom-nav>

    <v-content>
      <router-view @sync_item_removed="reloadDbItemCount" v-if="isUserActive"/>
      <inactive-user-view v-else></inactive-user-view>
    </v-content>
    <confirm-dialog ref="confirm"/>
  </v-app>
</template>

<script>
  import InactiveUserView from "@/views/InactiveUserView";
  import db from './database';
  import frendsApi from "@/frendsApi";
  import UiEventBus from "@/UiEventBus";
  import ConfirmDialog from "./components/ConfirmDialog";
  import Api from "@/api";
  import ApiCacheService from "@/api-cache.service";

  export default {
    name: 'MainApp',
    components: {ConfirmDialog, InactiveUserView},
    data() {
      return {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: 0,
        callback: () => {},
        snackbar: false,
        miniMenu: false,
        bottomNavSelected: '',
        showBottomNav: true,
        menuOpenState: false,
        syncItems: 0,
      }
    },
    methods: {
      async sendDebug() {
        const data = {
          unsyncedItems: this.syncItems,
          user: this.auth.user.username,
          hash: this.hash,
        };
        const isDbSupported = await this.$isDbSupported();
        const {latestUnsynced, oldestUnsynced} = await db.getLatestAddition();
        data.isDbSupported = isDbSupported;
        data.latestUnsynced = latestUnsynced;
        data.oldestUnsynced = oldestUnsynced;
        await frendsApi.debug(data);
      },
      logout() {
        ApiCacheService.clear();
        this.$store.state.auth.client.logout();
      },
      goToLink(name) {
        this.$router.push({name: name});
      },
      toggleMiniMenu() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          this.miniMenu = !this.miniMenu;
        } else {
          this.$store.commit('toggleMainMenuState');
        }
      },
      handleSelectedBottomNavItem(name) {
        const isPageBottomNavItem = this.pages.findIndex(p => p.mobileBottomNav && p.link === name) > -1;
        if (isPageBottomNavItem) {
          this.bottomNavSelected = name;
          this.showBottomNav = true;
        } else {
          this.showBottomNav = false;
        }
      },
      async reloadDbItemCount() {
        this.syncItems = await db.itemCount();
      },
    },
    watch: {
      notificationObject(to) {
        this.color = to.color;
        this.text = to.message;
        this.snackbar = true;
        this.buttonText = to.buttonText;
        this.showButton = to.showButton;
        this.timeOut = to.timeOut;
        this.callback = to.callback;
      },
      menuOpenComputed(to) {
        this.menuOpenState = to;
      },
      menuOpenState(to) {
        if (to !== this.$store.state.mainMenuOpen) {
          this.$store.commit('toggleMainMenuState');
        }
      },
      selectedRoute(to) {
        this.handleSelectedBottomNavItem(to);
      },
      $route(to, from) {
        if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      },
    },
    computed: {
      isUserActive() {
        return this.$store.state.auth.jehuUser.active;
      },
      pages() {
        const pages = [];
        pages.push({
          title: this.$t('calendar.title'),
          icon: 'calendar_today',
          dataCy: 'calendar',
          link: 'calendar',
          roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER'],
          primary: true,
          mobileBottomNav: true,
        });
        pages.push({
          title: this.$tc('projects.title', 2),
          icon: 'assignment',
          dataCy: 'projects',
          link: 'projects',
          roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER', 'PROJECT_CUSTOMER'],
          primary: true,
          mobileBottomNav: true,
        });
        pages.push({
          title: this.$t('tasks.title'),
          icon: 'ballot',
          dataCy: 'tasks',
          link: 'tasks',
          roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER'],
          primary: false,
          mobileBottomNav: true,
        });

        if (this.$showReclamations) {
          pages.push({
            title: this.$tc('reclamation.title', 2),
            icon: 'fas fa-exchange-alt',
            dataCy: 'reclamations',
            link: 'reclamations',
            roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER', 'PROJECT_CUSTOMER'],
            primary: true,
          });
        }

        pages.push({
          title: this.$t('tyomaaraykset.title'),
          icon: 'mdi mdi-clipboard-list',
          dataCy: 'tyomaaraykset',
          link: 'tyomaaraykset',
          roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER'],
          primary: true,
          mobileBottomNav: true,
        });

        if (this.$showReclamations) {
          pages.push({
            title: this.$t('reclamation_sync.menu_title'),
            icon: 'upload',
            dataCy: 'sync',
            link: 'sync',
            roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER', 'PROJECT_CUSTOMER'],
            items: this.syncItems,
            primary: true,
          });
        }

        pages.push({
          title: this.$t('installation_groups.title'),
          icon: 'people_outline',
          dataCy: 'installationGroups',
          link: 'installationGroups',
          roles: ['JEHU_ADMIN', 'INSTALLER_LEADER', 'INSTALLER_MEMBER', 'PUUSTELLI_USER'],
          primary: false,
        });
        return pages;
      },
      showHash() {
        return !(this.miniMenu && this.$vuetify.breakpoint.lgAndUp);
      },
      env() {
        return ENV_DESCRIPTION;
      },
      hash() {
        return COMMITHASH;
      },
      userFullName() {
        return `${this.auth.user.firstName} ${this.auth.user.lastName}`;
      },
      auth() {
        return this.$store.state.auth;
      },
      notificationObject() {
        return this.$store.state.notificationObject;
      },
      menuOpenComputed() {
        let menuState = true;
        if (this.$vuetify.breakpoint.mdAndDown) {
          menuState = this.$store.state.mainMenuOpen;
        }
        return menuState;
      },
      filteredSecondaryPages() {
        let pages = [];
        this.pages.forEach(page => {
          if (!page.primary) {
            if (this.$hasMatchingRoles(page.roles)) {
              pages.push(page);
            }
          }
        })
        return pages;
      },
      filteredPrimaryPages() {
        let pages = [];
        this.pages.forEach(page => {
          if (page.primary) {
            if (this.$hasMatchingRoles(page.roles)) {
              pages.push(page);
            }
          }
        })
        return pages;
      },
      filteredBottomNavPages() {
        let pages = [];
        this.pages.forEach(page => {
          if (page.mobileBottomNav) {
            if (this.$hasMatchingRoles(page.roles)) {
              pages.push(page);
            }
          }
        })
        return pages;
      },
      selectedRoute() {
        let name = '';
        if (this.$isCustomer) {
          name = 'projects';
        }
        if (this.$route.name) name = this.$route.name;
        return name;
      },
    },
    async mounted() {
      if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
      this.handleSelectedBottomNavItem(this.$route.name);
      this.$makeDialogsMovable();
      await this.reloadDbItemCount();

      UiEventBus.$on('synchronization-objects-changed', () => {
        this.reloadDbItemCount();
      })

      this.sendDebug();


      this.$root.$confirm = this.$refs.confirm.open;
    },
  }
</script>

<style class="scss">
  .move-attach {
    cursor: move;
  }

  @media print {
    body * {
      visibility: hidden;
    }
    .v-content {
      padding: 0px !important;
    }
  }
</style>
