<template>
  <v-list-tile @click="click" class="pa-0 ma-0 report-row-tile">
    <v-list-tile-avatar>
      <v-icon>{{ actualIcon }}</v-icon>
    </v-list-tile-avatar>
    <v-list-tile-content>
      {{ name }}
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
export default {
  props: ['icon', 'name', 'loading'],
  name: "ReportRow",
  methods: {
    click() {
      this.$emit('click');
    }
  },
  computed: {
    actualIcon() {
      if (this.loading === true) {
        return 'fas fa-circle-notch fa-spin';
      } else {
        return this.icon;
      }
    }
  }
}
</script>

<style>
  .report-row-tile a {
    padding: 0 !important;
  }
</style>
