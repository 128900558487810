<template>
  <v-container fluid fill-height ma-0 pa-0 class="projects">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <hb-back-button fallback="calendar" @back="storeSearchPhrase(true)"/>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="$t('projects.search_for_projects')"
          v-model="searchPhrase"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          full-width
          hide-details
          color="red lighten-3"
          data-cy="projects_search"
        >
          <v-icon style="cursor: pointer;" slot="append">search</v-icon>
        </v-text-field>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark icon @click="toggleFilterPanel" v-if="canToggleFilterPanel" data-cy="project-filter">
        <v-icon>filter_list</v-icon>
      </v-btn>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="projects-holder">
          <v-container fluid grid-list-md v-if="projects.length === 0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-alert :value="true" color="grey" icon="warning">
                  <div>{{ $t('projects.no_projects_found') }}</div>
                  <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}" color="white"><v-icon left size="20">people_outline</v-icon>{{ $t('installation_groups.title') }}</v-btn>
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>

          <v-list v-if="projects.length > 0" three-line class="pt-0 pb-0" avatar data-cy="projects_list">
              <dynamic-scroller
                  ref="scroller"
                  :items="filteredProjects"
                  :min-item-size="88"
                  class="scroller fixed-height-content"
                  @scroll.native="handleScroll"
                  key-field="id"
              >
                <template v-slot="{ item, index, active }">
                  <dynamic-scroller-item
                      :active="active"
                      :data-index="index"
                      :item="item"
                      :size-dependencies="[]">
                      <project-list-item :project="item" @save-search-phrase="storeSearchPhrase(false)"/>
                      <v-divider></v-divider>
                  </dynamic-scroller-item>
                </template>
              </dynamic-scroller>
          </v-list>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-filter-drawer
      v-model="showFilterPanel"
      dark
      :noPadding="true"
    >
      <v-toolbar class="primary darken-1">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-btn
                icon
                @click.stop="toggleFilterPanel"
                data-cy="toggle-filter-panel"
              >
                <v-icon color="white">chevron_right</v-icon>
              </v-btn>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="subheading font-weight-bold white--text">{{ $t('filter_list') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
        <v-layout row wrap class="px-4">
          <v-flex xs12>
            <v-checkbox
              @change="storeFilters"
              v-if="ownProjectsFilterEnabled"
              v-model="objectFilters.own"
              :label="$t('calendar.filter.own')"
              hide-details
              data-cy="filter-own-projects"
            ></v-checkbox>
          </v-flex>
        </v-layout>
    </hb-filter-drawer>
  </v-container>
</template>

<script>
  import ProjectListItem from "@/components/ProjectsView/ProjectListItem";
  import ApiCacheService from "@/api-cache.service";
  export default {
    name: "ProjectsView",
    components: {ProjectListItem},
    data() {
      return {
        loading: true,
        subtleLoading: false,
        showFilterPanel: false,
        searchPhrase: '',
        objectFilters: {
          own: false
        }
      }
    },
    methods: {
      handleScroll(e) {
        this.$store.commit('setProjectsViewScrollY', e.target.scrollTop);
        this.$store.state.projectsViewProjectCount = this.filteredProjects.length;
      },
      getDataFromCache(){
        const installationGroupIds = this.$store.state.installationGroups.filter(g => g.selected).map(group => group.id);
        this.$store.commit('setProjects', ApiCacheService.getAllProjects(installationGroupIds));
        this.$store.commit('setOwnProjects', ApiCacheService.getOwnProjects(this.$store.state.auth.user.id));
      },
      storeSearchPhrase(clear) {
        if (clear) {
          this.searchPhrase = '',
          this.$store.commit('setProjectsViewSearchText', '');
        } else {
          this.$store.commit('setProjectsViewSearchText', this.searchPhrase);
        }
      },
      storeFilters() {
        localStorage.setItem("project-filters", JSON.stringify(this.objectFilters));
        this.reloadProjects();
      },
      toggleFilterPanel() {
        this.showFilterPanel = !this.showFilterPanel;
      },
      getGroup(installationGroupId) {
        const group = this.$store.state.installationGroups.find(ig => ig.id === installationGroupId);
        if (group != null) {
          return group.name;
        }

        return '';
      },
      async reloadProjects() {
        if (this.projects.length === 0) {
          this.loading = true;
          this.subtleLoading = false;
        } else {
          this.loading = false;
          this.subtleLoading = true;
        }
        if (this.objectFilters.own) {
          await this.$store.dispatch('reloadOwnProjects' );
        } else {
          await this.$store.dispatch('reloadProjects' );
        }

        this.loading = false;
        this.subtleLoading = false;
      }
    },
    computed: {
      canToggleFilterPanel() {
        return this.ownProjectsFilterEnabled;
      },
      ownProjectsFilterEnabled() {
        return this.$isPuustelliUser;
      },
      projects() {
        if (this.objectFilters.own) {
          return this.$store.state.ownProjects;
        } else {
          return this.$store.state.projects;
        }
      },
      filteredProjects() {
        let projects = this.projects;

        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          console.log(projects.filter(r => r.id == null));
          return projects;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");

        const ret = projects.filter(p => {
          let found = [];
          const lowerSearchedString =
            (p.clientName !== null ? p.clientName.toLowerCase() : '') +
            (p.city !== null ? p.city.toLowerCase() : '') +
            // (p.contactPerson !== null ? p.contactPerson.toLowerCase() : '') +
            (p.customerInfo !== null ? p.customerInfo.toLowerCase() : '') +
            // (p.contactPersonEmail !== null ? p.contactPersonEmail.toLowerCase() : '') +
            (p.streetAddress !== null ? p.streetAddress.toLowerCase() : '') +
            //(p.type !== null ? p.type.toLowerCase() : '') +
            (this.getGroup(p.installationGroupId).toLowerCase());
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        });

        console.log(ret.filter(r => r.id == null));

        return ret;
      }
    },
    mounted() {
      // this.$store.commit('setProjectViewScrollY', 0);
      this.objectFilters.own = this.ownProjectsFilterEnabled;
      this.searchPhrase = this.$store.state.projectsViewSearchText;

      const storedFilters =  localStorage.getItem("project-filters");
      if (storedFilters != null) {
        const objectFilters = JSON.parse(storedFilters);

        if (!this.ownProjectsFilterEnabled) {
          objectFilters.own = false;
        }

        this.objectFilters = objectFilters;
      }
      this.getDataFromCache();
      if (this.$store.state.projectsViewScrollY > 0 && this.$store.state.projectsViewProjectCount === this.filteredProjects.length) {
        setTimeout(() => {
          this.$refs.scroller.$el.scrollTop = this.$store.state.projectsViewScrollY;
          this.$store.state.projectsViewScrollY = 0;
        });
      }
      this.reloadProjects();
    }
  }
</script>

<style lang="scss" scoped>
  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 112px);
    }
  }

  @media only screen and (min-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 104px);
    }
  }

  @media only screen and (min-width: 960px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 120px);
    }
  }

  @media only screen and (min-width: 1265px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }

  @media only screen and (min-width: 1300px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }
</style>
