<template>
    <v-list-tile
      avatar
      style="border-radius: 100px"
      class="calendar-custom-tile dense"
      :class="projectColor + ' lighten-4'"
      :to="{name: 'deliverylot', params: { projectId: deliveryLot.projectId, deliveryLotId: deliveryLot.id }}"
    >
      <v-list-tile-content>
        <v-list-tile-title class="grey--text text--darken-3"
                           :class="{subheading: $vuetify.breakpoint.mdAndUp, caption: $vuetify.breakpoint.smAndDown}">
          {{ deliveryLot.name }}
          <template v-if="$isPuustelliUser || $isAdmin"><span style="opacity: 0.65"> ({{ statusText }})</span>
          </template>
        </v-list-tile-title>
        <v-list-tile-sub-title
          :class="projectColor + '--text text--darken-4'"
          :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
          {{ deliveryLot.clientName }}, {{ deliveryLot.projectInfo }}
        </v-list-tile-sub-title>
        <v-list-tile-sub-title
          v-if="deliveryLot.deliveryExtraInfo"
          :class="projectColor + '--text text--darken-4'"
          :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
          {{ deliveryLot.deliveryExtraInfo }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-avatar v-if="deliveryLot.deliveryDateConfirmed">
        <v-icon :class="projectColor + ' lighten-3'"
                size="18"
                class="white--text">fas fa-truck-loading</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-avatar>
        <v-icon :class="projectColor + ' lighten-3'"
                class="white--text">{{ statusIcon }}
        </v-icon>
      </v-list-tile-avatar>
    </v-list-tile>
</template>

<script>
import parameters from "@/parameters";

export default {
  props: ['item', 'projectColors'],
  name: "CalendarTimelineDeliveryLot",
  computed: {
    projectColor() {
      const projectId = this.deliveryLot.projectId;
      const colorIndex = this.projectColors.findIndex(pc => pc.projectId === projectId);
      if (colorIndex > -1) {
        return this.projectColors[colorIndex].color;
      }
      return '';
    },
    deliveryLot() {
      return this.item;
    },
    statusIcon() {
      const lot = this.deliveryLot;
      if (lot.received) {
        return parameters.deliveryLotStatuses[lot.statusCode].icon;
      } else {
        return parameters.deliveryLotStatuses[lot.statusCode].notReceivedIcon;
      }
    },
    statuses() {
      return this.$store.state.systemData.deliveryLotStatuses;
    },
    statusText() {
      const status = this.statuses.find(s => s.key === this.deliveryLot.statusCode);
      if (status != null) {
        return status.value;
      }

      return '';
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
