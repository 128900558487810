<template>
  <v-list-tile
      avatar
      style="border-radius: 100px"
      class="calendar-custom-tile white"
      :to="{name: 'task', params: { activityId: task.activityId }}"
  >
    <v-list-tile-content>
      <v-list-tile-title class="grey--text text--darken-3"
                         :class="{subheading: $vuetify.breakpoint.mdAndUp, caption: $vuetify.breakpoint.smAndDown}">
        {{ $formatDateNoTime(task.scheduledEnd) }} &mdash; {{ task.subject }}
      </v-list-tile-title>
      <v-list-tile-sub-title v-if="task.isDeliveryLotTask"
                             :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
        {{ task.deliveryLot.name }}, {{ task.deliveryLot.projectInfo }}, {{
          task.deliveryLot.clientName }}
      </v-list-tile-sub-title>
      <v-list-tile-sub-title v-if="task.isProjectTask"
                             :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
        {{ task.project.customerInfo }}, {{ task.project.clientName }}
      </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-avatar>
      <v-icon v-if="taskIsCompleted" class="success lighten-2 white--text">done_all
      </v-icon>
      <v-icon v-else class="warning lighten-2 white--text">hourglass_empty</v-icon>
    </v-list-tile-avatar>
  </v-list-tile>
</template>

<script>
export default {
  props: ['item'],
  name: "CalendarTimelineTask",
  computed: {
    task() {
      return this.item
    },
    taskIsCompleted() {
      const task = this.task;
      return task.statecode === 1 && task.statuscode === 5;
    }
  },
}
</script>

<style scoped>

</style>
