import frends from './frends';

const frendsApi = {
  async debug(data) {
    try {
      const url = '/debug';
      await frends.axiosInstance.post(url, data)
    } catch (error) {
      console.log(error);
    }
  },
  async getTuotteet(projektiId, huoneId, kielinro) {
    const url = `/tuotteet?projektinro=${projektiId}&huonenro=${huoneId}&kielinro=${kielinro}`;
    const response = await frends.axiosInstance.get(url);
    return response.data;
  },

  async getReklamaatioTyypit(kielinro) {
    const response = await frends.axiosInstance.get(`/reklamaatiotyypit?kielinro=${kielinro}`);
    return response.data;
  },

  async getReklamaatioRivit(projektiId, toimituseraId, vertexProjektiNro, vertexHuoneNro, kielinro) {
    let url = `/reklamaatiorivit?projektiid=${projektiId}&kielinro=${kielinro}`;
    if (toimituseraId != null) {
      url += `&toimituseraid=${toimituseraId}`;
    }

    if (vertexProjektiNro != null && vertexHuoneNro != null) {
      url += `&vertex_projektinro=${vertexProjektiNro}&vertex_huonenro=${vertexHuoneNro}`;
    }

    const response = await frends.axiosInstance.get(url);

    const rivit = response.data;
    rivit.forEach(row => {
      if (row.d365projektiid) {
        row.d365projektiid = row.d365projektiid.substring(1, row.d365projektiid.length - 1).toLowerCase();
      }
      if (row.d365toimituseraid) {
        row.d365toimituseraid = row.d365toimituseraid.substring(1, row.d365toimituseraid.length -1).toLowerCase();
      }
    });

    let i = {};

    rivit.forEach(d => {
      const tunniste = d.rekl_rivitunniste;
      if (tunniste in i) {
        const key = tunniste + '_' + i[tunniste];
        i[tunniste] += 1;
        d.key = key;
      } else {
        d.key = tunniste;
        i[tunniste] = 1;
      }
    });

    return rivit;
  },
  async getReklamaatioRiviLiitteet(rivitunniste) {
    const url = `/reklamaatioriviliitteet?rekl_rivitunniste=${rivitunniste}`;
    const response = await frends.axiosInstance.get(url);
    return response.data;
  },
  async deleteReklamaatioRiviLiite(fileId) {
    const url = `/reklamaatioriviliite?fileid=${fileId}`;
    const response = await frends.axiosInstance.delete(url);
    return response.data;
  },
  async newReklamaatioRivi(projektinro, huonenro, rivinro, puusnetTilausId, kielinro, reclamationData, user) {
    const data = {
      projektinro: projektinro,
      huonenro: huonenro,
      rivinro: rivinro,
      tilausnro: puusnetTilausId,
      maara: reclamationData.amount,
      rekl_tyyppi: reclamationData.reclamationType,
      rekl_selite: reclamationData.reclamationInfo,
      rekl_rivitunniste: null,
      vuositarkastus: reclamationData.yearlyInspection,
      kielinro: kielinro,
      uuden_toimitus: reclamationData.newDeliveryOption,
      vastaanottaja: reclamationData.recipient,
    };

    if (user != null) {
      if (user.email != null && user.email.length > 0) {
        data.kayttaja_email = user.email;
      }
      if (user.firstName != null && user.lastName != null) {
        data.kayttaja_nimi = `${user.firstName} ${user.lastName}`;
      }
    }

    const url = '/reklamaatiorivi';

    const response = await frends.axiosInstance.post(url, data);
    return response.data;
  },
  async addReklamaatioRiviLiite(reklRiviTunniste, fileAsDataUrl, filename, type) {
    const url = '/reklamaatioriviliite';

    var bodyFormData = new FormData();
    bodyFormData.append("rekl_rivitunniste", reklRiviTunniste);
    let base64EncodedData = fileAsDataUrl;
    if (fileAsDataUrl.indexOf("data:") === 0) {
      base64EncodedData = fileAsDataUrl.split(",", 2)[1];
    }
    bodyFormData.append("tiedosto", new Blob([base64EncodedData], { type }), filename);

    const response = await frends.axiosInstance.post(url, bodyFormData);
    return response.data;
  },
  async getReklamaatioRiviLokit(rivitunniste, kielinro) {
    const url = `/reklamaatioriviloki?rekl_rivitunniste=${rivitunniste}&kielinro=${kielinro}`;
    const response = await frends.axiosInstance.get(url);
    return response.data;
  },
  async getReklamaatioRivi(rivitunniste, kielinro) {
    const url = `/reklamaatiorivi?rekl_rivitunniste=${rivitunniste}&kielinro=${kielinro}`;
    const response = await frends.axiosInstance.get(url);
    if (response.data.d365projektiid) {
      response.data.d365projektiid = response.data.d365projektiid.substring(1, response.data.d365projektiid.length -1).toLowerCase();
    }
    if (response.data.d365toimituseraid) {
      response.data.d365toimituseraid = response.data.d365toimituseraid.substring(1, response.data.d365toimituseraid.length - 1).toLowerCase();
    }
    return response.data;
  },
  async deleteReklamaatioRivi(rivitunniste) {
    const url = `/reklamaatiorivi?rekl_rivitunniste=${rivitunniste}`;
    const response = await frends.axiosInstance.delete(url);
  },
  async updateReklamaatioRiviStatus(rivitunniste, status, kielinro, user) {
    const data = {
      rekl_rivitunniste: rivitunniste,
      rekl_status: status,
      kielinro,
      kayttaja_email: user.email,
      kayttaja_nimi: `${user.firstName} ${user.lastName}`,
    }

    const url = '/reklamaatiorivistatus';
    const response = await frends.axiosInstance.post(url, data);
    return response.data;
  },
  async sendReclamationComment(rivitunniste, kielinro, user, kommentti, lisattyjonoon) {
    const data = {
      rekl_rivitunniste: rivitunniste,
      kielinro,
      kayttaja_email: user.email,
      kayttaja_nimi: `${user.firstName} ${user.lastName}`,
      selite: kommentti,
      lisattyjonoon
    }
    const url = '/reklamaatioriviloki';
    const response = await frends.axiosInstance.post(url, data);
    return response.data;
  },
  async getLuovutusliitteet(vertexProjektiNro, vertexHuoneNro) {
    const params = {
      vertexprojektinro: vertexProjektiNro,
      vertexhuonenro: vertexHuoneNro,
    };

    const url = '/luovutusliitteet';
    const response = await frends.axiosInstance.get(url, {params});
    return response.data;
  },
  async addLuovutusLiite(vertexProjektiNro, vertexHuoneNro, fileAsDataUrl, filename, type) {
    const url = '/luovutusliite';

    var bodyFormData = new FormData();
    bodyFormData.append("vertexprojektinro", vertexProjektiNro);
    bodyFormData.append("vertexhuonenro", vertexHuoneNro);
    let base64EncodedData = fileAsDataUrl;
    if (fileAsDataUrl.indexOf("data:") === 0) {
      base64EncodedData = fileAsDataUrl.split(",", 2)[1];
    }
    bodyFormData.append("tiedosto", new Blob([base64EncodedData], { type }), filename);

    const response = await frends.axiosInstance.post(url, bodyFormData);
    return response.data;
  },
  async deleteLuovutusLiite(fileId) {
    const url = `/luovutusliite`;
    const params = {
      fileId,
    };
    const response = await frends.axiosInstance.delete(url, { params });
    return response.data;
  },
  async getToimitusEraPakkaukset(puusnetToimEraNro) {
    const url = '/raportit/toimerapakkaukset';
    const params = {
      puusnet_toimeranro: puusnetToimEraNro,
    };

    const response = await frends.axiosInstance.get(url, { params, responseType: 'blob' });
    return response.data;
  },
  async getTyomaaraykset(kielinro, installationGroupIds, projektiid, toimituseraid, alkaenpvm, paattyenpvm, vain_kesken, vastaavaEmail) {
    const url = `/tyomaaraykset?kielinro=${kielinro}`;
    const data = {
      projektiid,
      toimituseraid,
      asennusryhmaid: installationGroupIds,
      alkaenpvm,
      paattyenpvm,
      vain_kesken,
      vastaavaemail: vastaavaEmail,
    };
    const response = await frends.axiosInstance.post(url, data);
    return response.data;
  },
  async getTyomaarays(tyomaaraysid, kielinro) {
    const url = `/tyomaaraykset/${tyomaaraysid}?kielinro=${kielinro}`;
    const response = await frends.axiosInstance.get(url);
    return response.data;
  },
  async getTyomaaraysRivit(tyomaaraysid, kielinro) {
    const params = {
      kielinro,
      tyomaaraysid,
    }
    const url = `/tyomaaraysrivit`;
    const response = await frends.axiosInstance.get(url, { params });
    return response.data;
  },
  async upsertTyomaarays(tyomaarays, kielinro) {
    let url = `/tyomaarays?kielinro=${kielinro}`;
    const data = { ...tyomaarays };
    const response = await frends.axiosInstance.post(url, data);
    return response.data;
  },
  async deleteTyomaarays(tyomaaraysid, kielinro) {
    const params = {
      kielinro,
      tyomaaraysid,
    }
    const url = `/tyomaarays`;
    const response = await frends.axiosInstance.delete(url, { params });
    return response.data;
  },
  async deleteTyomaaraysRivi(tyomaaraysid, rivinro, kielinro) {
    const params = {
      kielinro,
      rivinro,
      tyomaaraysid,
    }
    const url = `/tyomaarays/rivi`;
    const response = await frends.axiosInstance.delete(url, { params });
    return response.data;
  },
  async getTyomaaraysStatukset(kielinro) {
    const url = `/tyomaaraysstatukset?kielinro=${kielinro}`
    const response = await frends.axiosInstance.get(url);
    return response.data;
  },
  async addTyomaaraysRivi(tyomaaraysid, tyomaaraysRivi, kielinro) {
    const params = {
      kielinro,
      tyomaaraysid,
    }
    const url = `/tyomaaraysrivi`;
    const data = { ...tyomaaraysRivi };
    const response = await frends.axiosInstance.post(url, data, { params });
    return response.data;
  },
  async getMaat(kielinro) {
    const params = {
      kielinro,
    };
    const url = '/maat'
    const response = await frends.axiosInstance.get(url, { params });
    return response.data;
  },
  async getAsennusDokumentit(tilausnro, kielinro){
    const url = '/asennusdokumentit';
    const params = {
      tilausnro: tilausnro,
      kielinro: kielinro,
    };
    const response = await frends.axiosInstance.get(url, { params });
    return response.data;
  }
};

export default frendsApi;
