const deliveryLotStatuses = {
  100000000: { icon: 'remove', notReceivedIcon: 'remove', color: 'grey' },
  100000001: { icon: 'remove', notReceivedIcon: 'remove', color: 'grey' },
  100000002: { icon: 'done', notReceivedIcon: 'hourglass_empty', color: 'primary' },
  100000003: { icon: 'done', notReceivedIcon: 'hourglass_empty', color: 'primary' },
  100000004: { icon: 'done', notReceivedIcon: 'hourglass_empty', color: 'primary' },
  100000005: { icon: 'done', notReceivedIcon: 'hourglass_empty', color: 'primary' },
  100000006: { icon: 'done', notReceivedIcon: 'hourglass_empty', color: 'primary' },
  100000007: { icon: 'clear', notReceivedIcon: 'clear', color: 'error' },
  100000009: { icon: 'done_all', notReceivedIcon: 'done_all', color: 'success' },
  111100001: { icon: 'done_all', notReceivedIcon: 'done_all', color: 'success' },
};

const projectStatuses = {
  100000000: { icon: 'forum', color: 'primary' },
  100000001: { icon: 'forum', color: 'primary' },
  100000002: { icon: 'mdi mdi-handshake', color: 'primary' },
  100000003: { icon: 'handyman', color: 'primary' },
  100000004: { icon: 'done', color: 'primary' },
  100000005: { icon: 'delete', color: 'primary' },
  100000006: { icon: 'calculate', color: 'primary' },
  100000007: { icon: 'cancel', color: 'primary' },
}

const roomStatuses = {
  100000000: { icon: 'fas fa-door-open', color: 'grey' },
  100000001: { icon: 'fas fa-circle', color: 'grey' },
  100000002: { icon: 'fas fa-circle', color: 'warning' },
  100000003: { icon: 'fas fa-circle', color: 'success' },
};

const tyomaarainStatuses = {
  'TILATTU': { icon: 'hourglass_empty', color: 'grey' },
  'VARASTO': { icon: 'fas fa-box', color: 'warning' },
  'ASENNUS': { icon: 'handyman', color: 'warning' },
  'VALMIS': { icon: 'done_all', color: 'success' },
}

export default { deliveryLotStatuses, roomStatuses, projectStatuses, tyomaarainStatuses };
