import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './messages.js';

Vue.use(VueI18n);

let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fi';

// Initialize the localization library
const i18n = new VueI18n({
  locale: locale,
  messages,
});

export default i18n;
