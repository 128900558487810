<template>
  <v-list two-line dense class="pt-0 pb-0">
    <v-list-group
      v-if="item.children && item.children.length > 0"
      v-model="item.active"
      append-icon=""
    >
      <template v-slot:activator>
        <v-list-tile>
          <v-list-tile-action>
            <v-icon :style="{
                    display: item.children.findIndex(c => c.visible) > -1 ? 'block' : 'none',
                    paddingLeft: indent * indentValue + 'px',
                    marginRight: -indent * indentValue + 'px'}"
                    :color="item.active ? 'primary' : ''">keyboard_arrow_right</v-icon>
          </v-list-tile-action>
          <unit-list-item-content :item="item" :indent-value="indentValue" :indent="indent"/>
          <v-list-tile-action v-if="$showReclamations" style="min-width: 36px;" @click.stop="addItemToReclamationBasket">
            <div class="add-reclamation-holder">
              <v-btn :disabled="hasReclamation || hasReclamationSync" icon><v-icon size="16">fas fa-exchange-alt</v-icon></v-btn>
              <v-icon v-if="hasReclamation" class="reclamation-added" color="primary" size="16">check_circle</v-icon>
              <v-icon v-if="selectMultiple && !hasReclamation && !item.selected" class="reclamation-added" color="primary" size="16">far fa-square</v-icon>
              <v-icon v-if="selectMultiple && !hasReclamation && item.selected" class="reclamation-added" color="primary" size="16">far fa-check-square</v-icon>
              <v-icon v-if="hasReclamationSync" class="reclamation-added" color="primary" size="16">fas fa-clock</v-icon>
            </div>
          </v-list-tile-action>
        </v-list-tile>
      </template>
      <template v-for="childItem in item.children">
        <unit-list-item :style="{display: childItem.visible ? 'block': 'none'}"
                        :indent="indent + 1"
                        :item="childItem"
                        :key="childItem.niputus"
                        :has-reclamation-sync="childItem.hasReclamationSync"
                        :hasReclamation="childItem.hasReclamation"
                        :select-multiple="selectMultiple"
                        @add-unit-to-reclamation="addChildItemToReclamationBasket(childItem)"
        />
      </template>
    </v-list-group>
      <v-list-tile v-else>
        <v-list-tile-action></v-list-tile-action>
        <unit-list-item-content :indent="indent" :indent-value="indentValue" :item="item" />
        <v-list-tile-action v-if="$showReclamations" style="min-width: 36px;" @click.stop="addItemToReclamationBasket">
          <div class="add-reclamation-holder">
            <v-btn :disabled="!canCreateReclamation" icon>
              <v-icon size="16">fas fa-exchange-alt</v-icon>
            </v-btn>
            <v-icon v-if="hasReclamation" class="reclamation-added" color="primary" size="16">check_circle</v-icon>
            <v-icon v-if="selectMultiple && !hasReclamation && !item.selected" class="reclamation-added" color="primary" size="16">far fa-square</v-icon>
            <v-icon v-if="selectMultiple && !hasReclamation && item.selected" class="reclamation-added" color="primary" size="16">far fa-check-square</v-icon>
            <v-icon v-if="hasReclamationSync" class="reclamation-added" color="primary" size="16">fas fa-clock</v-icon>
          </div>
        </v-list-tile-action>
      </v-list-tile>
  </v-list>
</template>

<script>
  import UnitListItemContent from "./UnitListItemContent.vue";

  export default {
    name: "UnitListItem",
    components: {
      UnitListItemContent: UnitListItemContent,
    },
    props: {
      selectMultiple: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () =>  {},
      },
      indent: {
        type: Number,
        default: 0,
      },
      hasReclamationSync: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        indentValue: 15,
      }
    },
    computed: {
      canCreateReclamation() {
        return !this.item.hasReclamation && this.item.saa_reklamoida === 'true' && !this.hasReclamationSync;
      },
      hasReclamation() {
        return this.item.reklamoitu === 'true';
      }
    },
    methods: {
      addItemToReclamationBasket() {
        if (this.selectMultiple) {
          this.$set(this.item, 'selected', !!!this.item.selected);
        } else {
          this.$emit('add-unit-to-reclamation', this.item);
        }
      },
      addChildItemToReclamationBasket(unit) {
        if (this.selectMultiple) {
          this.$set(unit, 'selected', true);
        } else {
          this.$emit('add-unit-to-reclamation', unit);
        }
      },
      dismissDialog() {
        this.renderDialog = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-reclamation-holder {
    position: relative;

    .reclamation-added {
      position: absolute;
      bottom: -5px;
      right: -5px;
    }
  }
</style>
