import db from "@/dexie";
import frends from "./frends";

const databaseEnabledPromise = new Promise((resolve, reject) => {
  const db = window.indexedDB.open('test');
  db.onerror = function () {
    console.log('database not enabled');
    resolve(false);
  }

  db.onsuccess = function () {
    console.log('database enabled');
    resolve(true);
  }
});

export { databaseEnabledPromise };

export default {
  async setSettings(accessToken, baseUrl) {
    await db.session.put({ id: 1, accessToken, baseUrl });
    return 1;
  },
  async getSettings() {
    return db.session.get(1);
  },
  async putReclamation(projektinro, projekti_nimi, huonenro, huonenimi, tuote, rivinro, tilausnro,
    maara, rekl_tyyppi, rekl_selite, rekl_rivitunniste, kielinro, uuden_toimitus, vastaanottaja, vuositarkastus, kayttaja_nimi, kayttaja_email, kuvat) {

    const id = new Date().toISOString();
    const data = {
      id,
      projektinro,
      projekti_nimi,
      huonenro,
      huonenimi,
      tuote,
      rivinro,
      tilausnro,
      maara,
      rekl_tyyppi,
      rekl_selite,
      rekl_rivitunniste,
      kielinro,
      uuden_toimitus,
      vastaanottaja,
      kayttaja_email,
      kayttaja_nimi,
      kuvat,
      vuositarkastus,
    };

    await db.reclamations.put(data);

    return id;
  },
  async putReclamationAttachment(projekti_nimi, huonenimi, tuote, rekl_rivitunniste, nimi, tyyppi, kuva ) {
    const id = new Date().toISOString();
    const data = {
      id,
      projekti_nimi,
      huonenimi,
      tuote,
      rekl_rivitunniste,
      nimi,
      tyyppi,
      data: kuva,
    };

    await db.attachments.put(data);

    return id;
  },
  async getReclamationAttachments() {
    return db.attachments.toArray();
  },
  async getReclamationAttachment(id) {
    return db.attachments.get(id);
  },
  async deleteReclamationAttachment(id) {
    db.attachments.delete(id);
  },
  async getReclamations() {
    return db.reclamations.toArray();
  },
  async getReclamation(id) {
    return db.reclamations.get(id);
  },
  async deleteReclamation(id) {
    await db.reclamations.delete(id);
  },
  async getLuovutusLiite(id) {
    return db.room_images.get(id);
  },
  async deleteLuovutusLiite(id) {
    await db.room_images.delete(id);
  },
  async putLuovutusLiite(vertexProjectNumber, projectName, vertexRoomNumber, roomName, fileDataUrl, name, type) {
    const id = new Date().toISOString();
    const data = {
      id,
      projektinro: vertexProjectNumber,
      projekti_nimi: projectName,
      huonenro: vertexRoomNumber,
      huonenimi: roomName,
      data: fileDataUrl,
      nimi: name,
      tyyppi: type,
    }

    await db.room_images.put(data);

    return id;
  },
  async getLuovutusLiitteet() {
    return db.room_images.toArray();
  },
  async putStatusChange(projectName, apartmentId, apartmentName, statusCode, handoverComment) {
    const id = new Date().toISOString();
    const data = {
      id,
      projekti_nimi: projectName,
      apartmentId: apartmentId,
      huonenimi: apartmentName,
      statusCode: statusCode,
      handoverComment: handoverComment,
    };

    await db.statuses.put(data);

    return id;
  },
  async getStatusChanges() {
    return db.statuses.toArray();
  },
  async deleteStatusChange(id) {
    return db.statuses.delete(id);
  },
  async getStatusChange(id) {
    return db.statuses.get(id);
  },
  async putReclamationComment(rivitunniste, kielinro, user, kommentti, lisattyjonoon, projektinimi, huonenimi) {
    const id = new Date().toISOString();
    const data = {
      id,
      rekl_rivitunniste: rivitunniste,
      kielinro,
      user,
      selite: kommentti,
      lisattyjonoon,
      projekti_nimi: projektinimi,
      huonenimi
    }

    await db.comments.put(data);

    return id;
  },
  async getReclamationComments() {
    return db.comments.toArray();
  },
  async getReclamationComment(id) {
    return db.comments.get(id);
  },
  async deleteReclamationComment(id) {
    return db.comments.delete(id);
  },
  async itemCount() {
    const counts = await Promise.all([
    db.statuses.count(),
    db.room_images.count(),
    db.attachments.count(),
    db.reclamations.count(),
    db.comments.count()]);
    return counts.reduce((a, b) => a + b, 0);
  },
  async getLatestAddition() {
    const allLasts = await Promise.all([
      db.statuses.orderBy('id').last(),
      db.room_images.orderBy('id').last(),
      db.attachments.orderBy('id').last(),
      db.reclamations.orderBy('id').last(),
      db.comments.orderBy('id').last()]
    );
    const cleaned = allLasts.filter(al => al != null).map(al => al.id);
    cleaned.sort();

    const ret = {
      latestUnsynced: null,
      oldestUnsynced: null,
    }

    if (cleaned.length !== 0) {
      ret.oldestUnsynced = cleaned[0];
      ret.latestUnsynced = cleaned[cleaned.length - 1];
    }
    return ret;
  }
};
