<template>
  <v-container v-if="$showTyomaaraykset" fluid fill-height ma-0 pa-0 class="tyomaaraykset">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                       :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}"
                       color="primary lighten-2" height="3" v-show="subtleLoading"
                       :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <hb-back-button fallback="calendar"/>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
            :placeholder="$t('tyomaaraykset.search_for_tyomaaraykset')"
            v-model="filter.searchPhrase"
            @focus="$selectInputValue"
            class="title text-uppercase topbar-search"
            style="font-size: 20px;"
            single-line
            full-width
            hide-details
            color="red lighten-3"
        >
          <v-icon style="cursor: pointer;" slot="append">search</v-icon>
          <v-icon @click="showFilterPanel = !showFilterPanel" style="cursor: pointer;"
                  slot="append-outer">filter_list
          </v-icon>
        </v-text-field>
      </v-toolbar-title>
    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle/>

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap v-scroll.body="onScroll">
        <v-flex xs12 class="tyomaaraykset-holder">
          <v-container fluid grid-list-md v-if="tyomaaraykset.length === 0">
            <template v-if="filteredProjects.length > 0">
              <v-layout row wrap v-if="!loadingTyomaaraykset && tyomaaraykset.length === 0">
                <v-flex xs12>
                  <v-alert :value="selectedProject" color="grey" icon="warning">
                    <div>{{ $t('tyomaaraykset.not_found_for_project') }}</div>
                  </v-alert>
                  <v-alert :value="!selectedProject" type="info" outline>
                    <div>{{ $t('tyomaaraykset.more_filter_settings1') }}
                      <v-icon>filter_list</v-icon>
                      {{ $t('tyomaaraykset.more_filter_settings2') }}
                    </div>
                  </v-alert>
                </v-flex>
              </v-layout>
              <v-layout v-if="loadingTyomaaraykset" row wrap class="mt-5 pt-5">
                <v-flex xs12>
                  <hb-loading-indicator fill-height align-middle/>
                </v-flex>
              </v-layout>
            </template>
            <template v-if="filteredProjects.length === 0 && !loadingProjects">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-alert :value="true" color="grey" icon="warning">
                    <div>{{ $t('tyomaaraykset.no_projects_found') }}</div>
                    <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}"
                           color="white">
                      <v-icon left size="20">people_outline</v-icon>
                      {{ $t('installation_groups.title') }}
                    </v-btn>
                  </v-alert>
                </v-flex>
              </v-layout>
            </template>
          </v-container>
          <v-list v-if="!loadingTyomaaraykset && tyomaaraykset.length > 0" three-line class="py-0">
            <recycle-scroller ref="scroller" :item-size="$vuetify.breakpoint.mdAndUp ? 89 : 120"
                              :items="filteredTyomaaraykset"
                              class="scroller tyomaaraykset-list fixed-height-content"
                              key-field="tyomaaraysid"
                              @scroll.native="onScroll">
              <template v-slot="{ item }">
                <div>
                  <tyomaarays-list-item :tyomaarays="item" @row-clicked="goToTyomaarays"/>
                  <v-divider></v-divider>
                </div>
              </template>
            </recycle-scroller>
          </v-list>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-filter-drawer v-model="showFilterPanel" style="z-index: 10; overflow: visible">
      <v-subheader class="subheading pa-0">{{ $t('tyomaaraykset.filter_results') }}:</v-subheader>
      <v-layout row wrap>
        <v-flex xs12>
          <v-combobox
              v-model="filter.selectedProject"
              :items="filteredProjects"
              item-text="clientName"
              light
              class="my-3 no-wrap-on-list"
              attach
              hide-details
              :loading="loadingProjects"
              :menu-props="{left: true}"
              @change="selectedProjectChanged"
              :label="$t('tyomaaraykset.select_project')"
              return-object
              clearable
          />
          <v-combobox
              v-if="tyomaaraykset.length > 0"
              v-model="filter.selectedDeliveryLots"
              :items="filteredDeliveryLots"
              :disabled="filteredDeliveryLots.length === 0"
              :placeholder="filteredDeliveryLots.length === 0 ? $t('tyomaaraykset.no_data') : ''"
              item-text="name"
              return-object
              :loading="subtleLoading"
              clearable
              class="my-3 no-wrap-on-list"
              light
              attach
              hide-details
              :menu-props="{left: true}"
              :label="$t('tyomaaraykset.select_delivery_lot')"
          />
          <v-combobox
              v-if="tyomaaraykset.length > 0"
              v-model="filter.selectedInstallationGroup"
              :items="installationGroups"
              :disabled="installationGroups.length === 0"
              :placeholder="installationGroups.length === 0 ? $t('tyomaaraykset.no_data') : ''"
              item-text="name"
              :loading="subtleLoading"
              clearable
              class="my-3 no-wrap-on-list"
              light
              attach
              hide-details
              :menu-props="{left: true}"
              :label="$t('tyomaaraykset.select_installation_group')"
          />
          <v-checkbox
              v-if="$isPuustelliUser"
              v-model="filter.own"
              :label="$t('calendar.filter.own')"
              :disabled="loadingTyomaaraykset"
              @change="handleFilterChange"
              hide-details
          ></v-checkbox>
          <v-checkbox
              v-if="$isPuustelliUser"
              v-model="filter.underWork"
              :disabled="loadingTyomaaraykset"
              @change="handleFilterChange"
              :label="$t('tyomaaraykset.filter_only_under_work')"
              hide-details
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </hb-filter-drawer>

  </v-container>
</template>

<script>
import frendsApi from '../frendsApi.js';
import TyomaaraysListItem from "@/components/TyomaarayksetView/TyomaaraysListItem";

export default {
  name: "TyomaarayksetView",
  components: {
    TyomaaraysListItem,
  },
  props: {
    projectId: {
      type: [String, Boolean],
      default: false,
    },
    deliveryLotId: {
      type: String,
      default: null,
    },
    // TODO: asennusryhmällä pitää myös filtteröidä
    vertexProjectNumber: {
      type: String,
      default: '',
    },
    resetFilters: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      showFilterPanel: false,
      loadingProjects: false,
      loadingTyomaaraykset: false,
      tyomaaraykset: [],
      tyomaarayksetProject: null,
      statusColor: [],
      scrolled: false,
    }
  },
  methods: {
    handleFilterChange() {
      this.reloadTyomaaraykset(this.filter.selectedProject?.id);
    },
    onScroll(e) {
      this.scrolled = true;
      this.$store.state.tyomaarayksetView.scrollY = {
        y: window.scrollY
      };
    },
    async reloadProjectsIfNeeded() {
      if (this.projects == null || this.projects.length === 0) {
        return this.reloadProjects();
      }
    },
    goToTyomaarays(tyomaarays) {
      this.$router.push({name: 'tyomaarays', params: {tyomaaraysid: tyomaarays.tyomaaraysid + ''}});
    },
    async selectedProjectChanged() {
      const proj = this.selectedProject;
      this.filter.selectedDeliveryLots = null;
      //await this.reloadTyomaaraykset(proj ? proj.id : undefined);
      if (proj) {
        await this.reloadProject(this.selectedProject.id);
      }
    },
    async resetFilter(reload = true) {
      this.subtleLoading = true;
      this.filter.selectedProject = null;
      this.tyomaaraykset = [];
      this.tyomaarayksetProject = null;
      this.filter.selectedDeliveryLots = null;
      if (reload) {
        await this.reloadTyomaaraykset();
      }

      this.subtleLoading = false;
    },
    async reloadProjects() {
      this.loadingProjects = true;
      await this.$store.dispatch('reloadProjects');
      this.loadingProjects = false;
    },
    async reloadTyomaaraykset(projectid) {
      this.loadingTyomaaraykset = true;
      this.subtleLoading = true;
      try {
        const installationGroupIds = this.installationGroupIds;

        let vastaavaEmail;
        if (this.filter.own) {
          vastaavaEmail = this.$store.state.auth.jehuUser.email;
        }

        const tyomaaraykset = await frendsApi.getTyomaaraykset(this.$kielinro, installationGroupIds, projectid, null, null, null, this.filter.underWork, vastaavaEmail);
        this.tyomaarayksetProject = projectid;
        this.tyomaaraykset = tyomaaraykset;
        this.$store.state.tyomaarayksetView.tyomaaraykset = tyomaaraykset;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('tyomaaraykset.failed_to_fetch'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadTyomaaraykset(id);
              }
            });
      }
      this.subtleLoading = false;
      this.loadingTyomaaraykset = false;
    },
    async reloadProject(id) {
      if (this.project && this.project.project.id === id) {
        this.project = this.$store.state.projectDTO;
      } else {
        this.loading = true;
        await this.$store.dispatch('reloadProjectDTO', id);
        this.project = this.$store.state.projectDTO;
      }

      await this.reloadTyomaaraykset(id);

      this.loading = false;
    },
    resetScroll() {
      if (this.$store.state.tyomaarayksetView.scrollY != null) {
        const scrollY = this.$store.state.tyomaarayksetView.scrollY;
        this.$vuetify.goTo(scrollY.y, {
          duration: 200,
          easing: 'easeInOutCubic',
        })
      } else {
        this.$vuetify.goTo(0, {
          duration: 0,
          offset: 0,
        });
      }
    },
    tyomaaraysProject(tyomaarays) {
      let pid = tyomaarays.d365projektiid;
      if (pid.startsWith('{')) {
        pid = pid.substring(1, tyomaarays.d365projektiid.length - 1).toLowerCase();
      }
      const p = this.projects.find(p => p.id === pid);
      return p || {fullDescription: ''};
    },
  },
  computed: {
    selectedDeliveryLotsTitle() {
      return this.selectedDeliveryLots.map(dl => dl.name).join(', ');
    },
    searchPhrase() {
      return this.filter.searchPhrase;
    },
    selectedProject() {
      return this.filter.selectedProject;
    },
    selectedDeliveryLots() {
      return this.filter.selectedDeliveryLots;
    },
    selectedInstallationGroup() {
      return this.filter.selectedInstallationGroup;
    },
    projects() {
      return this.$store.state.projects;
    },
    project: {
      get() {
        return this.$store.state.tyomaarayksetView.project;
      },
      set(val) {
        this.$store.state.tyomaarayksetView.project = val;
      }
    },
    filter() {
      return this.$store.state.tyomaarayksetView.tyomaarayksetFilters;
    },
    showToolbarExtension() {
      return this.tyomaaraykset.length > 0;
    },
    filteredDeliveryLots() {
      if (!this.project) return [];
      const deliveryLots = [];

      if (this.project.deliveryLots) {
        this.project.deliveryLots.forEach(deliveryLot => {
          const deliveryLotIndex = this.tyomaaraykset.findIndex(tm => this.$removeCurlyBrackets(tm.d365toimituseraid) === deliveryLot.id);
          if (deliveryLotIndex > -1) {
            deliveryLots.push(deliveryLot);
          }
        });
      }

      return deliveryLots;
    },
    filteredProjects() {
      let projects = this.projects;

      return projects.sort((a, b) => {
        a = a.clientName.toLowerCase();
        b = b.clientName.toLowerCase();
        return b > a ? -1 : b < a ? 1 : 0;
      });
    },
    statusFilteredTyomaaraykset() {
      let tms = [...this.tyomaaraykset];
      if (this.selectedDeliveryLots) {
        tms = tms.filter(tm => this.selectedDeliveryLots.id === this.$removeCurlyBrackets(tm.d365toimituseraid));
      }
      if (this.selectedInstallationGroup) {
        tms = tms.filter(tm => this.selectedInstallationGroup.id === tm.asennusryhmaid);
      }
      return tms;
    },
    filteredTyomaaraykset() {
      if (!this.searchPhrase) {
        return this.statusFilteredTyomaaraykset;
      }
      const tms = this.statusFilteredTyomaaraykset;
      const searchPhraseLower = this.searchPhrase.trim().toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(" ");
      return tms.filter(tm => {
        const lowerSearchedString =
            (this.tyomaaraysProject(tm).fullDescription ? this.tyomaaraysProject(tm).fullDescription.toLowerCase() : '') +
            (tm.d365toimituseranimi ? tm.d365toimituseranimi.toLowerCase() : '') +
            (tm.asennusryhmanimi ? tm.asennusryhmanimi.toLowerCase() : '') +
            (tm.kuvaus ? tm.kuvaus.toLowerCase() : '') +
            (tm.lisatieto ? tm.lisatieto.toLowerCase() : '') +
            (tm.nimi ? tm.nimi.toLowerCase() : '') +
            (tm.tyomaaraysid ? tm.tyomaaraysid + "" : '');
        return searchPhraseArray.some(p => lowerSearchedString.includes(p));
      });
    },
    installationGroups() {
      return this.$store.state.installationGroups.filter(g => g.selected);
    },
    installationGroupIds() {
      return this.installationGroups.map(group => group.id);
    }
  },
  watch: {
    projectId(val) {
      if (!val) {
        this.resetFilter();
      }
    },
    selectedProject(val) {
      if (!val) {
        this.resetFilter();
      }
    }
  },
  async mounted() {
    if (this.resetFilters) {
      this.$store.dispatch('resetTyomaarayksetFilters');
    }

    await this.reloadProjectsIfNeeded();
    if (this.projectId) {
      if (this.selectedProject != null && this.selectedProject.id === this.projectId) {
        // Jos on tultu esim. back napilla ja valittu projekti on jo asetettuna
        this.tyomaaraykset = this.$store.state.tyomaarayksetView.tyomaaraykset;
        if (this.project && this.project.project.id !== this.projectId) {
          await this.reloadProject(this.projectId);
        }
      } else { // Asetetaan valittu projekti listasta parametrin perusteella
        this.$store.state.tyomaarayksetView.scrollY = null;
        const proj = this.projects.find(p => p.id === this.projectId);
        if (proj !== null) {
          await this.reloadProject(proj.id);
          this.filter.selectedProject = proj; // Muutos laukaisee watcherista latauksen
        }
      }
    } else {
      this.$store.state.tyomaarayksetView.scrollY = null;
      this.resetFilter(false);
    }

    if (this.deliveryLotId) {
      const deliveryLot = this.project.deliveryLots.find(dl => dl.id === this.deliveryLotId);
      if (deliveryLot != null) {
        this.filter.selectedDeliveryLots = deliveryLot
      }
    }

    await this.reloadTyomaaraykset();

    this.loading = false;

    this.resetScroll();
  }
}
</script>

<style lang="scss" scoped>

.show-system-bar-when-scrolling {
  position: fixed;
  background-color: white;
  z-index: 1;
  width: 100%
}

</style>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .fixed-height-content {
    margin-bottom: 0;
    height: calc(100vh - 112px);
  }

  .tyomaaraykset-list {
    .v-list__tile {
      height: 120px !important;
      padding-top: 8px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .fixed-height-content {
    margin-bottom: 0;
    height: calc(100vh - 112px);
  }
  .tyomaaraykset-list {
    .v-list__tile {
      height: 120px !important;
      padding-top: 8px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 960px) {
  .fixed-height-content {
    margin-bottom: 0;
    height: calc(100vh - 120px);
  }
  .tyomaaraykset-list {
    .v-list__tile {
      height: 86px !important;
      padding-top: 8px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 1265px) {
  .fixed-height-content {
    margin-bottom: 0;
    height: calc(100vh - 64px);
  }
}

@media only screen and (min-width: 1300px) {
  .fixed-height-content {
    margin-bottom: 0;
    height: calc(100vh - 64px);
  }
}

.no-wrap-on-list {
  .v-list__tile__title {
    height: auto !important;
    white-space: initial;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .v-list__tile {
    height: 100% !important;
  }
}

.topbar-search {
  .v-input__slot {
    padding: 0 !important;
  }
}

.tyomaaraykset {
  .v-toolbar__extension {
    background-color: #b30000 !important;
    border-color: #b30000 !important;
  }

  @media screen and (min-width: 1264px) {
    .v-toolbar__extension {
      background-color: #fff !important;
      border-color: #fff !important;
    }
  }
  /*
    ei väriä (0)
    Rakennusliikkeen hyväksyntää odottavat (marianne) (1)
    Myymälän käsittelyssä (punainen) (2)
    Tuotannossa (oranssi) (3)
    Toimitettu (keltainen) (4)
    Asennetut (vihreä) (5)
   */
  .status-badge {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: 3px;
  }

  .status-color-0 {
    .status-badge {
      background: #ddd;
    }
  }

  .status-color-1 {
    background: #E8EAF6;

    .status-badge {
      background: #3F51B5;
    }
  }

  .status-color-2 {
    background: #FFEBEE;

    .status-badge {
      background: #F44336;
    }
  }

  .status-color-3 {
    background: #FFF3E0;

    .status-badge {
      background: #FF9800;
    }
  }

  .status-color-4 {
    background: #FFFDE7;

    .status-badge {
      background: #FFEB3B;
    }
  }

  .status-color-5 {
    background: #E8F5E9;

    .status-badge {
      background: #4CAF50;
    }
  }
}

</style>
