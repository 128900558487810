import { render, staticRenderFns } from "./TyomaarayksetView.vue?vue&type=template&id=12c0c8be&scoped=true"
import script from "./TyomaarayksetView.vue?vue&type=script&lang=js"
export * from "./TyomaarayksetView.vue?vue&type=script&lang=js"
import style0 from "./TyomaarayksetView.vue?vue&type=style&index=0&id=12c0c8be&prod&lang=scss&scoped=true"
import style1 from "./TyomaarayksetView.vue?vue&type=style&index=1&id=12c0c8be&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c0c8be",
  null
  
)

export default component.exports