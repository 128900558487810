/* eslint-disable no-param-reassign */
import Vue from 'vue';
import moment from "moment";

import config from "../config";
import store from "../store";


const EnvPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $showReclamations() {
            return store.state.auth.jehuUser.reclamationsEnabled;
        },
        $showTyomaaraykset() {
          // TODO: tuleeko tähän jotain
          return true;
        },
      },
      methods: {
        $isEnv(anyOf) {
          return anyOf.indexOf(ENV_DESCRIPTION) !== -1
        },
      }
    });
  },
};

export default EnvPlugin;
