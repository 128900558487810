<template>
  <v-dialog v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ this.isNew ? $t('tyomaaraykset.create') : $t('tyomaaraykset.edit') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-content"  id="dialogContainer">
          <div class="title mb-3 font-weight-regular">{{ $t('tyomaarays.basic_info')}}</div>
          <v-form ref="form">
            <v-text-field :disabled="!($isPuustelliUser || $isAdmin)" :label="$t('name')" v-model="tyomaarays.nimi" :rules="[$rules.required]"></v-text-field>
            <v-text-field :disabled="!($isPuustelliUser || $isAdmin)" :label="$t('tyomaaraykset.extra_info')" v-model="tyomaarays.lisatieto"></v-text-field>
            <v-textarea :disabled="!($isPuustelliUser || $isAdmin)" :label="$t('tyomaaraykset.description')" v-model="tyomaarays.kuvaus" @focus="scrollToThis" />
            <hb-date-picker
              :label="$t('tyomaaraykset.tyopvm')"
              v-model="tyomaarays.tyopvm"
              :disabled="!($isPuustelliUser || $isAdmin || $isTeamLeader)"
              prepend-icon="event"
              :rules="[$rules.required]"
            />
            <v-autocomplete
                v-if="$isPuustelliUser || $isAdmin"
              :label="$t('tyomaaraykset.delivery_lot')"
              :disabled="!($isPuustelliUser || $isAdmin)"
              :items="deliveryLots"
              :item-text="item => item.name"
              :item-value="item => item.id"
              v-model="tyomaarays.d365toimituseraid"
              clearable
              @focus="scrollToThis"
            />
            <v-text-field v-else :label="$t('tyomaaraykset.delivery_lot')" disabled v-model="tyomaarays.d365toimituseranimi"></v-text-field>
            <v-autocomplete
              :label="$t('tyomaaraykset.installation_group')"
              :disabled="!($isPuustelliUser || $isAdmin)"
              :items="installationGroups"
              :item-text="item => item.name"
              :item-value="item => item.id"
              v-model="tyomaarays.asennusryhmaid"
              :rules="[$rules.required]"
              clearable
              @focus="scrollToThis"
            />

            <!-- asiakkaan yhteyshenkilö -->
            <v-autocomplete
              :loading="loadingUsers"
                v-if="puustelliContactPersons.length > 0"
                :label="$t('tyomaaraykset.puustelli_responsible')"
                :items="puustelliContactPersons"
                item-text="name"
                item-value="email"
                :disabled="!$isPuustelliUser && !$isAdmin"
                v-model="puustellivastaava"
            />
            <v-combobox v-if="$isPuustelliUser ||$isAdmin"
              :label="$t('tyomaaraykset.customer_contact_person')"
              :items="contactPersons"
              item-text="name"
              return-object

              v-model="asiakasyhteyshenkilo"
              @focus="scrollToThis"
            />
            <v-text-field v-else :label="$t('tyomaaraykset.customer_contact_person')" disabled v-model="tyomaarays.asiakasyhteyshenkilo"></v-text-field>

            <template v-if="$isPuustelliUser || $isAdmin">
              <div class="title mb-3 mt-3 font-weight-regular">{{ $t('tyomaarays.billing_info')}}</div>
              <v-text-field :label="$t('tyomaarays.laskutusasiakas')" v-model="tyomaarays.laskutusasiakas"></v-text-field>
              <v-select :items="countries" item-text="selite" item-value="koodi" clearable :placeholder="$t('tyomaarays.laskutusmaanro')" v-model="tyomaarays.laskutusmaanro"></v-select>
              <v-text-field :label="$t('tyomaarays.laskutusosoite')" v-model="tyomaarays.laskutusosoite"></v-text-field>
              <v-text-field :label="$t('tyomaarays.laskutuspostinro')" v-model="tyomaarays.laskutuspostinro"></v-text-field>
              <v-text-field :label="$t('tyomaarays.laskutuspostitoimp')" v-model="tyomaarays.laskutuspostitoimp"></v-text-field>
            </template>
          </v-form>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else>{{ $t('cancel') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="primary"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              :loading="loading || loadingUsers"
              @click="save"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{ $t('tyomaarays.save') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import frends from '../../frendsApi';
import FileCard from "@/components/FileCard";
import backgroundSendingService from "@/backgroundSendingService";
import UiEventBus from "@/UiEventBus";
import frendsApi from "../../frendsApi";
import HbDatePicker from "../HbDatePicker.vue";
import api from "@/api";

export default {
  name: "EditTyomaaraysDialog",
  components: {HbDatePicker},
  props: {
    projectDTO: {
      type: Object,
      default: () => {}
    },
    deliveryLot: {
      type: Object,
      default: () => {}
    },
    reclamationRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      loadingUsers: true,
      dialog: false,
      isNew: false,
      tyomaarays: {},
      asiakasyhteyshenkilo: null,
      puustellivastaava: null,
      puustelliContactPersons: [],
      newProductDeliveryOptions: [
        { value: this.$t('reclamation.new_product_delivery_options.1'), text: this.$t('reclamation.new_product_delivery_options.1') },
        { value: this.$t('reclamation.new_product_delivery_options.2'), text: this.$t('reclamation.new_product_delivery_options.2') },
        { value: this.$t('reclamation.new_product_delivery_options.3'), text: this.$t('reclamation.new_product_delivery_options.3') },
        { value: this.$t('reclamation.new_product_delivery_options.4'), text: this.$t('reclamation.new_product_delivery_options.4') },
      ],
    }
  },
  watch: {
    dialog(newValue, oldValue) {
      if (!newValue) {
        this.$refs.form.reset();
        this.loadingUsers = true;
        this.$nextTick(() => {
          this.tyomaarays = {};
          this.puustellivastaava = null;
          this.asiakasyhteyshenkilo = null;
        });
      }
    },
    asiakasyhteyshenkilo(to) {
      if (!this.$isAdmin && !this.$isPuustelliUser) {

      }
      console.log('watch asiakasyhteyshenkilo', to);
      if (!to) {
        this.tyomaarays.asiakasyhteyshenkilo = null;
        this.tyomaarays.asiakasyhteyshenkiloemail = null;
      } else if (to.name) {
        this.tyomaarays.asiakasyhteyshenkilo = to.name;
        this.tyomaarays.asiakasyhteyshenkiloemail = to.email;
      } else {
        this.tyomaarays.asiakasyhteyshenkilo = to;
        this.tyomaarays.asiakasyhteyshenkiloemail = null;
      }
    }
  },
  computed: {
    countries() {
      return this.$store.state.countries;
    },
    deliveryLots() {
      return this.projectDTO?.deliveryLots || [];
    },
    installationGroups() {
      return this.$store.state.installationGroups;
    },
    contactPersons() {
      return this.projectDTO?.contactPersons || [];
    },
  },
  methods: {
    scrollToThis(event) {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          const opts = {
            offset: 50,
            duration: 100,
            container: '#dialogContainer'
          };
          this.$vuetify.goTo(event.target, opts);
        }, 200);
      }
    },
    open(tyomaarays) {
      this.dialog = true;

      if (this.$isPuustelliUser || this.$isAdmin) {
        api.getSystemUsers().then(users => {
          this.loadingUsers = false;
          this.puustelliContactPersons = _.sortBy(users, 'name').filter(f => !f.name.startsWith('#')).filter(f => f.email);

          if (this.tyomaarays.puustellivastaavaemail) {
            const puustelliCP = this.puustelliContactPersons.find(u => u.email.toLowerCase() === this.tyomaarays.puustellivastaavaemail.toLowerCase());
            if (puustelliCP) {
              this.puustellivastaava = puustelliCP.email;
            }
          }
        })
      } else {
        this.loadingUsers = false;
        this.puustelliContactPersons = [
          {
            name: tyomaarays.puustellivastaava,
            email: tyomaarays.puustellivastaavaemail,
          }
        ]

        this.puustellivastaava = this.tyomaarays.puustellivastaavaemail;
      }

      this.tyomaarays = { tyopvm: null, ...tyomaarays };
      if (this.tyomaarays.d365toimituseraid && this.tyomaarays.d365toimituseraid.startsWith('{')) {
        this.tyomaarays.d365toimituseraid = this.tyomaarays.d365toimituseraid.substring(1, this.tyomaarays.d365toimituseraid.length - 1).toLowerCase();
      }

      if (this.tyomaarays.asiakasyhteyshenkiloemail) {
        const cp = this.contactPersons.filter(p => p.email).find(p => p.email.toLowerCase() === this.tyomaarays.asiakasyhteyshenkiloemail.toLowerCase());
        if (cp) {
          this.asiakasyhteyshenkilo = cp;
        } else {
          const newCp = { email: this.tyomaarays.asiakasyhteyshenkiloemail, name: this.tyomaarays.asiakasyhteyshenkilo };
          this.asiakasyhteyshenkilo = newCp;
        }
      } else if (this.tyomaarays.asiakasyhteyshenkilo) {
        this.asiakasyhteyshenkilo = { email: this.tyomaarays.asiakasyhteyshenkiloemail, name: this.tyomaarays.asiakasyhteyshenkilo };
      } else {
        this.asiakasyhteyshenkilo = null;
      }



      this.isNew = !this.tyomaarays.tyomaaraysid;
      this.loading = false;
    },
    dismissDialog() {
      this.dialog = false;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.projectDTO && this.projectDTO.project) {
        this.tyomaarays.d365projektiid = this.projectDTO.project.id;
      }

      this.tyomaarays.asennusryhmanimi = this.installationGroups.find(g => g.id === this.tyomaarays.asennusryhmaid).name;

      if (this.$isAdmin || this.$isPuustelliUser) {
        if (this.puustellivastaava) {
          const vastaava = this.puustelliContactPersons.find(p => p.email === this.puustellivastaava);
          this.tyomaarays.puustellivastaava = vastaava.name;
          this.tyomaarays.puustellivastaavaemail = vastaava.email;
        }
      }

      this.loading = true;

      try {
        const response = await frendsApi.upsertTyomaarays(this.tyomaarays, this.$kielinro);
        this.$emit('tyomaarays-saved', this.tyomaarays);
        this.tyomaarays = response;
        if (this.reclamationRows && this.reclamationRows.length > 0) {
          for (const r of this.reclamationRows) {
            const tyomaaraysRivi = {
              rekl_rivitunniste: r.rekl_rivitunniste,
              selite: r.rekl_selite,
              tilausnro: r.rekl_tilausnro,
            };
            await frendsApi.addTyomaaraysRivi(this.tyomaarays.tyomaaraysid, tyomaaraysRivi, this.$kielinro);
          }
        }
        if (this.$route.name !== 'tyomaarays') {
          this.$router.push({
            name: 'tyomaarays',
            params: {tyomaaraysid: this.tyomaarays.tyomaaraysid + ''}
          });
        }
        this.dismissDialog();
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
  async mounted() {
    this.$store.dispatch('reloadTyomaaraysStatukset', { kielinro: this.$kielinro });
  },
}
</script>

<style lang="scss" scoped>
.text-content {
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.text-details {
  font-size: 13px;
  color: rgba(0,0,0,.54);
}

@media screen and (min-width: 600px) {
  .text-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }

  .text-details {
    font-size: 14px;
  }
}
</style>
