<template>
  <v-list-tile
    class="tyomaarays-list"
    :class="'status-color-' + tyomaarays.rekl_statusvari"
    avatar
    :style="{opacity: parseInt(tyomaarays.rekl_statusnro, 10) === 9 ? '0.65' : '1'}"
  >
    <v-list-tile-content style="cursor: pointer;" @click="tyomaaraysClicked">
      <v-list-tile-title class="font-weight-bold">{{tyomaarays.tyomaaraysid}}: {{ tyomaarays.nimi }}</v-list-tile-title>
      <v-list-tile-sub-title class="caption text--primary">
        <v-layout row wrap>
          <v-flex xs12 md4>
            <strong>{{ $t('tyomaaraykset.project') }}:</strong> {{ tyomaarays.d365projektinimi }}
            <br />
            <strong>{{ $t('tyomaaraykset.delivery_lot') }}:</strong> {{ tyomaarays.d365toimituseranimi }}
          </v-flex>
          <v-flex xs12 md4><strong>{{ $t('tyomaaraykset.installation_group') }}:</strong> {{ tyomaarays.asennusryhmanimi }}</v-flex>
          <v-flex xs12 md4 v-if="tyomaarays.tyopvm"><strong>{{ $t('tyomaaraykset.tyopvm') }}:</strong> {{$formatDateIncludeWeekday(tyomaarays.tyopvm) }}</v-flex>
        </v-layout>
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
export default {
  props: ['tyomaarays'],
  name: "TyomaaraysListItem",
  methods: {
    tyomaaraysClicked() {
      this.$emit('row-clicked', this.tyomaarays);
    }
  },
}
</script>

<style scoped>

</style>
