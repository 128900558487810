<template>
  <v-list-tile
    avatar
    dark
    style="border-radius: 100px"
    class="calendar-custom-tile grey darken-2"
    :to="{name: 'tyomaarays', params: { tyomaaraysid: tyomaarays.tyomaaraysid + '' }}"
  >
    <v-list-tile-content>
      <v-list-tile-title class="grey--text text--lighten-3"
                         :class="{subheading: $vuetify.breakpoint.mdAndUp, caption: $vuetify.breakpoint.smAndDown}">
        {{ $formatDateNoTime(tyomaarays.tyopvm) }} &mdash; {{ tyomaarays.tyomaaraysid }}: {{ tyomaarays.nimi }} <template v-if="tyomaarays.lisatieto">({{ tyomaarays.lisatieto }})</template>
      </v-list-tile-title>
      <v-list-tile-sub-title class="grey--text text--lighten-2" v-if="tyomaarays.d365projektinimi || tyomaarays.d365toimituseranimi || tyomaarays.asiakasyhteyshenkilo"
                             :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
        {{ tyomaarays.d365projektinimi }}{{ tyomaarays.d365projektinimi && tyomaarays.d365toimituseranimi ? ', ' : ''}}
        {{ tyomaarays.d365toimituseranimi }}
      </v-list-tile-sub-title>
      <!--
      <v-list-tile-sub-title v-if="task.isProjectTask"
                             :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">
        {{ task.project.customerInfo }}, {{ task.project.clientName }}
      </v-list-tile-sub-title>
      -->
    </v-list-tile-content>
    <v-list-tile-avatar>
      <v-icon class="darken-3 grey--text text--lighten-1" :class="tyomaaraysStatus.color">
        {{ tyomaaraysStatus.icon }}
      </v-icon>
    </v-list-tile-avatar>
  </v-list-tile>
</template>

<script>
import parameters from '@/parameters.js';
const tyomaarainStatuses = parameters.tyomaarainStatuses;

export default {
  props: ['item'],
  name: "CalendarTimelineTyomaarays",
  computed: {
    tyomaarays() {
      return this.item
    },
    tyomaaraysStatus() {
      const tyomaarays = this.tyomaarays;
      return tyomaarainStatuses[tyomaarays.tyomaarainstatus];
    }
  },
}
</script>

<style scoped>

</style>
