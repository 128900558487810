import Vue from 'vue';

const config = new Vue({
  data() {
    return {
      showReclamations: false
    }
  },
  watch: {
    cfg(to) {
      localStorage.setItem("cfg", JSON.stringify(to));
    }
  },
  computed: {
    cfg() {
      return {
        showReclamations: this.showReclamations,
      }
    }
  },
});


const cfgString = localStorage.getItem("cfg");
if (cfgString != null) {
  const cfg = JSON.parse(cfgString);
  if (cfg.showReclamations != null) {
    config.showReclamations = cfg.showReclamations;
  }
}


export default config;
