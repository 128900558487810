import axios from 'axios';
import moment from 'moment';

const api = {
  async getProjectRootDocuments(projectId) {
    const documentsResponse = await axios.get(`/api/Project/${projectId}/documents`);
    const folder = documentsResponse.data;
    folder.folders.forEach(f => {
      if (f.objectCount && f.objectCount > 0) {
        f.children = [];
      }
    });
    return [...folder.folders, ...folder.files];
  },
  async getProjectFolderContent(projectId, folderId) {
    const documentsResponse = await axios.get(`/api/Project/${projectId}/documents/${folderId}/folder`);
    const folder = documentsResponse.data;
    //return [];
    folder.folders.forEach(f => {
      if (f.objectCount && f.objectCount > 0) {
        f.children = [];
      }
    });
    return [...folder.folders, ...folder.files];
  },
  async getDeliveryLotRootDocuments(deliveryLotId) {
    const documentsResponse = await axios.get(`/api/DeliveryLot/${deliveryLotId}/documents`);
    const folder = documentsResponse.data;
    if (!folder) {
      return [];
    }
    folder.folders.forEach(f => {
      if (f.objectCount && f.objectCount > 0) {
        f.children = [];
      }
    });
    return [...folder.folders, ...folder.files];
  },
  async getDeliveryLotFolderContent(deliveryLotId, folderId) {
    const documentsResponse = await axios.get(`/api/DeliveryLot/${deliveryLotId}/documents/${folderId}/folder`);
    const folder = documentsResponse.data;
    folder.folders.forEach(f => {
      if (f.objectCount && f.objectCount > 0) {
        f.children = [];
      }
    });
    return [...folder.folders, ...folder.files];
  },
  async getDocument(containerType, containerId, fileId) {
    const response = await axios.get(`/api/${containerType}/${containerId}/documents/${fileId}/file`);
    return response.data;
  },
  /**
   *
   * @param {!string} apartmentId
   * @param {!Object} data
   * @param {!int} data.statusCode
   * @param {!string} data.handoverComment
   */
  async updateApartmentStatus(apartmentId, data) {
    const response = await axios.post(`/api/Apartment/${apartmentId}/status`, data);
    return response.data;
  },

  async getSystemData() {
    const response = await axios.get('/api/SystemData');
    return response.data;
  },

  async handOverDeliveryLot(deliveryLotId, data) {
    const response = await axios.post(`/api/DeliveryLot/${deliveryLotId}/handover`, data);
    return response.data;
  },
  async acceptDeliveryLotHandOver(deliveryLotId) {
    const response = await axios.post(`/api/DeliveryLot/${deliveryLotId}/accept_handover`);
    return response.data;
  },
  async setDeliveryLotCarried(deliveryLotId, data) {
    const response = await axios.post(`/api/DeliveryLot/${deliveryLotId}/set_as_carried`, data);
    return response.data;
  },
  async setProjectReady(projectId, projectReadyDate, moveInDate, setMoveInDateForDeliveryLots) {
    const response = await axios.post(`/api/Project/${projectId}/status/ready`, {
      readyDate: projectReadyDate,
      moveInDate,
      setMoveInDateForDeliveryLots
    });
    return response.data;
  },
  async getProjects(installationGroupIds, own) {
    const params = {};
    if (own) {
      params.ownedProjectsOnly = own;
    }
    const response = await axios.post('/api/User/projects', { installationGroupIds }, { params });
    return response.data;
  },
  async sendReclamationComment(data) {
    const response = await axios.post('/api/puustelli/jehu/reklamaatioriviloki', data);
    return response.data;
  },
  async reloadTasksForUser(installationGroupIds, fromDate) {
    const params = { from_date: fromDate };
    const response = await axios.post('/api/User/tasks', { installationGroupIds }, { params });
    return response.data;
  },
  async getDeliveryLotsForInstallationGroups(installationGroupIds) {
    const res = await axios.post('/api/User/lots', {installationGroupIds});
    const lots = res.data;
    lots.forEach(l => {
      l.startDate = moment(l.installationStartDate).startOf('isoWeek');
      l.endDate = moment(l.startDate).add(l.installationWeekCount, 'weeks');
    });
    return lots;
  },
  async getSystemUsers() {
    const res= await axios.get('/api/SystemData/Users');
    const users = res.data;
    return users;
  }
}

export default api;

