<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-card tile
              class="text-xs-center"
              elevation="2"
              style="cursor: pointer;"
              @click="addImage"
              v-on="on"
              data-cy="add-image-button"
      >
        <v-img aspect-ratio="1">
          <template v-slot:placeholder>
            <v-layout class="ma-0" align-center justify-center fill-height>
              <div>
                <template v-if="uploadingFiles">
                  <v-progress-circular indeterminate size="34" width="2" color="primary"></v-progress-circular>
                </template>
                <template v-else-if="images">
                  <v-icon size="36" color="primary">add_a_photo</v-icon>
                  <br />
                  <span class="primary--text text-uppercase font-weight-bold caption">{{ $t('reclamation.add_image') }}</span>
                </template>
                <template v-else>
                  <v-icon size="36" color="primary">mdi mdi-file-upload</v-icon>
                  <br />
                  <span class="primary--text text-uppercase font-weight-bold caption">{{ $t('reclamation.add_file') }}</span>
                </template>
              </div>
            </v-layout>
          </template>
        </v-img>
        <input type="file" style="display:none" :accept="acceptInner" :capture="capture" :multiple="!capture" ref="imageFileInput" @change="fileSelected" data-cy="image-file-input">
      </v-card>
    </template>
    <v-list>
      <v-list-tile>
        <v-list-tile-title @click="openCamera">{{ $t('reclamation.open_camera') }}</v-list-tile-title>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-title @click="addFile">{{ $t('reclamation.add_file') }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['uploadingFiles', 'accept'],
  name: 'AddFileCard',
  data() {
    return {
      captureSupported: false,
      capture: false,
    };
  },
  computed: {
    images() {
      return this.acceptInner.startsWith('image/');
    },
    acceptInner() {
      return this.accept != null ? this.accept : 'image/*';
    }
  },
  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async fileSelected(event) {
      const files = event.target.files;

      const uploads = [];
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        const asDataUrl = await this.toBase64(f);
        uploads.push({dataUrl: asDataUrl, name: f.name, type: f.type});
      }
      this.$refs.imageFileInput.value = "";
      this.$emit('files-selected', uploads);
    },
    openCamera() {
      this.capture = true;
      this.$nextTick(() => {
        this.$refs.imageFileInput.click();
      });
    },
    addFile() {
      this.capture = false;
      this.$nextTick(() => {
        this.$refs.imageFileInput.click();
      });
    },
    addImage() {
      if (!this.captureSupported) {
        this.$refs.imageFileInput.click();
      }
    },
  },
  mounted() {
    const el = document.createElement('input');
    this.captureSupported = el.capture !== undefined;
    console.log('capture supported: ' + this.captureSupported);
  }
}
</script>

<style scoped>

</style>
