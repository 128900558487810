import db from './database';
import frendsApi from './frendsApi';
import frends from "@/frends";
import apiCollection from "@/api";

import UiEventBus from "@/UiEventBus";

const attachments = {};
const luovutusliitteet = {};
const reclamationRows = {};
const statuses = {};
const comments = {};


const addSyncRequest = async (tag) => {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    const sw = await navigator.serviceWorker.ready;
    console.log('register reclamation sync request', { tag })
    await sw.sync.register(tag);
  }
}

const TAGS = {
  STATUKSET: 'sync-statuses',
  REKLAMAATIOT: 'sync-reclamations',
  LUOVUTUS_LIITTEET: 'sync-luovutusliitteet',
  REKLAMAATIO_LIITTEET: 'sync-attachment',
  KOMMENTIT: 'sync-comments'
}


const reclamationService = {
  async sendReclamationComment(id) {
    console.log('BackgroundSendingService: sendReclamationComment');

    if (!(id in comments)) {
      comments[id] = true;
    } else {
      throw new Error("reclamation already being created")
    }

    const comment = await db.getReclamationComment(id);
    try {
      const response = await frendsApi.sendReclamationComment(comment.rekl_rivitunniste, comment.kielinro,  comment.user, comment.selite, comment.lisattyjonoon);
      await db.deleteReclamationComment(id);
      UiEventBus.$emit('synchronization-objects-changed');
      return response;
    } catch (error) {
      await addSyncRequest(TAGS.KOMMENTIT);
      throw error;
    } finally {
      delete comments[id];
    }
  },

  async sendStatusChangeFromDB(id) {
    console.log('sendStatusChangeFromDB');

    if (!(id in statuses)) {
      statuses[id] = true;
    } else {
      throw new Error("reclamation already being created")
    }

    const statusChange = await db.getStatusChange(id);
    const apartmentId = statusChange.apartmentId;
    const data = {
      statusCode: statusChange.statusCode,
      handoverComment: statusChange.handoverComment,
    }
    try {
      const response = await apiCollection.updateApartmentStatus(apartmentId, data);
      await db.deleteStatusChange(id);
      UiEventBus.$emit('synchronization-objects-changed');
      return response;
    } catch (error) {
      addSyncRequest(TAGS.STATUKSET);
      throw error;
    } finally {
      delete statuses[id];
    }
  },
  async sendLuovutusLiitteet(ids) {
    console.log('send luovutusliitteet');
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (!(id in luovutusliitteet)) {
        luovutusliitteet[id] = true;
      } else {
        throw new Error("file already being uploaded")
      }
      const liiteData = await db.getLuovutusLiite(id);
      try {
        await frendsApi.addLuovutusLiite(liiteData.projektinro, liiteData.huonenro, liiteData.data, liiteData.nimi, liiteData.tyyppi);
        await db.deleteLuovutusLiite(id);
        UiEventBus.$emit('synchronization-objects-changed');
      } catch (error) {
        addSyncRequest(TAGS.LUOVUTUS_LIITTEET);
        throw error;
      } finally {
        delete luovutusliitteet[id];
      }
    }
  },
  async sendAttachments(ids) {
    console.log('send attachments');
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (!(id in attachments)) {
        attachments[id] = true;
      } else {
        throw new Error("file already being uploaded")
      }
      const attachmentData = await db.getReclamationAttachment(id);
      try {
        await frendsApi.addReklamaatioRiviLiite(attachmentData.rekl_rivitunniste, attachmentData.data, attachmentData.nimi, attachmentData.tyyppi);
        await db.deleteReclamationAttachment(id);
        UiEventBus.$emit('synchronization-objects-changed');
      } catch (error) {
        addSyncRequest(TAGS.REKLAMAATIO_LIITTEET);
        throw error;
      } finally {
        delete attachments[id];
      }
    }
  },
  async sendReclamation(reclamationData) {
    const data = { ...reclamationData, lisattyjonoon: reclamationData.id };
    delete data.kuvat;

    if (reclamationData.kayttaja_email != null && reclamationData.kayttaja_email.length > 0) {
      data.kayttaja_email = reclamationData.kayttaja_email;
    }
    if (reclamationData.kayttaja_nimi != null) {
      data.kayttaja_nimi = reclamationData.kayttaja_nimi;
    }

    const url = '/reklamaatiorivi';
    const response = await frends.axiosInstance.post(url, data);
    const createdReclamation = response.data;

    return createdReclamation;
  },

  async uploadReclamationFromDB(id) {
    console.log('uploadReclamation from db');
    const reclamation = await db.getReclamation(id);

    if (!(id in reclamationRows)) {
      reclamationRows[id] = true;
    } else {
      throw new Error("reclamation already being created")
    }

    try {
      const createdReclamation = await this.sendReclamation(reclamation);
      await db.deleteReclamation(reclamation.id);
      UiEventBus.$emit('synchronization-objects-changed');
      const kuvat = reclamation.kuvat;
      const attachmentIds = [];
      for (let i = 0; i < kuvat.length; i += 1) {
        const file = kuvat[i];
        const id = await db.putReclamationAttachment(reclamation.projekti_nimi, reclamation.huonenimi, reclamation.tuote, createdReclamation.rekl_rivitunniste, file.nimi, file.tyyppi, file.data);
        attachmentIds.push(id);
      }

      await this.sendAttachments(attachmentIds);
      UiEventBus.$emit('synchronization-objects-changed');
    } catch (error) {
      addSyncRequest(TAGS.REKLAMAATIOT);
      console.log("Error sending reclamation", error);
      throw error;
    } finally {
      delete reclamationRows[id];
    }
  }
}

export default reclamationService;
