<template>
  <v-container fluid fill-height ma-0 pa-0 class="inactive">
    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

    </v-toolbar>
      <v-layout row wrap>
        <v-flex xs12 class="tasks-holder">

          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-alert :value="true" outline color="error" icon="warning">
                  <div>{{ $t('user_not_active' )}}</div>
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'InactiveUserView'
}
</script>

<style scoped>

</style>
