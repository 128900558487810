/* eslint-disable */

Date.prototype.getWeek = function (dowOffset) {
  /* getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

  dowOffset = typeof (dowOffset) === 'int' ? dowOffset : 0; // default dowOffset to zero
  const newYear = new Date(this.getFullYear(), 0, 1);
  let day = newYear.getDay() - dowOffset; // the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  const daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
  let weeknum;
// if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      const nYear = new Date(this.getFullYear() + 1, 0, 1);
      let nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /* if the next year starts before the middle of
      the week, it is week #1 of that year */
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;
};

const padStart = (string, targetLength, padString) => {
// eslint-disable-next-line no-bitwise
  let y = targetLength >> 0;
  string = String(string);
  padString = String(padString);
  if (string.length > targetLength) {
    return String(string);
  }

  y = targetLength - string.length;
  if (targetLength > padString.length) {
    padString += padString.repeat(targetLength / padString.length);
  }
  return padString.slice(0, y) + String(string);
};

const pad = (n, length = 2) => padStart(n, length, '0');


export default {
  getComponent(vue) {
    const VDatePickerDateTable = vue.options.components.VDatePickerDateTable;
    const CustomDatePickerTable = VDatePickerDateTable.extend({
      name: 'CustomDatePickerTable',
      methods: {
        getWeekNumber() {
          const week = new Date(this.displayedYear, this.displayedMonth, 1);
          return week.getWeek();
        },
      },
    });
    const BaseDatePicker = vue.options.components.VDatePicker;
    const CustomDatePicker = BaseDatePicker.extend({
      name: 'CustomDatePicker',
      methods: {
        genDateTable() {
          return this.$createElement(CustomDatePickerTable, {
            props: {
              allowedDates: this.allowedDates,
              color: this.color,
              current: this.current,
              dark: this.dark,
              disabled: this.disabled,
              events: this.events,
              eventColor: this.eventColor,
              firstDayOfWeek: this.firstDayOfWeek,
              format: this.dayFormat,
              light: this.light,
              locale: this.locale,
              min: this.min,
              max: this.max,
              readonly: this.readonly,
              scrollable: this.scrollable,
              showWeek: this.showWeek,
              tableDate: `${pad(this.tableYear, 4)}-${pad(this.tableMonth + 1)}`,
              value: this.value,
              weekdayFormat: this.weekdayFormat,
            },
            ref: 'table',
            on: {
              input: this.dateClick,
              tableDate: (value) => this.tableDate = value,
              'click:date': (value) => this.$emit('click:date', value),
              'dblclick:date': (value) => this.$emit('dblclick:date', value),
            },
          });
        },
      },
    });

    return CustomDatePicker;
  },
};
