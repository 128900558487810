<template>
<!--  <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" v-if="contactPersons !== null && contactPersons.length > 1" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">-->
  <v-list v-if="installationGroup" :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
    <v-divider inset class="mt-0 mb-0"></v-divider>
    <v-list-group>
      <template slot="activator">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">people</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ title }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ installationGroup.name }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>

      <v-list-tile class="mb-2 full-height" v-for="installer in sortedInstallers" :key="installer.id">
        <v-list-tile-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ installer.name != null ? installer.name : '' }}</v-list-tile-title>
          <v-list-tile-sub-title style="height: 100% !important;" class="py-2" :class="{caption: $vuetify.breakpoint.xsOnly}">
            <v-layout row wrap>
              <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-0 pb-0'">
                {{ installer.roleText }}
              </v-flex>
              <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                {{ installer.email != null ? installer.email : '' }}
              </v-flex>
              <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1'">
                {{ installer.phone != null ? installer.phone : '' }}
              </v-flex>
            </v-layout>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-menu bottom left>
            <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
            <v-list>
              <v-list-tile v-if="installer.phone != null" :href="`tel:${installer.phone}`">
                <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
              </v-list-tile>
              <v-divider v-if="installer.phone != null && installer.email != null"></v-divider>
              <v-list-tile v-if="installer.email != null" :href="`mailto:${installer.email}`">
                <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-list-tile-action>
      </v-list-tile>
    </v-list-group>

  </v-list>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'InstallationGroup',
  props: ['installationGroup', 'installers', 'title'],
  computed: {
    sortedInstallers() {
      return _.sortBy(this.installers, 'roleId', 'name');
    }
  },
}
</script>

<style scoped>

</style>
