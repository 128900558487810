/* eslint-disable no-param-reassign */
import Vue from 'vue';
import store from '../store.js';
import _ from 'lodash';

const RolesPlugin = {
  install(/* vue */) {
    Vue.mixin({
      methods: {
        $hasMatchingRoles(roles) {
          const role = store.state.auth.role;
          const matchingRoles = roles.findIndex(r => r === role);
          return matchingRoles > -1;
        },
        $hasMatchingGroups(groupId) {
          const idList = _.map(store.state.installationGroups, 'id');
          const matchingGroups = idList.findIndex(ig => ig === groupId);
          return matchingGroups > -1;
        },
      },
      computed: {
        $showInstallationUnits() {
          return (this.$isAdmin || this.$isPuustelliUser || this.$isTeamLeader);
        },
        $isAdmin() {
          const role = store.state.auth.role;
          return role === 'JEHU_ADMIN';
        },
        $isTeamLeader() {
          const role = store.state.auth.role;
          return role === 'INSTALLER_LEADER';
        },
        $isTeamMember() {
          const role = store.state.auth.role;
          return role === 'INSTALLER_MEMBER';
        },
        $isPuustelliUser() {
          const role = store.state.auth.role;
          return role === 'PUUSTELLI_USER';
        },
        $isCustomer() {
          const role = store.state.auth.role;
          return role === 'PROJECT_CUSTOMER';
        },
      },
    });
  },
};

export default RolesPlugin;
