<template>
  <v-dialog v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('reclamation.create_reclamation') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-content"  id="dialogCnt">
          <div class="grey--text caption">{{ $t('reclamation.product') }}</div>
          <div v-if="!hasUnits" class="title mb-3 font-weight-regular">{{ $t('reclamation.no_product')}}</div>
          <template v-else v-for="(unit, index) of units">
            <div :key="index" class="title mb-3 font-weight-regular">{{ unit.malliselite }}</div>
          </template>
          <v-form ref="form">
            <v-select
              v-model="reclamationType"
              :items="reclamationTypes"
              item-text="selite"
              item-value="tyyppi"
              :label="$t('reclamation.reclamation_type')" :rules="[$rules.required]" />
            <template v-if="!hasUnits">
              <v-text-field :label="$t('reclamation.reclamation_amount')" v-model="reclamationAmount" :rules="amountRules" type="number">
                <template v-slot:append>
                  <v-btn :disabled="reclamationAmount < 2" small class="ml-0 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(-1)"><v-icon>remove</v-icon></v-btn>
                  <v-btn small class="ml-3 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(1)"><v-icon>add</v-icon></v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-else-if="hasUnits && units.length === 1">
              <v-text-field :disabled="hasUnits && units.length === 1" :label="$t('reclamation.reclamation_amount')" v-model="reclamationAmount" :rules="amountRules" type="number">
                <template v-slot:append>
                  <v-btn :disabled="reclamationAmount < 2" small class="ml-0 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(-1)"><v-icon>remove</v-icon></v-btn>
                  <v-btn :disabled="reclamationAmount >= units[0].maara" small class="ml-3 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(1)"><v-icon>add</v-icon></v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-else-if="hasUnits && units.length > 1">
              <v-text-field disabled :label="$t('reclamation.reclamation_amount')" v-model="reclamationAmount" :rules="amountRules" type="number">
                <template v-slot:append>
                  <v-btn disabled small class="ml-0 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(-1)"><v-icon>remove</v-icon></v-btn>
                  <v-btn disabled small class="ml-3 mt-0 mr-0" icon color="primary" @click="toggleReclamationAmount(1)"><v-icon>add</v-icon></v-btn>
                </template>
              </v-text-field>
            </template>
            <v-select
              v-model="deliveryOption"
              :items="newProductDeliveryOptions"
              :label="$t('reclamation.new_product_delivery')" :rules="[$rules.required]" />

            <v-combobox
              ref="recipientCombobox"
              :label="$t('reclamation.recipient')"
              :items="personList"
              :item-text="item => item.name + ' - ' + item.title"
              @input="handleInput"
              @click:clearable="recipient = ''"
              clearable
              @focus="scrollToThis"
            />

            <v-checkbox :label="$t('reclamation.yearly_inspection')" v-model="yearlyInspection"></v-checkbox>

            <v-textarea :label="$t('reclamation.reclamation_details')" v-model="reclamationDetails" :rules="[$rules.required]" @focus="scrollToThis" />
          </v-form>

          <template>
            <v-container pl-0 pr-0 pt-3 pb-5 grid-list-md fluid>
              <v-layout row wrap>
                <v-flex
                  v-for="(img, index) in files"
                  :key="index"
                  :xs3="$vuetify.breakpoint.smAndUp"
                  :xs4="$vuetify.breakpoint.xsOnly"
                  d-flex
                >
                  <file-card :img="img" :removing-file="removingFile" @delete-image="deleteImage(index)"></file-card>
                </v-flex>
                <v-flex :xs3="$vuetify.breakpoint.smAndUp"
                        :xs4="$vuetify.breakpoint.xsOnly">
                  <add-file-card :uploading-files="uploadingFiles"
                                 @files-selected="fileSelected"></add-file-card>
                </v-flex>
              </v-layout>
            </v-container>
          </template>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else>{{ $t('cancel') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="primary"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              :loading="loading"
              @click="addUnitToReclamation"
              :disabled="addUnitDisabled"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{ $t('reclamation.create_reclamation') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import frends from '../../frendsApi';
  import FileCard from "@/components/FileCard";
  import backgroundSendingService from "@/backgroundSendingService";
  import UiEventBus from "@/UiEventBus";
  import AddFileCard from "../AddFileCard.vue";

  export default {
    name: "AddUnitToReclamationDialog",
    components: {AddFileCard, FileCard},
    props: {
      project: {
        type: Object,
        default: () => {}
      },
      projectDTO: {
        type: Object,
        default: () => {}
      },
      deliveryLot: {
        type: Object,
        default: () => {}
      },
      apartment: {
        type: Object,
        default: () => {}
      },
      reclamationRows: {
        type: Array,
        default: () => [],
      },
      puustelliContactPersons: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      dialog(newValue, oldValue) {
        if (!newValue) {
          this.$refs.form.reset();
          this.dialog = false;
          this.$nextTick(() => {
            this.files = [];
            this.reclamationDetails = '';
            this.reclamationType = '';
            this.reclamationAmount = 1;
          })
        }
      }
    },
    data() {
      return {
        uploadingFiles: false,
        files: [],
        removingFile: false,
        yearlyInspection: false,
        loading: false,
        dialog: false,
        reclamationDetails: '',
        units: [],
        reclamationType: '',
        reclamationAmount: 1,
        newProductDeliveryOptions: [
          { value: this.$t('reclamation.new_product_delivery_options.1'), text: this.$t('reclamation.new_product_delivery_options.1') },
          { value: this.$t('reclamation.new_product_delivery_options.2'), text: this.$t('reclamation.new_product_delivery_options.2') },
          { value: this.$t('reclamation.new_product_delivery_options.3'), text: this.$t('reclamation.new_product_delivery_options.3') },
          { value: this.$t('reclamation.new_product_delivery_options.4'), text: this.$t('reclamation.new_product_delivery_options.4') },
        ],
        deliveryOption: '',
        recipient: '',
      }
    },
    methods: {
      handleInput(value) {
        if (this.dialog && value) {
          if (value.name) {
            this.recipient = value.name + ' - ' + value.title;
          } else {
            this.recipient = value;
          }
        } else {
          this.recipient = '';
        }
      },
      scrollToThis(event) {
        if (this.$vuetify.breakpoint.xsOnly) {
          setTimeout(() => {
            const opts = {
              offset: 50,
              duration: 100,
              container: '#dialogCnt'
            };
            this.$vuetify.goTo(event.target, opts);
          }, 200);
        }
      },
      deleteImage(index) {
        this.files.splice(index, 1);
      },
      async fileSelected(files) {
        this.uploadingFiles = true;
        this.files.push(...files);
        this.uploadingFiles = false;
      },
      toggleReclamationAmount(amount) {
        const reclamationAmount = parseInt(this.reclamationAmount);
        if (isNaN(reclamationAmount)) {
          this.reclamationAmount = 1;
        } else {
          this.reclamationAmount = reclamationAmount + amount;
        }
      },
      openDialog(units) {
        this.dialog = true;
        this.units = [ ...units ];

        this.reclamationAmount = 1;
        this.reclamationDetails = '';
        this.reclamationType = '';
        this.loading = false;
        //clear value from search
        this.$refs.recipientCombobox.lazySearch = '';
      },
      dismissDialog() {
        this.dialog = false;
      },
      async uploadFiles(riviTunniste, files) {
        for (let i = 0; i < files.length; i += 1) {
          const fileDataUrl = files[i].dataUrl;
          await frends.addReklamaatioRiviLiite(riviTunniste, fileDataUrl, files[i].name, files[i].type);
        }
      },
      async addBackgroundReclamation() {
        for (const unit of this.units) {
          const projektinro = unit ? unit.projektinro : this.apartment ? this.apartment.vertexProject : null;
          const huonenimi = this.apartment ? this.apartment.name : '';
          const huonenro = unit ? unit.huonenro : this.apartment ? this.apartment.vertexTargetNumber : null;
          const rivinro = unit ? unit.rivinro : null;
          const tuotenimi = unit ? unit.malliselite : 'Ei tuotetta';
          const vuositarkastus = this.yearlyInspection;
          const puusnetOrderNumber = this.apartment.puusnetOrderNumber;

          const user = this.$store.state.auth.user;

          let kayttaja_email = '';
          if (user.email != null && user.email.length > 0) {
            kayttaja_email = user.email;
          }

          let kayttaja_nimi = '';
          if (user.firstName != null && user.lastName != null) {
            kayttaja_nimi = `${user.firstName} ${user.lastName}`;
          }

          const files = this.files.map(file => {
            const fileDataUrl = file.dataUrl;
            return {
              tyyppi: file.type,
              nimi: file.name,
              data: fileDataUrl,
            };
          });

          const canUseDB = await this.$isDbSupported();

          if (canUseDB) {
            const id = await this.$db.putReclamation(projektinro, this.project.clientName,
              huonenro, huonenimi, tuotenimi, rivinro, puusnetOrderNumber, this.reclamationAmount,
              this.reclamationType, this.reclamationDetails, null, this.$kielinro, this.deliveryOption, this.recipient, vuositarkastus, kayttaja_nimi, kayttaja_email, files
            );

            const unit2 = unit;
            try {
              if (unit) {
                unit.hasReclamationSync = true;
              }

              const timeoutPromise = new Promise(((resolve, reject) => {
                setTimeout(() => {
                  resolve('timeout-reached');
                }, 3000);
              }))

              const uploadReclamationFromDBPromise = backgroundSendingService.uploadReclamationFromDB(id).then(() => {
                if (unit2) {
                  unit2.hasReclamationSync = false;
                  unit2.hasReclamation = true;
                }
              });

              const result = await Promise.race([timeoutPromise, uploadReclamationFromDBPromise]);

              if (result === 'timeout-reached') {
                this.$emit('reclamation-added-to-sync');
                UiEventBus.$emit('synchronization-objects-changed');
              } else {
                this.$emit('reclamation-added', unit2);
              }
            } catch (error) {
              console.log(error);
              UiEventBus.$emit('synchronization-objects-changed');
              this.$emit('reclamation-added-to-sync');
            }
          } else {
            let rivi = null;
            try {
              rivi = await frends.newReklamaatioRivi(projektinro, huonenro, rivinro, puusnetOrderNumber, this.$kielinro,
                {
                  amount: this.reclamationAmount,
                  reclamationType: this.reclamationType,
                  reclamationInfo: this.reclamationDetails,
                  newDeliveryOption: this.deliveryOption,
                  yearlyInspection: vuositarkastus,
                  recipient: this.recipient,
                }, this.$store.state.auth.user);
              if (unit) unit.hasReclamation = true;
              this.$emit('reclamation-added');
            } catch (error) {
              console.log(error);
              this.$store.dispatch('showNotification', {
                color: 'error',
                message: this.$t('reclamation.new_reclamation_failed'),
                timeOut: 5000,
                showButton: false,
                buttonText: '',
                callback: () => {
                }
              });
            }

            if (rivi != null && rivi.rekl_rivitunniste != null) {
              try {
                await this.uploadFiles(rivi.rekl_rivitunniste, this.files);

                // TODO: näytä kaikille kerralla? samoin errorit
                this.$store.dispatch('showNotification',
                  {
                    color: 'success',
                    message: this.$t('reclamation.new_reclamation_success'),
                    timeOut: 4000,
                    showButton: false,
                  });
              } catch (error) {
                console.log(error);
                this.$store.dispatch('showNotification',
                  {
                    color: 'error',
                    message: this.$t('reclamation.failed_to_upload_reclamation_attachments'),
                    timeOut: 4000,
                    showButton: false,
                  });
              }
            }
          }
        }
        this.dismissDialog();
      },
      async addUnitToReclamation() {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true;

        try {
          await this.addBackgroundReclamation();
        } catch (error) {
          console.log(error);
        }

        this.loading = false;
      },
    },
    computed: {
      hasUnits() {
        return this.units.length > 0 && this.units[0] != null;
      },
      addUnitDisabled() {
        return this.hasUnits && this.units.some(unit => unit.hasReclamation)
      },
      personList() {
        let personList = [];

        if (this.projectDTO.contactPersons) {
          this.projectDTO.contactPersons.map(person => {
            const contactPerson = {
              name: person.name,
              title: person.role
            }
            personList.push(contactPerson);
          });
        }
        if (this.projectDTO.manager) {

            const contactPerson = {
              name: this.projectDTO.manager.name,
              title: 'Puustelli ' + this.$t('projects.titles.manager')
            }
            personList.push(contactPerson);
        }
        return personList;
      },
      amountRules() {
        return [
          (value) => value > 0 || this.$t('reclamation.amount_above_0'),
          (value) => this.hasUnits ? value <= this.units[0].maara || this.$t('reclamation.amount_below_unit_amount') : true,
        ]
      },
      reclamationTypes() {
        return this.$store.state.reclamationTypes;
      }
    },
    async mounted() {
      this.$store.dispatch('reloadReclamationTypes', { kielinro: this.$kielinro });
    },
  }
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  .text-details {
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  @media screen and (min-width: 600px) {
    .text-content {
      max-height: calc(100vh - 300px);
      overflow: auto;
    }

    .text-details {
      font-size: 14px;
    }
  }
</style>
