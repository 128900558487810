/* eslint-disable no-param-reassign */
import Vue from 'vue';
import moment from "moment";

const LocalePlugin = {
  install(/* vue */) {
    Vue.mixin({
      methods: {
        $isDateSet(date) {
          return !(date === undefined || date === null || date === '');
        },
        $formatDate(date) {
          if (!this.$isDateSet(date)) return undefined;
          moment.locale(this.$i18n.locale);
          return moment(date).format('L LT');
        },
        $formatDateNoTime(date) {
          if (!this.$isDateSet(date)) return undefined;
          moment.locale(this.$i18n.locale);
          return moment(date).format('L');
        },
        $formatTimeNoDate(date) {
          if (!this.$isDateSet(date)) return undefined;
          return moment(date).format('HH:mm');
        },
        $formatDateIncludeWeekday(date) {
          if (!this.$isDateSet(date)) return undefined;
          return moment(date).format('ddd D.M.YYYY');
        }
      }
    });
  },
};

export default LocalePlugin;
