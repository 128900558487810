import { render, staticRenderFns } from "./SingleRoomView.vue?vue&type=template&id=1ca377a7&scoped=true&xmlns%3Av-touch=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./SingleRoomView.vue?vue&type=script&lang=js"
export * from "./SingleRoomView.vue?vue&type=script&lang=js"
import style0 from "./SingleRoomView.vue?vue&type=style&index=0&id=1ca377a7&prod&lang=scss&scoped=true"
import style1 from "./SingleRoomView.vue?vue&type=style&index=1&id=1ca377a7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca377a7",
  null
  
)

export default component.exports