/* eslint-disable no-param-reassign */
import Vue from 'vue';
import i18n from '../i18n.js';
import axios from "axios";

const LocalePlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $kielinro() {
          const locale = this.$locale;

          switch (locale) {
            case 'fi': return 'FIN'; break;
            case 'en': return 'ENG'; break;
            case 'sv': return 'SWE'; break;
            default: return 'FIN';
          }
        },
        $locale() {
          if (this.$getLocale()) return this.$getLocale();
          return 'fi';
        },
      },
      methods: {
        $setLocale(locale) {
          i18n.locale = locale;
          localStorage.setItem('locale', locale);
          axios.defaults.headers.common['X-Jehu-Language'] = locale;
        },
        $getLocale() {
          return localStorage.getItem('locale');
        },
      }
    });
  },
};

export default LocalePlugin;
