<template>
  <v-layout :fill-height="fillHeight" row justify-center :align-center="alignMiddle">
    <v-flex xs12 class="text-xs-center">
      <v-progress-circular
        :size="size"
        :width="width"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-flex>
  </v-layout>

</template>

<script>
  export default {
    name: "LoadingIndicator",
    props: {
      fillHeight: {
        type: Boolean,
        default: false,
      },
      alignMiddle: {
        type: Boolean,
        default: true,
      },
      size: {
        type: Number,
        default: 70,
      },
      width: {
        type: Number,
        default: 3,
      }
    },
  }
</script>

<style scoped>

</style>
