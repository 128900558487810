<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
    <v-card tile>
      <v-card-title :class="options.color" class="white--text">
        <h3>{{ $t('reclamation.send_comment_dialog.title') }}</h3>
      </v-card-title>
      <v-card-text>

        <v-textarea
          v-model="selite"
          auto-grow
          row-height="1"
          clearable
          light
          :label="$t('reclamation.send_comment_dialog.comment')"
        />

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn flat @click.native="cancel()">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat :color="options.color" @click.native="agree">{{ $t('reclamation.send_comment_dialog.agree') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import frendsApi from "../../frendsApi";
import database from "@/database";
import backgroundSendingService from "@/backgroundSendingService";
import UiEventBus from "@/UiEventBus";

  export default {
    name: 'AddCommentDialog',
    data() {
      return {
        selite: '',
        kielinro: '',
        apartmentName: '',
        reclamationRow: null,
        project: null,
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          color: 'primary',
          width: 290,
        }
      };
    },
    methods: {
      open(reclamationRow, kielinro, project, apartmentName) {
        this.selite = '';
        this.kielinro = kielinro;
        this.apartmentName = apartmentName;

        this.reclamationRow = reclamationRow;
        this.project = project;

        this.dialog = true;
      },
      async agree() {
        this.saving = true;
        if (await this.$isDbSupported()) {
          await this.sendCommentWithDb();
        } else {
          await this.sendCommentNow();
        }
        this.saving = true;
      },
      cancel() {
        this.selite = '';
        this.kielinro = '';
        this.dialog = false;
      },
      async sendCommentWithDb() {
        const id = await database.putReclamationComment(this.reclamationRow.rekl_rivitunniste, this.kielinro,  this.$store.state.auth.user, this.selite, this.reclamationRow.rekl_rivinperustamispvm, this.project.clientName, this.apartmentName);

        const promise = new Promise((async (resolve, reject) => {
          setTimeout(() => {
            resolve({ type: 'timeout' });
          }, 5000);

          if ('onLine' in navigator) {
            if (!navigator.onLine) {
              resolve({ type: 'timeout' });
            }
          }

          try {
            const response = await backgroundSendingService.sendReclamationComment(id);
            resolve({ type: 'sent', response });
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }));

        try {
          const result = await promise;
          if (result.type === 'sent') {
            this.$emit('update-logs');
            await this.$store.dispatch('showNotification', {
              color: 'success',
              message: this.$t('reclamation.comment_created_succesfully'),
              showButton: false,
            });
          } else {
            //this.$emit('comment-added-to-sync');
            UiEventBus.$emit('synchronization-objects-changed');
            await this.$store.dispatch('showNotification', {
              color: 'warning',
              message: this.$t('reclamation.comment_created_added_to_sync'),
              showButton: false,
            });
          }
          this.cancel();
        } catch (error) {
          console.log(error);
          UiEventBus.$emit('synchronization-objects-changed');
          await this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.comment_create_failed'),
              timeOut: 4000,
              showButton: false,
            });
        }
      },
      async sendCommentNow() {
        try {
          await frendsApi.sendReclamationComment(this.reclamationRow.rekl_rivitunniste, this.kielinro,  this.$store.state.auth.user, this.selite, this.reclamationRow.rekl_rivinperustamispvm);
          this.$emit('update-logs');
          this.cancel();
        } catch (error) {
          console.log(error);
          await this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.send_comment_dialog.error'),
              timeOut: 4000,
              showButton: false,
            });
        }
      },
    },
  }
</script>
