<template>
  <v-container v-if="$isAdmin || $isPuustelliUser" fluid fill-height ma-0 pa-0 class="reclamations">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        Sisäiset asetukset
      </v-toolbar-title>
    </v-toolbar>

    <hb-loading-indicator fill-height align-middle v-if="loading"/>

    <v-fade-transition>
      <v-layout row wrap v-if="!loading">
        <v-flex xs12 class="reclamations-holder">
          <v-switch label="Näytä reklamaatiot" v-model="config.showReclamations"/>
        </v-flex>
      </v-layout>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import config from "../config";
  export default {
    data() {
      return {
        loading: false,
        subtleLoading: false,
        config,
      }
    },
    created() {

    }
  }
</script>

<style scoped>

</style>
