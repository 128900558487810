<template>
  <div
    v-if="isImageFile"
    class="section-to-print auto-height my-3 px-2 image-position"
    avatar
    style="page-break-inside: avoid"
  >
  <img style="max-width: 600px !important; height: auto" :src="attachment.public_url">
  </div>
</template>

<script>
import MimeTypes from "mime-types";

export default {
  props: ['attachment'],
  name: "PrintAttachments",
  computed: {
    isImageFile() {
      if (this.attachment.tiedostonimi.endsWith('jfif')) {
        return true;
      }
      else if (this.attachment.type) {
        return this.attachment.type.startsWith('image/');
      } else {
        let lookup = MimeTypes.lookup(this.attachment.tiedostonimi);
        return lookup.startsWith('image/');
      }
    },
  }
}
</script>

<style>

.image-position {
  display: flex;

}
.auto-height * {
  height: auto !important;
}
.web-pr-2 {
  padding-right: 8px;
}
.web-pl-2 {
  padding-left: 8px;
}
.web-mt-3 {
  margin-top: 12px;
}
.web-mb-3 {
  margin-bottom: 12px;
}

</style>

<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }
  .web-mb-3 {
    margin-bottom: 0px;
  }
  .web-pr-2 {
    padding-right: 0px !important;
  }
  .web-pl-2 {
    padding-left: 0px !important;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page { margin: 0 0 0 0; }
  body  { margin: 1.6cm; }
}

</style>
