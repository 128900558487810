<template>
  <div :style="{'padding-top': $vuetify.breakpoint.mdAndUp ? '36px' : '24px'}" >
    <div class="grey--text lighten-2">{{ item.datesFormatted }}
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: ['item'],
  name: "CalendarTimelineWeek"
}
</script>

<style scoped>

</style>
