import Keycloak from 'keycloak-js'
import axios from 'axios'
import moment from 'moment'
import keycloakConfigDevelopment from '../../keycloak.Development.json'

moment.locale('fi');

let keyCloakConfig;
//if (process.env.NODE_ENV === 'production') {
if (process.env.NODE_ENV === 'production' && "true" !== process.env.VUE_APP_DEV) {
  keyCloakConfig = {
    'VUE_APP_KeycloakAuthUrl': process.env['VUE_APP_KeycloakAuthUrl'],
    'VUE_APP_KeycloakRealm': process.env['VUE_APP_KeycloakRealm'],
    'VUE_APP_KeycloakClientId': process.env['VUE_APP_KeycloakClientId']
  }
} else {
  keyCloakConfig = keycloakConfigDevelopment
}

let keycloakClient = new Keycloak({
  url: keyCloakConfig['VUE_APP_KeycloakAuthUrl'],
  realm: keyCloakConfig['VUE_APP_KeycloakRealm'],
  clientId: keyCloakConfig['VUE_APP_KeycloakClientId'],
});

function onAuthSuccess() {
  auth.client = keycloakClient;
  auth.accessTokenExpires = moment(auth.client.tokenParsed.exp * 1000).format('LLLL');
  auth.accessToken = auth.client.token;
  auth.idToken = auth.client.idToken;

  let userRoleInfo = JSON.parse(localStorage.getItem('userRoleInfo'));
  if (userRoleInfo && userRoleInfo.id && userRoleInfo.id === auth.client.idTokenParsed.sub) {
    auth.role = userRoleInfo.role;
    auth.jehuUser = userRoleInfo.jehuUser;
  }

  const userDataPromise = axios.get('api/User').then((response) => {
    const jehuUser = response.data;
    auth.role = jehuUser.role;
    auth.jehuUser = jehuUser;
    // store this as this query is expensive
    localStorage.setItem('userRoleInfo', JSON.stringify({
      id: auth.client.idTokenParsed.sub,
      role: auth.role,
      jehuUser
    }));
  }).catch((error) => {
    console.log('error');
  });

  const authUserPromise = new Promise((resolve, reject) => {
    auth.client.loadUserProfile().then((profile) => {
      auth.user = profile;
      resolve();
    });
  });

  return Promise.all([userDataPromise, authUserPromise]);
}

keycloakClient.onAuthRefreshSuccess = onAuthSuccess;

async function init() {
  auth.client = keycloakClient;
  try {
    const authenticated = await keycloakClient.init({
      flow: 'standard',
      checkLoginIframe: false,
    })

    if (auth.success) {
      onAuthSuccess()
        .then(() => {
          auth.success(authenticated);
        });
      // Update/Create UI
    }
  } catch (error) {
    // no session
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const idpHint = params['kc_idp_hint'];
    const result = await keycloakClient.login({idpHint});
  }
}

const auth = {
  client: null,
  accessTokenExpires: null,
  user: null,
  role: '',
  success: null,
  authRefreshSuccess: null,
  accessToken: null,
  idToken: null,
  init,
};

export default auth;
