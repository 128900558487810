import clientConfigDevelopment from "../../client.Development";
import axios from "axios";

let baseUrl;
if (process.env.NODE_ENV === 'production' && "true" !== process.env.VUE_APP_DEV) {
  baseUrl = process.env.VUE_APP_FrendsBaseUrl;
} else {
  baseUrl = clientConfigDevelopment.VUE_APP_FrendsBaseUrl;
}

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

function setAuth(auth) {
  axiosInstance.interceptors.request.use(config => {
    if (auth.client && auth.client.authenticated) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
      config.headers['X-JWT-IDTOKEN'] = `${auth.idToken}`;
    }
    return config;
  }, error => {
    return Promise.reject(error)
  })
}

export default { axiosInstance, setAuth, baseUrl };
