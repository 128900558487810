<template>
  <v-container v-if="!$isCustomer" fluid fill-height ma-0 pa-0 class="tasks">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <hb-back-button fallback="calendar" />

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="$t('tasks.search_for_task')"
          v-model="searchPhrase"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          full-width
          hide-details
          color="red lighten-3"
        >

          <v-icon style="cursor: pointer;" slot="append">search</v-icon>
          <v-icon @click="toggleFilterPanel" style="cursor: pointer;" slot="append-outer">filter_list</v-icon>
        </v-text-field>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="tasks-holder">

          <v-container fluid grid-list-md v-if="$store.state.tasks.length === 0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-alert :value="true" color="grey" icon="warning">
                  <div>{{ $t('tasks.no_tasks_found') }}</div>
                  <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}" color="white"><v-icon left size="20">people_outline</v-icon>{{ $t('installation_groups.title') }}</v-btn>
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>

          <v-list v-if="$store.state.tasks.length > 0" three-line class="pt-0 pb-0" avatar>

            <DynamicScroller
                ref="scroller"
                :items="filteredTasks"
                :min-item-size="88"
                class="scroller fixed-height-content xxx"
                @scroll.native="handleScroll"
                key-field="activityId"
            >
              <template v-slot="{ item, index, active }">
                <DynamicScrollerItem
                    :active="active"
                    :data-index="index"
                    :item="item"
                    :size-dependencies="[]">

                  <v-list-tile
                      :style="{opacity: item.done ? '0.65' : '1'}"
                      :to="{name: 'task', params: { activityId: item.activityId }}"
                  >
                    <v-list-tile-avatar>
                      <v-icon v-if="item.done" color="success">done_outline</v-icon>
                      <v-icon v-if="!item.done && item.typeCode === 100000001" color="warning">
                        label
                      </v-icon>
                      <v-icon v-if="!item.done && item.typeCode === 100000011" color="light-blue">
                        people_outline
                      </v-icon>
                    </v-list-tile-avatar>

                    <v-list-tile-content>
                      <v-list-tile-title class="font-weight-bold">{{
                          item.subject
                        }}
                      </v-list-tile-title>
                      <template v-if="item.isDeliveryLotTask">
                        <v-list-tile-title class="caption">
                          {{ getGroup(item.deliveryLot.installationGroupId) }} &bull;
                          <template v-if="$vuetify.breakpoint.smAndUp">{{
                              $t('tasks.deadline')
                            }}
                          </template>
                          {{ $formatDateNoTime(item.scheduledEnd) }}
                        </v-list-tile-title>
                        <v-list-tile-title class="caption grey--text">{{ item.deliveryLot.name }},
                          {{ item.deliveryLot.projectInfo }}
                        </v-list-tile-title>
                      </template>
                      <template v-if="item.isProjectTask">
                        <v-list-tile-title class="caption">
                          {{ getGroup(item.project.installationGroupId) }} &bull;
                          <template v-if="$vuetify.breakpoint.smAndUp">{{
                              $t('tasks.deadline')
                            }}
                          </template>
                          {{ $formatDateNoTime(item.scheduledEnd) }}
                        </v-list-tile-title>
                        <v-list-tile-title class="caption grey--text">{{ item.project.clientName }},
                          {{ item.project.customerInfo }}
                        </v-list-tile-title>
                      </template>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-icon>chevron_right</v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>

                </DynamicScrollerItem>
              </template>
            </DynamicScroller>

            <!--            <div v-for="task in filteredTasks"-->
            <!--                 :key="task.activityId">-->
            <!--              <v-list-tile-->
            <!--                :style="{opacity: task.done ? '0.65' : '1'}"-->
            <!--                :to="{name: 'task', params: { activityId: task.activityId }}"-->
            <!--              >-->
            <!--                <v-list-tile-avatar>-->
            <!--                  <v-icon v-if="task.done" color="success">done_outline</v-icon>-->
            <!--                  <v-icon v-if="!task.done && task.typeCode === 100000001" color="warning">label</v-icon>-->
            <!--                  <v-icon v-if="!task.done && task.typeCode === 100000011" color="light-blue">people_outline</v-icon>-->
            <!--                </v-list-tile-avatar>-->

            <!--                <v-list-tile-content>-->
            <!--                  <v-list-tile-title class="font-weight-bold">{{ task.subject }}</v-list-tile-title>-->
            <!--                  <template v-if="task.isDeliveryLotTask">-->
            <!--                    <v-list-tile-title class="caption">{{ getGroup(task.deliveryLot.installationGroupId) }} &bull; <template v-if="$vuetify.breakpoint.smAndUp">{{ $t('tasks.deadline') }} </template>{{ $formatDateNoTime(task.scheduledEnd) }}</v-list-tile-title>-->
            <!--                    <v-list-tile-title class="caption grey&#45;&#45;text">{{ task.deliveryLot.name }}, {{ task.deliveryLot.projectInfo }}</v-list-tile-title>-->
            <!--                  </template>-->
            <!--                  <template v-if="task.isProjectTask">-->
            <!--                    <v-list-tile-title class="caption">{{ getGroup(task.project.installationGroupId) }} &bull; <template v-if="$vuetify.breakpoint.smAndUp">{{ $t('tasks.deadline') }} </template>{{ $formatDateNoTime(task.scheduledEnd) }}</v-list-tile-title>-->
            <!--                    <v-list-tile-title class="caption grey&#45;&#45;text">{{ task.project.clientName }}, {{ task.project.customerInfo }}</v-list-tile-title>-->
            <!--                  </template>-->
            <!--                </v-list-tile-content>-->

            <!--                <v-list-tile-action>-->
            <!--                  <v-icon>chevron_right</v-icon>-->
            <!--                </v-list-tile-action>-->
            <!--              </v-list-tile>-->
            <!--              <v-divider></v-divider>-->
            <!--            </div>-->
          </v-list>
        </v-flex>
      </v-layout>
    </v-fade-transition>
    <hb-filter-drawer
      v-model="showFilterPanel">
      <v-subheader class="subheading pa-0">{{ $t('filter_list') }}:</v-subheader>
      <v-layout row wrap>
        <v-flex xs12>
          <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  :value="computedDateFormatted"
                  :label="$t('tasks.tasks_from_date')"
                  prepend-icon="event"
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :locale="locale" v-model="tasksFromDate" @change="reloadTasksForUser" @input="dateMenu = false"></v-date-picker>
          </v-menu>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.taskTypes[100000001]"
            :label="$t('tasks.filter_types.100000001')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.taskTypes[100000011]"
            :label="$t('tasks.filter_types.100000011')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-if="$isPuustelliUser"
            @change="storeFilters"
            v-model="objectFilters.own"
            :label="$t('calendar.filter.own')"
            hide-details
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </hb-filter-drawer>
  </v-container>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "TasksView",
    components: {
    },
    data() {
      return {
        showFilterPanel: false,
        objectFilters: {
          own: false,
          taskTypes: {100000011: true, 100000001: true},
        },
        loading: true,
        subtleLoading: false,
        searchPhrase: '',
        selectedTask: '',
        tasksFromDate: null,
        dateMenu: false,
      }
    },
    methods: {
      handleScroll(e) {
        this.$store.state.tasksView.scrollY = e.target.scrollTop;
        this.$store.state.tasksView.scrollYItemsCount = this.filteredTasks.length;
      },
      storeFilters() {
        localStorage.setItem("task-filters", JSON.stringify(this.objectFilters));
      },
      toggleFilterPanel() {
        this.showFilterPanel = !this.showFilterPanel;
      },
      formatDeliveryWeek(deliveryWeek) {
        deliveryWeek = deliveryWeek.split(".");
        return deliveryWeek.join("/");
      },
      getGroup(installationGroupId) {
        const group = this.$store.state.installationGroups.find(ig => ig.id === installationGroupId);
        if (group != null) {
          return group.name;
        }
        return '';
      },
      async reloadTasksForUser() {
        if (this.$store.state.tasks.length === 0) {
          this.loading = true;
          this.subtleLoading = false;
        } else {
          this.loading = false;
          this.subtleLoading = true;
        }
        this.$store.commit('setTasksFromDate', this.tasksFromDate);
        await this.$store.dispatch('reloadTasksForUser');

        this.loading = false;
        this.subtleLoading = false;
      },
      getDateFromWeek(week, year) {
        return moment().day("Monday").year(year).week(week).toDate();
      },
    },
    computed: {
      locale() {
        return this.$locale;
      },
      computedDate() {
        return moment(this.tasksFromDate).hours(12).toDate();
      },
      computedDateFormatted() {
        return this.$formatDateNoTime(this.computedDate);
      },
      filteredTasks() {
        let tasks = this.$store.state.tasks;
        if (this.objectFilters.own) {
          const userId = this.$store.state.auth.jehuUser.id;
          tasks = tasks.filter(task => (task.isDeliveryLotTask && task.deliveryLot.managerId === userId) || (!task.isDeliveryLotTask && task.project.managerId === userId));
        }

        tasks = tasks.filter(task => {
          return this.objectFilters.taskTypes[task.typeCode];
        });

        tasks.sort((a, b) => {
          a = a.scheduledEnd;
          b = b.scheduledEnd;
          return b > a ? -1 : b < a ? 1 : 0;
        });

        tasks.sort((a, b) => {
          return a.done === b.done ? 0 : a.done ? 1 : -1;
        });


        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          return tasks;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return tasks.filter(p => {
          let found = [];
          const lowerSearchedString =
            (p.taskHiearchyDetails !== null ? p.taskHiearchyDetails.toLowerCase() : '') +
            (p.subject !== null ? p.subject.toLowerCase() : '') +
            //(p.type !== null ? p.type.toLowerCase() : '') +
            (p.description !== null ? p.description.toLowerCase() : '') +
            (this.getGroup(p.installationGroupId).toLowerCase());
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      }
    },
    async mounted() {
      this.objectFilters.own = !!this.$isPuustelliUser;

      const storedFilters =  localStorage.getItem("task-filters");
      if (storedFilters != null) {
        const objectFilters = JSON.parse(storedFilters);
        if (!this.$isPuustelliUser) {
          objectFilters.own = false;
        }
        if (!objectFilters.taskTypes) {
          objectFilters.taskTypes = this.objectFilters.taskTypes;
        }
        this.objectFilters = objectFilters;
      }

      if (this.$isCustomer) {
        this.$router.push({ name: 'projects' });
      }

      if (this.$store.state.tasksView.scrollY > 0 ) {
        if (this.$store.state.tasksView.scrollYItemsCount === this.filteredTasks.length)
          setTimeout(() => {
            this.$refs.scroller.$el.scrollTop = this.$store.state.tasksView.scrollY;
            this.$store.state.tasksView.scrollY = 0;
          })
      }

      this.tasksFromDate = this.$store.state.tasksView.tasksFromDate;
      await this.reloadTasksForUser();
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 56px);
    }
  }

  @media only screen and (min-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 56px);
    }
  }

  @media only screen and (min-width: 960px) {
    .fixed-height-content {
      margin-bottom: -30px;
      height: calc(100vh - 65px);
    }
  }

  @media only screen and (min-width: 1265px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }

  @media only screen and (min-width: 1300px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }
</style>

