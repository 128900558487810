<template>
  <v-container v-if="$showReclamations" fluid fill-height ma-0 pa-0 class="reclamations">

    <v-fab-transition v-if="!loading && filteredReclamations.length > 0">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="primary"
            :loading="generatingExcel"
            fab dark bottom right fixed
            @click="exportExcel"
            style="margin-bottom: 50px"
          >
            <v-icon>download</v-icon>
          </v-btn>
        </template>
        {{ $t('reclamation.download_excel') }}
      </v-tooltip>
    </v-fab-transition>

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app :extended="!!selectedReclamationStatus && ($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser)">

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <hb-back-button fallback="calendar" />

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="$t('reclamation.search_for_reclamations')"
          v-model="filter.searchPhrase"
          @focus="$selectInputValue"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          full-width
          hide-details
          color="red lighten-3"
        >
          <v-icon style="cursor: pointer;" slot="append">search</v-icon>
          <v-icon @click="showFilterPanel = !showFilterPanel" style="cursor: pointer;" slot="append-outer">filter_list</v-icon>
        </v-text-field>
      </v-toolbar-title>

      <template slot="extension">
        <v-layout v-if="showToolbarExtension" justify-start light>
          <template v-if="reclamationRows.length > 0">
            <v-btn v-if="filteredReclamations.length > 0 && selectedReclamations.length <= 0"
                   :disabled="selectAllRowsButtonDisabled"
                   @click="selectAllVisibleReclamations"
                   :small="$vuetify.breakpoint.mdAndDown"
                   :light="$vuetify.breakpoint.lgAndUp"
                   outline class="ml-0"
                   :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">
              {{ $t('reclamation.select_visible_rows') }}
            </v-btn>
            <v-btn v-if="selectedReclamations.length > 0"
                   @click="clearReclamationSelections"
                   :small="$vuetify.breakpoint.mdAndDown"
                   :light="$vuetify.breakpoint.lgAndUp"
                   outline class="ml-0"
                   :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">
              {{ $t('reclamation.select_none') }}
            </v-btn>
          </template>
          <template v-if="selectedReclamationStatus === 4 || selectedReclamationStatus === 6 && ($isTeamMember || $isTeamLeader || $isPuustelliUser || $isAdmin)">
            <v-btn @click="markReclamationsAsInstalled" :disabled="updatingStatuses" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline class="ml-0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">{{ $t('reclamation.mark_as_installed') }}</v-btn>
          </template>
          <template v-if="selectedReclamationStatus === 7 && ($isAdmin || $isCustomer || $isPuustelliUser)">
            <v-btn @click="approveReclamations" :disabled="updatingStatuses" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline class="ml-0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">
              {{ $vuetify.breakpoint.xsOnly ? $t('reclamation.approve') : $tc('reclamation.approve_reclamation', 2) }}
            </v-btn>
            <v-btn @click="declineReclamations" :disabled="updatingStatuses" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline class="ml-0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">
              {{ $vuetify.breakpoint.xsOnly ? $t('reclamation.decline') : $tc('reclamation.decline_reclamation', 2) }}
            </v-btn>
          </template>
          <template v-if="selectedReclamations.length > 0 && ($isPuustelliUser || $isAdmin)">
            <v-btn @click="createTyomaarays" :disabled="updatingStatuses" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline class="ml-0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'">{{ $t('tyomaaraykset.create') }}</v-btn>
          </template>
        </v-layout>
      </template>
    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap v-scroll.body="onScroll">
        <v-flex xs12 class="reclamations-holder">
          <v-container fluid grid-list-md v-if="reclamationRows.length === 0">
            <template v-if="filteredProjects.length > 0">
              <v-layout row wrap v-if="!loadingReclamationRows && reclamationRows.length === 0">
                <v-flex xs12>
                  <v-alert :value="selectedProject" color="grey" icon="warning">
                    <div>{{ $t('reclamation.no_reclamations_found_for_project') }}</div>
                  </v-alert>
                  <v-alert :value="!selectedProject" type="info" outline>
                    <div class="mb-3">{{ $t('reclamation.reclamations_start_help') }} {{ $t('reclamation.more_filter_settings1') }} <v-icon>filter_list</v-icon> {{ $t('reclamation.more_filter_settings2') }}</div>
                    <div>
                      <v-combobox
                        v-model="filter.selectedProject"
                        :items="filteredProjects"
                        item-text="clientName"
                        return-object
                        color="info"
                        solo
                        hide-details
                        :loading="loadingProjects"
                        @change="selectedProjectChanged"
                        :label="$t('reclamation.select_project')" />
                    </div>
                  </v-alert>
                </v-flex>
              </v-layout>
              <v-layout v-if="loadingReclamationRows" row wrap class="mt-5 pt-5">
                <v-flex xs12>
                  <hb-loading-indicator fill-height align-middle />
                </v-flex>
              </v-layout>
            </template>
            <template v-if="filteredProjects.length === 0 && !loadingProjects">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-alert :value="true" color="grey" icon="warning">
                    <div>{{ $t('reclamation.no_projects_found') }}</div>
                    <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}" color="white"><v-icon left size="20">people_outline</v-icon>{{ $t('installation_groups.title') }}</v-btn>
                  </v-alert>
                </v-flex>
              </v-layout>
            </template>
          </v-container>
          <v-system-bar color="white" class="show-system-bar-when-scrolling"
                        v-if="selectedProject && !loadingReclamationRows"
                        :height="$vuetify.breakpoint.smAndDown ? 70 : 40">
            <v-flex xs12 justify="start" class="ma-0">
              <span class="px-2"><span class="black--text mr-1">{{ $t('reclamation.selected_project' )}}:</span>{{ selectedProject.clientName }}</span>
              <span class="px-2" v-if="selectedDeliveryLots.length > 0"><span class="black--text mr-1">{{ $tc('delivery_lot.title', selectedDeliveryLots.length )}}:</span>{{ selectedDeliveryLotsTitle }}</span>
            </v-flex>
          </v-system-bar>
          <v-list :style="{marginTop: $vuetify.breakpoint.smAndDown ? '70px' : '40px'}" v-if="!loadingReclamationRows && reclamationRows.length > 0" three-line class="pt-0 pb-0">
            <template v-for="reclamationRow in filteredReclamations">
              <reclamation-list-item :key="reclamationRow.key" :reclamation-row="reclamationRow" @row-clicked="goToReclamation" :updating-statuses="updatingStatuses" />
              <v-divider style="border-color: #FFF;" :key="`${reclamationRow.key}_divider`"></v-divider>
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-filter-drawer v-model="showFilterPanel" style="z-index: 10; overflow: visible">
      <v-subheader class="subheading pa-0">{{ $t('reclamation.filter_results') }}:</v-subheader>
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedProject"
        :items="filteredProjects"
        item-text="clientName"
        light
        class="my-2 no-wrap-on-list"
        attach
        hide-details
        :loading="loadingProjects"
        :menu-props="{left: true}"
        @change="selectedProjectChanged"
        :label="$t('reclamation.select_project')"
        return-object
      />
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedDeliveryLots"
        :items="filteredDeliveryLots"
        :disabled="filteredDeliveryLots.length === 0"
        :placeholder="filteredDeliveryLots.length === 0 ? $t('reclamation.no_data') : ''"
        item-text="name"
        return-object
        :loading="subtleLoading"
        clearable
        class="my-2 no-wrap-on-list"
        light
        attach
        multiple
        hide-details
        :menu-props="{left: true}"
        :label="$t('reclamation.select_delivery_lot')"
      />
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedApartments"
        :items="filteredApartments"
        item-text="name"
        :menu-props="{left: true}"
        multiple
        :loading="subtleLoading"
        class="my-2 no-wrap-on-list"
        attach
        clearable
        light
        hide-details
        :label="$t('reclamation.select_apartment')"
      />
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedStatuses"
        :items="statuses"
        :menu-props="{left: true}"
        :loading="subtleLoading"
        clearable
        class="my-2 no-wrap-on-list"
        attach
        multiple
        light
        hide-details
        :label="$t('reclamation.select_status')"
      />
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedTypes"
        :items="types"
        multiple
        :menu-props="{left: true}"
        :loading="subtleLoading"
        clearable
        light
        attach
        class="my-2 no-wrap-on-list"
        hide-details
        :label="$t('reclamation.select_type')"
      />
      <v-combobox
        v-if="filter.selectedProject && reclamationRows.length > 0"
        v-model="filter.selectedRekltoimeraNimet"
        :items="reklToimeraNimet"
        class="my-2 no-wrap-on-list"
        multiple
        :menu-props="{left: true}"
        attach
        :loading="subtleLoading"
        item-text="name"
        :placeholder="reklToimeraNimet.length === 0 ? $t('reclamation.no_data') : ''"
        clearable
        light
        :label="$t('reclamation.select_rekltoimeranimi')"
      />
      <v-checkbox class="my-2 no-wrap-on-list" :label="$t('reclamation.filter_by_yearly_inspection')" v-model="filter.filterByVuositarkastus">

      </v-checkbox>
    </hb-filter-drawer>

    <confirm-dialog ref="confirmReclamationStatusChanges" />

    <edit-tyonaarays-dialog
      ref="editTyomaaraysDialog"
      v-if="($isPuustelliUser || $isAdmin)"
      :projectDTO="project"
      :reclamation-rows="selectedReclamations"
    />

  </v-container>
</template>

<script>
  import {write, utils} from 'xlsx';
  import { saveAs } from 'file-saver';

  import frendsApi from '../frendsApi.js';
  import ConfirmDialog from "../components/ConfirmDialog.vue";
  import ReclamationListItem from "@/components/ReclamationsView/ReclamationListItem";
  import EditTyonaaraysDialog from "../components/SingleTyomaaraysView/EditTyomaaraysDialog.vue";

  export default {
    name: "ReclamationsView",
    components: {
      ReclamationListItem,
      ConfirmDialog: ConfirmDialog,
      EditTyonaaraysDialog: EditTyonaaraysDialog,
    },
    props: {
      projectId: {
        type: [String, Boolean],
        default: false,
      },
      deliveryLotId: {
        type: String,
        default: null,
      },
      vertexProjectNumber: {
        type: String,
        default: '',
      },
      vertexTargetNumber: {
        type: [String, Number],
        default: '',
      },
      resetFilters: {
        type: Boolean,
        default: false,
      },
      reclamationStatusFilter: {
        type: String,
        default: null,
        required: false,
      }
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        showFilterPanel: false,
        loadingProjects: false,
        loadingReclamationRows: false,
        reclamationRows: [],
        reclamationRowProject: null,
        statusColor: [],
        updatingStatuses: false,
        generatingExcel: false,
        scrolled: false,
      }
    },
    methods: {
      async createTyomaarays() {
        await this.$refs.editTyomaaraysDialog.open({});
      },
      clearReclamationSelections() {
        this.reclamationRows.forEach(r => r.selected = false);
      },
      selectAllVisibleReclamations() {
        this.filteredReclamations.forEach(r => r.selected = !this.rowSelectionDisabled(r));
      },
      onScroll(e) {
        this.scrolled = true;
        this.$store.state.reclamationsView.scrollY = {
          y: window.scrollY
        };
      },
      async reloadProjectsIfNeeded() {
        if (this.projects == null || this.projects.length === 0) {
          return this.reloadProjects();
        }
      },
      async approveReclamations() {
        const reclamationsToUpdate = [];
        this.reclamationRows.forEach(rr => {
          if (rr.selected) reclamationsToUpdate.push(rr);
        });

        const confirm = await this.$refs.confirmReclamationStatusChanges.open(this.$tc('reclamation.approve_reclamation', 2), this.$t('reclamation.approve_reclamations_amount', {amount: reclamationsToUpdate.length}), { color: 'warning' });
        if (!confirm) return;

        this.updatingStatuses = true;

        const promises = [];
        reclamationsToUpdate.forEach(row => {
          promises.push(this.updateReclamationRowStatus(row, 'RAKLIIKE_HYVAKSYTTY'));
        });

        Promise.all(promises).then((rows) => {
          this.updatingStatuses = false;
        }).catch((error) => {
          console.log(error);
          this.updatingStatuses = false;
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_statuses'),
              timeOut: 4000,
              showButton: false,
            });
        });
      },
      async declineReclamations() {
        const reclamationsToUpdate = [];
        this.reclamationRows.forEach(rr => {
          if (rr.selected) reclamationsToUpdate.push(rr);
        });

        const confirm = await this.$refs.confirmReclamationStatusChanges.open(this.$tc('reclamation.decline_reclamation', 2), this.$t('reclamation.decline_reclamations_amount', {amount: reclamationsToUpdate.length}), { color: 'warning' });
        if (!confirm) return;

        this.updatingStatuses = true;

        const promises = [];
        reclamationsToUpdate.forEach(row => {
          promises.push(this.updateReclamationRowStatus(row, 'RAKLIIKE_HYLATTY'));
        });

        Promise.all(promises).then((rows) => {
          this.updatingStatuses = false;
        }).catch((error) => {
          console.log(error);
          this.updatingStatuses = false;
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_statuses'),
              timeOut: 4000,
              showButton: false,
            });
        });
      },
      async markReclamationsAsInstalled() {
        const reclamationsToUpdate = [];
        this.reclamationRows.forEach(rr => {
          if (rr.selected) reclamationsToUpdate.push(rr);
        });

        const confirm = await this.$refs.confirmReclamationStatusChanges.open(this.$t('reclamation.mark_as_installed'), this.$t('reclamation.mark_as_installed_amount', {amount: reclamationsToUpdate.length}), { color: 'warning' });
        if (!confirm) return;

        this.updatingStatuses = true;

        const promises = [];
        reclamationsToUpdate.forEach(row => {
          promises.push(this.updateReclamationRowStatus(row, 'ASENNETTU'));
        });

        try {
          await Promise.all(promises);
          this.updatingStatuses = false;
        } catch (error) {
          console.log(error);
          this.updatingStatuses = false;
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_statuses'),
              timeOut: 4000,
              showButton: false,
            });
        }
      },
      async updateReclamationRowStatus(reclamationRow, status) {
        this.$set(reclamationRow, 'updatingStatus', true);
        const rowIndex = this.reclamationRows.findIndex(rr => rr.rekl_rivitunniste === reclamationRow.rekl_rivitunniste);
        try {
          const updatedRow = await frendsApi.updateReklamaatioRiviStatus(reclamationRow.rekl_rivitunniste, status, this.$kielinro,  this.$store.state.auth.user);
          updatedRow.selected = false;
          updatedRow.updatingStatus = false;
          if (rowIndex > -1) {
            this.reclamationRows.splice(rowIndex, 1, updatedRow);
          }
          return updatedRow;
        } catch (error) {
          if (rowIndex > -1) {
            this.reclamationRows[rowIndex].updatingStatus = false;
          }
          throw error;
        }
      },
      goToReclamation(reclamationRow) {
        this.$router.push({name: 'reclamationrow', params: { rowId: reclamationRow.rekl_rivitunniste}});
      },
      rowSelectionDisabled(row) {
        const rowStatus = parseInt(row.rekl_statusnro, 10);

        if (this.updatingStatuses) return true;

        if (rowStatus === 4 || rowStatus === 6 || rowStatus === 7) {
          if (this.selectedReclamationStatus === 4 || this.selectedReclamationStatus === 6) {
            return !(rowStatus === 4 || rowStatus === 6);
          }
          if (this.selectedReclamationStatus === 7) {
            return rowStatus !== 7;
          }
          return false;
        }
        return true;
      },
      async selectedProjectChanged() {
        const proj = this.selectedProject;

        this.filter.selectedTypes = [];
        this.filter.selectedApartments = [];
        this.filter.selectedDeliveryLots = [];
        this.filter.selectedStatuses = [];
        this.filter.selectedRekltoimeraNimet = [];

        if (proj) {
          await this.reloadProject(this.selectedProject.id);
        } else {
          this.reclamationRows = [];
        }

        // setNewProjectRoute
        this.$router.replace({ name: 'reclamations', params: { projectId: this.selectedProject.id } });
      },
      resetFilter() {
        this.filter.selectedProject = null;
        this.reclamationRows = [];
        this.reclamationRowProject = null;
        this.filter.selectedTypes = [];
        this.filter.selectedApartments = [];
        this.filter.selectedDeliveryLots = [];
        this.filter.selectedStatuses = [];
        this.filter.selectedRekltoimeraNimet = [];
        this.filter.filterByVuositarkastus = false;
      },
      async reloadProjects() {
        this.loadingProjects = true;
        await this.$store.dispatch('reloadProjects');
        this.loadingProjects = false;
      },
      async reloadReclamationRows(id) {
        if (this.reclamationRows.length > 0 && this.reclamationRowProject === id) {
          // Already loaded reclamations for this project
          return;
        }

        this.loadingReclamationRows = true;
        try {
          const reclamationRows = await frendsApi.getReklamaatioRivit(id, null, null, null, this.$kielinro);
          this.reclamationRowProject = id;
          reclamationRows.forEach(rr => rr.selected = false);

          const ASENNETTU = '9';
          const ODOTTAA_RAKENNUSLIIKKEEN_HYVAKSYNTAA = '7';
          const HYLATTY = '5';

          const sortOthers = (a, b) => {
            if (b.rekl_jakelupvm == null) {
              return -1;
            }

            if (a.rekl_jakelupvm == null) {
              return 1;
            }

            const aDate = new Date(a.rekl_jakelupvm);
            const bDate = new Date(b.rekl_jakelupvm);
            return aDate - bDate;
          }

          const sortInstalled = (a, b) => {
            const aDate = new Date(a.rekl_jakelupvm);
            const bDate = new Date(b.rekl_jakelupvm);
            return bDate - aDate;
          }

          const sortWaiting = (a, b) => {
            const aDate = new Date(a.rekl_rivinperustamispvm);
            const bDate = new Date(b.rekl_rivinperustamispvm);
            return aDate - bDate;
          }

          const statusOrderIndex = (a) => {
            switch (a.rekl_statusnro) {
              case ODOTTAA_RAKENNUSLIIKKEEN_HYVAKSYNTAA: return 1;
              case ASENNETTU: return 3;
              case HYLATTY: return 4;
              default: return 2;
            }
          }

          reclamationRows.sort((a, b) => {
            // 'Odottaa rakennusliikkeen hyväksyntää' ('7') first by rekl_rivinperustamispvm
            // then others by rekl_jakelupvm
            // then 'Asennettu' ('9') by rekl_jakelupvm
            if (a.rekl_statusnro !== b.rekl_statusnro) {

              const aStatus = statusOrderIndex(a);
              const bStatus = statusOrderIndex(b);

              // Status is not same, but they are combined into 'others', date matters
              if (aStatus === 2 && bStatus === 2) {
                return sortOthers(a, b);
              }
              return aStatus - bStatus;
            } else if (a.rekl_statusnro === ODOTTAA_RAKENNUSLIIKKEEN_HYVAKSYNTAA) {
              return sortWaiting(a, b);
            } else if (a.rekl_statusnro === ASENNETTU) {
              return sortInstalled(a, b);
            } else {
              return sortOthers(a, b);
            }
          });

          this.reclamationRows = reclamationRows;
          this.$store.state.reclamationsView.reclamationRows = reclamationRows;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadReclamationRows(id);
              }
            });
        }
        this.loadingReclamationRows = false;
      },
      async reloadProject(id) {

        if (this.project && this.project.project.id === id) {
          this.project = this.$store.state.projectDTO;
        } else {
          this.loading = true;
          await this.$store.dispatch('reloadProjectDTO', id);
          this.project = this.$store.state.projectDTO;
        }

        await this.reloadReclamationRows(id);

        this.reclamationRows.forEach(reclamationRow => {
          const apartment = this.projectApartments.find(apartment => parseInt(apartment.vertexTargetNumber, 10) === parseInt(reclamationRow.huonenro, 10) && apartment.vertexProject === reclamationRow.projektinro);
          this.$set(reclamationRow, 'apartment', apartment);
        });

        this.loading = false;
      },
      exportExcel() {
        this.generatingExcel = true;

        const wb = utils.book_new();
        /*Juuso worksheet name max 31 chars, cut to 30*/
        const regex = /[\\\/\?\*\[\]]/gim;
        const ws_name = this.selectedProject.clientName.substring(0, 30).replaceAll(regex, ' ');

        const headers = [
          this.$t('reclamation.product'),
          this.$t('reclamation.apartment'),
          this.$t('reclamation.type'),
          this.$t('reclamation.order_number'),
          this.$t('reclamation.status'),
          this.$t('reclamation.statuspvm'),
          this.$t('reclamation.delivery'),
          this.$t('reclamation.created'),
          this.$t('reclamation.reclamation_details'),
          this.$t('reclamation.rekltoimeranimi'),
          this.$t('reclamation.rekltoimeranro'),
          this.$t('reclamation.vuositarkastus')
        ];
        const data = this.filteredReclamations.map(r => [
          r.malliselite ? r.rekl_tuoteselite : this.$t('reclamation.no_product'),
          r.apartment ? r.apartment.name : this.$t('reclamation.no_apartment'),
          r.rekl_tyyppiselite,
          r.rekl_tilausnro || this.$t('reclamation.no_order_number'),
          r.rekl_statusteksti || '',
          r.rekl_rivinstatuspvm ? this.$formatDateNoTime(r.rekl_rivinstatuspvm) : '',
          r.rekl_jakelupvm ? this.$formatDateIncludeWeekday(r.rekl_jakelupvm) : this.$t('reclamation.no_delivery_date'),
          this.$formatDateNoTime(r.rekl_rivinperustamispvm),
          r.rekl_selite,
          r.rekltoimeranimi ? r.rekltoimeranimi : '',
          r.rekltoimeranro ? r.rekltoimeranro : '',
          r.vuositarkastus === "true" ? 'X' : ''
        ]);

        /* make worksheet */
        const ws_data = [
          headers,
          ...data,
        ];
        const ws = utils.aoa_to_sheet(ws_data);

        /* Add the worksheet to the workbook */
        utils.book_append_sheet(wb, ws, ws_name);

        const wbOut = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
          }
          return buf;
        }

        const fileName = `${this.$t('reclamation.report_file_name')}.xlsx`;

        saveAs(new Blob([s2ab(wbOut)], { type: "application/octet-stream" }), fileName);

        this.generatingExcel = false;
      },
      resetScroll() {
        if (this.$store.state.reclamationsView.scrollY != null) {
          const scrollY = this.$store.state.reclamationsView.scrollY;
          this.$vuetify.goTo(scrollY.y, {
            duration: 200,
            easing: 'easeInOutCubic',
          })
        } else {
          this.$vuetify.goTo(0, {
            duration: 0,
            offset: 0,
          });
        }
      }
    },
    computed: {
      selectedReclamations() {
        return this.filteredReclamations.filter(r => r.selected);
      },
      selectedDeliveryLotsTitle() {
        return this.selectedDeliveryLots.map(dl => dl.name).join(', ');
      },
      projectApartments() {
        if (this.project != null) {
          const dlAparts = this.project.deliveryLots.map(dl => dl.apartments);

          const all = [];
          dlAparts.forEach(aparts => all.push(...aparts));
          return all;
        }

        return [];
      },
      statuses() {
        const statuses = [];
        let index = 0;
        this.reclamationRows.forEach(row => {
          if (statuses.findIndex(s => s.text === row.rekl_statusteksti) === -1) {
            statuses.push({ value: row.rekl_statusnro, text: row.rekl_statusteksti });
            index += 1;
          }
        });
        statuses.sort((a, b) => a.value - b.value);
        return statuses;
      },
      types() {
        const types = [];
        let index = 0;
        this.reclamationRows.forEach(row => {
          if (types.findIndex(t => t.text == row.rekl_tyyppiselite) === -1) {
            types.push({ value: index, text: row.rekl_tyyppiselite });
            index += 1;
          }
        });
        types.sort((a, b) => a.value - b.value);
        return types;
      },
      reklToimeraNimet() {
        const reklToimeraNimet = [];
        const no_reklToimEraNimi = this.$t('reclamation.no_rekltoimera');

        if (this.selectedDeliveryLots.length > 0 && this.selectedApartments.length === 0) {
          this.reclamationRows.forEach(reclamation => {
            this.selectedDeliveryLots.forEach( dl => {
              if (dl.id === reclamation.d365toimituseraid) {
                reklToimeraNimet.push({text: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : null, name: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : no_reklToimEraNimi});
              }
            });
          });
        } else if (this.selectedDeliveryLots.length > 0 && this.selectedApartments.length > 0) {
          this.reclamationRows.forEach(reclamation => {
            this.selectedDeliveryLots.forEach( dl => {
              if (dl.id === reclamation.d365toimituseraid) {
                this.selectedApartments.forEach( apartment => {
                  if (reclamation.apartment && apartment.id === reclamation.apartment.id) {
                    reklToimeraNimet.push({text: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : null, name: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : no_reklToimEraNimi});
                  }
                });
              }
            });
          });
        } else if (this.selectedDeliveryLots.length === 0 && this.selectedApartments.length > 0) {
          this.reclamationRows.forEach(reclamation => {
            this.selectedApartments.forEach( apartment => {
              if (reclamation.apartment && reclamation.apartment.id === apartment.id) {
                reklToimeraNimet.push({text: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : null, name: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : no_reklToimEraNimi});
              }
            });
          });
        } else {
          this.reclamationRows.forEach(reclamation => {
            reklToimeraNimet.push({text: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : null, name: reclamation.rekltoimeranimi ? reclamation.rekltoimeranimi : no_reklToimEraNimi});
          });
        }
        return reklToimeraNimet.sort((a, b) => a.value - b.value);
      },
      searchPhrase() {
        return this.filter.searchPhrase;
      },
      selectedProject() {
        return this.filter.selectedProject;
      },
      selectedTypes() {
        return this.filter.selectedTypes;
      },
      selectedDeliveryLots() {
        return this.filter.selectedDeliveryLots;
      },
      selectedApartments() {
        return this.filter.selectedApartments;
      },
      selectedStatuses() {
        return this.filter.selectedStatuses;
      },
      selectedRekltoimeraNimet() {
        return this.filter.selectedRekltoimeraNimet;
      },
      filterByVuositarkastus() {
        return this.filter.vuositarkastus;
      },
      projects() {
        return this.$store.state.projects;
      },
      project: {
        get() {
          return this.$store.state.reclamationsView.project;
        },
        set(val) {
          this.$store.state.reclamationsView.project = val;
        }
      },
      filter() {
        return this.$store.state.reclamationsView.reclamationFilters;
      },
      showToolbarExtension() {
        return this.selectedReclamationStatus || this.reclamationRows.length > 0;
      },
      selectedReclamationStatus() {
        const rIndex = this.reclamationRows.findIndex(row => row.selected);
        if (rIndex > -1) {
          return parseInt(this.reclamationRows[rIndex].rekl_statusnro, 10);
        }
        return false;
      },
      filteredDeliveryLots() {
        if (!this.project) return [];
        const deliveryLots = [];

        if (this.project.deliveryLots) {
          this.project.deliveryLots.forEach(deliveryLot => {
            const deliveryLotIndex = this.reclamationRows.findIndex(reclamationRow => reclamationRow.d365toimituseraid === deliveryLot.id);
            if (deliveryLotIndex > -1) {
              deliveryLots.push(deliveryLot);
            }
          });
        }

        return deliveryLots;
      },
      filteredProjects() {
        let projects = this.projects;

        return projects.sort((a, b) => {
          a = a.clientName.toLowerCase();
          b = b.clientName.toLowerCase();
          return b > a ? -1 : b < a ? 1 : 0;
        });
      },
      statusFilteredReclamations() {
        let reclamationRows = [...this.reclamationRows];

        if (this.selectedApartments.length > 0) {
          function eq(a, b) {
            return Number.parseInt(a, 10) === Number.parseInt(b, 10);
          }

          reclamationRows = reclamationRows.filter(reclamation => {
            let found;

            found = this.selectedApartments.findIndex(nro => eq(nro.vertexTargetNumber, reclamation.huonenro) && nro.vertexProject === reclamation.projektinro) > -1;

            if (!found && this.selectedApartments.findIndex(x => x.key === 'ROOM_NOT_DEFINED') > -1) {
              found = reclamation.apartment === undefined;
            }
            return found;
          });
        }

        if (this.selectedStatuses.length > 0) {
          reclamationRows = reclamationRows.filter(reclamation => this.selectedStatuses.map(t => t.text).indexOf(reclamation.rekl_statusteksti) > -1);
        }

        if (this.selectedTypes.length > 0) {
          reclamationRows = reclamationRows.filter(reclamation => this.selectedTypes.map(t => t.text).indexOf(reclamation.rekl_tyyppiselite) > -1);
        }

        if (this.selectedDeliveryLots.length > 0) {
          reclamationRows = reclamationRows.filter(reclamation => this.selectedDeliveryLots.map(dl => dl.id).indexOf(reclamation.d365toimituseraid) > -1);
        }

        if (this.selectedRekltoimeraNimet.length > 0) {
          reclamationRows = reclamationRows.filter(reclamation => this.selectedRekltoimeraNimet.map(dl => dl.text).indexOf(reclamation.rekltoimeranimi) > -1);
        }

        return reclamationRows;
      },
      filteredReclamations() {
        const reclamationRows = this.statusFilteredReclamations;
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return reclamationRows
            .filter(p => {
              if (this.filter.filterByVuositarkastus) {
                return p.vuositarkastus === "true";
              }

              return true;
            })
            .filter(p => {
          let found = [];
          const lowerSearchedString =
            (p.rekl_tuoteselite ? p.rekl_tuoteselite.toLowerCase() : '') +
            (p.rekltoimeranimi ? p.rekltoimeranimi.toLowerCase() : '') +
            (p.rekltoimeranro ? p.rekltoimeranro : '') +
            (p.rekl_tyyppiselite ? p.rekl_tyyppiselite.toLowerCase() : '') +
            (p.rekl_statusteksti ? p.rekl_statusteksti.toLowerCase() : '') +
            (p.apartment && p.apartment.name ? p.apartment.name.toLowerCase() : '') +
            (p.apartment && p.apartment.puusnetOrderNumber ? p.apartment.puusnetOrderNumber.toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      },
      filteredApartments() {
        if (!this.project) return [];
        const apartmentWithNoDefinedRoom = {key: 'ROOM_NOT_DEFINED', name: this.$t('reclamation.room_not_defined')};
        const apartments = [apartmentWithNoDefinedRoom];

        if (this.projectApartments) {
          this.projectApartments.forEach(apartment => {
            const apartmentIndex = this.reclamationRows.findIndex(reclamationRow => parseInt(reclamationRow.huonenro, 10) === parseInt(apartment.vertexTargetNumber, 10) && reclamationRow.projektinro === apartment.vertexProject);
            if (apartmentIndex > -1) {
              if (this.selectedDeliveryLots.length > 0) {
                if (this.selectedDeliveryLots.findIndex(dl => dl.id === apartment.deliveryLotId) > -1) {
                  apartments.push(apartment);
                }
              } else {
                apartments.push(apartment);
              }
            }
          });
        }
        return apartments;
      },
      selectAllRowsButtonDisabled() {
        if (!this.project) return true;

        if (this.selectedReclamations.length > 0) return true;

        if(this.filteredReclamations.length <= 0) return true;

        //show button only when filtered reclamations have only one state
        let status = "0";
        let singlestatus = true;
        let selectiondisabled = false;
        let arr = this.filteredReclamations;
        for (var i = 0; i < arr.length; i++) {
          // if visible rows contain a row that has selection disabled, no need to go further
          if(this.rowSelectionDisabled(arr[i])) {
            selectiondisabled = true;
            break;
          }

          if(status === "0" || status === arr[i].rekl_statusteksti) {
            status = arr[i].rekl_statusteksti;
          }
          else
          {
            singlestatus = false;
            break;
          }
        }

        if(selectiondisabled || !singlestatus)
        {
          return true;
        }

        return false;
      }
    },
    watch: {
      projectId(val) {
        if (!val) {
          this.resetFilter();
        }
      }
    },
    async mounted() {
      if (this.resetFilters) {
        this.$store.dispatch('resetReclamationFilters');
      }

      await this.reloadProjectsIfNeeded();
      if (this.projectId) {
        if (this.selectedProject != null && this.selectedProject.id === this.projectId) {
          // Jos on tultu esim. back napilla ja valittu projekti on jo asetettuna
          this.reclamationRows = this.$store.state.reclamationsView.reclamationRows;

          if (this.project && this.project.project.id !== this.projectId) {
            await this.reloadProject(this.projectId);
          }
        } else { // Asetetaan valittu projekti listasta parametrin perusteella
          this.$store.state.reclamationsView.scrollY = null;
          const proj = this.projects.find(p => p.id === this.projectId);
          if (proj !== null) {
            await this.reloadProject(proj.id);
            this.filter.selectedProject = proj; // Muutos laukaisee watcherista latauksen
          }
        }
      } else {
        this.$store.state.reclamationsView.scrollY = null;
        this.resetFilter();
      }

      if (this.deliveryLotId) {
        const deliveryLot = this.project.deliveryLots.find(dl => dl.id === this.deliveryLotId);
        if (deliveryLot != null) {
          this.filter.selectedDeliveryLots = [Object.assign({}, deliveryLot)]
        }
      }

      if (this.vertexProjectNumber && this.vertexTargetNumber) {
        const apartment = this.projectApartments.find(a => parseInt(a.vertexTargetNumber, 10) === parseInt(this.vertexTargetNumber, 10) && a.vertexProject === this.vertexProjectNumber);

        if (apartment != null) {
          this.filter.selectedApartments = [];
          this.filter.selectedApartments.push(Object.assign({}, apartment));
        }
      }


      if (this.reclamationStatusFilter != null && this.statuses) {
        const status = this.statuses.find(st => st.value === this.reclamationStatusFilter);
        if (status) {
          this.filter.selectedStatuses.push(status);
        }
      }

      this.loading = false;

      this.resetScroll();
    }
  }
</script>

<style lang="scss" scoped>

.show-system-bar-when-scrolling {
  position: fixed; background-color: white; z-index: 1; width: 100%
}

</style>

<style lang="scss">
  .no-wrap-on-list {
    .v-list__tile__title {
      height: auto !important;
      white-space: initial;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .v-list__tile {
      height: 100% !important;
    }
  }

  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  .reclamation-list {
    .v-list__tile {
      height: auto !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }


  .reclamations,
  .tyomaarays {
    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }
    /*
      ei väriä (0)
      Rakennusliikkeen hyväksyntää odottavat (marianne) (1)
      Myymälän käsittelyssä (punainen) (2)
      Tuotannossa (oranssi) (3)
      Toimitettu (keltainen) (4)
      Asennetut (vihreä) (5)
     */
    .status-badge {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: auto;
      width: 3px;
    }
    .status-color-0 {
      .status-badge {
        background: #ddd;
      }
    }
    .status-color-1 {
      background: #E8EAF6;

      .status-badge {
        background: #3F51B5;
      }
    }
    .status-color-2 {
      background: #FFEBEE;

      .status-badge {
        background: #F44336;
      }
    }
    .status-color-3 {
      background: #FFF3E0;

      .status-badge {
        background: #FF9800;
      }
    }
    .status-color-4 {
      background: #FFFDE7;

      .status-badge {
        background: #FFEB3B;
      }
    }
    .status-color-5 {
      background: #E8F5E9;

      .status-badge {
        background: #4CAF50;
      }
    }
  }

</style>
