import 'babel-polyfill';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'viewerjs/dist/viewer.css'

import Vue from 'vue';
import i18n from './i18n.js';
import Vuetify from 'vuetify';
import Vue2TouchEvents from 'vue2-touch-events'
import VueRouter from 'vue-router';
import axios from 'axios';
import auth from './auth';
import frends from './frends';
import VueVirtualScroller from 'vue-virtual-scroller'
import VueViewer from 'v-viewer'

import App from './App.vue';
import store from './store';
import routes from './routes';
import ValidationPlugin from './plugins/validation.plugin';
import LocalePlugin from './plugins/locale.plugin';
import DateFormatPlugin from './plugins/dateformat.plugin';
import EnvPlugin from './plugins/env.plugin';
import RolesPlugin from './plugins/roles.plugin';
import HelperPlugin from "@/plugins/helper.plugin";
import AppInsightsPlugin from "@/plugins/appinsights.plugin";

import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import FilterDrawer from './components/FilterDrawer.vue';
import LanguageSelector from "./components/LanguageSelector.vue";

import database from "@/database";

import './registerServiceWorker';

import CustomDatePicker from '@/components/CustomDatePicker';

auth.success = initApp;
auth.init();

function tokenInterceptor (auth) {
  axios.interceptors.request.use(config => {
    if (auth.client && auth.client.authenticated) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
      config.headers['X-JWT-IDTOKEN'] = `${auth.idToken}`;// TODO: might be needed in /api/UserRole for authorization based on attributes not just email to save some calls
    }
    return config;
  }, error => {
    return Promise.reject(error)
  })
}

function refreshToken() {
  return auth.client.updateToken(120);// do refresh when equal or less than 2 minutes left
}

function accessDeniedInterceptor (auth) {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response.status === 401) {
      console.log('accessDeniedInterceptor error', error.response.headers['www-authenticate']);
      const isTokenExpired = error.response && error.response.headers['www-authenticate'] && error.response.headers['www-authenticate'].indexOf('Bearer') !== -1;
      console.log('isTokenExpired', isTokenExpired);
      if (isTokenExpired) {
        refreshToken();//TODO: return promise? this is far fetched scenario because of refreshToken should be called timely
        return Promise.reject(error);
      }
      else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  });
}

tokenInterceptor(auth);
accessDeniedInterceptor(auth);
frends.setAuth(auth);

let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fi';
axios.defaults.headers.common['X-Jehu-Language'] = locale;

async function sendSyncCommand() {
  // Syncmanager ei firefoxissa
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    const sw = await navigator.serviceWorker.ready;
    console.log('register reclamation sync')
    await sw.sync.register('sync-reclamations');
    await sw.sync.register('sync-statuses');
    await sw.sync.register('sync-comments');
    await sw.sync.register('sync-luovutusliitteet');
  }
}

function initApp(authenticated) {
  database.setSettings(auth.accessToken, frends.baseUrl)
    .then(() => {
      return sendSyncCommand();
    });

  setInterval(refreshToken, 1000 * 60);

  Vue.config.productionTip = false;
  Vue.use(Vuetify,
    {
      theme: {
        primary: '#d31e0f',
        secondary: '#990000',
        accent: '#4E342E',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      },
    });
  Vue.use(VueRouter);//at least this must be after auth init, else router fails
  Vue.use(Vue2TouchEvents);
  Vue.use(ValidationPlugin);
  Vue.use(LocalePlugin);
  Vue.use(DateFormatPlugin);
  Vue.use(RolesPlugin);
  Vue.use(EnvPlugin);
  Vue.use(HelperPlugin);
  Vue.use(AppInsightsPlugin);
  Vue.use(VueVirtualScroller)
  Vue.use(VueViewer)

  Vue.component('hb-back-button', BackButton);
  Vue.component('hb-main-menu-button', MainMenuButton);
  Vue.component('hb-loading-indicator', LoadingIndicator);
  Vue.component('hb-filter-drawer', FilterDrawer);
  Vue.component('hb-language-selector', LanguageSelector);
  Vue.component('hb-custom-date-picker', CustomDatePicker.getComponent(Vue));

  const router = new VueRouter({routes});

  const vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  });

  axios.post('/api/User/auth');

  store.dispatch('reloadInstallationGroups')
    .then(() => {
      return store.dispatch('reloadSystemData', { kielinro: vue.$kielinro });
    }).then(() => {
      vue.$mount('#app');
  });
}

function tokenRefresh(){

}
