<template>
  <v-dialog :value="true"
            v-if="dialog"
            hide-overlay
            persistent
            content-class="movable-dialog"
            :width="$vuetify.breakpoint.xsOnly ? '' : 'auto '"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <div class="resizable">
      <v-card class="fill-height">
        <v-toolbar dark color="grey darken-4 move-attach">
          <v-toolbar-title>{{ deliveryLot.statusCode === 100000006 ? $t('projects.hand_over_delivery_lot') : $t('projects.edit_hand_over_details') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-content">
          <div class="editable-content" ref="editableContent" contenteditable="true" v-text="this.deliveryLot.deliveryInfo"></div>
          <!-- <v-textarea ref="infoTextArea" v-model="deliveryLot.deliveryInfo" :label="$t('projects.hand_over_additional_info')" /> -->
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions :style="{padding: $vuetify.breakpoint.xsOnly ? '0' : ''}">
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :disabled="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else>{{ $t('cancel') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="primary"
              dark
              :loading="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              @click="saveHandOver"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{ deliveryLot.statusCode === 100000006 ?  $t('projects.save_hand_over') : $t('save') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import api from "@/api";

  export default {
    name: "HandOverDialog",
    data() {
      return {
        dialog: false,
        deliveryLot: {},
        saving: false,
      }
    },
    methods: {
      async saveHandOver() {
        this.saving = true;

        this.deliveryLot.deliveryInfo = this.$refs.editableContent.innerText;

        try {
          await api.handOverDeliveryLot(this.deliveryLot.id, {
            deliveryInfo: this.deliveryLot.deliveryInfo,
          });
          this.$emit('dismiss', true);
          this.$emit('delivery-handed-over');
          this.$store.dispatch('showNotification', {
            color: 'success',
            message: this.deliveryLot.statusCode === 100000006 ? this.$t('projects.hand_over_success') : this.$t('projects.hand_over_changes_saved_success'),
            showButton: false,
          });
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification', {
            color: 'error',
            message: this.$t('projects.failed_to_hand_over_delivery'),
            showButton: false,
          });
        }
        this.saving = false;
      },
      openDialog(deliveryLot) {
        this.deliveryLot = Object.assign({}, deliveryLot);
        this.dialog = true;
        /* this.$nextTick(() => {
          this.$refs.infoTextArea.focus();
        }) */
      },
      dismissDialog() {
        this.dialog = false;
      },
    },
    watch: {
      dialog(val) {
        if (!val) {
          this.deliveryLot = {};
          this.$emit('dismiss', false);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding-bottom: 50px;
  }

  .text-details {
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  .resizable {
    height: 100%;
  }

  .editable-content {
    white-space: pre-wrap;
    min-height: 100%;
    padding-bottom: 20px;

    &:focus {
      outline: 0 solid transparent;
    }
  }

  @media screen and (min-width: 600px) {
    .text-content {
      padding-bottom: 0;
      overflow: auto;
      height: calc(100% - 130px);
    }

    .text-details {
      font-size: 14px;
    }

    .resizable {
      resize: both;
      overflow: auto;
      width: 600px;
      height: 400px;
      min-width: 30vw;
      max-width: 90vw;
      min-height: 30vh;
      max-height: 80vh;
    }
  }
</style>
