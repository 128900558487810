<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid fill-height ma-0 pa-0 class="room">

    <v-toolbar  color="primary" dark fixed app :extended="($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser) && !loading">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="room" :fallback-params="{deliveryLotId: deliveryLotId, projectId: projectId, vertexProjectNumber: vertexProjectNumber, vertexTargetNumber: vertexTargetNumber}" />

      <v-toolbar-title style="margin-left: 0;">{{ apartment ? `${apartment.name} - ${$t('room.handover_printing_info')}` : `${$t('room.loading_handover_data')}...` }}</v-toolbar-title>

      <template slot="extension">
        <v-layout v-if="!loading" justify-start light>
          <v-btn :disabled="loading"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click="print"
                 >
            {{ $t('room.print_handover_info') }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-layout row wrap v-if="!loading">
      <v-flex xs12>
        <v-container fluid class="pa-3">
          <v-layout row wrap id="section-to-print">
            <v-flex xs12 class="web-mb-3">
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ apartment ? apartment.name : '' }} ({{ statusDescription }})
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex xs12 md6 class="d-flex print-pa-0" :class="$vuetify.breakpoint.mdAndUp && 'web-pr-2'">
              <v-list>
                <v-list-tile class="auto-height">
                  <v-list-tile-content>

                    <v-list-tile-title>
                      {{ $t('apartment_print.project_details') }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="project && project.clientName">
                      {{ $t('apartment_print.condominium') }}: {{ project.clientName }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      {{ $t('apartment_print.address') }}: {{ project.streetAddress }}, {{ project.postalCode }} {{ project.city }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.contactPerson">
                      {{ $t('apartment_print.contact_person') }}: {{ project.contactPerson }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.customerInfo">
                      {{ $t('apartment_print.customer') }}: {{ project.customerInfo }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      {{ $t('apartment_print.status') }}: {{ statusDesc }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="project && project.puusnetProjectNumber">
                      {{ $t('apartment_print.puusnet_project_number') }}: {{ project.puusnetProjectNumber }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="projectDTO && projectDTO.installationGroup.name">
                      {{ $t('apartment_print.install_group') }}: {{ projectDTO.installationGroup.name }}
                    </v-list-tile-sub-title>

                    <v-list-tile-title class="mt-3">
                      {{ $t('apartment_print.delivery_lot_details') }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="deliveryLot && deliveryLot.statusCode">
                      {{ $t('apartment_print.delivery_lot_status') }}: {{ statusDescription }}
                    </v-list-tile-sub-title>

                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex xs12 md6 class="d-flex print-pa0" :class="$vuetify.breakpoint.mdAndUp && 'web-pl-2'">
              <v-list>
                <v-list-tile class="auto-height" >
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ $t('apartment_print.contacts') }}
                    </v-list-tile-title>
                    <template v-for="(puustelliContactPerson, index) in puustelliContactPersons" >
                      <div :key="puustelliContactPerson.id" :class="index !== 0 && 'mt-1'">
                        <v-list-tile-sub-title>{{ puustelliContactPerson.name != null ? puustelliContactPerson.name : '' }}</v-list-tile-sub-title>
                        <v-list-tile-sub-title>
                          <v-layout row wrap>
                            <v-flex xs12>
                              {{ getPuustelliTitleText(puustelliContactPerson) }}
                            </v-flex>
                            <v-flex>
                              {{ puustelliContactPerson.email != null ? puustelliContactPerson.email : '' }}{{ puustelliContactPerson.phone != null ? ', puh. ' + puustelliContactPerson.phone : '' }}
                            </v-flex>
                          </v-layout>
                        </v-list-tile-sub-title>
                      </div>
                    </template>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex xs12 class="web-mt-3">
              <v-list class="auto-height">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ $t('apartment_print.comments') }}</v-list-tile-title>
                    <v-list-tile-sub-title v-if="apartment.handOverComments"><span style="white-space: pre-wrap">{{ apartment.handOverComments }}</span></v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="statusUserInfo" class="caption pt-2 font-italic"><span style="white-space: pre-wrap">{{ statusUserInfo }}</span></v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex v-if="reclamationRows.length > 0" xs12 class="web-mt-3" >
              <v-list>
                <v-list-tile class="auto-height">
                  <v-list-tile-content>
                    <v-list-tile-title class="mb-2">
                      {{ $t('apartment_print.reclamation_rows') }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <template v-for="reclamationRow in reclamationRows">
                  <print-reclamation-list-item :key="reclamationRow.key" :reclamation-row="reclamationRow"/>
                </template>
              </v-list>
            </v-flex>
            <v-flex xs12 class="web-mt-3" >
              <v-list>
                <v-list-tile class="auto-height">
                  <v-list-tile-content>
                    <v-list-tile-title class="mb-2">
                      Liitteet
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <template v-for="attachment in attachments">
                  <print-attachments :key="attachment.fileid" :attachment="attachment"/>
                </template>
              </v-list>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import frends from "../frendsApi";
import PrintReclamationListItem from "./HandOverPrintView/PrintReclamationListItem";
import PrintAttachments from "./HandOverPrintView/PrintAttachements";

import parameters from "@/parameters";
const deliveryLotStatuses = parameters.deliveryLotStatuses;

export default {
  name: "HandOverPrintView",
  components: {PrintAttachments, PrintReclamationListItem},
  props: {
    projectId: {
      type: String,
      default: '',
    },
    deliveryLotId: {
      type: String,
      default: '',
    },
    vertexProjectNumber: {
      type: String,
      default: '',
    },
    vertexTargetNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      attachments: [],
      reclamationRows: []
    }
  },
  methods: {
    async reloadApartmentReclamations() {
      try {
        let rivit = await frends.getReklamaatioRivit(this.projectId, null, this.vertexProjectNumber, this.vertexTargetNumber, this.$kielinro);
        rivit = rivit.filter(rivi => parseInt(rivi.huonenro, 10) === parseInt(this.vertexTargetNumber, 10) && this.vertexProjectNumber === rivi.projektinro);
        this.reclamationRows = rivit;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
            timeOut: 10000,
            showButton: true,
            buttonText: this.$t('try_again'),
            callback: () => {
              this.reloadApartmentReclamations();
            }
          });
      }
    },
    getPuustelliTitleText(contactPerson) {
      return contactPerson.title.join(', ');
    },
    print() {
      window.print();
    },
    async reloadHandOverAttachments() {
      try {
        const images = [];
        const liitteet = await frends.getLuovutusliitteet(this.vertexProjectNumber, this.vertexTargetNumber);
        this.attachments = liitteet;
      } catch (error) {
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('room.failed_to_fetch_handover_attachments'),
            timeOut: 10000,
            showButton: true,
            buttonText: this.$t('try_again'),
            callback: () => {
              this.reloadApartmentReclamations();
            }
          });
      }
    },
    async reloadProject() {
      if (!this.$store.state.projectDTO.project || this.$store.state.projectDTO.project.id !== this.projectId) {
        await this.$store.dispatch('reloadProjectDTO', this.projectId);
      } else {
        await this.$store.dispatch('reloadProjectDTO', this.projectId);
      }
    },
  },
  computed: {
    statusUserInfo() {
      return [this.$formatDate(this.apartment.statusTimestamp), this.apartment.statusUser, this.apartment.statusEmail].filter(x => !!x).join(', ');
    },
    statusDescription() {
      const statusCode = this.deliveryLot.statusCode;
      const statuses = this.systemData.deliveryLotStatuses;
      const status = statuses.find(s => s.key === statusCode);
      return status.value;
    },
    puustelliContactPersons() {
      const puustelliContactPersons = [];
      const owner = Object.assign({}, this.projectDTO.owner);
      if (owner) {
        owner.title = [this.$t('projects.titles.owner')];
        puustelliContactPersons.push(owner);
      }
      const clientSecretary = Object.assign({}, this.projectDTO.clientSecretary);
      if (clientSecretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === clientSecretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.clientSecretary'));
        } else {
          clientSecretary.title = [this.$t('projects.titles.clientSecretary')];
          puustelliContactPersons.push(clientSecretary);
        }
      }
      const secretary = Object.assign({}, this.projectDTO.secretary);
      if (secretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === secretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.secretary'));
        } else {
          secretary.title = [this.$t('projects.titles.secretary')];
          puustelliContactPersons.push(secretary);
        }
      }
      const manager = Object.assign({}, this.projectDTO.manager);
      if (manager) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === manager.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.manager'));
        } else {
          manager.title = [this.$t('projects.titles.manager')];
          puustelliContactPersons.push(manager);
        }
      }
      return puustelliContactPersons;
    },
    statusDesc() {
      const statusCode = this.project.projectStatus;
      const statuses = this.systemData.projectStatuses;
      const status = statuses.find(t => t.key === statusCode);
      return status.value;
    },
    systemData() {
        return this.$store.state.systemData;
    },
    loading() {
      if (!this.apartment) {
        return true;
      } else {
        return false;
      }
    },
    apartment() {
      if (this.deliveryLot) {
        return this.deliveryLot.apartments.find(a => a.vertexTargetNumber === this.vertexTargetNumber && a.vertexProject === this.vertexProjectNumber);
      }
      return null;
    },
    deliveryLot() {
      if (this.projectDTO && this.projectDTO.deliveryLots) {
        return this.projectDTO.deliveryLots.find(lot => lot.id === this.deliveryLotId);
      }
      return null;
    },
    projectDTO() {
      return this.$store.state.projectDTO;
    },
    project() {
      return this.$store.state.projectDTO.project;
    },
  },
  mounted() {
    this.reloadProject();
    this.reloadHandOverAttachments();
    this.reloadApartmentReclamations();
  }
}
</script>

<style>

  .auto-height * {
    height: auto !important;
  }
  .web-pr-2 {
    padding-right: 8px;
  }
  .web-pl-2 {
    padding-left: 8px;
  }
  .web-mt-3 {
    margin-top: 12px;
  }
  .web-mb-3 {
    margin-bottom: 12px;
  }

</style>

<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }
  .web-mb-3 {
    margin-bottom: 0px;
  }
  .web-pr-2 {
    padding-right: 0px !important;
  }
  .web-pl-2 {
    padding-left: 0px !important;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page { margin: 70px 0 0 0; }
  body  { margin: 1.6cm; }
}

</style>
