<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-list-tile v-on="on">
        <v-list-tile-action>
          <v-icon>language</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title class="body-2 font-weight-bold">{{ selectedLanguage.text }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <v-list>
      <v-list-tile v-for="item in languageItems" :key="item.value" @click="languageChanged(item)" :input-value="selectedLanguage.value === item.value">
        <v-list-tile-title>{{ item.text }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    data() {
      return {
        languageItems: [
          {text: 'Suomi', value: 'fi'},
          {text: 'Svenska', value: 'sv'},
        ],
        selectedLanguage: {text: 'Suomi', value: 'fi'},
      }
    },
    methods: {
      languageChanged(language) {
        this.$setLocale(language.value);
        this.selectedLanguage = language;
        window.location.reload();
      },
    },
    mounted() {
      const userLanguageIndex = this.languageItems.findIndex(l => l.value === this.$locale);
      if (userLanguageIndex > -1) {
        this.selectedLanguage = this.languageItems[userLanguageIndex];
      }
    }
  }
</script>

<style scoped>

</style>
