<template>
  <v-dialog :value="true"
            v-if="dialog"
            hide-overlay
            persistent
            content-class="movable-dialog"
            :width="$vuetify.breakpoint.xsOnly ? '' : 'auto '"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="grey darken-4 move-attach">
        <v-toolbar-title>{{ $t('room.change_room_status', {room: apartment.name}) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dismissDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-radio-group v-model="selectedStatus" hide-details class="mb-3" :disabled="statusDisabled">
          <v-radio
            v-for="status in systemData.apartmentStatuses"
            :key="status.key"
            :label="status.value"
            :value="status.key"
          ></v-radio>
        </v-radio-group>
        <v-textarea ref="infoTextArea" v-model="handOverComments" :label="$t('comment')" rows="8" no-resize :disabled="statusDisabled"/>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            color="default"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            @click="dismissDialog"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-else>{{ $t('cancel') }}</span>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="primary"
            dark
            :loading="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            @click="saveStatus"
            v-if="!statusDisabled"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-else>{{ $t('save') }}</span>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import apiCollection from "../../api.js";
  import database from "@/database";
  import backgroundSendingService from "@/backgroundSendingService";
  import UiEventBus from "@/UiEventBus";

  export default {
    name: "StatusDialog",
    props: {
      systemData: {
        type: Object,
        default: () => {},
      },
      attachments: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        dialog: false,
        apartment: {},
        project: {},
        saving: false,
        handOverComments: '',
        selectedStatus: null,
      }
    },
    methods: {
      attachmentsAddedToSync() {
        this.$store.dispatch('showNotification',
          {
            color: 'warning',
            message: this.$t('room.attachment_added_to_sync'),
            timeOut: 10000,
            showButton: false,
          });
      },
      reloadHandOverAttachments() {
        this.$emit('reload-attachments');
      },
      attachmentDeleted(img) {
        this.$emit('attachment-deleted', img);
      },
      async saveStatus() {
        this.saving = true;

        if (await this.$isDbSupported()) {
          await this.saveStatusWithDb();
        } else {
          await this.saveStatusNow();
        }

        this.saving = false;
      },
      async saveStatusWithDb() {
        const id = await database.putStatusChange(this.project.clientName, this.apartment.id, this.apartment.name, this.selectedStatus, this.handOverComments);

        const promise = new Promise((async (resolve, reject) => {
          setTimeout(() => {
            resolve({ type: 'timeout' });
          }, 5000);

          if ('onLine' in navigator) {
            if (!navigator.onLine) {
              resolve({ type: 'timeout' });
            }
          }

          try {
            const response = await backgroundSendingService.sendStatusChangeFromDB(id);
            resolve({ type: 'sent', response });
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }));

        try {
          const result = await promise;
          if (result.type === 'sent') {
            this.$emit('room-status-changed', result.response);
            this.$store.dispatch('showNotification', {
              color: 'success',
              message: this.$t('room.status_changed_succesfully'),
              showButton: false,
            });
          } else {
            this.$emit('room-status-change-added-to-sync');
            UiEventBus.$emit('synchronization-objects-changed');
            this.$store.dispatch('showNotification', {
              color: 'warning',
              message: this.$t('room.status_change_added_to_sync'),
              showButton: false,
            });
          }
          this.dialog = false;
        } catch (error) {
          console.log(error);
          UiEventBus.$emit('synchronization-objects-changed');
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('room.status_change_failed'),
              timeOut: 4000,
              showButton: false,
            });
        }
      },
      async saveStatusNow() {
        try {
          const response = await apiCollection.updateApartmentStatus(this.apartment.id, {
            statusCode: this.selectedStatus,
            handoverComment: this.handOverComments,
          });
          this.$emit('room-status-changed', response);
          this.$store.dispatch('showNotification', {
            color: 'success',
            message: this.$t('room.status_changed_succesfully'),
            showButton: false,
          });
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification', {
            color: 'error',
            message: this.$t('room.status_change_failed'),
            showButton: false,
          });
        }
      },
      openDialog(apartment, project) {
        this.apartment = Object.assign({}, apartment);
        this.project = Object.assign({}, project);
        this.selectedStatus = apartment.statusCode;
        this.handOverComments = apartment.handOverComments;
        this.dialog = true;
        /*
        this.$nextTick(() => {
          this.$refs.infoTextArea.focus();
        })
        */
      },
      dismissDialog() {
        this.dialog = false;
      },
    },
    computed: {
      statusDisabled() {
        //kommentoitiin tämä 4.Luovutettu tilan lukitus, koska meni koko pohjoinen suomi sekaisin
        //Heidin pyynnöstä 16.11.2023 Juuso
        //return this.apartment.statusCode === 100000003;
        return false;
      },
    },
    watch: {
      dialog(val) {
        if (!val) {
          this.apartment = {};
          this.$emit('dismiss', false);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  .text-details {
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  @media screen and (min-width: 600px) {
    .text-content {
      max-height: calc(100vh - 300px);
      overflow: auto;
    }

    .text-details {
      font-size: 14px;
    }
  }
</style>
