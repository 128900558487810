<template>
  <v-layout row wrap>
    <v-flex
        v-for="(img, index) in attachments"
        :key="img.fileid"
        xs3
        d-flex
    >
      <file-card :img="img" :removing-file="filesBeingRemoved[img.fileid] === true"
                 :allow-delete="!$isCustomer"
                 @delete-image="deleteImage" @open-image="openFullImage(index)"></file-card>
    </v-flex>
    <v-flex xs3>
      <add-file-card :uploading-files="uploadingFiles"
                     @files-selected="uploadAttachments"></add-file-card>
    </v-flex>
    <confirm-dialog ref="confirmDialog"/>
  </v-layout>
</template>

<script>
import AddFileCard from "@/components/AddFileCard";
import FileCard from "@/components/FileCard";
import frendsApi from "@/frendsApi";
import ConfirmDialog from "@/components/ConfirmDialog";
import database from "@/database";
import backgroundSendingService from "@/backgroundSendingService";
import UiEventBus from "@/UiEventBus";

export default {
  props: ['attachments', 'vertexProjectNumber', 'projectName', 'vertexRoomNumber', 'roomName'],
  name: 'HandoverAttachments',
  components: {ConfirmDialog,
    FileCard,
    AddFileCard},
  data() {
    return {
      filesBeingRemoved: {},
      uploadingFiles: false,
      removingFile: false,
      options: {
        url: 'data-src'
      },
    }
  },
  methods: {
    openFullImage(index) {
      const images = this.attachments.map(a => {
        return {
          ...a,
          src: a.thumb_url || a.dataUrl || a.public_url,
          "data-src": a.dataUrl || a.public_url,
          alt: a.info || a.tiedostonimi
        }
      })

      // viewerjs ottaa thumbnailit src tägistä, ja kun avaa, käyttää url tägiä
      const $viewer = this.$viewerApi({
        options: {
          inline: false,
          button: true,
          navbar: true,
          title: true,
          toolbar: true,
          tooltip: true,
          movable: true,
          zoomable: true,
          rotatable: true,
          scalable: true,
          transition: false,
          fullscreen: false,
          keyboard: true,
          url: 'data-src',
          initialViewIndex: index,
        },
        images,
      });
    },
    async deleteImage(img) {
      const confirm = await this.$refs.confirmDialog.open(this.$t('room.confirm_title'), this.$t('room.confirm_delete_handover_attachment'), {color: 'warning'});
      if (!confirm) {
        return;
      }

      this.$set(this.filesBeingRemoved, img.fileid, true);
      try {
        await frendsApi.deleteLuovutusLiite(img.fileid);
        this.$emit('attachment-deleted', img);
      } catch (error) {
        this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('room.failed_to_delete_handover_attachment'),
              timeOut: 4000,
              showButton: false,
            });
      }
      this.filesBeingRemoved[img.fileid] = false;
    },
    async uploadAttachments(files) {
      this.uploadingFiles = true;

      if (await this.$isDbSupported()) {
        await this.uploadFilesWithDb(files);
      } else {
        await this.uploadFiles(files);
      }

      this.uploadingFiles = false;
    },
    async uploadFilesWithDb(files) {
      const ids = [];
      for (let i = 0; i < files.length; i += 1) {
        const fileDataUrl = files[i].dataUrl;
        const id = await database.putLuovutusLiite(this.vertexProjectNumber, this.projectName, this.vertexRoomNumber, this.roomName, fileDataUrl, files[i].name, files[i].type);
        ids.push(id);
      }
      const promise = new Promise((async (resolve, reject) => {
        setTimeout(() => {
          resolve('timeout')
        }, 5000);

        if ('onLine' in navigator) {
          if (!navigator.onLine) {
            resolve({type: 'timeout'});
          }
        }

        try {
          await backgroundSendingService.sendLuovutusLiitteet(ids);
          resolve('sent');
        } catch (error) {
          reject(error);
        }
      }));
      try {
        const result = await promise;
        if (result === 'sent') {
          this.$emit('attachments-uploaded');
        } else {
          UiEventBus.$emit('synchronization-objects-changed');
          this.$emit('attachments-added-to-sync');
        }
      } catch (error) {
        console.log(error);
        UiEventBus.$emit('synchronization-objects-changed');
        this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('room.failed_to_upload_handover_attachments'),
              timeOut: 4000,
              showButton: false,
            });
      }
    },
    async uploadFiles(files) {
      try {
        for (let i = 0; i < files.length; i += 1) {
          const fileDataUrl = files[i].dataUrl;
          await frendsApi.addLuovutusLiite(this.vertexProjectNumber, this.vertexRoomNumber, fileDataUrl, files[i].name, files[i].type);
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('room.failed_to_upload_handover_attachments'),
              timeOut: 4000,
              showButton: false,
            });
      }

      this.$emit('attachments-uploaded');
    },
  },
}
</script>

<style scoped>

</style>
