<template>
  <v-container fluid fill-height ma-0 pa-0 class="sync-reclamations">

    <v-toolbar color="primary" dark fixed app  :extended="$vuetify.breakpoint.lgAndUp">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="calendar"/>

      <v-toolbar-title style="margin-left: 0;">{{ $t('reclamation_sync.title') }}</v-toolbar-title>
      <template slot="extension">
        <v-layout v-if="$vuetify.breakpoint.lgAndUp" justify-start light>
          <v-btn :loading="syncing" :disabled="unsentRows < 1" :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp" outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click="sync">{{ $t('reclamation_sync.start_sync') }}</v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle/>

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="reclamations-to-sync-holder">
          <v-list class="pt-0 pb-0" three-line v-if="combinedRows.length > 0">
            <div v-for="row in combinedRows" :key="row.id">
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon color="primary" v-if="row.status == null">fas fa-clock</v-icon>
                  <v-icon color="primary" v-else-if="row.status === 'ok'">check</v-icon>
                  <v-icon color="primary" v-else-if="row.status === 'error'">error</v-icon>
                  <v-icon color="primary" v-else-if="row.status === 'sending'">fas fa-sync fa-spin</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title class="font-weight-bold">{{ row.projekti_nimi }}</v-list-tile-title>
                  <v-list-tile-sub-title> {{ $t('reclamation_sync.types.' + row.type) }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title> {{ row.huonenimi }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title> {{ row.tuote }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>

                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon><v-icon>more_vert</v-icon></v-btn>
                    </template>

                    <v-list>
                      <v-list-tile @click="deleteFileFromSyncList(row)">
                        <v-list-tile-title>{{ $t('projects.delete_file_from_sync_list') }}</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile @click="menu = false">
                        <v-list-tile-title>{{ $t('close') }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>


                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </div>
          </v-list>
          <div v-else class="title pa-4">
            {{ $t('reclamation_sync.no_content')}}
          </div>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <v-fab-transition>
      <v-btn
        color="primary"
        dark
        fab
        fixed
        bottom
        :loading="syncing"
        right
        @click="sync"
        style="margin-bottom: 50px"
        v-show="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon>loop</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-container>
</template>

<script>
import database from "@/database";
import backgroundSendingService from "@/backgroundSendingService";
import UiEventBus from "@/UiEventBus";

export default {
  name: 'SyncView',
  data() {
    return {
      loading: false,
      menu: false,
      searchPhrase: '',
      reclamations: [],
      attachments: [],
      roomImages: [],
      statusChanges: [],
      reclamationComments: [],
      syncing: false,
    }
  },
  methods: {
    async deleteFileFromSyncList(row) {
      const confirm = await this.$root.$confirm(this.$t('projects.delete_file_from_sync_list_dialog.title'), this.$t('projects.delete_file_from_sync_list_dialog.description'), {
        color: 'error',
        agree: this.$t('projects.delete_file_from_sync_list_dialog.agree'),
      });
      if (!confirm) return;

      if (row.type === 'reclamation_row') {
        await database.deleteReclamation(row.id);
        const recs = await database.getReclamations();
        recs.forEach(r => r.type = 'reclamation_row');
        this.reclamations = recs;

      } else if (row.type === 'status_change') {
        await database.deleteStatusChange(row.id);
        const statusChanges = await database.getStatusChanges();
        statusChanges.forEach(r => r.type = 'status_change');
        this.statusChanges = statusChanges;

      } else if (row.type === 'room_image') {
        await database.deleteLuovutusLiite(row.id);
        const roomAttachments = await database.getLuovutusLiitteet();
        roomAttachments.forEach(r => r.type = 'room_image');
        this.roomImages = roomAttachments;

      } else if (row.type === 'reclamation_comment') {
        await database.deleteReclamationComment(row.id);
        const reclamationComments = await database.getReclamationComments();
        reclamationComments.forEach(r => r.type = 'reclamation_comment');
        this.reclamationComments = reclamationComments;

      } else {
        await database.deleteReclamationAttachment(row.id);
        const attachments = await database.getReclamationAttachments();
        attachments.forEach(r => r.type = 'reclamation_attachment');
        this.attachments = attachments;
      }

      this.$emit('sync_item_removed');
    },
    goBack() {
      if (this.$store.state.previousRouteName !== null) {
        this.$router.go(-1);
      } else {
        this.$router.push({name: "calendar"});
      }
    },
    async sync() {
      this.syncing = true;
      await this.loadReclamations();

      for (let i = 0; i < this.reclamations.length; i++) {
        const reclamation = this.reclamations[i];
        this.$set(reclamation, 'status', 'sending');
        try {
          await backgroundSendingService.uploadReclamationFromDB(reclamation.id);
          this.$set(reclamation, 'status', 'ok');
        } catch (error) {
          console.log(error);
          this.$set(reclamation, 'status', 'error');
        }
      }

      for (let i = 0; i < this.attachments.length; i++) {
        const attachment = this.attachments[i];
        this.$set(attachment, 'status', 'sending');
        try {
          await backgroundSendingService.sendAttachments([attachment.id]);
          this.$set(attachment, 'status', 'ok');
        } catch (error) {
          console.log(error);
          this.$set(attachment, 'status', 'error');
        }
      }

      for (let i = 0; i < this.roomImages.length; ++i) {
        const image = this.roomImages[i];
        this.$set(image, 'status', 'sending');
        try {
          await backgroundSendingService.sendLuovutusLiitteet([image.id]);
          image.status = 'ok';
        } catch (error) {
          console.log(error);
          image.status = 'error';
        }
      }
      for (let i = 0; i < this.statusChanges.length; ++i) {
        const statusChange = this.statusChanges[i];
        this.$set(statusChange, 'status', 'sending');
        try {
          await backgroundSendingService.sendStatusChangeFromDB(statusChange.id);
          statusChange.status = 'ok';
        } catch (error) {
          console.log(error);
          statusChange.status = 'error';
        }
      }
      for (let i = 0; i < this.reclamationComments.length; ++i) {
        const reclamationComment = this.reclamationComments[i];
        this.$set(reclamationComment, 'status', 'sending');
        try {
          await backgroundSendingService.sendReclamationComment(reclamationComment.id);
          reclamationComment.status = 'ok';
        } catch (error) {
          console.log(error);
          reclamationComment.status = 'error';
        }
      }

      UiEventBus.$emit('synchronization-objects-changed');
      this.syncing = false;
    },
    async loadReclamations() {
      if (await this.$isDbSupported()) {
        const recs = await database.getReclamations();
        const attachments = await database.getReclamationAttachments();
        const roomAttachments = await database.getLuovutusLiitteet();
        const statusChanges = await database.getStatusChanges();
        const reclamationComments = await database.getReclamationComments();
        recs.forEach(r => r.type = 'reclamation_row');
        attachments.forEach(r => r.type = 'reclamation_attachment');
        roomAttachments.forEach(r => r.type = 'room_image');
        statusChanges.forEach(r => r.type = 'status_change');
        reclamationComments.forEach(r => r.type = 'reclamation_comment');
        this.reclamations = recs;
        this.attachments = attachments;
        this.roomImages = roomAttachments;
        this.statusChanges = statusChanges;
        this.reclamationComments = reclamationComments;
      }
    }
  },
  computed: {
    combinedRows() {
      return [...this.reclamations, ...this.attachments, ...this.statusChanges, ...this.roomImages, ...this.reclamationComments];
    },
    unsentRows() {
      return this.combinedRows.filter(r => r.status == null);
    }
  },
  async mounted() {
    await this.loadReclamations();
  }
}
</script>

<style lang="scss" scoped>
</style>


<style lang="scss">
.sync-reclamations {

  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  .v-toolbar__extension {
    background-color: #b30000 !important;
    border-color: #b30000 !important;
  }

  @media screen and (min-width: 1264px) {
    .v-toolbar__extension {
      background-color: #fff !important;
      border-color: #fff !important;
    }
  }
}
</style>

