<template>
  <v-dialog :value="true"
            v-if="dialog"
            hide-overlay
            persistent
            content-class="movable-dialog"
            :width="$vuetify.breakpoint.xsOnly ? '' : 'auto '"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="grey darken-4 move-attach">
        <v-toolbar-title>{{ $t('tyomaaraykset.change_tyomaarain_status', {tyomaarain: tyomaarain.name}) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dismissDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-radio-group v-model="tyomaarain.tyomaarainstatus" hide-details class="mb-3" :disabled="statusDisabled">
          <v-radio
            v-for="status in tyomaarainStatuses"
            :key="status.koodi"
            :label="status.selite"
            :value="status.koodi"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            color="default"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            @click="dismissDialog"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-else>{{ $t('cancel') }}</span>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="primary"
            dark
            :loading="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            @click="saveStatus"
            v-if="!statusDisabled"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-else>{{ $t('save') }}</span>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import frendsApi from "@/frendsApi";

export default {
  name: "TyomaarainStatusDialog",
  props: {
    tyomaarainStatuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      tyomaarain: {},
      saving: false,
    }
  },
  methods: {
    async saveStatus() {
      this.saving = true;
      try {
        const response = await frendsApi.upsertTyomaarays(this.tyomaarain, this.$kielinro);
        this.$emit('status-changed', response);
        this.$store.dispatch('showNotification', {
          color: 'success',
          message: this.$t('tyomaaraykset.status_changed_succesfully'),
          showButton: false,
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('tyomaaraykset.status_change_failed'),
          showButton: false,
        });
      }
      this.saving = false;
    },
    openDialog(tyomaarain) {
      this.tyomaarain = Object.assign({}, tyomaarain);
      this.dialog = true;
    },
    dismissDialog() {
      this.dialog = false;
    },
  },
  computed: {
    statusDisabled() {
      return this.tyomaarain.tyomaarainstatus === 'ASENNETTU';
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.tyomaarain = {};
        this.$emit('dismiss', false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  .text-details {
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  @media screen and (min-width: 600px) {
    .text-content {
      max-height: calc(100vh - 300px);
      overflow: auto;
    }

    .text-details {
      font-size: 14px;
    }
  }
</style>
