<template>
  <v-container v-if="!$isCustomer" fluid fill-height ma-0 pa-0 class="task">

    <v-toolbar color="primary" dark fixed app :extended="$vuetify.breakpoint.lgAndUp && ($isTeamLeader || $isTeamMember || $isAdmin  || $isPuustelliUser)">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="tasks" />

      <v-toolbar-title style="margin-left: 0;">{{ currentlySelectedTask ? currentlySelectedTask.subject : '' }}</v-toolbar-title>

      <template v-if="$isTeamLeader || $isTeamMember || $isAdmin || $isPuustelliUser">
        <template slot="extension">
          <v-layout v-if="$vuetify.breakpoint.lgAndUp" justify-start light>
            <v-btn :loading="updateTaskStatusIsLoading" :disabled="taskIsCompleted" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline style="margin-left: 0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'" @click="markTaskAsDone">{{ $t('tasks.mark_as_done') }}</v-btn>
          </v-layout>
        </template>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="task-holder">

          <v-container fluid grid-list-md v-if="!currentlySelectedTask">
            <v-layout row wrap>
              <v-flex xs12>
                <v-alert :value="true" color="grey" icon="warning">
                  <div>{{ $t('tasks.selected_task_not_found') }}</div>
                  <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}" color="white"><v-icon left size="20">people_outline</v-icon>{{ $t('installation_groups.title') }}</v-btn>
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container v-if="currentlySelectedTask" fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>
              <v-flex xs12 lg7 xl8 :pt-0="$vuetify.breakpoint.mdAndDown">

                <v-card :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('tasks.task_details') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('tasks.task_details') }}</v-subheader>

                    <v-flex v-if="$vuetify.breakpoint.lgAndUp && taskIsCompleted" xs12>
                      <v-alert class="ma-0" outline :value="true" type="success">
                        {{ $t('tasks.task_done') }}
                      </v-alert>
                    </v-flex>

                    <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-2">
                      <v-list-tile style="margin-top: -15px;">
                        <v-list-tile-action>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">label</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title style="white-space: normal; overflow: initial; height: auto;">{{ currentlySelectedTask.subject }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset class="mt-0 mb-0"></v-divider>

                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">insert_invitation</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('tasks.deadline') }}</v-list-tile-title>
                          <v-list-tile-sub-title>{{ $formatDateNoTime(currentlySelectedTask.scheduledEnd) }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset class="mt-0 mb-0"></v-divider>

                      <v-list-tile>
                        <v-list-tile-action>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">assignment</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content v-if="currentlySelectedTask.deliveryLot">
                          <v-list-tile-title>{{ currentlySelectedTask.deliveryLot.clientName }}</v-list-tile-title>
                          <v-list-tile-sub-title>{{ currentlySelectedTask.deliveryLot.projectInfo }}</v-list-tile-sub-title>
                          <v-list-tile-sub-title>{{ currentlySelectedTask.deliveryLot.name }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-content v-if="currentlySelectedTask.project">
                          <v-list-tile-title>{{ currentlySelectedTask.project.clientName }}</v-list-tile-title>
                          <v-list-tile-sub-title>{{ currentlySelectedTask.project.customerInfo }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn v-if="currentlySelectedTask.deliveryLot" icon :to="{name: 'project', params: { id: currentlySelectedTask.deliveryLot.projectId }}"><v-icon>chevron_right</v-icon></v-btn>
                          <v-btn v-if="currentlySelectedTask.project" icon :to="{name: 'project', params: { id: currentlySelectedTask.project.id }}"><v-icon>chevron_right</v-icon></v-btn>
                        </v-list-tile-action>
                      </v-list-tile>

                    </v-list>

                  </v-card-text>
                </v-card>

                <v-layout ma-2 v-if="$vuetify.breakpoint.mdAndDown && ($isTeamLeader || $isTeamMember || $isAdmin || $isPuustelliUser)" align-center>
                  <v-flex xs12>
                    <template v-if="currentlySelectedTask.typeCode === 100000001 && ($isTeamMember || $isTeamLeader || $isAdmin || $isPuustelliUser)">
                      <v-btn v-if="!taskIsCompleted" @click="markTaskAsDone" :loading="updateTaskStatusIsLoading" large block color="success"><v-icon left>check_circle</v-icon> {{ $t('tasks.mark_as_done') }}</v-btn>
                      <v-alert v-else outline :value="true" type="success">
                        {{ $t('tasks.task_done') }}
                      </v-alert>
                    </template>
                    <template v-if="currentlySelectedTask.typeCode === 100000011 && ($isAdmin || $isPuustelliUser)">
                      <v-btn v-if="!taskIsCompleted" @click="markTaskAsDone" :loading="updateTaskStatusIsLoading" large block color="success"><v-icon left>check_circle</v-icon> {{ $t('tasks.mark_meeting_as_done') }}</v-btn>
                      <v-alert v-else outline :value="true" type="success">
                        {{ $t('tasks.start_meeting_is_done') }}
                      </v-alert>
                    </template>
                  </v-flex>
                </v-layout>

              </v-flex>

              <v-flex xs12 lg5 xl4 :pb-0="$vuetify.breakpoint.mdAndDown">

              </v-flex>
            </v-layout>
          </v-container>

        </v-flex>
      </v-layout>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "SingleTaskView",
    props: {
      activityId: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
        updateTaskStatusIsLoading: false,
      }
    },
    methods: {
      formatDeliveryWeek(deliveryWeek) {
        deliveryWeek = deliveryWeek.split(".");
        return deliveryWeek.join("/");
      },
      getDateFromWeek(week, year) {
        return moment().day("Monday").year(year).week(week).toDate();
      },
      async markTaskAsDone() {
        if (this.currentlySelectedTask.typeCode === 100000011 && !this.$isAdmin && !this.$isPuustelliUser) return;
        if (this.currentlySelectedTask.typeCode === 100000001 && !this.$isTeamMember && !this.$isTeamLeader && !this.$isAdmin && !this.$isPuustelliUser) return;
        this.updateTaskStatusIsLoading = true;
        await this.$store.dispatch('setTaskCompleted', this.activityId);
        this.updateTaskStatusIsLoading = false;
      },
      async reloadTasksForUser() {
        this.loading = true;
        await this.$store.dispatch('reloadTasksForUser');
        this.loading = false;
      },
    },
    computed: {
      currentlySelectedTask() {
        const taskIndex = this.$store.state.tasks.findIndex(task => task.activityId === this.activityId);
        if (taskIndex > -1) {
          return this.$store.state.tasks[taskIndex];
        }
        return false;
      },
      taskIsCompleted() {
        if (this.currentlySelectedTask.statecode === 1 && this.currentlySelectedTask.statuscode === 5) return true;
        return false;
      },
    },
    mounted() {
      if (this.$isCustomer) {
        this.$router.push({ name: 'projects' });
      }
      if (this.$store.state.tasks.length === 0) {
        this.reloadTasksForUser();
      } else {
        this.loading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  .task {

    .topbar-search {
      .v-input__slot {
        padding: 0 !important;
      }
    }

    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }
  }
</style>
