<template>
  <v-list-tile
    class="reclamation-list"
    :class="'status-color-' + reclamationRow.rekl_statusvari"
    avatar
    :style="{opacity: parseInt(reclamationRow.rekl_statusnro, 10) === 9 ? '0.65' : '1'}"
  >
    <div class="status-badge"></div>
    <v-list-tile-avatar>
      <v-progress-circular indeterminate v-if="reclamationRow.updatingStatus" size="18" width="2" color="primary"></v-progress-circular>
      <v-icon v-else-if="parseInt(reclamationRow.rekl_statusnro, 10) === 9" color="success">done_outline</v-icon>
      <template v-else>
        <v-layout justify-center>
          <v-checkbox
            color="primary"
            @click.stop.prevent="reclamationRow.selected = !reclamationRow.selected"
            :disabled="rowSelectionDisabled"
            v-model="reclamationRow.selected"
            hide-details
            style="margin-left: 8px; margin-top: 0;"
          ></v-checkbox>
        </v-layout>
      </template>
    </v-list-tile-avatar>

    <v-list-tile-content style="cursor: pointer;" @click="reclamationRowClicked">
      <v-list-tile-title class="font-weight-bold">{{ reclamationRow.malliselite ? reclamationRow.rekl_tuoteselite : $t('reclamation.no_product') }}</v-list-tile-title>
      <v-list-tile-sub-title class="caption text--primary">
        <v-layout row wrap>
          <v-flex xs6 md4><strong>{{ $t('reclamation.apartment') }}:</strong> {{ reclamationRow.apartment ? reclamationRow.apartment.name : $t('reclamation.no_apartment') }}</v-flex>
          <v-flex xs6 md4><strong>{{ $t('reclamation.type') }}:</strong> {{ reclamationRow.rekl_tyyppiselite }}</v-flex>
          <v-flex xs6 md4><strong>{{ $t('reclamation.order_number') }}:</strong> {{ reclamationRow.rekl_tilausnro ? reclamationRow.rekl_tilausnro : $t('reclamation.no_order_number') }}</v-flex>
          <v-flex xs6 md4>
            <strong>{{ $t('reclamation.status') }}:</strong> {{ reclamationRow.rekl_statusteksti ? reclamationRow.rekl_statusteksti : '' }}
            <template v-if="reclamationRow.rekl_rivinstatuspvm">
              ({{ $formatDateNoTime(reclamationRow.rekl_rivinstatuspvm) }})
            </template>
          </v-flex>
          <v-flex xs6 md4 v-if="reclamationRow.rekl_jakelupvm"><strong>{{ $t('reclamation.delivery') }}:</strong> {{$formatDateIncludeWeekday(reclamationRow.rekl_jakelupvm) }}</v-flex>
          <v-flex xs6 md4 v-else-if="reclamationRow.rekl_jakeluarvio"><strong>{{ $t('reclamation.delivery_estimate') }}:</strong> {{ reclamationRow.rekl_jakeluarvio }}</v-flex>
          <v-flex xs6 md4 v-else><strong>{{ $t('reclamation.delivery') }}:</strong> {{ $t('reclamation.no_delivery_date') }}</v-flex>
          <v-flex xs6 md4><strong>{{ $t('reclamation.created') }}:</strong> {{ $formatDateNoTime(reclamationRow.rekl_rivinperustamispvm) }}</v-flex>
          <v-flex xs6 md4><strong>{{ $t('reclamation.rekltoimeranimi') }}:</strong> {{ reclamationRow.rekltoimeranimi ? reclamationRow.rekltoimeranimi : '' }}</v-flex>
          <v-flex xs6 md4><strong>{{ $t('reclamation.rekltoimeranro') }}:</strong> {{ reclamationRow.rekltoimeranro ? reclamationRow.rekltoimeranro : '' }}</v-flex>
          <v-flex xs6 md4 v-if="vuositarkastus"><strong>{{ $t('reclamation.vuositarkastus') }}</strong></v-flex>
        </v-layout>
      </v-list-tile-sub-title>
      <v-list-tile-sub-title class="caption mt-2">{{ reclamationRow.rekl_selite }}</v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
export default {
  props: ['reclamationRow', 'updatingStatuses'],
  name: "ReclamationListItem",
  computed: {
    vuositarkastus() {
      return this.reclamationRow.vuositarkastus === "true";
    },
    rowSelectionDisabled() {
      const row = this.reclamationRow;
      const rowStatus = parseInt(row.rekl_statusnro, 10);

      if (this.updatingStatuses) return true;

      if (rowStatus === 4 || rowStatus === 6 || rowStatus === 7) {
        if (this.selectedReclamationStatus === 4 || this.selectedReclamationStatus === 6) {
          return !(rowStatus === 4 || rowStatus === 6);
        }
        if (this.selectedReclamationStatus === 7) {
          return rowStatus !== 7;
        }
        return false;
      }
      return true;
    }
  },
  methods: {
    reclamationRowClicked() {
      this.$emit('row-clicked', this.reclamationRow);
    }
  },
}
</script>

<style scoped>

</style>
