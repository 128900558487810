<template>
  <v-layout :style="{height: $vuetify.breakpoint.mdAndUp ? '60px' : '52px'}" class="pr-2" :class="{'v-list--dense': $vuetify.breakpoint.smAndDown}">
    <div :style="{position: 'absolute', left: $vuetify.breakpoint.mdAndUp ? '6px' : '12px', top: $vuetify.breakpoint.mdAndUp ? 0 : '4px' }">
      <v-avatar :color="avatarColor" v-if="item.showWeekNumber"
                :size="$vuetify.breakpoint.mdAndUp ? 56 : 44" class="elevation-2">
        <span :class="item.isCurrentWeek ? 'white--text text--darken-1' : 'grey--text text--darken-1'"
              class="font-weight-bold">
        {{ item.weekNumber }}
          </span>
      </v-avatar>
    </div>
    <v-flex xs12 style="padding-left: 68px">
      <calendar-timeline-week v-if="item.type === 'week'" :item="item"></calendar-timeline-week>
      <calendar-timeline-delivery-lot v-else-if="item.type === 'deliverylot'" :item="item" :project-colors="projectColors"></calendar-timeline-delivery-lot>
      <calendar-timeline-task v-else-if="item.type === 'task'" :item="item"></calendar-timeline-task>
      <calendar-timeline-tyomaarays v-else-if="item.type === 'tyomaarays'" :item="item"></calendar-timeline-tyomaarays>
      <div v-else-if="item.type === 'empty'"></div>
    </v-flex>
  </v-layout>
</template>

<script>
import CalendarTimelineTask from "@/components/Calendar/CalendarTimelineTask";
import CalendarTimelineWeek from "@/components/Calendar/CalendarTimelineWeek";
import CalendarTimelineDeliveryLot from "@/components/Calendar/CalendarTimelineDeliveryLot";
import CalendarTimelineTyomaarays from "@/components/Calendar/CalendarTimelineTyomaarays";

// :style="{fontSize: $vuetify.breakpoint.mdAndUp ? '13px' : '11px'}">

export default {
  components: {
    CalendarTimelineTyomaarays,
    CalendarTimelineDeliveryLot, CalendarTimelineWeek, CalendarTimelineTask},
  props: ['item', 'projectColors'],
  name: 'CalendarTimelineItem',
  computed: {
    avatarColor() {
      if (this.item.isCurrentWeek) {
        return 'primary';
      } else if (this.item.type === 'empty') {
        return '#f5f5f5 '; // grey lighten-2
      } else {
        return 'white';
      }
    }
  },
}
</script>

<style scoped>

</style>
