<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid fill-height ma-0 pa-0 class="room">

    <v-toolbar color="primary" dark fixed app :extended="($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser) && !loading">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="project" :fallback-params="{id: project ? project.id : '' }" />

      <v-toolbar-title style="margin-left: 0;">{{ this.apartment ? this.apartment.name : '' }}</v-toolbar-title>

      <template slot="extension">
        <v-layout v-if="!loading" justify-start light>
          <v-btn :disabled="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="openStatusDialog"
                 v-if="($isAdmin || $isTeamMember || $isTeamLeader || $isPuustelliUser)">
            {{ $t('room.change_status') }}
          </v-btn>
          <v-btn :disabled="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 :to="{ name: 'roomhandoverprint', params: { deliveryLotId: deliveryLotId, projectId: projectId, vertexProjectNumber: vertexProjectNumber, vertexTargetNumber: vertexTargetNumber, apartmentName: apartment && apartment.name, project: project}}">
            {{ $t('room.print_handover_info') }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <v-fade-transition>
      <v-layout row wrap v-if="!loading">
        <v-flex xs12 class="room-holder" :class="{'status-dialog-open': $refs.statusDialog.dialog}">
          <v-container fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>

              <v-flex xs12 lg5 xl4 :pb-0="$vuetify.breakpoint.mdAndDown">
                <v-card v-if="project" :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}" data-cy="apartment-details">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('room.room_info') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('room.room_info') }}</v-subheader>
                    <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 height-auto">

                      <v-list-tile >
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">place</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content @click="goToProject">
                          <v-list-tile-title>{{ project.clientName }}</v-list-tile-title>
                          <v-list-tile-sub-title v-if="deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.streetAddress }}, {{ deliveryLot.city }}, {{ deliveryLot.postalCode }}</v-list-tile-sub-title>
                          <v-list-tile-sub-title v-else-if="project.streetAddress != null && project.streetAddress.length > 0" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ project.streetAddress }}, {{ project.city }}, {{ project.postalCode }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-menu bottom left>
                            <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                            <v-list>
                              <v-list-tile :href="mapsAddress" target="_blank" v-if="(deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0) || (project.streetAddress != null && project.streetAddress.length > 0)">
                                <v-list-tile-action><v-icon>map</v-icon></v-list-tile-action>
                                <v-list-tile-title>{{ $t('projects.show_on_map') }}</v-list-tile-title>
                              </v-list-tile>
                              <v-divider v-if="(deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0) || (project.streetAddress != null && project.streetAddress.length > 0)"></v-divider>
                              <v-list-tile exact :to="{name: 'project', params: {id: project.id}}">
                                <v-list-tile-action><v-icon>assignment</v-icon></v-list-tile-action>
                                <v-list-tile-title>{{ $t('projects.move_to_project') }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </v-list-tile-action>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile exact exact-active-class="" :to="{name: 'deliverylot', params: {projectId: project.id, deliveryLotId: deliveryLot.id}}">
                        <v-list-tile-avatar>
                          <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-truck-loading</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('room.delivery_lot') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.name }}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action>
                          <v-icon>chevron_right</v-icon>
                        </v-list-tile-action>
                      </v-list-tile>

                      <template v-if="apartment.puusnetOrderNumber">
                        <v-divider inset></v-divider>

                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">file_copy</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('room.puusnet_order_number') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ apartment.puusnetOrderNumber }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>

                      <template v-if="apartment.installationUnits && $showInstallationUnits">
                        <v-divider inset></v-divider>

                        <v-list-tile>
                          <v-list-tile-avatar>
                            <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-screwdriver</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('room.installation_units') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $roundNumber(apartment.installationUnits, 1) }} {{ $t('delivery_lot.pcs') }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>

                      <v-divider inset></v-divider>

                    </v-list>

                    <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0 height-auto">
                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon size="24" :color="statusColor">{{ statusIcon }}</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ statusDescription }}</v-list-tile-title>
                          <v-list-tile-sub-title v-if="apartment.handOverComments" :class="{caption: $vuetify.breakpoint.xsOnly}"><span style="white-space: pre-wrap">{{ apartment.handOverComments }}</span></v-list-tile-sub-title>
                          <v-list-tile-sub-title v-if="statusUserInfo" :class="{caption: $vuetify.breakpoint.xsOnly}"><span style="white-space: pre-wrap">{{ statusUserInfo }}</span></v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>

                    <v-list v-if="reclamationRows.length > 0 && $showReclamations" two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-2 pt-0 height-auto">
                      <v-divider inset class="mt-0 mb-0"></v-divider>
                      <v-list-tile :to="{name: 'reclamations', params: { projectId, deliveryLotId, vertexProjectNumber, vertexTargetNumber, resetFilters: true }}">
                        <v-list-tile-avatar>
                          <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-exchange-alt</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $tc('reclamation.title', 2) }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('reclamation.total_reclamations', { reclamationCount: reclamationRows.length }) }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-icon>chevron_right</v-icon>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>

                  </v-card-text>
                </v-card>

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}" data-cy="apartment-pictures">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('room.general_pictures') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-progress-linear v-if="loadingAttachments" height="2" indeterminate class="pt-0 mb-0 pb-0 mt-0"  />
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('room.general_pictures') }}</v-subheader>
                    <v-container grid-list-sm fluid>
                      <handover-attachments :attachments="attachments"
                                            :vertex-project-number="vertexProjectNumber"
                                            :project-name="project.clientName"
                                            :vertex-room-number="vertexTargetNumber"
                                            :room-name="apartment.name"
                                            @attachments-uploaded="reloadHandOverAttachments"
                                            @attachments-added-to-sync="attachmentsAddedToSync"
                                            @attachment-deleted="attachmentDeleted"
                      ></handover-attachments>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}" data-cy="apartment-pictures">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('room.installation_documents') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-progress-linear v-if="loadingInstallationDocuments" height="2" indeterminate class="pt-0 mb-0 pb-0 mt-0"  />
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('room.installation_documents') }}</v-subheader>
                    <v-container fluid>
                      <template v-for="(file) in installationDocuments">
                        <v-layout :key="`tr-1-${file.fileid}`" class="mb-3" :class="[$vuetify.breakpoint.mdAndDown && 'mt-2 mb-5']">
                          <v-flex
                            xs9
                          >
                            <a :href="file.public_url" target="_blank">{{ file.tiedostonimi}}</a>
                          </v-flex>
                          <v-flex
                            xs3
                          >
                            {{ $formatDate(file.modified || file.created) }}
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-container>
                  </v-card-text>
                </v-card>

              </v-flex>
              <v-flex xs12 lg7 xl8 :pt-0="$vuetify.breakpoint.mdAndDown">

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}" data-cy="apartment-units">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    <span>{{ $t('room.units') }}</span>
                    <v-spacer></v-spacer>
                    <v-menu bottom left v-if="$showReclamations">
                      <v-btn small icon slot="activator" style="margin: -4px 0;"><v-icon color="white">more_vert</v-icon></v-btn>
                      <v-list class="pt-0 pb-0">
                        <v-list-tile @click="openAddUnitToReclamationDialog(null)">
                          <v-list-tile-action><v-icon size="20">fas fa-exchange-alt</v-icon></v-list-tile-action>
                          <v-list-tile-title>{{ $t('room.make_reclamation_without_unit') }}</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="openAddSelectedToReclamationDialog">
                          <v-list-tile-action><v-icon size="20">fas fa-exchange-alt</v-icon></v-list-tile-action>
                          <v-list-tile-title>{{ $t('room.make_reclamation_selected_units') }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown" class="white justify-space-between">
                      <span>{{ $t('room.units') }}</span><br />
                      <v-spacer></v-spacer>
                      <v-menu bottom left v-if="$showReclamations">
                        <v-btn small icon slot="activator" style="margin: -4px 0;"><v-icon color="grey darken-1">more_vert</v-icon></v-btn>
                        <v-list class="pt-0 pb-0">
                          <v-list-tile @click="openAddUnitToReclamationDialog(null)">
                            <v-list-tile-action><v-icon size="20">fas fa-exchange-alt</v-icon></v-list-tile-action>
                            <v-list-tile-title>{{ $t('room.make_reclamation_without_unit') }}</v-list-tile-title>
                          </v-list-tile>
                          <v-list-tile @click="openAddSelectedToReclamationDialog">
                            <v-list-tile-action><v-icon size="20">fas fa-exchange-alt</v-icon></v-list-tile-action>
                            <v-list-tile-title>{{ $t('room.make_reclamation_selected_units') }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                    </v-subheader>
                    <v-container fluid grid-list-xs pa-3>
                      <v-layout row wrap>
                        <v-flex xs12 sm4 lg2>
                          <v-text-field
                            :placeholder="$t('room.search_with_drawing_id')"
                            v-model="filterDrawingId"
                            single-line
                            full-width
                            outline
                            hide-details
                            color="grey darken-3"
                          >
                            <v-icon style="cursor: pointer;" slot="append">fas fa-hashtag</v-icon>
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 lg5>
                          <v-text-field
                            :placeholder="$t('room.search_for_unit')"
                            v-model="searchPhrase"
                            single-line
                            full-width
                            outline
                            hide-details
                            color="grey darken-3"
                          >
                            <v-icon style="cursor: pointer;" slot="append">filter_list</v-icon>
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 lg5>
                          <v-select
                            :items="makeFilters"
                            :placeholder="$t('room.search_with_make')"
                            v-model="filterMake"
                            single-line
                            full-width
                            outline
                            hide-details
                            clearable
                            color="grey darken-3"
                          >
                            <v-icon style="cursor: pointer;" slot="append">extension</v-icon>
                          </v-select>
                        </v-flex>
                        <v-flex xs12 v-if="$showReclamations">
                          <v-layout :class="$vuetify.breakpoint.mdAndDown ? 'mt-2' : 'mt-0'" class="justify-space-between align-center">
                            <div>
                                <span class="pr-2 subheading">{{ $t('room.reclamation_filter_show_label') }}</span>
                                <v-btn-toggle v-model="filterBy" mandatory>
                                  <v-btn flat value="none" :small="$vuetify.breakpoint.xsOnly">
                                    <span>{{ $t('room.all') }}</span>
                                  </v-btn>
                                  <v-btn flat value="reclamations" :small="$vuetify.breakpoint.xsOnly">
                                    <span>{{$t('room.reclamations')}}</span>
                                  </v-btn>
                                  <v-btn flat value="post-delivery" :small="$vuetify.breakpoint.xsOnly">
                                    <span>{{ $t('room.post-deliveries')}}</span>
                                  </v-btn>
                                </v-btn-toggle>

                                <v-btn-toggle v-model="selectMultiple" class="" :class="{ 'ml-2': $vuetify.breakpoint.smAndUp , 'mt-2': $vuetify.breakpoint.xsOnly}" >
                                  <v-btn flat :value="true" :small="$vuetify.breakpoint.xsOnly" @click.stop="selectMultipleClick">
                                    <v-icon left size="14" v-if="selectMultiple">far fa-check-square</v-icon>
                                    <v-icon left size="14" v-else>far fa-square</v-icon>
                                    <span>{{$t('room.multi_product_reclamation_toggle')}}</span>
                                  </v-btn>
                                </v-btn-toggle>
                            </div>
                            <div v-if="$vuetify.breakpoint.smAndUp && selectMultiple && $showReclamations" :style="{ 'margin-right': $vuetify.breakpoint.mdAndDown ? '-13px' : '-10px' }">
                              <v-checkbox :disabled="!selectMultiple" hide-details @click.stop="reclamationCheckboxClick" class="pa-0 ma-0"
                                          :indeterminate="visibleSelectedUnits.length > 0 && selectedUnits.length !== selectableUnits.length" :value="visibleSelectedUnits.length > 0"></v-checkbox>
                            </div>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-divider v-if="$vuetify.breakpoint.lgAndUp"></v-divider>

                    <v-progress-linear v-if="loadingUnits" height="2" indeterminate class="pt-0 mb-0 pb-0" style="margin-top: -2px;" />
                    <template v-for="item in units">
                      <unit-list-item :select-multiple="selectMultiple" :style="{display: item.visible ? 'block': 'none'}" :item="item" :key="item.niputus" :has-reclamation-sync="item.hasReclamationSync" @add-unit-to-reclamation="openAddUnitToReclamationDialog" />
                    </template>

                    <v-container fluid>
                      <v-layout v-if="$showReclamations">
                        <v-flex xs12>
                          <v-btn @click="openAddUnitToReclamationDialog(null)"
                                 :small="$vuetify.breakpoint.xsOnly" block outline>
                            <v-icon left size="18">fas fa-exchange-alt</v-icon>
                            {{ $t('room.make_reclamation_without_unit') }}
                          </v-btn>
                          <template v-if="selectMultiple">
                          <v-btn @click="openAddSelectedToReclamationDialog"
                                 :small="$vuetify.breakpoint.xsOnly"
                                 :disabled="selectedUnits.length === 0" block outline>
                            <v-icon left size="18">fas fa-exchange-alt</v-icon>
                            {{ $t('room.make_reclamation_selected_units') }}
                          </v-btn>
                          <v-btn v-if="$vuetify.breakpoint.xsOnly" small @click="clearSelected"
                                 :disabled="selectedUnits.length === 0" block outline>
                            {{ $t('room.clear_selected_reclamation_units') }}
                          </v-btn>
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-container>

                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <add-unit-to-reclamation-dialog ref="reclamationDialog"
                                    :projectDTO="projectDTO"
                                    :project="project"
                                    :delivery-lot="deliveryLot"
                                    :apartment="apartment"
                                    :puustelliContactPersons="puustelliContactPersons"
                                    @reclamation-added-to-sync="reclamationAddedToSync"
                                    @reclamation-added="reclamationAdded"/>

    <status-dialog ref="statusDialog"
                   :system-data="systemData"
                   @room-status-changed="handleRoomStatusUpdate"
                   :attachments="attachments"
                   @reload-attachments="reloadHandOverAttachments"
                   @attachment-deleted="attachmentDeleted" />

  </v-container>
</template>

<script>
import frends from '../frendsApi.js';
import UnitListItem from '../components/SingleRoomView/UnitListItem.vue';
import AddUnitToReclamationDialog
  from "../components/SingleRoomView/AddUnitToReclamationDialog.vue";
import StatusDialog from "@/components/SingleRoomView/StatusDialog";
import database from "@/database";
import parameters from "@/parameters";
import HandoverAttachments from "@/components/SingleRoomView/HandoverAttachments";

const roomStatuses = parameters.roomStatuses;

  export default {
    name: "SingleRoomView",
    components: {
      HandoverAttachments,
      UnitListItem: UnitListItem,
      AddUnitToReclamationDialog: AddUnitToReclamationDialog,
      StatusDialog: StatusDialog,
    },
    props: {
      projectId: {
        type: String,
        default: '',
      },
      deliveryLotId: {
        type: String,
        default: '',
      },
      vertexProjectNumber: {
        type: String,
        default: '',
      },
      vertexTargetNumber: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        selectMultiple: false,
        filterBy: 'none',
        loading: true,
        loadingUnits: true,
        attachments: [],
        updatingStatus: false,
        searchPhrase: '',
        filterDrawingId: '',
        filterMake: '',
        makeFilters: [],
        units: [],
        reclamationRows: [],
        showOnlyReclamations: false,
        reclamationsInDB: [],
        loadingAttachments: false,
        installationDocuments: [],
        loadingInstallationDocuments: false
      }
    },
    computed: {
      visibleSelectedUnits() {
        return this.selectedUnits.filter(u => u.visible);
      },
      selectableUnits() {
        return this.units.filter(u => u.saa_reklamoida === "true");
      },
      selectedUnits() {
        const r = (u, l) => {
          if (u.selected) {
            l.push(u);
          }
          if (u.children) {
            u.children.forEach(c => {
              r(c, l);
            })
          }
        }
        const selected = [];
        this.units.forEach(u => r(u, selected));
        return selected;
      },
      puustelliContactPersons() {
        const puustelliContactPersons = [];
        const owner = Object.assign({}, this.projectDTO.owner);
        if (owner) {
          owner.title = [this.$t('projects.titles.owner')];
          puustelliContactPersons.push(owner);
        }
        const clientSecretary = Object.assign({}, this.projectDTO.clientSecretary);
        if (clientSecretary) {
          const contactIndex = puustelliContactPersons.findIndex(c => c.id === clientSecretary.id);
          if (contactIndex > -1) {
            puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.clientSecretary'));
          } else {
            clientSecretary.title = [this.$t('projects.titles.clientSecretary')];
            puustelliContactPersons.push(clientSecretary);
          }
        }
        const secretary = Object.assign({}, this.projectDTO.secretary);
        if (secretary) {
          const contactIndex = puustelliContactPersons.findIndex(c => c.id === secretary.id);
          if (contactIndex > -1) {
            puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.secretary'));
          } else {
            secretary.title = [this.$t('projects.titles.secretary')];
            puustelliContactPersons.push(secretary);
          }
        }
        const manager = Object.assign({}, this.projectDTO.manager);
        if (manager) {
          const contactIndex = puustelliContactPersons.findIndex(c => c.id === manager.id);
          if (contactIndex > -1) {
            puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.manager'));
          } else {
            manager.title = [this.$t('projects.titles.manager')];
            puustelliContactPersons.push(manager);
          }
        }
        return puustelliContactPersons;
      },
      mapsAddress() {
        const objectUsedForMapsUrl = this.deliveryLot.streetAddress && this.deliveryLot.streetAddress.length > 0 ? this.deliveryLot : this.project;
        return `https://maps.google.com?q=${objectUsedForMapsUrl.streetAddress}+${objectUsedForMapsUrl.city}+${objectUsedForMapsUrl.postalCode}`;
      },
      project() {
        return this.$store.state.projectDTO.project;
      },
      projectDTO() {
        return this.$store.state.projectDTO;
      },
      apartment() {
        if (this.deliveryLot) {
          return this.deliveryLot.apartments.find(a => a.vertexTargetNumber === this.vertexTargetNumber && a.vertexProject === this.vertexProjectNumber);
        }
        return null;
      },
      deliveryLot() {
        if (this.projectDTO && this.projectDTO.deliveryLots) {
          return this.projectDTO.deliveryLots.find(lot => lot.id === this.deliveryLotId);
        }
        return null;
      },
      systemData() {
        return this.$store.state.systemData;
      },
      statusDescription() {
        const statuses = this.systemData.apartmentStatuses;
        const statusCode = this.apartment.statusCode;
        const status = statuses.find(s => s.key === statusCode);
        return status.value;
      },
      statusUserInfo() {
        return [this.$formatDate(this.apartment.statusTimestamp), this.apartment.statusUser, this.apartment.statusEmail].filter(x => !!x).join(', ');
      },
      statusIcon() {
        return roomStatuses[this.apartment.statusCode].icon;
      },
      statusColor() {
        return roomStatuses[this.apartment.statusCode].color;
      },
    },
    watch: {
      searchPhrase() {
        this.units.forEach(row => {
          this.recurseFindUnits(row);
        })
      },
      filterDrawingId() {
        this.units.forEach(row => {
          this.recurseFindUnits(row);
        })
      },
      filterMake() {
        this.units.forEach(row => {
          this.recurseFindUnits(row);
        })
      },
      filterBy() {
        this.units.forEach(row => {
          this.recurseFindUnits(row);
        });
      },
    },
    methods: {
      clearSelected() {
        this.visibleSelectedUnits.forEach(u => this.$set(u, 'selected', false));
      },
      selectMultipleClick() {
        this.selectedUnits.forEach(u => this.$set(u, 'selected', false));
      },
      reclamationCheckboxClick() {
        console.log(this.selectedUnits, 'reclamationcheckbox')
        if (this.visibleSelectedUnits.length === 0) {
          this.selectableUnits.forEach(u => {
            if (u.visible) {
              this.$set(u, 'selected', true);
            }
          });
        } else {
          this.clearSelected();
        }
      },
      reclamationAdded(unit) {
        if (unit) {
          unit.reklamoitu = "true";
          unit.selected = false;
          this.selectMultiple = false;
        }
      },
      handleRoomStatusUpdate(apartment) {
        this.apartment.statusCode = apartment.statusCode;
        this.apartment.statusDescription = apartment.statusDescription;
        this.apartment.handOverComments = apartment.handOverComments;
      },
      openStatusDialog() {
        this.$refs.statusDialog.openDialog(this.apartment, this.project);
      },
      goToProject() {
        this.$router.push({name: 'project', params: {id: this.project.id}});
      },
      unitHasReclamationSync(unit) {
        const projektinro = unit.projektinro;
        const huonenro = unit.huonenro;
        const rivinro = unit.rivinro;

        const unitIndex = this.reclamationsInDB.findIndex(reclamation => {
          return reclamation.projektinro === projektinro
          && reclamation.huonenro === huonenro
          && reclamation.rivinro === rivinro
          && reclamation.tilausnro === this.apartment.puusnetOrderNumber;
        });

        return unitIndex > -1;
      },
      reclamationAddedToSync() {
        this.$store.dispatch('showNotification',
          {
            color: 'warning',
            message: this.$t('reclamation.added_to_sync'),
            timeOut: 10000,
            showButton: false,
          });
        this.selectMultiple = false;
      },
      async reloadApartmentReclamations() {
        try {
          let rivit = await frends.getReklamaatioRivit(this.projectId, null, this.vertexProjectNumber, this.vertexTargetNumber, this.$kielinro);
          rivit = rivit.filter(rivi => parseInt(rivi.huonenro, 10) === parseInt(this.vertexTargetNumber, 10) && this.vertexProjectNumber === rivi.projektinro);
          this.reclamationRows = rivit;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadApartmentReclamations();
              }
            });
        }
      },
      openAddUnitToReclamationDialog(unit) {
        this.$refs.reclamationDialog.openDialog([unit]);
      },
      openAddSelectedToReclamationDialog() {
        const selectedUnits = this.selectedUnits;
        if (selectedUnits.length > 0) {
          this.$refs.reclamationDialog.openDialog(selectedUnits);
        }
      },
      recurseFindUnits(row) {
        if (row.children.length > 0) {
          row.children.forEach(child => {
            this.recurseFindUnits(child);
          });
        }

        let foundDrawingId = true;
        if (this.filterDrawingId.length > 0 && row.piirtoid + '' !== this.filterDrawingId) {
          foundDrawingId = false;
        }

        row.highlight = false;

        const foundMake = row.valmiste && row.valmiste.toLowerCase().includes(this.filterMake ? this.filterMake.toLowerCase() : '');
        if (foundMake && this.filterMake && this.filterMake.length > 0) {
          row.highlight = true;
        }

        const found = this.phraseFound(row);
        if (found && this.searchPhrase.length > 0) {
          row.highlight = true;
        }

        let filterByReclamation = true;
        if (this.filterBy === 'reclamations' && (row.reklamoitu !== "true" && !row.hasReclamationSync)) {
          filterByReclamation = false;
        } else if (this.filterBy === 'post-delivery' && row.jalkitoimitus !== "true") {
          filterByReclamation = false;
        }

        if (found && foundDrawingId && foundMake && filterByReclamation) {
          row.visible = true;
          row.active = false;
        } else if (row.children.findIndex(child => child.visible) > -1) {
          row.visible = true;
          row.active = true;
        } else {
          row.visible = false;
          row.active = false;
        }
      },
      phraseFound(row) {
        const elements = [row.malliselite, row.vari, row.valmiste, row.m1, row.m2, row.m3, row.katisyys];
        return elements
          .filter(e => e != null)
          .map(e => e.toString().toLowerCase())
          .some(e => e.indexOf(this.searchPhrase.toLowerCase()) > -1);
      },
      resolveTree(frames) {
        const root = { niputus: '1', children: [], malliselite: null, visible: true };
        const allRoots = [];
        const stack = [root];
        frames.forEach(f => {
          f.visible = true;
          f.highlight = false;
          f.active = false;
          f.children = [];
          f.projectId = this.projectId;
          f.vertexTargetNumber = this.vertexTargetNumber;
          f.hasReclamationSync = this.unitHasReclamationSync(f);

          const niputusParts = f.niputus.split(".");
          let last = stack[stack.length - 1];
          let lastNiputusParts = last.niputus.split(".");
          while (niputusParts.length <= lastNiputusParts.length) {
            stack.pop();
            last = stack[stack.length - 1];
            lastNiputusParts = last.niputus.split(".");
          }
          // first level and niputus changes(for example, 1.1 -> 2.1, so change the root from "1" to "2" because "2" does not exist.)
          if (lastNiputusParts.length === 1 && lastNiputusParts[0] !== niputusParts[0]) {
            allRoots.push(stack.pop());
            last = { niputus: `${niputusParts[0]}`, children: [], malliselite: null };
            stack.push(last);
          }

          if (lastNiputusParts.length === 1 && niputusParts.length === 2 && niputusParts[1] === '1') {
            last.malliselite = f.malliselite;
            last.vari = f.niputus + ' - ' + f.piirtoid;
          }

          last.children.push(f);
          stack.push(f);
        });

        if (stack.length > 0) {
            allRoots.push(stack[0]);
        }

        const topLevel = [];
        allRoots.forEach(root => root.children.forEach(c => topLevel.push(c)));

        this.units = topLevel;
      },
      async getRoomUnits() {
        try {
          const units = await frends.getTuotteet(this.vertexProjectNumber, this.vertexTargetNumber, this.$kielinro);
          this.resolveTree(units);
          let makeFilters = [];
          units.forEach(row => {
            if (makeFilters.findIndex(f => f === row.valmiste) === -1) makeFilters.push(row.valmiste);
          });
          this.makeFilters = makeFilters;
        } catch (error) {
          console.log(error);
        }
      },
      async reloadAll() {
        this.loadingAttachments = true;
        this.loading = true;
        this.loadingUnits = true;
        try {
          await this.reloadProject();
          this.loading = false;

          const attachmentPromise = this.reloadHandOverAttachments();
          this.reloadInstallationDocuments();
          await this.reloadApartmentReclamations();
          await this.getRoomUnits();

        } catch (error) {
        }

        this.loading = false;
        this.loadingUnits = false;
      },
      async attachmentDeleted(img) {
        this.attachments = this.attachments.filter(a => a.fileid !== img.fileid);
        await this.reloadHandOverAttachments();
      },
      attachmentsAddedToSync() {
        this.$store.dispatch('showNotification',
          {
            color: 'warning',
            message: this.$t('room.attachment_added_to_sync'),
            timeOut: 10000,
            showButton: false,
          });
      },
      async reloadHandOverAttachments() {
        this.loadingAttachments = true;
        try {
          const liitteet = await frends.getLuovutusliitteet(this.vertexProjectNumber, this.vertexTargetNumber);
          this.attachments = liitteet.map(file => {
            file.alt = this.$formatDate(file.modified);
            return file;
          });
        } catch (error) {
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('room.failed_to_fetch_handover_attachments'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadApartmentReclamations();
              }
            });
        }
        this.loadingAttachments = false;
      },
      async reloadProject() {
        if (!this.$store.state.projectDTO.project || this.$store.state.projectDTO.project.id !== this.projectId) {
          await this.$store.dispatch('reloadProjectDTO', this.projectId);
        } else {
          this.$store.dispatch('reloadProjectDTO', this.projectId);
        }
      },
      async reloadInstallationDocuments(){
        if(!this.apartment.puusnetOrderNumber){
          return;
        }
        this.loadingInstallationDocuments = true;
        try {

          this.installationDocuments = await frends.getAsennusDokumentit(this.apartment.puusnetOrderNumber, this.$kielinro);
        } catch (error) {
          console.log(error);
        }
        this.loadingInstallationDocuments = false;
      }
    },
    async mounted() {
      if (await this.$isDbSupported()) {
        this.reclamationsInDB = await database.getReclamations();
      }
      this.reloadAll();
    },
  }
</script>

<style lang="scss" scoped>
  .status-dialog-open {
    padding-bottom: 408px;
  }
</style>

<style lang="scss">
  .room {
    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }

    .topbar-search {
      .v-input__slot {
        padding: 0 !important;
      }
    }

    .height-auto {
      .v-list__tile {
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .v-list__tile__avatar {
        margin-top: unset;
      }
    }

    .unit-title {
      font-size: 12px !important;
      line-height: 16px;
      display: block;
    }

    .unit-details {
      display: block;
    }

    .v-treeview-node__root {
      height: auto;
    }

    @media screen and (min-width: 960px) {
      .unit-title {
        font-size: 15px !important;
        line-height: 18px;
      }
    }
  }
</style>
