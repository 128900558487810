<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('projects.installation_card') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 text-content">
          <v-list three-line :dense="$vuetify.breakpoint.mdAndDown">
            <template v-for="key in Object.keys(installationCardObjects)">
              <v-list-tile :key="key + '_tile'">
                <v-list-tile-content>
                  <v-list-tile-title class="font-weight-bold body-1">{{ $t('projects.installation_card_objects.' + key) }}</v-list-tile-title>
                  <v-list-tile-sub-title class="font-weight-medium">{{ getInstallationCardDesc(key) }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>{{ installationCardObjects[key].details }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>

          <div class="ma-3 pb-4">
            <div class="font-weight-bold body-1">{{ $t('projects.installation_card_objects.asennuksestaSovittua') }}</div>
            <div class="font-weight-medium text-details">{{ installationCard['asennuksestaSovittua'] ? installationCard['asennuksestaSovittua'] : '-' }}</div>
          </div>

        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    name: "InstallationCardDialog",
    props: {
      installationCard: {
        type: [Boolean, Object],
        default: false,
      }
    },
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
      getInstallationCardDesc(key) {
        const puusnetKey = this.keys[key];
        const options = this.systemData.projectOptions[puusnetKey];
        const value = this.installationCard[key];
        const option = options.find(o => o.key === value);
        if (option == null) {
          return '-';
        }
        return option.value;
      },
      openDialog() {
        this.dialog = true;
      },
      dismissDialog() {
        this.dialog = false;
      },
    },
    computed: {
      systemData() {
        return this.$store.state.systemData;
      },
      keys() {
        const keys = {
          "tulvariJaAplivi": "new_tulvarijaaplivi",
          "kodinkoneAsennus": "new_kodinkoneasennus",
          "ledValot": "new_ledvalot",
          "kylpyhuonekalusteAsennus": "new_kylpyhuonekalusteetasennus",
          "liesituuletinAsennus": "new_liesituuletinasennus",
          "pistorasianKehyksenAsennus": "new_pistorasiankehyksenasennus",
          "allasToimittaja": "new_allastoimittaja",
          "altaanAsennus": "new_altaanasennus",
          "valitilaMateriaalinToimittaja": "new_vlitilamateriaalintoimittaja",
          "valitilaMateriaalinAsennus": "new_vlitilamateriaalinasennus",
          "malliasunto": "new_malliasunto",
        };

        return keys;
      },
      installationCardObjects() {
        if (!this.installationCard) return {};
        let installationCardObjects = {};
        Object.keys(this.installationCard).forEach(key => {
          if (key.includes('Details')) return;
          if (key.includes('asennuksestaSovittua')) return;
          installationCardObjects[key] = {
            value: this.installationCard[key],
            details: this.installationCard[key + 'Details']
          }
        });
        return installationCardObjects;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  .text-details {
    white-space: pre-line;
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  @media screen and (min-width: 600px) {
    .text-content {
      max-height: calc(100vh - 300px);
      overflow: auto;
    }

    .text-details {
      font-size: 14px;
    }
  }
</style>
