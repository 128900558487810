<template>
  <v-dialog :value="true"
            v-if="dialog"
            hide-overlay
            persistent

            content-class="movable-dialog"
            :width="$vuetify.breakpoint.xsOnly ? '' : 'auto '"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <div :class="newInfo ? 'resizable-small' : 'resizable'">
      <v-card class="fill-height">
        <v-toolbar dark color="grey darken-4 move-attach">
          <v-toolbar-title>
            <template v-if="newInfo">{{ $t('delivery_lot.fill_carrying_details')}}</template>
            <template v-else>Haalaustiedot</template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-content">
          <v-text-field :label="$t('email')" v-model="deliveryLot.carryingDoneEmail" readonly v-if="!newInfo"></v-text-field>
          <v-text-field :label="$t('date')" v-model="carryingDoneDate" readonly v-if="!newInfo"></v-text-field>
          <v-text-field :label="$t('name')" v-model="carryingDoneName" :readonly="!newInfo"></v-text-field>
          <v-textarea :label="$t('remarks')" v-model="carryingDoneExtraInfo" rows="6" auto-grow :readonly="!newInfo"></v-textarea>
          <!--          <div class="editable-content" ref="editableContent" contenteditable="true" v-text="this.deliveryLot.carryingDoneExtraInfo"></div>-->
          <!-- <v-textarea ref="infoTextArea" v-model="deliveryLot.deliveryInfo" :label="$t('projects.hand_over_additional_info')" /> -->
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions :style="{padding: $vuetify.breakpoint.xsOnly ? '0' : ''}">
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :disabled="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else-if="newInfo">{{ $t('cancel') }}</span>
              <span v-else>{{ $t('close') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition v-if="newInfo">
            <v-btn
              color="primary"
              dark
              :loading="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              @click="saveCarryingInfo"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{$t('save') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import api from "@/api";
  import moment from "moment";

  export default {
    name: "SetAsCarriedDialog",
    data() {
      return {
        dialog: false,
        deliveryLot: null,
        carryingDoneName: '',
        carryingDoneExtraInfo: '',
        saving: false,
      }
    },
    computed: {
      carryingDoneDate() {
        return moment(this.deliveryLot.carryingDoneDate).format('ddd L LT');
      },
      newInfo() {
        return this.deliveryLot.carryingDoneDate == null;
      },
      userFullName() {
        return `${this.auth.user.firstName} ${this.auth.user.lastName}`;
      },
      auth() {
        return this.$store.state.auth;
      },
    },
    methods: {
      async saveCarryingInfo() {
        this.saving = true;

        try {
          await api.setDeliveryLotCarried(this.deliveryLot.id, {
            carryingDoneExtraInfo: this.carryingDoneExtraInfo,
            carryingDoneName: this.carryingDoneName,
          });
          this.$emit('dismiss', true);
          this.$emit('delivery-carried');
          // this.$store.dispatch('showNotification', {
          //   color: 'success',
          //   message: this.deliveryLot.statusCode === 100000006 ? this.$t('projects.hand_over_success') : this.$t('projects.hand_over_changes_saved_success'),
          //   showButton: false,
          // });
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification', {
            color: 'error',
            message: this.$t('delivery_lot.failed_to_set_as_carried'),
            showButton: false,
          });
        }
        this.saving = false;
      },
      openDialog(deliveryLot) {
        this.deliveryLot = Object.assign({}, deliveryLot);
        if (!this.newInfo) {
          this.carryingDoneName = this.deliveryLot.carryingDoneName;
          this.carryingDoneExtraInfo = this.deliveryLot.carryingDoneExtraInfo;
        } else {
          this.carryingDoneName = this.userFullName;
          this.carryingDoneExtraInfo = '';
        }

        this.dialog = true;
        /* this.$nextTick(() => {
          this.$refs.infoTextArea.focus();
        }) */
      },
      dismissDialog() {
        this.dialog = false;
      },
    },
    watch: {
      dialog(val) {
        if (!val) {
          this.deliveryLot = {};
          this.carryingDoneExtraInfo = '';
          this.carryingDoneName = '';
          this.$emit('dismiss', false);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .text-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding-bottom: 50px;
  }

  .text-details {
    font-size: 13px;
    color: rgba(0,0,0,.54);
  }

  .resizable {
    height: 100%;
  }

  .editable-content {
    white-space: pre-wrap;
    min-height: 100%;
    padding-bottom: 20px;

    &:focus {
      outline: 0 solid transparent;
    }
  }

  @media screen and (min-width: 600px) {
    .text-content {
      padding-bottom: 0;
      overflow: auto;
      height: calc(100% - 130px);
    }

    .text-details {
      font-size: 14px;
    }

    .resizable {
      resize: both;
      overflow: auto;
      width: 600px;
      height: 540px;
      min-width: 30vw;
      max-width: 90vw;
      min-height: 30vh;
      max-height: 80vh;
    }

    .resizable-small {
      resize: both;
      overflow: auto;
      width: 600px;
      height: 400px;
      min-width: 30vw;
      max-width: 90vw;
      min-height: 30vh;
      max-height: 80vh;
    }
  }
</style>
