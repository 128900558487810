<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid fill-height ma-0 pa-0 class="deliverylot" v-scroll.body="onScroll">

    <v-toolbar color="primary" dark fixed app :extended="isHandOverPossible || isAcceptHandOverPossible || isSetReceivePossible">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="project" :fallback-params="{id: project ? project.id : '' }" />

      <v-toolbar-title style="margin-left: 0;">{{ deliveryLot ? deliveryLot.name : '' }}</v-toolbar-title>

      <v-spacer>
      </v-spacer>
      <v-menu bottom left>
        <v-btn slot="activator" dark icon><v-icon>more_vert</v-icon></v-btn>
        <v-list>
          <v-list-tile v-if="showOpenInDynamicsLink" :href="dynamicsUrl" target="_blank">
            <v-list-tile-title>{{ $t('projects.open_in_dynamics' )}}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="createTyomaarays" v-if="($isPuustelliUser || $isAdmin)">
            <v-list-tile-title>{{ $t('tyomaaraykset.create') }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

      <template slot="extension">
        <v-layout v-if="isHandOverPossible || isAcceptHandOverPossible || isSetReceivePossible || isSetCarriedPossible" justify-start light>
          <v-btn v-if="isHandOverPossible"
                 :disabled="updatingStatus"
                 :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="handOverDeliveryLot">
            {{ deliveryLot.statusCode === 100000006 ? $t('projects.hand_over_delivery_lot') : $refs.handOverDialog && $refs.handOverDialog.dialog ? $t('projects.hide_delivery_hand_over_info') : $t('projects.show_delivery_hand_over_info') }}
          </v-btn>
          <v-btn v-if="isAcceptHandOverPossible"
                 :disabled="updatingStatus"
                 :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="acceptHandOver">
            {{ $t('projects.accept_hand_over_delivery') }}
          </v-btn>
          <v-btn v-if="isSetReceivePossible"
                 :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="markDeliveryLotAsReceived">
            {{ $t('delivery_lot.mark_as_received') }}
          </v-btn>
          <v-btn v-if="isSetCarriedPossible"
                 :loading="savingCarried"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="carryingActionButtonPressed">
            {{ carryingActionButtonText }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <v-fade-transition>
      <v-layout row wrap v-if="!loading">
        <v-flex xs12 class="deliverylot-holder">
          <v-container fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>

              <v-flex xs12 lg5 xl4 :pb-0="$vuetify.breakpoint.mdAndDown">
                <delivery-lot-details-card
                  :reclamation-rows="reclamationRows"
                  :rooms="rooms"
                  :delivery-lot="deliveryLot"
                  :project-d-t-o="projectDTO"
                  :project="project"></delivery-lot-details-card>
              </v-flex>

              <v-flex xs12 lg7 xl8 :pt-0="$vuetify.breakpoint.mdAndDown">


                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown}" data-cy="delivery-lot-documents">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text" @click="documentsExpanded = !documentsExpanded">
                    <span>{{ $t('delivery_lot.documents') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!documentsExpanded" small icon style="margin: -4px 0;" @click.stop="documentsExpanded = true"><v-icon color="white">expand_more</v-icon></v-btn>
                    <v-btn v-if="documentsExpanded" small icon style="margin: -4px 0;" @click.stop="documentsExpanded = false"><v-icon color="white">expand_less</v-icon></v-btn>
                    <template v-if="deliveryLot.documentURL">
                      <v-menu bottom left @click.native.stop>
                        <v-btn small icon slot="activator" style="margin: -4px 0;"><v-icon color="white">more_vert</v-icon></v-btn>
                        <v-list class="pt-0 pb-0">
                          <v-list-tile :href="deliveryLot.documentURL" target="_blank">
                            <v-list-tile-action><v-icon>open_in_new</v-icon></v-list-tile-action>
                            <v-list-tile-title>{{ $t('view_documents_in_sharepoint') }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-card-title>
                  <template v-if="$vuetify.breakpoint.lgAndUp">
                    <v-expand-transition>
                      <div v-show="documentsExpanded">
                        <v-card-text  class="pl-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
                          <document-tree container-type="DeliveryLot" :container-id="deliveryLotId" :loading="loading" />
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </template>
                  <template v-if="$vuetify.breakpoint.mdAndDown">
                    <v-card-text class="pl-0 pr-0 pb-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
                      <v-subheader @click="documentsExpanded = !documentsExpanded">
                        <span>{{ $t('delivery_lot.documents') }}</span>

                        <v-spacer></v-spacer>
                        <v-btn v-if="!documentsExpanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="documentsExpanded = true"><v-icon color="grey">expand_more</v-icon></v-btn>
                        <v-btn v-if="documentsExpanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="documentsExpanded = false"><v-icon color="grey">expand_less</v-icon></v-btn>
                        <template v-if="deliveryLot.documentURL">
                          <v-menu bottom left @click.native.stop>
                            <v-btn small icon slot="activator" style="margin: -4px -4px -4px 5px;"><v-icon color="grey">more_vert</v-icon></v-btn>
                            <v-list class="pt-0 pb-0">
                              <v-list-tile :href="deliveryLot.documentURL" target="_blank">
                                <v-list-tile-action><v-icon>open_in_new</v-icon></v-list-tile-action>
                                <v-list-tile-title>{{ $t('view_documents_in_sharepoint') }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-subheader>
                    </v-card-text>
                    <v-expand-transition>
                      <div v-show="documentsExpanded">
                        <v-card-text class="pl-0 pt-0">
                          <document-tree container-type="DeliveryLot" :container-id="deliveryLotId" :loading="loading" />
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                    <v-divider v-if="$vuetify.breakpoint.mdAndDown"></v-divider>
                  </template>
                </v-card>
                <reports :delivery-lot="deliveryLot"></reports>
                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}" data-cy="delivery-lot-apartments">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    <span>{{ $t('projects.apartments') }}</span>
                    <v-spacer></v-spacer>
                    <template v-if="rooms.length > 0 && !loading && !generatingExcel">
                      <v-menu bottom left @click.native.stop>
                        <v-btn small icon slot="activator" style="margin: -4px -4px -4px 5px;"><v-icon color="white">more_vert</v-icon></v-btn>
                        <v-list class="pt-0 pb-0">
                          <v-list-tile @click="exportExcel">
                            <v-list-tile-action><v-icon>download</v-icon></v-list-tile-action>
                            <v-list-tile-title>{{ $t('delivery_lot.download_excel') }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('projects.apartments') }}
                      <v-spacer></v-spacer>
                      <template v-if="rooms.length > 0 && !loading && !generatingExcel">
                        <v-menu bottom left @click.native.stop>
                          <v-btn small icon slot="activator" style="margin: -4px -4px -4px 5px;"><v-icon color="grey">more_vert</v-icon></v-btn>
                          <v-list class="pt-0 pb-0">
                            <v-list-tile @click="exportExcel">
                              <v-list-tile-action><v-icon>download</v-icon></v-list-tile-action>
                              <v-list-tile-title>{{ $t('delivery_lot.download_excel') }}</v-list-tile-title>
                            </v-list-tile>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-subheader>
                    <v-list class="rooms" v-if="rooms.length > 0 && !loading" >
                      <template v-for="(room, index) in rooms">
                        <v-list-tile :data-cy="`delivery-lot-apartment_${index}`" v-if="room.vertexProject && room.vertexTargetNumber" :key="`${room.vertexProject}_${room.vertexTargetNumber}`" :to="{ name: 'room', params: { deliveryLotId: deliveryLotId, projectId: projectId, vertexProjectNumber: room.vertexProject, vertexTargetNumber: room.vertexTargetNumber}}">
                          <v-list-tile-avatar>
                            <v-icon size="14" :color="apartmentStatusColor(room)">{{ apartmentStatusIcon(room) }}</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ room.name }}</v-list-tile-title>
                            <v-list-tile-sub-title v-if="$showInstallationUnits">{{ $t('room.installation_units') }}: {{ room.installationUnits ? $roundNumber(room.installationUnits, 1) : '-' }}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="font-weight-bold wrap-text" v-if="room.handOverComments"><span style="white-space: pre-wrap">{{ room.handOverComments }}</span></v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action-text v-if="room.extraInfo">
                            {{ room.extraInfo }}
                          </v-list-tile-action-text>
                        </v-list-tile>
                        <v-divider v-if="index < rooms.length - 1" :key="`${room.vertexProject}_${room.vertexTargetNumber}_divider`"></v-divider>
                      </template>
                    </v-list>
                    <template v-if="rooms.length < 1 && !loading">
                      <v-container fluid grid-list-md>
                        <v-layout row wrap>
                          <v-flex xs12><v-icon size="16" color="grey">info</v-icon> {{ $t('projects.no_rooms') }}</v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <hand-over-dialog ref="handOverDialog" @delivery-handed-over="reloadProject" />

    <set-as-carried-dialog ref="setAsCarriedDialog" @delivery-carried="setAsCarriedDone" />

    <edit-tyonaarays-dialog
      v-if="($isPuustelliUser || $isAdmin)"
      ref="editTyomaaraysDialog"
      :projectDTO="projectDTO"
    />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import parameters from '../parameters.js';
  import HandOverDialog from "../components/SingleProjectView/HandOverDialog.vue";
  import DocumentTree from "../components/DocumentTree.vue";
  import api from "@/api";
  import frendsApi from "@/frendsApi";
  import Reports from "@/components/SingleDeliveryLotView/Reports";
  import DeliveryLotDetailsCard from "@/components/SingleDeliveryLotView/DeliveryLotDetailsCard";
  import SetAsCarriedDialog from "@/components/SingleDeliveryLotView/SetAsCarriedDialog";
  import { write, utils } from 'xlsx';
  import { saveAs } from 'file-saver';
  import EditTyonaaraysDialog from "../components/SingleTyomaaraysView/EditTyomaaraysDialog.vue";

  const roomStatuses = parameters.roomStatuses;

  export default {
    name: "SingleDeliveryLotView",
    components: {
      EditTyonaaraysDialog,
      SetAsCarriedDialog,
      DeliveryLotDetailsCard,
      Reports,
      HandOverDialog: HandOverDialog,
      DocumentTree: DocumentTree,
    },
    props: {
      projectId: {
        type: String,
        default: '',
      },
      deliveryLotId: {
        type: [Boolean, String],
        default: false,
      }
    },
    data() {
      return {
        loading: true,
        reclamationRows: [],
        updatingStatus: false,
        documentsExpanded: false,
        savingCarried: false,
        generatingExcel: false,
      }
    },
    computed: {
      carryingActionButtonText() {
        if (this.$refs.setAsCarriedDialog && this.$refs.setAsCarriedDialog.dialog) {
          return this.$t('delivery_lot.hide_carrying_details');
        } else if (this.deliveryLot.carryingDoneDate == null) {
          return this.$t('delivery_lot.mark_as_carried');
        } else {
          return this.$t('delivery_lot.show_carrying_details');
        }
      },
      isSetCarriedPossible() {
        return this.deliveryLot && this.deliveryLot.carryingGroupId != null && this.userCanSetCarried;
      },
      userCanSetCarried() {
        const installationGroups = this.$store.state.installationGroups;
        return this.$isPuustelliUser || this.$isAdmin || ((this.$isTeamLeader || this.$isTeamMember) && installationGroups.find(ig => ig.id === this.deliveryLot.carryingGroupId) != null);
      },
      showOpenInDynamicsLink() {
        return (this.$isPuustelliUser || this.$isAdmin) && this.project != null && this.$dynamicsDeliveryLotUrlConfigured;
      },
      dynamicsUrl() {
        if (this.deliveryLot) {
          return this.$dynamicsDeliveryLotUrl(this.deliveryLot.id);
        }
        return '';
      },
      rooms() {
        const rooms = this.deliveryLot.apartments;
        //20.9.2024 Juuso:
        //sort by order and then name
        rooms.sort((a, b) => {
          //jos order on sama, niin sortataan nimen mukaan
          if (a.order === b.order) {
            return a.name.localeCompare(b.name);
          }
          //muuten order mukaan
          return a.order - b.order;
          //lopputuloksena sort on order asc, name asc
        });
        //vanha oli vain order eli saman huoneiston (esim C50) sisällä menivät satunnaiseen järjestykseen
        //rooms.sort((a, b) => a.order - b.order);
        return rooms;
      },
      project() {
        return this.projectDTO.project;
      },
      projectDTO() {
        return this.$store.state.projectDTO;
      },
      deliveryLot() {
        if (this.deliveryLotId && this.project) {
          return this.projectDTO.deliveryLots.find(lot => lot.id === this.deliveryLotId);
        }
        return null;
      },
      isAcceptHandOverPossible() {
        return !(this.$isTeamMember || this.$isTeamLeader) && this.deliveryLot && this.deliveryLot.statusCode === 100000009;
      },
      isHandOverPossible() {
        return !this.$isCustomer && this.deliveryLot && (this.deliveryLot.statusCode === 100000006 && this.deliveryLot.received || this.deliveryLot.statusCode === 100000009);
      },
      isSetReceivePossible() {
        return this.deliveryLot && !this.deliveryLot.received && (this.$isTeamLeader || this.$isAdmin);
      },
      systemData() {
        return this.$store.state.systemData;
      },
    },
    methods: {
      async createTyomaarays() {
        await this.$refs.editTyomaaraysDialog.open({
          d365toimituseraid: this.deliveryLotId,
        });
      },
      onScroll(e) {
        this.$store.commit('setDeliveryLotViewScrollY', window.scrollY);
      },
      async setAsCarriedDone() {
        this.savingCarried = true;

        try {
          await this.$store.dispatch('reloadProjectDTO', this.projectId);
        } catch (error) {
          // ignore
        }

        this.savingCarried = false;
      },
      async carryingActionButtonPressed() {
        if (this.$refs.setAsCarriedDialog.dialog) {
          this.$refs.setAsCarriedDialog.dismissDialog();
        } else {
          this.$refs.setAsCarriedDialog.openDialog(this.deliveryLot);
        }
      },
      apartmentStatusIcon(apartment) {
        return roomStatuses[apartment.statusCode].icon;
      },
      apartmentStatusColor(apartment) {
        return roomStatuses[apartment.statusCode].color;
      },
      async acceptHandOver() {
        try {
          this.updatingStatus = true;
          await api.acceptDeliveryLotHandOver(this.deliveryLot.id);
          await this.reloadProject();
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('delivery_lot.failed_to_update_status'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.updatingStatus = false;
      },
      handOverDeliveryLot() {
        if (this.$isCustomer) return;
        if (!this.$refs.handOverDialog.dialog) {
          this.$refs.handOverDialog.openDialog(this.deliveryLot);
        } else {
          this.$refs.handOverDialog.dialog = false;
        }
      },
      async reloadDeliveryLotReclamations() {
        if (!this.$showReclamations) {
          return;
        }
        try {
          const rivit = await frendsApi.getReklamaatioRivit(this.project.id, this.deliveryLotId, null, null, this.$kielinro);
          this.reclamationRows = rivit;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadDeliveryLotReclamations();
              }
            });
        }
      },
      async reloadProject() {
        if (!this.$store.state.projectDTO.project || this.$store.state.projectDTO.project.id !== this.projectId) {
          await this.$store.dispatch('reloadProjectDTO', this.projectId);
          this.loading = false;
        } else {
          this.$store.dispatch('reloadProjectDTO', this.projectId);
          this.loading = false;
        }
      },
      async markDeliveryLotAsReceived() {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        this.updatingStatus = true;
        try {
          await axios.post(`/api/DeliveryLot/${this.deliveryLotId}/received`);
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('delivery_lot.failed_to_update_status'),
              timeOut: 4000,
              showButton: false,
            });
        }
        await this.reloadProject();
        this.updatingStatus = false;
      },
      exportExcel() {
        this.generatingExcel = true;

        const wb = utils.book_new();
        const regex = /[\\\/\?\*\[\]]/gim;
        const ws_name = this.deliveryLot.name.substring(0, 30).replaceAll(regex, ' ');

        const headers = [
          this.$t('delivery_lot.room.room'),
          this.$t('delivery_lot.room.status'),
          this.$t('delivery_lot.room.installation_units'),
          this.$t('delivery_lot.room.hand_over_comment'),
          this.$t('delivery_lot.room.extra_info'),
        ];
        const data = this.rooms.map(r => [
          r.name || '',
          this.statusDescription(r.statusCode),
          r.installationUnits ? this.$roundNumber(r.installationUnits, 1) : '-',
          r.handOverComments || '',
          r.extraInfo || '',
        ]);

        /* make worksheet */
        const ws_data = [
          headers,
          ...data,
        ];
        const ws = utils.aoa_to_sheet(ws_data);

        /* Add the worksheet to the workbook */
        utils.book_append_sheet(wb, ws, ws_name);

        const wbOut = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
          }
          return buf;
        }

        const fileName = `${this.$t('delivery_lot.excel_file_name')}.xlsx`;

        saveAs(new Blob([s2ab(wbOut)], { type: "application/octet-stream" }), fileName);

        this.generatingExcel = false;
      },
      statusDescription(statusCode) {
        const statuses = this.systemData.apartmentStatuses;
        const status = statuses.find(s => s.key === statusCode);
        return status.value;
      },
    },
    async mounted() {
      await this.reloadProject();
      this.reloadDeliveryLotReclamations();
      if (this.$store.state.deliveryLotViewScrollY > 0) {
        this.$vuetify.goTo(this.$store.state.deliveryLotViewScrollY, {
          duration: 200,
          easing: 'easeInOutCubic',
          offset: this.$vuetify.breakpoint.mdAndUp ? -128 : -43
        })
      }
    },
  }
</script>

<style lang="scss">
  .rooms {
    .v-list__tile {
      height: 100% !important;
      padding-top: 12px;
      padding-bottom: 12px;

    }
    .wrap-text {
      word-wrap: break-word;
    }
  }

  .deliverylot {
    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    .topbar-search {
      .v-input__slot {
        padding: 0 !important;
      }
    }

    .v-list--dense {
      .contact-persons-header {
        .v-list__tile {
          height: 60px;
        }
      }
    }

    .contact-persons-header {
      .v-list__tile {
        height: 62px;
      }
    }

    .contact-persons-list {
      &:after,
      &:before {
        display: none !important;
      }
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }
  }

  .topbar-search {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  .unit-title {
    font-size: 12px !important;
    line-height: 16px;
    display: block;
  }

  .unit-details {
    display: block;
  }

  @media screen and (min-width: 960px) {
    .unit-title {
      font-size: 15px !important;
      line-height: 18px;
    }
  }

</style>
