<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
    <v-card tile>
      <v-card-title :class="options.color" class="white--text">
        <h3>{{ title }}</h3>
      </v-card-title>
      <v-card-text v-show="!!message">
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn flat @click.native="cancel()">{{options.cancel}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat :color="options.color" v-if="options.agree" @click.native="agree()">{{options.agree}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <confirm-dialog ref="confirm"></confirm-dialog>
   *
   * Call it:
   * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {});
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <confirm-dialog ref="confirm"></confirm-dialog>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$confirm = this.$refs.confirm.open;
   * }
   */
  export default {
    name: 'ConfirmDialog',
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          color: 'primary',
          agree: this.$t('yes'),
          cancel: this.$t('no'),
          width: 290,
        }
      };
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });

      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  }
</script>
