<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <template v-if="project != null">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ project.clientName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-alert v-if="!canSetReady" :value="true" color="grey" type="warning">{{ $t('projects.cant_set_okt_as_ready')}}</v-alert>
              <v-alert v-else :value="true" color="grey" type="info">{{ $t('projects.set_project_completed_long')}}</v-alert>
            </v-flex>
          <v-flex xs12>
            <hb-date-picker :disabled="!canSetReady" v-model="readyDate" :label="$t('projects.project_ready_date')"
                            prepend-icon="event"></hb-date-picker>
          </v-flex>
          <v-flex xs12>
            <hb-date-picker :disabled="!canSetReady" v-model="moveInDate" :label="$t('projects.move_in_date')"
                            prepend-icon="event"></hb-date-picker>
              <!--            <v-menu-->
<!--              v-model="menu2"-->
<!--              :close-on-content-click="false"-->
<!--              :nudge-right="40"-->
<!--              lazy-->
<!--              transition="scale-transition"-->
<!--              offset-y-->
<!--              full-width-->
<!--              min-width="290px"-->
<!--            >-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-text-field-->
<!--                  :value="computedDateFormatted"-->
<!--                  :label=""-->
<!--                  readonly-->
<!--                  v-on="on"-->
<!--                ></v-text-field>-->
<!--              </template>-->
<!--              <v-date-picker :locale="locale" v-model="readyDate" @input="menu2 = false"></v-date-picker>-->
<!--            </v-menu>-->
          </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :disabled="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else>{{ $t('cancel') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition v-if="canSetReady">
            <v-btn
              color="primary"
              dark
              :loading="saving"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              @click="setReady"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{ $t('save') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import api from "@/api";
import moment from "moment";
import HbDatePicker from "@/components/HbDatePicker.vue";

export default {
  name: "ProjectReadyDialog",
  components: {HbDatePicker},
  data() {
    return {
      saving: false,
      dialog: false,
      project: null,
      menu2: false,
      readyDate: moment().toDate().toISOString().substring(0, 10),
      moveInDate: null,
      originalMoveInDate: null,
    }
  },
  computed: {
    locale() {
      return this.$locale;
    },
    canSetReady() {
      return this.project.type !== 100000000; // okt
    },
  },
  methods: {
    async setReady() {
      this.saving = true;
      try {
        const readyDate = this.readyDate ? moment(this.readyDate).toDate() : null;
        const moveInDate = this.moveInDate ? moment(this.moveInDate).toDate() : null;
        let setMoveInDateForDeliveryLots = false;
        if (this.originalMoveInDate && !moment(moveInDate).isSame(moment(this.originalMoveInDate))) {
          setMoveInDateForDeliveryLots = true;
        }
        await api.setProjectReady(this.project.id, readyDate, moveInDate, setMoveInDateForDeliveryLots);
        this.$emit('project-updated');
        this.dismissDialog();
      } catch (error) {
        console.log(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('projects.error_when_updating_project_status'),
            timeOut: 4000,
            showButton: false,
          });
      }
      this.saving = false;
    },
    openDialog(project) {
      this.project = Object.assign({}, project);
      this.moveInDate = project.moveInDate ? moment(project.moveInDate).toDate().toISOString().substring(0, 10) : null;
      this.originalMoveInDate = moment(this.moveInDate);
      this.dialog = true;
      // this.$nextTick(() => {
      //   this.$refs.infoTextArea.focus();
      // })
    },
    dismissDialog() {
      this.dialog = false;
      this.moveInDate = null;
      this.originalMoveInDate = null;
    },
  },
}
</script>

<style scoped>

</style>
