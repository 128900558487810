import Dexie from "dexie";

const db = new Dexie("jehu");

db.version(1).stores({
  reclamations: 'id',
  attachments: 'id',
  statuses: 'id',
  room_images: 'id',
});

db.version(2).stores({
  reclamations: 'id',
  attachments: 'id',
  statuses: 'id',
  room_images: 'id',
  session: 'id',
})

db.version(3).stores({
  reclamations: 'id',
  attachments: 'id',
  statuses: 'id',
  room_images: 'id',
  session: 'id',
  comments: 'id'
});

export default db;
