import Calendar from './views/CalendarView.vue';
import ProjectsView from './views/ProjectsView.vue';
import SingleProjectView from './views/SingleProjectView.vue';
import InstallationGroupsView from './views/InstallationGroupsView.vue';
import TasksView from './views/TasksView.vue';
import ReclamationsView from './views/ReclamationsView.vue';
import SingleTaskView from './views/SingleTaskView.vue';
import SingleRoomView from './views/SingleRoomView.vue';
import SingleReclamationRowView from './views/SingleReclamationRowView.vue';
import ConfigView from "./views/ConfigView.vue";
import SingleDeliveryLotView from "./views/SingleDeliveryLotView.vue";
import SyncView from "@/views/SyncView";
import HandOverPrintView from "./components/HandOverPrintView";
import TyomaarayksetView from "./views/TyomaarayksetView.vue";
import SingleTyomaaraysView from "./views/SingleTyomaaraysView.vue";

export default [
  {
    path: '/',
    name: 'calendar',
    component: Calendar,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
  },
  {
    path: '/groups',
    name: 'installationGroups',
    component: InstallationGroupsView,
  },
  {
    path: '/projects/:id',
    name: 'project',
    component: SingleProjectView,
    props: true,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView,
    props: true,
  },
  {
    path: '/tasks/:activityId',
    name: 'task',
    component: SingleTaskView,
    props: true,
  },
  {
    path: '/projects/:projectId/deliverylot/:deliveryLotId/room/:vertexProjectNumber/:vertexTargetNumber',
    name: 'room',
    component: SingleRoomView,
    props: true,
  },
  {
    path: '/projects/:projectId/deliverylot/:deliveryLotId/room/:vertexProjectNumber/:vertexTargetNumber/handOverPrint',
    name: 'roomhandoverprint',
    component: HandOverPrintView,
    props: true,
  },
  {
    path: '/projects/:projectId/deliverylot/:deliveryLotId',
    name: 'deliverylot',
    component: SingleDeliveryLotView,
    props: true,
  },
  {
    path: '/reclamations/:projectId?/:deliveryLotId?/:vertexProjectNumber?/:vertexTargetNumber?',
    name: 'reclamations',
    component: ReclamationsView,
    props: route => ({
      ...route.params,
      reclamationStatusFilter: route.query.rekl_statusnro,
    })
  },
  {
    path: '/sync',
    name: 'sync',
    component: SyncView,
    props: true,
  },
  {
    path: '/reclamationrow/:rowId',
    name: 'reclamationrow',
    component: SingleReclamationRowView,
    props: true,
  },
  {
    path: '/cfg',
    name: 'config',
    component: ConfigView
  },
  {
    path: '/tyomaaraykset',
    name: 'tyomaaraykset',
    component: TyomaarayksetView,
    props: true,
  },
  {
    path: '/tyomaarays/:tyomaaraysid',
    name: 'tyomaarays',
    component: SingleTyomaaraysView,
    props: true,
  },
  { path: '*', redirect: '/' },
];
