<template>
  <v-container fluid fill-height ma-0 pa-0 class="installation-groups">

    <v-toolbar color="primary" dark fixed app>
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="calendar" />

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="$t('installation_groups.search_for_groups')"
          v-model="searchPhrase"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          full-width
          hide-details
          color="red lighten-3"
        >
          <v-icon style="cursor: pointer;" slot="append">search</v-icon>
        </v-text-field>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu bottom left>
        <v-btn slot="activator" dark icon><v-icon>more_vert</v-icon></v-btn>
        <v-list>
          <v-list-tile @click="selectAll">
            <v-list-tile-title>{{ $t('installation_groups.select_all') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="removeSelections">
            <v-list-tile-title>{{ $t('installation_groups.remove_selections') }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="installation-groups-holder">
          <v-list class="pt-0 pb-0" >
            <div v-for="group in filteredProjectsInstallationgGroups"
                 :key="group.id">
              <v-list-tile @click="selectionChanged(group)">
                <v-list-tile-action>
                  <v-icon v-if="group.selected" color="primary">check_box</v-icon>
                  <v-icon v-else color="primary">check_box_outline_blank</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title class="font-weight-bold">{{ group.name }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <v-fab-transition>
      <v-btn
        color="primary"
        dark
        fab
        fixed
        bottom
        right
        @click="goBack"
        v-show="$vuetify.breakpoint.xs"
      >
        <v-icon>check</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-container>
</template>

<script>
  export default {
    name: 'InstallationGroupsView',
    data() {
      return {
        loading: false,
        searchPhrase: '',
      }
    },
    methods: {
      selectAll() {
        const unselectedGroups = this.installationGroups.filter(ig => ig.selected === false);
        unselectedGroups.forEach(ig => {
          this.selectionChanged(ig);
        })
      },
      removeSelections() {
        const selectedGroups = this.installationGroups.filter(ig => ig.selected === true);
        selectedGroups.forEach(ig => {
          this.selectionChanged(ig);
        })
      },
      isChecked(group) {
        return group.selected;
      },
      selectionChanged(group) {
        this.$store.dispatch('selectInstallationGroup', group);
      },
      goBack() {
        if (this.$store.state.previousRouteName !== null) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: "calendar" });
        }
      },
    },
    computed: {
      filteredProjectsInstallationgGroups() {
        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          return this.installationGroups;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        return this.installationGroups.filter(ig => ig.name.toLowerCase().indexOf(searchPhraseLower) !== -1);
      },
      installationGroups() {
        return this.$store.state.installationGroups;
      }
    },
    mounted() {
      if (this.$isCustomer) {
        this.$router.push({ name: 'projects' });
      }
      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      })
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
