<template>
  <v-container v-if="$showReclamations" fluid fill-height ma-0 pa-0 class="reclamation-row" :extended="reclamationNeedsApproval || reclamationCanBeMarkedAsInstalled">

    <v-toolbar color="primary" dark fixed app>
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="reclamations" :fallback-params="{ projectId: reclamationRow.d365projektiid, vertexProjectNumber: reclamationRow.projektinro, vertexTargetNumber: reclamationRow.huonenro}" />

      <v-toolbar-title style="margin-left: 0;">{{ $tc('reclamation.title', 1) }}: {{ reclamationRow.rekl_tuoteselite }}</v-toolbar-title>

      <template v-if="($isTeamMember || $isTeamLeader || $isPuustelliUser || $isAdmin) && reclamationCanBeDeleted">
        <v-spacer></v-spacer>

        <v-menu bottom left @click.native.stop>
          <v-btn small icon slot="activator"><v-icon>more_vert</v-icon></v-btn>
          <v-list class="pt-0 pb-0">
            <v-list-tile @click="deleteReclamation" target="_blank">
              <v-list-tile-action><v-icon>delete</v-icon></v-list-tile-action>
              <v-list-tile-title>{{ $t('reclamation.delete_reclamation') }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </template>

      <template slot="extension">
        <v-layout v-if="(reclamationNeedsApproval || reclamationCanBeMarkedAsInstalled)" justify-start light>
          <v-btn v-if="reclamationCanBeMarkedAsInstalled && ($isTeamMember || $isTeamLeader || $isPuustelliUser || $isAdmin)"
                 :disabled="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="markAsInstalled">
            {{ $t('reclamation.mark_as_installed') }}
          </v-btn>
          <v-btn v-if="reclamationNeedsApproval && ($isAdmin || $isCustomer || $isPuustelliUser)"
                 :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 style="margin-left: 0"
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="approveReclamation">
            {{ $vuetify.breakpoint.xsOnly ? $t('reclamation.approve') : $tc('reclamation.approve_reclamation', 1) }}
          </v-btn>
          <v-btn v-if="reclamationNeedsApproval && ($isAdmin || $isCustomer || $isPuustelliUser)"
                 :loading="updatingStatus"
                 :small="$vuetify.breakpoint.mdAndDown"
                 :light="$vuetify.breakpoint.lgAndUp"
                 outline
                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                 @click.native="declineReclamation">
            {{ $vuetify.breakpoint.xsOnly ? $t('reclamation.decline') : $tc('reclamation.decline_reclamation', 1) }}
          </v-btn>
        </v-layout>
      </template>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle />

    <v-fade-transition>
      <v-layout v-if="!loading" row wrap>
        <v-flex xs12 class="reclamation-row-holder">

          <v-container fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>

              <v-flex :class="infoColumns" :pb-0="$vuetify.breakpoint.mdAndDown">
                <v-card v-if="reclamationRow" :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('reclamation.reclamation_info') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('reclamation.reclamation_info') }}</v-subheader>
                    <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0">

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-cube</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('reclamation.product_definition') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ reclamationRow.rekl_tuoteselite }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">update</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('reclamation.status') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ reclamationRow.rekl_statusteksti }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">list</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('reclamation.type') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ reclamationRow.rekl_tyyppiselite }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <template v-if="reclamationRow.rekl_tilausnro">
                        <v-divider inset></v-divider>

                        <v-list-tile>
                          <v-list-tile-action>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">file_copy</v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('room.puusnet_order_number') }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ reclamationRow.rekl_tilausnro }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">event</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ deliveryTitle }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryDate }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-divider inset></v-divider>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">access_time</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('reclamation.created') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $formatDate(reclamationRow.rekl_rivinperustamispvm) }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>

                    </v-list>
                    <template v-if="reclamationRow && reclamationRow.tyomaaraysid">
                      <v-divider inset></v-divider>
                      <v-list two-line class="pb-0 pt-0">

                        <v-list-tile :to="{name: 'tyomaarays', params: { tyomaaraysid: String(reclamationRow.tyomaaraysid) }}">
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">mdi-clipboard-list</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ $t('tyomaarays.title')}}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ reclamationRow.tyomaaraysid }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-icon>chevron_right</v-icon>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </template>
                    <template v-if="project">
                      <v-list two-line class="pb-0 pt-0">
                        <v-divider inset></v-divider>
                        <v-list-tile :to="{name: 'project', params: { id: this.project.id }}">
                          <v-list-tile-avatar>
                            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">assignment</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ project.customerInfo }}</v-list-tile-title>
                            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ project.clientName }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-icon>chevron_right</v-icon>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </template>


                    <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
                      <v-divider inset v-if="deliveryLot"></v-divider>
                      <v-list-tile v-if="deliveryLot" :to="{name: 'deliverylot', params: { projectId: project.id, deliveryLotId: deliveryLot.id }}">
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-truck-loading</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $tc('delivery_lot.title', 1) }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.name }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-icon>chevron_right</v-icon>
                        </v-list-tile-action>
                      </v-list-tile>
                      <v-divider inset v-if="apartment"></v-divider>
                      <v-list-tile v-if="apartment" :to="{name: 'room', params: { projectId: project.id, deliveryLotId: deliveryLot.id, vertexProjectNumber: apartment.vertexProject, vertexTargetNumber: apartment.vertexTargetNumber }}">
                        <v-list-tile-avatar>
                          <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-building</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ $t('reclamation.apartment') }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ apartment.name }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-icon>chevron_right</v-icon>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>



                  </v-card-text>
                </v-card>

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('reclamation.reclamation_details') }}
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">{{ $t('reclamation.reclamation_details') }}</v-subheader>
                    <p>{{ reclamationRow.rekl_selite }}</p>
                  </v-card-text>
                </v-card>

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('reclamation.reclamation_note') }}
                  </v-card-title>
                  <v-card-text>
                    <v-subheader class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">{{ $t('reclamation.reclamation_note') }}</v-subheader>
                    <p style="white-space: pre-line">{{ reclamationRow.rekl_muistio }}</p>
                  </v-card-text>
                </v-card>

              </v-flex>

              <v-flex :class="liitteetColumns" :pt-0="$vuetify.breakpoint.mdAndDown" v-show="reclamationRow.naytaliitteet === 'true'">
                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('reclamation.general_pictures') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-progress-linear v-if="loadingAttachments" height="2" indeterminate class="pt-0 mb-0 pb-0" style="margin-top: -2px;" />

                      <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('reclamation.general_pictures') }}</v-subheader>
                      <v-container grid-list-sm fluid>
                        <v-layout row wrap>
                            <v-flex
                              v-for="(img, index) in files"
                              :key="img.fileid"
                              xs4
                              sm3
                              md2
                              lg6
                              xl4
                              d-flex
                            >
                              <file-card :img="img" :removing-file="removingFile"
                                @delete-image="deleteImage(img.fileid)" @open-image="openFullImage(index)"></file-card>
                            </v-flex>
                          <v-flex xs4
                                  sm3
                                  md2
                                  lg6
                                  xl4
                                  d-flex>
                            <add-file-card v-show="reclamationRow.liitelisays === 'true'" @files-selected="fileSelected"></add-file-card>
                          </v-flex>
                        </v-layout>
                      </v-container>

                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex :class="lokiColumns" :pt-0="$vuetify.breakpoint.mdAndDown" v-show="reclamationRow.naytaloki === 'true'">

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('reclamation.reclamation_logs') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-progress-linear v-if="loadingLogs" height="2" indeterminate class="pt-0 mb-0 pb-0" style="margin-top: -2px;" />

                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('reclamation.reclamation_logs') }}</v-subheader>

                    <v-container>
                      <v-layout>
                        <v-flex cols="12">

                          <v-btn v-show="reclamationRow.lokilisays === 'true'"
                                 :small="$vuetify.breakpoint.mdAndDown"
                                 :light="$vuetify.breakpoint.lgAndUp"
                                 class="mb-4"
                                 outline
                                 :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                                 @click.native="sendReclamationComment">
                            {{ $t('reclamation.send_comment') }}
                          </v-btn>

                          <v-timeline dense clipped>
                            <template v-for="(log, index) in reclamationRowLogs">
                              <v-timeline-item
                                class="mb-3"
                                :color="index === 0 ? 'primary' : 'grey'"
                                small
                                :key="log.rivinro"
                              >
                                <v-layout wrap>
                                  <v-flex 12>
                                    <div class="caption grey--text">
                                      {{ $formatDate(log.perustamisaika) }}
                                    </div>
                                    <div class="body-1">{{ log.selite }}</div>
                                    <div class="body-2">{{ log.lisatieto }}</div>
                                    <div class="caption grey--text">{{ log.perustajanimi }}</div>
                                  </v-flex>
                                </v-layout>
                              </v-timeline-item>
                            </template>
                          </v-timeline>
                        </v-flex>
                      </v-layout>
                    </v-container>

                  </v-card-text>
                </v-card>
              </v-flex>

            </v-layout>
          </v-container>

        </v-flex>
      </v-layout>
    </v-fade-transition>
    <add-comment-dialog ref="addCommentDialog" @update-logs="reloadReclamationLogs"/>
    <confirm-dialog ref="confirmImageDelete" />

  </v-container>
</template>

<script>
  import frendsApi from '../frendsApi.js';
  import ConfirmDialog from "../components/ConfirmDialog.vue";
  import FileCard from "@/components/FileCard";
  import AddFileCard from "@/components/AddFileCard";
  import AddCommentDialog from "../components/SingleReclamationView/AddCommentDialog";

  export default {
    name: "SingleReclamationRowView",
    components: {
      AddCommentDialog,
      AddFileCard,
      FileCard,
      ConfirmDialog: ConfirmDialog,
    },
    props: {
      rowId: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        loadingAttachments: false,
        loadingLogs: false,
        uploadingFiles: false,
        removingFile: false,
        reclamationRow: {},
        files: [],
        uploads: [],
        reclamationRowLogs: [],
        updatingStatus: false,
      }
    },
    methods: {
      sendReclamationComment() {
        this.$refs.addCommentDialog.open(this.reclamationRow, this.$kielinro, this.project, this.apartment.name);
      },
      async approveReclamation() {
        this.updatingStatus = true;
        try {
          const approvedStatus = 'RAKLIIKE_HYVAKSYTTY';
          const updatedRow = await frendsApi.updateReklamaatioRiviStatus(this.rowId, approvedStatus, this.$kielinro,  this.$store.state.auth.user);
          this.reclamationRow = updatedRow;
          this.reloadReclamationLogs();
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_status'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.updatingStatus = false;
      },
      async declineReclamation() {
        const confirm = await this.$refs.confirmImageDelete.open(this.$tc('reclamation.decline_reclamation', 1), this.$t('reclamation.decline_reclamation_confirm'), { color: 'warning' });
        if (!confirm)
          return;

        this.updatingStatus = true;
        try {
          const declinedStatus = 'RAKLIIKE_HYLATTY';
          const updatedRow = await frendsApi.updateReklamaatioRiviStatus(this.rowId, declinedStatus, this.$kielinro,  this.$store.state.auth.user);
          this.reclamationRow = updatedRow;
          this.reloadReclamationLogs();
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_status'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.updatingStatus = false;
      },
      async markAsInstalled() {
        this.updatingStatus = true;
        try {
          const asennettu_status = 'ASENNETTU';
          const updatedRow = await frendsApi.updateReklamaatioRiviStatus(this.rowId, asennettu_status, this.$kielinro,  this.$store.state.auth.user);
          this.reclamationRow = updatedRow;
          this.reloadReclamationLogs();
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_update_reclamation_row_status'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.updatingStatus = false;
      },
      async deleteReclamation() {
        const confirm = await this.$refs.confirmImageDelete.open(this.$t('reclamation.delete_reclamation'), this.$t('reclamation.delete_reclamation_confirm'), { color: 'warning' });
        if (!confirm)
          return;

        try {
          await frendsApi.deleteReklamaatioRivi(this.rowId);
          this.$router.push({
            name: 'reclamations',
            params: {
              projectId: this.reclamationRow.d365projektiid,
            },
          })
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_delete_reclamation_row'),
              timeOut: 4000,
              showButton: false,
            });
        }
      },
      openFullImage(index) {
        const images = this.files.map(a => {
          return {
            ...a,
            src: a.thumb_url || a.dataUrl || a.public_url,
            "data-src": a.dataUrl || a.public_url,
            alt: a.info || a.tiedostonimi
          }
        })

        const $viewer = this.$viewerApi({
          options: {
            inline: false,
            button: true,
            navbar: true,
            title: true,
            toolbar: true,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: false,
            fullscreen: false,
            keyboard: true,
            url: 'data-src',
            initialViewIndex: index,
          },
          images,
        });
      },
      async deleteImage(fileId) {
        const confirm = await this.$refs.confirmImageDelete.open(this.$t('reclamation.delete_image'), this.$t('reclamation.delete_image_confirm'), { color: 'warning' });
        if (!confirm) return;

        this.removingFile = true;
        try {
          await frendsApi.deleteReklamaatioRiviLiite(fileId);
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_remove_reclamation_attachments'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.removingFile = false;
        this.reloadReclamationAttachments();
      },
      addImage() {
        this.$refs.imageFileInput.click();
      },
      async fileSelected(files) {
        this.uploadingFiles = true;

        files.forEach(f => this.uploads.push(f));

        if (this.reclamationRow.rekl_rivitunniste != null) {
          try {
            await this.uploadFiles(this.reclamationRow.rekl_rivitunniste, this.uploads);
          } catch (error) {
            console.log(error);
            this.$store.dispatch('showNotification',
              {
                color: 'error',
                message: this.$t('reclamation.failed_to_upload_reclamation_attachments'),
                timeOut: 4000,
                showButton: false,
              });
          }
        }

        this.uploadingFiles = false;
        this.uploads = [];
        this.reloadReclamationAttachments();
      },
      async uploadFiles(riviTunniste, files) {
        for (let i = 0; i < files.length; i += 1) {
          const fileDataUrl = files[i].dataUrl;
          await frendsApi.addReklamaatioRiviLiite(riviTunniste, fileDataUrl, files[i].name, files[i].type);
        }
      },
      async reloadProject(id) {
        if (!this.$store.state.projectDTO.project || this.$store.state.projectDTO.project.id !== id) {
          this.loading = true;
          await this.$store.dispatch('reloadProjectDTO', id);
          this.loading = false;
        } else {
          this.subtleLoading = true;
          this.loading = false;
          await this.$store.dispatch('reloadProjectDTO', id);
          this.subtleLoading = false;
        }
      },
      async reloadReclamationRow() {
        this.loading = true;

        try {
          const reclamationRow = await frendsApi.getReklamaatioRivi(this.rowId, this.$kielinro);
          this.reclamationRow = reclamationRow;
          this.reloadReclamationAttachments();
          this.reloadReclamationLogs();
          this.reloadProject(this.reclamationRow.d365projektiid);
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_row'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.loading = false;
      },
      async reloadReclamationAttachments() {
        this.loadingAttachments = true;
        try {
          const files = await frendsApi.getReklamaatioRiviLiitteet(this.rowId);
          this.files = files.map(file => {
            file.alt = this.$formatDate(file.modified);
            return file;
          });
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_attachments'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.loadingAttachments = false;
      },
      async reloadReclamationLogs() {
        this.loadingLogs = true;
        try {
          const logs = await frendsApi.getReklamaatioRiviLokit(this.rowId, this.$kielinro);
          this.reclamationRowLogs = logs;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_logs'),
              timeOut: 4000,
              showButton: false,
            });
        }
        this.loadingLogs = false;
      },
    },
    computed: {
      lokiColumns() {
        if (this.reclamationRow.naytaliitteet === 'false') {
          return 'xs12 lg6 xl6';
        } else {
          return 'xs12 lg4 xl4';
        }
      },
      liitteetColumns() {
        if (this.reclamationRow.naytaloki === 'false') {
          return 'xs12 lg6 xl6';
        } else {
          return 'xs12 lg4 xl4';
        }
      },
      infoColumns() {
        if (this.reclamationRow.naytaloki === 'false' || this.reclamationRow.naytaliitteet === 'false') {
          return 'xs12 lg6 xl6';
        } else {
          return 'xs12 lg4 xl4';
        }
      },
      deliveryDate() {
        if (this.reclamationRow.rekl_jakelupvm != null) {
          return this.$formatDateIncludeWeekday(this.reclamationRow.rekl_jakelupvm);
        } else if (this.reclamationRow.rekl_jakeluarvio != null) {
          return this.reclamationRow.rekl_jakeluarvio;
        } else {
          return this.$t('reclamation.no_delivery_date');
        }
      },
      deliveryTitle() {
        if (this.reclamationRow.rekl_jakelupvm == null && this.reclamationRow.rekl_jakeluarvio != null) {
          return this.$t('reclamation.delivery_estimate');
        } else {
          return this.$t('reclamation.delivery');
        }
      },
      reclamationNeedsApproval() {
        return parseInt(this.reclamationRow.rekl_statusnro, 10) === 7;
      },
      reclamationCanBeMarkedAsInstalled() {
        return parseInt(this.reclamationRow.rekl_statusnro, 10) === 4 || parseInt(this.reclamationRow.rekl_statusnro, 10) === 6;
      },
      reclamationCanBeDeleted() {
        return parseInt(this.reclamationRow.rekl_statusnro, 10) === 7 || parseInt(this.reclamationRow.rekl_statusnro, 10) === 0;
      },
      project() {
        return this.$store.state.projectDTO.project;
      },
      projectDTO() {
        return this.$store.state.projectDTO;
      },
      deliveryLot() {
        if (this.projectDTO && this.projectDTO.deliveryLots && this.projectDTO.deliveryLots.length > 0 && this.reclamationRow.rekl_rivitunniste) {
          const deliveryLotIndex = this.projectDTO.deliveryLots.findIndex(lot => lot.id === this.reclamationRow.d365toimituseraid);
          if (deliveryLotIndex > -1) {
            return this.projectDTO.deliveryLots[deliveryLotIndex];
          }
        }
        return false;
      },
      projectApartments() {
        if (this.projectDTO && this.projectDTO.deliveryLots) {
          const dlAparts = this.projectDTO.deliveryLots.map(dl => dl.apartments);
          const all = [];
          dlAparts.forEach(aparts => all.push(...aparts));
          return all;
        }
        return [];
      },
      apartment() {
        if (this.projectApartments.length > 0 && this.reclamationRow.rekl_rivitunniste) {
          const apartmentIndex = this.projectApartments.findIndex(apartment => parseInt(apartment.vertexTargetNumber) === parseInt(this.reclamationRow.huonenro) && apartment.vertexProject === this.reclamationRow.projektinro);
          if (apartmentIndex > -1) {
            return this.projectApartments[apartmentIndex];
          }
        }
        return false;
      }
    },
    mounted() {
      this.reloadReclamationRow();

      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  .reclamation-row {
    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    .topbar-search {
      .v-input__slot {
        padding: 0 !important;
      }
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }
  }

  .reclamation-log-list {
    .v-list__tile {
      height: auto !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .v-list__tile__title {
      height: auto;
    }

    .v-list__tile__sub-title, .v-list__tile__title {
      white-space: normal;
    }
  }
</style>
