<template>
  <v-menu
    lazy
    :disabled="disabled"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    full-width
    :nudge-right="40"
    max-width="290px"
    min-width="290px">
    <v-text-field
      slot="activator"
      :disabled="disabled"
      :label="label"
      :rules="rules"
      :value="$formatDateNoTime(innerValue)"
      required
      append-icon="event"
      readonly></v-text-field>
    <hb-custom-date-picker :autosave="true"
                           show-week
                           v-model="innerValue"
                           locale="fi-fi"
                           no-title
                           scrollable
                           first-day-of-week="1"
                           actions>
      <template v-slot:default="{ }">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </template>
    </hb-custom-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: ['label', 'disabled', 'value', 'rules'],
  name: 'HbDatePicker',
  data() {
    return {
      innerValue: null,
    };
  },
  watch: {
    value(to) {
      this.innerValue = to;
    },
    innerValue(to) {
      const formatted = moment(this.value).format("yyyy-MM-DD");
      if (to !== formatted) {
        this.$emit('input', to);
      }
    },
  },
  mounted() {
    if (this.value == null) {
      return;
    }
    this.innerValue = moment(this.value).format("yyyy-MM-DD");
  }
};
</script>

<style scoped>

</style>
