import { render, staticRenderFns } from "./SingleProjectView.vue?vue&type=template&id=7ac5c476&scoped=true&xmlns%3Av-touch=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./SingleProjectView.vue?vue&type=script&lang=js"
export * from "./SingleProjectView.vue?vue&type=script&lang=js"
import style0 from "./SingleProjectView.vue?vue&type=style&index=0&id=7ac5c476&prod&lang=scss&scoped=true"
import style1 from "./SingleProjectView.vue?vue&type=style&index=1&id=7ac5c476&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac5c476",
  null
  
)

export default component.exports