<template>
  <v-list-tile
    @click="openProject"
  >
    <v-list-tile-avatar>
      <v-icon color="light-blue">assignment</v-icon>
    </v-list-tile-avatar>

    <v-list-tile-content>
      <v-list-tile-title class="font-weight-bold">{{ project.clientName }}</v-list-tile-title>
      <v-list-tile-title class="caption" v-if="!$isCustomer">{{ group }}
        <template v-if="!$isCustomer">&bull; {{ $formatDateNoTime(project.deliveryStartDate) }}</template>
      </v-list-tile-title>
      <v-list-tile-title class="caption" v-if="$isCustomer">{{ project.streetAddress }}, {{ project.postalCode }}
        {{ project.city }}
      </v-list-tile-title>
      <v-list-tile-title class="caption grey--text">{{ project.customerInfo }}</v-list-tile-title>
    </v-list-tile-content>

    <v-list-tile-action>
      <v-icon>chevron_right</v-icon>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
export default {
  props: ['project'],
  name: "ProjectListItem",
  computed: {
    group() {
      const group = this.$store.state.installationGroups.find(ig => ig.id === this.project.installationGroupId);
      if (group != null) {
        return group.name;
      }

      return '';
    },
  },
  methods: {
    openProject() {
      this.$emit('save-search-phrase');
      this.$router.push({name: 'project', params: { id: this.project.id }});
    }
  }
}
</script>

<style scoped>

</style>
