<template>
  <v-dialog v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ this.isNew ? $t('tyomaarays.add_row') : $t('tyomaarays.edit_row') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dismissDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-content"  id="dialogContainer">
          <template v-if="loading">
            <hb-loading-indicator />
          </template>
          <template v-if="!loading">
            <div class="title mb-3 font-weight-regular">{{ $t('tyomaarays.select_reclamation_or_give_description')}}</div>
            <v-form ref="form">
              <v-autocomplete
                :label="$t('tyomaarays.reclamation')"
                :items="filteredReclamationRows"
                :item-text="item => (item.huonenimi || '') + ' ' + item.rekl_selite + item.rekl_tuoteselite"
                :item-value="item => item.rekl_rivitunniste"
                v-model="tyomaaraysRivi.rekl_rivitunniste"
                @change="tyomaaraysRivi.kuvaus = ''"
                clearable
                @focus="scrollToThis"
              >
                <template v-slot:selection="data">
                  <v-list-tile-content>
                    <v-list-tile-title><strong>{{data.item.huonenimi}}:</strong> {{ data.item.rekl_tuoteselite ? data.item.rekl_tuoteselite : $t('reclamation.no_product') }}</v-list-tile-title>
                  </v-list-tile-content>
                </template>
                <template v-slot:item="{item}">
                    <v-list-tile-content>
                      <v-list-tile-title><strong>{{item.huonenimi}}:</strong> {{ item.rekl_tuoteselite ? item.rekl_tuoteselite : $t('reclamation.no_product') }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{ item.rekl_selite }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                </template>
              </v-autocomplete>
              <v-textarea rows="3" :disabled="!!tyomaaraysRivi.rekl_rivitunniste" :label="$t('tyomaaraykset.description')" v-model="tyomaaraysRivi.kuvaus" @focus="scrollToThis" />
            </v-form>
          </template>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="default"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :left="$vuetify.breakpoint.xsOnly"
              @click="dismissDialog"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
              <span v-else>{{ $t('cancel') }}</span>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="primary"
              :disabled="loading"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :bottom="$vuetify.breakpoint.xsOnly"
              :right="$vuetify.breakpoint.xsOnly"
              :loading="saving"
              @click="save"
            >
              <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
              <span v-else>{{ $t('tyomaarays.save_row') }}</span>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import frends from '../../frendsApi';
import FileCard from "@/components/FileCard";
import backgroundSendingService from "@/backgroundSendingService";
import UiEventBus from "@/UiEventBus";
import frendsApi from "../../frendsApi";
import {orderBy} from "lodash";

export default {
  name: "EditTyomaaraysRiviDialog",
  components: {},
  props: {
    tyomaarays: {
      type: Object,
      default: () => {}
    },
    projectDTO: {
      type: Object,
      default: () => {}
    },
    deliveryLot: {
      type: Object,
      default: () => {}
    },
    tyomaaraysRivit: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dialog(newValue, oldValue) {
      if (!newValue) {
        this.$refs.form.reset();
        this.dialog = false;
        this.$nextTick(() => {
          // TODO: mitä nollataan
          this.reclamationDetails = '';
          this.reclamationType = '';
        });
      }
    }
  },
  computed: {
    tyomaaraysStatukset() {
      return this.$store.state.tyomaaraysStatukset;
    },
    deliveryLots() {
      return this.projectDTO?.deliveryLots || [];
    },
    installationGroups() {
      return this.projectDTO ? Object.values(this.projectDTO?.installationGroups) : [];
    },
    contactPersons() {
      return this.projectDTO?.contactPersons || [];
    },
    filteredReclamationRows() {
      let reclamations = [...this.reclamationRows];
      this.tyomaaraysRivit.forEach(tmr => {
        reclamations = reclamations.filter(r => r.rekl_rivitunniste !== tmr.rekl_rivitunniste);
      })
      // Tehdään sorttaus huoneen nimen ja reklamaation tuoteselitteen mukaan
      reclamations.forEach(r => {
        r._selectSortName = [r.huonenimi || 'zzzzzzzzzzzzzzzzz', r.rekl_tuoteselite || 'zzzzzzzzzzzzzzzzz'].join('')
      })
      // Poistetaan kaikki reklamaatiot, jotka on jo liitetty työmaaraykseen
      reclamations = reclamations.filter(r => r != null && (r.tyomaaraysid === null || this.tyomaaraysRivi?.rekl_rivitunniste === r.rekl_rivitunniste))
      reclamations = orderBy(reclamations, ['_selectSortName'], ['asc'])
      return reclamations;
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialog: false,
      isNew: false,
      tyomaaraysRivi: {},
      reclamationRows: [],
    }
  },
  methods: {
    async reloadReclamationRows() {
      this.loading = true;
      let projectId = this.tyomaarays.d365projektiid;
      if (projectId) {
        projectId = this.$removeCurlyBrackets(projectId)
      }

      let toimituseraId = this.tyomaarays.d365toimituseraid;
      if (toimituseraId) {
        toimituseraId = this.$removeCurlyBrackets(toimituseraId)
      }

      try {
        const reclamationRows = await frendsApi.getReklamaatioRivit(projectId, toimituseraId, null, null, this.$kielinro);
        this.reclamationRows = reclamationRows;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showNotification',
          {
            color: 'error',
            message: this.$t('tyomaarays.error_when_loading_reclamations'),
            timeOut: 4000,
            showButton: false,
          });
      }
      this.loading = false;
    },
    scrollToThis(event) {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          const opts = {
            offset: 50,
            duration: 100,
            container: '#dialogContainer'
          };
          this.$vuetify.goTo(event.target, opts);
        }, 200);
      }
    },
    async open(tyomaaraysRivi) {
      this.dialog = true;
      this.tyomaaraysRivi = { ...tyomaaraysRivi };
      this.isNew = !this.tyomaaraysRivi.tyomaaraysrivinro;
      this.loading = false;
      console.log('projectDTO', this.projectDTO);
      this.reloadReclamationRows();
    },
    dismissDialog() {
      this.dialog = false;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.tyomaaraysRivi.rekl_rivitunniste) {
        const reclamationRow = this.reclamationRows.find(r => r.rekl_rivitunniste === this.tyomaaraysRivi.rekl_rivitunniste);
        if (reclamationRow) {
          this.tyomaaraysRivi.tilausnro = reclamationRow.rekl_tilausnro;
          if (!this.tyomaaraysRivi.kuvaus) {
            this.tyomaaraysRivi.selite = reclamationRow.rekl_selite;
          }
        }

      }
      console.log('tyomaarays', this.tyomaarays);
      console.log('tyomaaraysRivi', this.tyomaaraysRivi);
      console.log('reclamationRows', this.reclamationRows);

      this.saving = true;
      try {
        const response = await frendsApi.addTyomaaraysRivi(this.tyomaarays.tyomaaraysid, this.tyomaaraysRivi, this.$kielinro);
        console.log('response', response);
        this.$emit('saved', response);
        this.dismissDialog();
      } catch (error) {
        console.log(error);
      }
      this.saving = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.text-content {
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.text-details {
  font-size: 13px;
  color: rgba(0,0,0,.54);
}

@media screen and (min-width: 600px) {
  .text-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }

  .text-details {
    font-size: 14px;
  }
}
</style>
