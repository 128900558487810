<template>
  <v-card v-if="project" :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}" data-cy="delivery-lot-details">
    <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
      {{ $t('delivery_lot.delivery_lot_info') }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('delivery_lot.delivery_lot_info') }}</v-subheader>
      <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-2">

        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">place</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title style="cursor: pointer;" @click="goToProject">{{ project.clientName }}</v-list-tile-title>
            <v-list-tile-sub-title v-if="deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.streetAddress }}, {{ deliveryLot.city }}, {{ deliveryLot.postalCode }}</v-list-tile-sub-title>
            <v-list-tile-sub-title v-else-if="project.streetAddress != null && project.streetAddress.length > 0" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ project.streetAddress }}, {{ project.city }}, {{ project.postalCode }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-menu bottom left>
              <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
              <v-list>
                <v-list-tile :href="mapsAddress" target="_blank" v-if="(deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0) || (project.streetAddress != null && project.streetAddress.length > 0)">
                  <v-list-tile-action><v-icon>map</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.show_on_map') }}</v-list-tile-title>
                </v-list-tile>
                <v-divider v-if="(deliveryLot.streetAddress != null && deliveryLot.streetAddress.length > 0) || (project.streetAddress != null && project.streetAddress.length > 0)"></v-divider>
                <v-list-tile exact :to="{name: 'project', params: {id: project.id}}">
                  <v-list-tile-action><v-icon>assignment</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.move_to_project') }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-list-tile-action>
        </v-list-tile>

        <template v-if="deliveryLot.puusnetDeliveryNumber">
          <v-divider inset></v-divider>

          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">file_copy</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('delivery_lot.puusnet_delivery_number') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.puusnetDeliveryNumber }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <template>
          <v-divider inset></v-divider>

          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-truck-loading</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ deliveryLot.deliveryDateConfirmed ? $t('delivery_lot.delivery_date_confirmed') : $t('delivery_lot.delivery_date_unconfirmed') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ weekday(deliveryLot.deliveryDate) }}  {{ $formatDateNoTime(deliveryLot.deliveryDate) }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-divider inset v-if="deliveryLot.deliveryExtraInfo"></v-divider>

          <v-list-tile  v-if="deliveryLot.deliveryExtraInfo">
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-exclamation-triangle</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ deliveryLot.deliveryExtraInfo }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile v-if="deliveryLot.lisatiedotJehuun" class="multiline">
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-info</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('delivery_lot.lisatiedot_jehuun') }}</v-list-tile-title>
              <v-list-tile-sub-title class="v-list-subtitle-auto-wrap wrap-text">
                {{ deliveryLot.lisatiedotJehuun }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-divider inset></v-divider>

          <template v-if="deliveryLot.installationStartDate">
            <v-list-tile>
              <v-list-tile-avatar>
                <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-screwdriver</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('delivery_lot.installation_start_date') }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ weekday(deliveryLot.installationStartDate) }} {{ $formatDateNoTime(deliveryLot.installationStartDate) }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0" v-if="puustelliContactPersons != null && puustelliContactPersons.length === 1">
            <v-divider inset class="mt-0 mb-0"></v-divider>

            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ getPuustelliTitleText(puustelliContactPersons[0]) }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ puustelliContactPersons[0].name != null ? puustelliContactPersons[0].name : '' }}{{ puustelliContactPersons[0].phone != null ? ', puh. ' + puustelliContactPersons[0].phone : '' }}</v-list-tile-sub-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ puustelliContactPersons[0].email != null ? puustelliContactPersons[0].email : '' }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-menu bottom left>
                  <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                  <v-list>
                    <v-list-tile v-if="puustelliContactPersons[0].phone != null" :href="`tel:${puustelliContactPersons[0].phone}`">
                      <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                      <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                    </v-list-tile>
                    <v-divider v-if="puustelliContactPersons[0].phone != null && puustelliContactPersons[0].email != null"></v-divider>
                    <v-list-tile v-if="puustelliContactPersons[0].email != null" :href="`mailto:${puustelliContactPersons[0].email}`">
                      <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                      <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>

            </v-list-tile>
          </v-list>

          <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" v-if="puustelliContactPersons !== null && puustelliContactPersons.length > 1" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
            <v-divider inset class="mt-0 mb-0"></v-divider>

            <v-list-group class="contact-persons-list">
              <template v-slot:activator>
                <v-list-tile style="height: auto !important;" class="contact-persons-header" avatar>
                  <v-list-tile-avatar>
                    <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ $tc('projects.puustelli_contact', 2) }}</v-list-tile-title>
                    <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('in_total') }} {{ puustelliContactPersons.length }} {{ $t('projects.puustelli_contact_persons') }}.</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile class="mb-2 full-height" v-for="puustelliContactPerson in puustelliContactPersons" :key="puustelliContactPerson.id">
                <v-list-tile-action>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ puustelliContactPerson.name != null ? puustelliContactPerson.name : '' }}</v-list-tile-title>
                  <v-list-tile-sub-title style="height: 100% !important;" class="py-2" :class="{caption: $vuetify.breakpoint.xsOnly}">
                    <v-layout row wrap>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-0 pb-0'">
                        {{ getPuustelliTitleText(puustelliContactPerson) }}
                      </v-flex>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                        {{ puustelliContactPerson.email != null ? puustelliContactPerson.email : '' }}
                      </v-flex>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-1'">
                        {{ puustelliContactPerson.phone != null ? puustelliContactPerson.phone : '' }}
                      </v-flex>
                    </v-layout>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-menu bottom left>
                    <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                    <v-list>
                      <v-list-tile v-if="puustelliContactPerson.phone != null" :href="`tel:${puustelliContactPerson.phone}`">
                        <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                        <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                      </v-list-tile>
                      <v-divider v-if="puustelliContactPerson.phone != null && puustelliContactPerson.email != null"></v-divider>
                      <v-list-tile v-if="puustelliContactPerson.email != null" :href="`mailto:${puustelliContactPerson.email}`">
                        <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                        <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
              </v-list-tile>

            </v-list-group>
          </v-list>

          <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0" v-if="contactPersons !== null && contactPersons.length === 1" style="margin-top: -10px;">
            <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>

            <v-list-tile>
              <v-list-tile-avatar>
                <v-icon v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $tc('projects.client_contact', 1) }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ contactPersons[0].name != null ? contactPersons[0].name : '' }}{{ contactPersons[0].phone != null ? ', puh. ' + contactPersons[0].phone : '' }}</v-list-tile-sub-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ contactPersons[0].email != null ? contactPersons[0].email : '' }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-menu bottom left>
                  <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                  <v-list>
                    <v-list-tile v-if="contactPersons[0].phone != null" :href="`tel:${contactPersons[0].phone}`">
                      <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                      <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                    </v-list-tile>
                    <v-divider v-if="contactPersons[0].phone != null && contactPersons[0].email != null"></v-divider>
                    <v-list-tile v-if="contactPersons[0].email != null" :href="`mailto:${contactPersons[0].email}`">
                      <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                      <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>

          <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" v-if="contactPersons !== null && contactPersons.length > 1" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
            <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>

            <v-list-group class="contact-persons-list">
              <template v-slot:activator>
                <v-list-tile style="height: auto !important;" class="contact-persons-header">
                  <v-list-tile-avatar>
                    <v-icon v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ $tc('projects.client_contact', 2) }}</v-list-tile-title>
                    <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('in_total') }} {{ contactPersons.length }} {{ $t('projects.client_contact_persons') }}.</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile class="mb-2 full-height" v-for="contactPerson in contactPersons" :key="contactPerson.id">
                <v-list-tile-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ contactPerson.name != null ? contactPerson.name : '' }}</v-list-tile-title>
                  <v-list-tile-sub-title style="height: 100% !important;" class="py-2" :class="{caption: $vuetify.breakpoint.xsOnly}">
                    <v-layout row wrap>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-0 pb-0'">
                        {{ getRoleTitleText(contactPerson) }}
                      </v-flex>
                      <v-flex xs12 class="text-truncate" v-if="contactPerson.roleDescription" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                        {{ contactPerson.roleDescription }}
                      </v-flex>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                        {{ contactPerson.email != null ? contactPerson.email : '' }}
                      </v-flex>
                      <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1'">
                        {{ contactPerson.phone != null ? contactPerson.phone : '' }}
                      </v-flex>
                    </v-layout>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-menu bottom left>
                    <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                    <v-list>
                      <v-list-tile v-if="contactPerson.phone != null" :href="`tel:${contactPerson.phone}`">
                        <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                        <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                      </v-list-tile>
                      <v-divider v-if="contactPerson.phone != null && contactPerson.email != null"></v-divider>
                      <v-list-tile v-if="contactPerson.email != null" :href="`mailto:${contactPerson.email}`">
                        <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                        <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-list-tile-action>
              </v-list-tile>

            </v-list-group>
          </v-list>

          <v-list v-if="contactPersons === null || (contactPersons && contactPersons.length === 0)" two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
            <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>
            <v-list-tile class="contact-persons-header">
              <v-list-tile-avatar>
                <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'black' : 'error'">fas fa-exclamation</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $tc('projects.client_contact', 2) }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('projects.clients_contacts_are_missing') }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <installation-group :installationGroup="installationGroup" :installers="installers" :title="$t('projects.installation_group')"></installation-group>
          <installation-group :installationGroup="carryingGroup" :installers="carryingGroupMembers" :title="$t('projects.carrying_group')"></installation-group>
          <v-divider inset></v-divider>
          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-crop-alt</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('projects.frames') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.frameEstimation }} {{ $t('delivery_lot.pcs') }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <!--<template v-if="deliveryLotInstallationUnits && $showInstallationUnits">-->
          <template>
            <v-divider inset></v-divider>

            <v-list-tile>
              <v-list-tile-avatar>
                <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-screwdriver</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('room.installation_units') }}  ({{ $t('delivery_lot.handed_over_and_total') }})</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $roundNumber(deliveryLotInstallationUnitsCompleted, 1) }} / {{ $roundNumber(deliveryLotInstallationUnits, 1) }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <template v-if="deliveryLot.combineFittings !== null">
            <v-divider inset></v-divider>

            <v-list-tile>
              <v-list-tile-avatar>
                <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-ring</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('delivery_lot.combine_fittings') }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.combineFittings ? $t('yes') : $t('no') }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </template>

        <template v-if="$isPuustelliUser || $isAdmin">
          <v-divider inset></v-divider>

          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon size="24" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">{{ statusIcon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ !deliveryLot.received ? $t('delivery_lot.not_received') : $t('delivery_lot.status') }}</v-list-tile-title>
              <v-list-tile-sub-title v-if="deliveryLot.received" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ statusDescription }}</v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="deliveryLot.handOverAcceptedEmail" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.handOverAcceptedEmail }}, {{ weekday(deliveryLot.handOverAcceptedDate) }} {{ $formatDateNoTime(deliveryLot.handOverAcceptedDate) }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <template v-if="($isPuustelliUser || $isAdmin) && deliveryLot.deliveryInfo">
          <v-divider inset></v-divider>
          <v-list-tile class="full-height">
            <v-list-tile-avatar>
              <v-icon size="24" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">mdi mdi-information-outline</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content class="content-padding">
              <v-list-tile-title>{{ $t('projects.hand_over_comment') }}</v-list-tile-title>
              <v-list-tile-sub-title class="v-list-subtitle-auto-wrap" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.deliveryInfo }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <template>
          <v-divider inset></v-divider>
          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon size="24" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">mdi mdi-cube-outline</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('projects.delivery_lot_volume') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ deliveryLot.installationVolume }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <v-list v-if="reclamationRows.length > 0 && $showReclamations" two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-2 pt-0">
          <v-divider inset class="mt-0 mb-0"></v-divider>
          <v-list-tile avatar :to="{name: 'reclamations', params: { projectId: this.project.id, deliveryLotId: this.deliveryLotId, resetFilters: true }}">
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-exchange-alt</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $tc('reclamation.title', 2) }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('reclamation.total_reclamations', { reclamationCount: reclamationRows.length }) }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon>chevron_right</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>

      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import parameters from "@/parameters";
const deliveryLotStatuses = parameters.deliveryLotStatuses;
import dayjs from "dayjs";
import InstallationGroup from "@/components/SingleProjectView/InstallationGroup.vue";

const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

export default {
  components: {InstallationGroup},
  props: ['reclamationRows', 'deliveryLot', 'project', 'rooms', 'projectDTO'],
  name: "DeliveryLotDetailsCard",
  methods: {
    weekday(date) {
      return this.$t(`projects.week_days.${dayjs(date).isoWeekday()}`);
    },
    goToProject() {
      this.$router.push({name: 'project', params: {id: this.project.id}});
    },
    getPuustelliTitleText(contactPerson) {
      return contactPerson.title.join(', ');
    },
    getRoleTitleText(contactPerson) {
      let text = '';
      const title = contactPerson.title;
      const role = contactPerson.role;
      if (title != null && title.length > 0) {
        text += title;
      }
      if (title && role) {
        text += ', ';
      }
      if (role) {
        text += role;
      }
      return text;
    },
  },
  computed: {
    contactPersons() {
      const dto = this.$store.state.projectDTO;
      if (dto.contactPersons !== null && dto.contactPersons.length > 0) {
        return dto.contactPersons;
      } else {
        return null;
      }
    },
    puustelliContactPersons() {
      const puustelliContactPersons = [];
      const owner = Object.assign({}, this.deliveryLot.owner);
      if (owner) {
        owner.title = [this.$t('projects.titles.owner')];
        puustelliContactPersons.push(owner);
      }
      const clientSecretary = Object.assign({}, this.projectDTO.clientSecretary);
      if (clientSecretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === clientSecretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.clientSecretary'));
        } else {
          clientSecretary.title = [this.$t('projects.titles.clientSecretary')];
          puustelliContactPersons.push(clientSecretary);
        }
      }
      const secretary = Object.assign({}, this.deliveryLot.secretary);
      if (secretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === secretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.secretary'));
        } else {
          secretary.title = [this.$t('projects.titles.secretary')];
          puustelliContactPersons.push(secretary);
        }
      }
      const manager = Object.assign({}, this.deliveryLot.manager);
      if (manager) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === manager.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.manager'));
        } else {
          manager.title = [this.$t('projects.titles.manager')];
          puustelliContactPersons.push(manager);
        }
      }
      return puustelliContactPersons;
    },
    installers() {
      if (this.installationGroup != null) {
        const allInstallers = this.projectDTO.installationGroupMembers;
        if (allInstallers == null) {
          return [];
        }
        if (this.installationGroup.id in allInstallers) {
          return allInstallers[this.installationGroup.id];
        }
      }
      return [];
    },
    installationGroup() {
      const groupId = this.deliveryLot.installationGroupId;
      const installationGroups = this.projectDTO.installationGroups;
      return installationGroups[groupId];
    },
    carryingGroup() {
      const carryingGroupId = this.deliveryLot.carryingGroupId;
      const installationGroups = this.projectDTO.installationGroups;
      return installationGroups[carryingGroupId];
    },
    carryingGroupMembers() {
      if (this.carryingGroup != null) {
        const allInstallers = this.projectDTO.installationGroupMembers;

        if (allInstallers == null) {
          return [];
        }

        if (this.carryingGroup.id in allInstallers) {
          return allInstallers[this.carryingGroup.id];
        }
      }

      return [];
    },
    statusIcon() {
      if (this.deliveryLot && this.deliveryLot.received) {
        return deliveryLotStatuses[this.deliveryLot.statusCode].icon;
      } else {
        return deliveryLotStatuses[this.deliveryLot.statusCode].notReceivedIcon;
      }
    },
    systemData() {
      return this.$store.state.systemData;
    },
    statusDescription() {
      const statusCode = this.deliveryLot.statusCode;
      const statuses = this.systemData.deliveryLotStatuses;
      const status = statuses.find(s => s.key === statusCode);
      return status.value;
    },
    deliveryLotId() {
      return this.deliveryLot.id;
    },
    projectId() {
      return this.project.id;
    },

    mapsAddress() {
      const objectUsedForMapsUrl = this.deliveryLot.streetAddress && this.deliveryLot.streetAddress.length > 0 ? this.deliveryLot : this.project;
      return `https://maps.google.com?q=${objectUsedForMapsUrl.streetAddress}+${objectUsedForMapsUrl.city}+${objectUsedForMapsUrl.postalCode}`;
    },
    deliveryLotInstallationUnits() {
      if (this.rooms) {
        let installationUnits = 0;
        this.rooms.forEach(apartment => {
          installationUnits = installationUnits + apartment.installationUnits;
        });
        return installationUnits;
      }
      return false;
    },
    deliveryLotInstallationUnitsCompleted() {
      if (this.rooms) {
        const handOverRooms = this.rooms.filter(room => parseInt(room.statusCode) === 100000002 || parseInt(room.statusCode) == 100000003 || parseInt(room.statusCode) == 100000001);
        let installationUnits = 0;
        handOverRooms.forEach(apartment => {
          installationUnits = installationUnits + apartment.installationUnits;
        });
        return installationUnits;
      }
      return false;
    },
  },
}
</script>

<style scoped lang="scss">
.v-list-title-auto-wrap {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.v-list-subtitle-auto-wrap {
  color: rgba(0,0,0,.54);
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.content-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}
.multiline > :first-child {
  height: 100% !important;
}
.full-height {
  .v-list__tile {
    height: 100% !important;
  }
}
</style>
