<template>
  <v-card :dark="$vuetify.breakpoint.mdAndDown" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown}" v-if="projectDTO != null" data-cy="project-details">
    <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
      {{ $t('projects.project_info') }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('projects.project_info') }}</v-subheader>

      <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">place</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ project.fullDescription }}</v-list-tile-title>
            <v-list-tile-sub-title v-if="project.streetAddress != null && project.streetAddress.length > 0" :class="{caption: $vuetify.breakpoint.xsOnly}">{{ project.streetAddress }}, {{ project.city }}, {{ project.postalCode }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action v-if="project.streetAddress != null && project.streetAddress.length > 0">
            <v-btn icon :href="mapsAddress" target="_blank"><v-icon>map</v-icon></v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-divider inset></v-divider>

      <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">{{ statusIcon }}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t('projects.project_status') }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ statusDesc }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-divider inset></v-divider>

      <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">file_copy</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t('projects.new_puusnet_projno') }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ project.puusnetProjectNumber }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <template v-if="project.moveInDate">
        <v-divider inset></v-divider>
        <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">calendar_month</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('projects.move_in_date') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">
                {{ $formatDateNoTime(project.moveInDate) }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </template>

      <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0" v-if="puustelliContactPersons != null && puustelliContactPersons.length === 1">
        <v-divider inset class="mt-0 mb-0"></v-divider>

        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ getPuustelliTitleText(puustelliContactPersons[0]) }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ puustelliContactPersons[0].name != null ? puustelliContactPersons[0].name : '' }}{{ puustelliContactPersons[0].phone != null ? ', puh. ' + puustelliContactPersons[0].phone : '' }}</v-list-tile-sub-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ puustelliContactPersons[0].email != null ? puustelliContactPersons[0].email : '' }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-menu bottom left>
              <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
              <v-list>
                <v-list-tile v-if="puustelliContactPersons[0].phone != null" :href="`tel:${puustelliContactPersons[0].phone}`">
                  <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                </v-list-tile>
                <v-divider v-if="puustelliContactPersons[0].phone != null && puustelliContactPersons[0].email != null"></v-divider>
                <v-list-tile v-if="puustelliContactPersons[0].email != null" :href="`mailto:${puustelliContactPersons[0].email}`">
                  <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-list-tile-action>

        </v-list-tile>
      </v-list>

      <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" v-if="puustelliContactPersons !== null && puustelliContactPersons.length > 1" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
        <v-divider inset class="mt-0 mb-0"></v-divider>

        <v-list-group class="contact-persons-list" style="margin-bottom: 6px;">
          <template v-slot:activator>
            <v-list-tile class="contact-persons-header" avatar>
              <v-list-tile-avatar>
                <v-icon :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $tc('projects.puustelli_contact', 2) }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('in_total') }} {{ puustelliContactPersons.length }} {{ $t('projects.puustelli_contact_persons') }}.</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile class="mb-2 full-height" v-for="puustelliContactPerson in puustelliContactPersons" :key="puustelliContactPerson.id">
            <v-list-tile-action>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ puustelliContactPerson.name != null ? puustelliContactPerson.name : '' }}</v-list-tile-title>
              <v-list-tile-sub-title style="height: 100% !important;" class="py-2" :class="{caption: $vuetify.breakpoint.xsOnly}">
                <v-layout row wrap>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-0 pb-0'">
                    {{ getPuustelliTitleText(puustelliContactPerson) }}
                  </v-flex>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                    {{ puustelliContactPerson.email != null ? puustelliContactPerson.email : '' }}
                  </v-flex>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-1'">
                    {{ puustelliContactPerson.phone != null ? puustelliContactPerson.phone : '' }}
                  </v-flex>
                </v-layout>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-menu bottom left>
                <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                <v-list>
                  <v-list-tile v-if="puustelliContactPerson.phone != null" :href="`tel:${puustelliContactPerson.phone}`">
                    <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                    <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                  </v-list-tile>
                  <v-divider v-if="puustelliContactPerson.phone != null && puustelliContactPerson.email != null"></v-divider>
                  <v-list-tile v-if="puustelliContactPerson.email != null" :href="`mailto:${puustelliContactPerson.email}`">
                    <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                    <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-list-tile-action>
          </v-list-tile>

        </v-list-group>
      </v-list>

      <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0" v-if="contactPersons !== null && contactPersons.length === 1" style="margin-top: -10px;">
        <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>

        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $tc('projects.client_contact', 1) }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ contactPersons[0].name != null ? contactPersons[0].name : '' }}{{ contactPersons[0].phone != null ? ', puh. ' + contactPersons[0].phone : '' }}</v-list-tile-sub-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ contactPersons[0].email != null ? contactPersons[0].email : '' }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-menu bottom left>
              <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
              <v-list>
                <v-list-tile v-if="contactPersons[0].phone != null" :href="`tel:${contactPersons[0].phone}`">
                  <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                </v-list-tile>
                <v-divider v-if="contactPersons[0].phone != null && contactPersons[0].email != null"></v-divider>
                <v-list-tile v-if="contactPersons[0].email != null" :href="`mailto:${contactPersons[0].email}`">
                  <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                  <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" v-if="contactPersons !== null && contactPersons.length > 1" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
        <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>

        <v-list-group class="contact-persons-list">
          <template v-slot:activator>
            <v-list-tile style="height: auto !important;" class="contact-persons-header">
              <v-list-tile-avatar>
                <v-icon v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ $tc('projects.client_contact', 2) }}</v-list-tile-title>
                <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('in_total') }} {{ contactPersons.length }} {{ $t('projects.client_contact_persons') }}.</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile class="mb-2 full-height" v-for="contactPerson in contactPersons" :key="contactPerson.id">
            <v-list-tile-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ contactPerson.name != null ? contactPerson.name : '' }}</v-list-tile-title>
              <v-list-tile-sub-title style="height: 100% !important;" class="py-2" :class="{caption: $vuetify.breakpoint.xsOnly}">
                <v-layout row wrap>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-0 pb-0'">
                    {{ getRoleTitleText(contactPerson) }}
                  </v-flex>
                  <v-flex xs12 class="text-truncate" v-if="contactPerson.roleDescription" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                    {{ contactPerson.roleDescription }}
                  </v-flex>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1 pb-0'">
                    {{ contactPerson.email != null ? contactPerson.email : '' }}
                  </v-flex>
                  <v-flex xs12 class="text-truncate" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pt-1'">
                    {{ contactPerson.phone != null ? contactPerson.phone : '' }}
                  </v-flex>
                </v-layout>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-menu bottom left>
                <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                <v-list>
                  <v-list-tile v-if="contactPerson.phone != null" :href="`tel:${contactPerson.phone}`">
                    <v-list-tile-action><v-icon>call</v-icon></v-list-tile-action>
                    <v-list-tile-title>{{ $t('projects.call') }}</v-list-tile-title>
                  </v-list-tile>
                  <v-divider v-if="contactPerson.phone != null && contactPerson.email != null"></v-divider>
                  <v-list-tile v-if="contactPerson.email != null" :href="`mailto:${contactPerson.email}`">
                    <v-list-tile-action><v-icon>email</v-icon></v-list-tile-action>
                    <v-list-tile-title>{{ $t('projects.send_email') }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-list-tile-action>
          </v-list-tile>

        </v-list-group>
      </v-list>

      <v-list v-if="contactPersons === null || (contactPersons && contactPersons.length === 0)" two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0" :style="{marginTop: $vuetify.breakpoint.mdAndDown ? '-5px' : '-10px'}">
        <v-divider v-if="puustelliContactPersons === null || puustelliContactPersons.length === 0" inset class="mt-0 mb-0"></v-divider>
        <v-list-tile class="contact-persons-header">
          <v-list-tile-avatar>
            <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'black' : 'error'">fas fa-exclamation</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $tc('projects.client_contact', 2) }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('projects.clients_contacts_are_missing') }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <InstallationGroup :installationGroup="installationGroup" :installers="installers" :title="$t('projects.installation_group')"></InstallationGroup>
      <InstallationGroup :installationGroup="carryingGroup" :installers="carryingGroupMembers" :title="$t('projects.carrying_group')"></InstallationGroup>

      <v-list three-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
        <v-divider inset class="mt-0 mb-0"></v-divider>

        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-building</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t('projects.type') }}: {{ typeDesc }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('projects.apartment_count') }}: {{ project.apartmentCount }} kpl</v-list-tile-sub-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('projects.frames') }}: {{ project.frameEstimation }} kpl</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action></v-list-tile-action>
        </v-list-tile>
      </v-list>

      <template>
        <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
          <v-divider inset class="mt-0 mb-0"></v-divider>

          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-screwdriver</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('room.installation_units') }}  ({{ $t('delivery_lot.handed_over_and_total') }})</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $roundNumber(deliveryLotInstallationUnitsCompleted, 1) }} / {{ $roundNumber(deliveryLotInstallationUnits, 1) }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </template>

      <template v-if="startMeetingTask && !$isCustomer">
        <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
          <v-divider inset class="mt-0 mb-0"></v-divider>

          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon v-if="!updatingStartMeetingStatus && !startMeetingTaskIsDone" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">people_outline</v-icon>
              <v-progress-circular
                v-if="updatingStartMeetingStatus"
                indeterminate
                color="white"
                :width="2"
                :size="18"
              ></v-progress-circular>
              <v-icon v-if="!updatingStartMeetingStatus && startMeetingTaskIsDone" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">done_all</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('projects.start_meeting') }}</v-list-tile-title>
              <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ weekday(startMeetingTask.scheduledEnd) }} {{ $formatDateNoTime(startMeetingTask.scheduledEnd) }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-menu bottom left>
                <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                <v-list>
                  <template v-if="$isAdmin || $isPuustelliUser">
                    <v-list-tile @click="markStartMeetingAsDone">
                      <v-list-tile-action><v-icon>check</v-icon></v-list-tile-action>
                      <v-list-tile-title>{{ $t('projects.mark_start_meeting_done') }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                  <v-list-tile :to="{name: 'task', params: { activityId: startMeetingTask.activityId }}">
                    <v-list-tile-action><v-icon>chevron_right</v-icon></v-list-tile-action>
                    <v-list-tile-title>{{ $t('projects.move_to_task') }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </template>

      <v-list one-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-0 pt-0">
        <v-divider inset class="mt-0 mb-0"></v-divider>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-clipboard-list</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title><v-btn class="ml-0" small outline @click="openInstallationCardDialog">{{ $t('projects.open_installation_card') }}</v-btn></v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-list v-if="reclamationRows.length > 0 && $showReclamations" two-line :dense="$vuetify.breakpoint.mdAndDown" class="pb-2 pt-0">
        <v-divider inset class="mt-0 mb-0"></v-divider>
        <v-list-tile avatar :to="{name: 'reclamations', params: { projectId: this.project.id, resetFilters: true }}">
          <v-list-tile-avatar>
            <v-icon size="20" :color="$vuetify.breakpoint.lgAndUp ? 'primary' : ''">fas fa-exchange-alt</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $tc('reclamation.title', 2) }}</v-list-tile-title>
            <v-list-tile-sub-title :class="{caption: $vuetify.breakpoint.xsOnly}">{{ $t('reclamation.total_reclamations', { reclamationCount: reclamationRows.length }) }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>chevron_right</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

    </v-card-text>

    <InstallationCardDialog ref="installationCardDialog" :installationCard="this.project ? this.project.installationCard : false"/>
  </v-card>
</template>

<script>
import InstallationCardDialog from "@/components/SingleProjectView/InstallationCardDialog";
import InstallationGroup from "@/components/SingleProjectView/InstallationGroup";
import parameters from "@/parameters";
import dayjs from "dayjs";

const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)


export default {
  name: 'ProjectDetailsCard',
  components: {InstallationGroup, InstallationCardDialog},
  props: ['reclamationRows', 'projectDTO'],
  data() {
    return {
      updatingStartMeetingStatus: false,
    }
  },
  methods: {
    weekday(date) {
      return this.$t(`projects.week_days.${dayjs(date).isoWeekday()}`);
    },
    getPuustelliTitleText(contactPerson) {
      return contactPerson.title.join(', ');
    },
    getRoleTitleText(contactPerson) {
      let text = '';
      const title = contactPerson.title;
      const role = contactPerson.role;

      if (title != null && title.length > 0) {
        text += title;
      }

      if (title && role) {
        text += ', ';
      }

      if (role) {
        text += role;
      }

      return text;
    },
    openInstallationCardDialog() {
      this.$refs.installationCardDialog.openDialog();
    },
    async markStartMeetingAsDone() {
      if (!this.$isAdmin && !this.$isPuustelliUser) return;
      this.updatingStartMeetingStatus = true;
      await this.$store.dispatch('setTaskCompleted', this.startMeetingTask.activityId);
      this.updatingStartMeetingStatus = false;
    },
  },
  computed: {
    statusIcon() {
      const statusCode = this.project.projectStatus;
      return parameters.projectStatuses[statusCode].icon;
    },
    statusDesc() {
      const statusCode = this.project.projectStatus;
      const statuses = this.systemData.projectStatuses;
      const status = statuses.find(t => t.key === statusCode);
      return status.value;
    },
    systemData() {
      return this.$store.state.systemData;
    },
    typeDesc() {
      const typeCode = this.project.type;
      const types = this.systemData.projectTypes;
      const type = types.find(t => t.key === typeCode);

      if (!type) {
        return '';
      }
      return type.value;
    },
    installers() {
      if (this.installationGroup != null) {
        const allInstallers = this.projectDTO.installationGroupMembers;

        if (allInstallers == null) {
          return [];
        }

        if (this.installationGroup.id in allInstallers) {
          return allInstallers[this.installationGroup.id];
        }
      }

      return [];
    },
    mapsAddress() {
      return `https://maps.google.com?q=${this.project.streetAddress}+${this.project.city}+${this.project.postalCode}`;
    },
    contactPersons() {
      const dto = this.$store.state.projectDTO;
      if (dto.contactPersons !== null && dto.contactPersons.length > 0) {
        return dto.contactPersons;
      } else {
        return null;
      }
    },
    puustelliContactPersons() {
      const puustelliContactPersons = [];
      const owner = Object.assign({}, this.projectDTO.owner);
      if (owner) {
        owner.title = [this.$t('projects.titles.owner')];
        puustelliContactPersons.push(owner);
      }
      const clientSecretary = Object.assign({}, this.projectDTO.clientSecretary);
      if (clientSecretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === clientSecretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.clientSecretary'));
        } else {
          clientSecretary.title = [this.$t('projects.titles.clientSecretary')];
          puustelliContactPersons.push(clientSecretary);
        }
      }
      const secretary = Object.assign({}, this.projectDTO.secretary);
      if (secretary) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === secretary.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.secretary'));
        } else {
          secretary.title = [this.$t('projects.titles.secretary')];
          puustelliContactPersons.push(secretary);
        }
      }
      const manager = Object.assign({}, this.projectDTO.manager);
      if (manager) {
        const contactIndex = puustelliContactPersons.findIndex(c => c.id === manager.id);
        if (contactIndex > -1) {
          puustelliContactPersons[contactIndex].title.push(this.$t('projects.titles.manager'));
        } else {
          manager.title = [this.$t('projects.titles.manager')];
          puustelliContactPersons.push(manager);
        }
      }
      return puustelliContactPersons;
    },
    manager() {
      return this.projectDTO.manager;
    },
    project() {
      return this.projectDTO.project;
    },
    installationGroup() {
      return this.projectDTO.installationGroup;
    },
    startMeetingTask() {
      if (!this.$isCustomer) {
        return this.projectDTO.startMeetingTask;
      }
      return false;
    },
    startMeetingTaskIsDone() {
      return this.startMeetingTask.statecode === 1 && this.startMeetingTask.statuscode === 5;
    },
    carryingGroup() {
      if (this.project.carryingGroupId) {
        return this.projectDTO.installationGroups[this.project.carryingGroupId];
      }
      return null;
    },
    carryingGroupMembers() {
      if (this.carryingGroup != null) {
        const allInstallers = this.projectDTO.installationGroupMembers;

        if (allInstallers == null) {
          return [];
        }

        if (this.carryingGroup.id in allInstallers) {
          return allInstallers[this.carryingGroup.id];
        }
      }

      return [];
    },
    deliveryLots() {
      return this.projectDTO.deliveryLots;
    },
    rooms() {
      let rs = [];
      this.deliveryLots.forEach(dl => rs = [...rs, ...dl.apartments]);
      return rs;
    },
    deliveryLotInstallationUnits() {
      if (this.rooms) {
        let installationUnits = 0;
        this.rooms.forEach(apartment => {
          installationUnits = installationUnits + apartment.installationUnits;
        });
        return installationUnits;
      }
      return false;
    },
    deliveryLotInstallationUnitsCompleted() {
      if (this.rooms) {
        const handOverRooms = this.rooms.filter(room => parseInt(room.statusCode) === 100000002 || parseInt(room.statusCode) == 100000003 || parseInt(room.statusCode) == 100000001);
        let installationUnits = 0;
        handOverRooms.forEach(apartment => {
          installationUnits = installationUnits + apartment.installationUnits;
        });
        return installationUnits;
      }
      return false;
    },
  },
}
</script>

<style lang="scss">
.full-height {
  .v-list__tile {
    height: 100% !important;
  }
}
</style>
