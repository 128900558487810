<template>
  <v-btn icon @click="toggleMainMenu" data-cy="mobile_menu_button">
    <v-icon>menu</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "MainMenuButton",
    methods: {
      toggleMainMenu() {
        this.$store.commit('toggleMainMenuState');
      },
    }
  }
</script>

<style scoped>

</style>
