<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid fill-height ma-0 pa-0 class="project">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app :extended="receiveWorkMode">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>
      <hb-back-button fallback="projects" />

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ project.clientName }}</v-toolbar-title>

      <template v-if="$isAdmin || $isTeamLeader || $isPuustelliUser">
        <v-spacer></v-spacer>

        <v-menu bottom left>
          <v-btn slot="activator" dark icon><v-icon>more_vert</v-icon></v-btn>
          <v-list>
            <v-list-tile :disabled="receiveWorkMode || allDeliveryLotsUnacceptable" @click="setReceiveWorksMode">
              <v-list-tile-title>{{ $t('projects.accepting_works') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="showOpenInDynamicsLink" :href="dynamicsUrl" target="_blank">
              <v-list-tile-title>{{ $t('projects.open_in_dynamics' )}}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="createTyomaarays" v-if="($isPuustelliUser || $isAdmin)">
              <v-list-tile-title>{{ $t('tyomaaraykset.create') }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>

        <template slot="extension">
          <v-layout v-if="receiveWorkMode" justify-start light>
            <v-btn :loading="workAcceptingIsLoading" :disabled="deliveryLotsToSetReceived.length === 0"
                   :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline style="margin-left: 0"
                   :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'" @click="markSelectedDeliveryLotsReceived">
              {{ $t('projects.accept_works') }}
            </v-btn>
            <v-btn :disabled="workAcceptingIsLoading" :small="$vuetify.breakpoint.mdAndDown" :light="$vuetify.breakpoint.lgAndUp" outline :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'grey darken-1'" @click="resetReceivedDeliveryLotsList">{{ $t('cancel') }}</v-btn>
          </v-layout>
          <v-layout v-else-if="canSetProjectReady" justify-start light>
            <v-btn :small="$vuetify.breakpoint.mdAndDown"
                   :loading="updatingProjectStatus"
                   :light="$vuetify.breakpoint.lgAndUp"
                   outline
                   style="margin-left: 0"
                   :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
                   @click.native="setProjectReady">
              {{ $t('projects.set_project_completed')}}
            </v-btn>
          </v-layout>
        </template>
      </template>

    </v-toolbar>

    <v-fade-transition hide-on-leave>
      <v-layout row wrap v-if="!loading" v-scroll.body="onScroll">
        <v-flex xs12 class="project-holder">
          <v-container fluid :grid-list-lg="$vuetify.breakpoint.lgAndUp" :class="{'pa-0': $vuetify.breakpoint.mdAndDown}">
            <v-layout row wrap>
              <v-flex xs12 lg5 xl4 :pb-0="$vuetify.breakpoint.mdAndDown">

                <ProjectDetailsCard :projectDTO="projectDTO" :reclamation-rows="reclamationRows"></ProjectDetailsCard>

                <v-card v-if="unfinishedTasks.length > 0 && !$isCustomer" class="grey lighten-5" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    {{ $t('projects.unfinished_tasks') }}
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown">{{ $t('projects.unfinished_tasks') }}</v-subheader>

                    <v-list two-line :dense="$vuetify.breakpoint.mdAndDown" class="pa-0">
                      <div v-for="task in unfinishedTasks" :key="task.activityId">
                        <v-list-tile :class="{'grey lighten-5': $vuetify.breakpoint.mdAndDown}" :to="{name: 'task', params: { activityId: task.activityId }}">
                          <v-list-tile-avatar>
                            <v-icon color="warning">label</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ task.subject }}</v-list-tile-title>
                            <v-list-tile-sub-title>{{ $formatDateNoTime(task.scheduledEnd) }} &bull; {{ task.deliveryLot.name }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-icon>chevron_right</v-icon>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider></v-divider>
                      </div>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 lg7 xl8 :pt-0="$vuetify.breakpoint.mdAndDown">

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown}" data-cy="project-documents">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text" @click="documentsExpanded = !documentsExpanded">
                    <span>{{ $t('projects.documents') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!documentsExpanded" small icon style="margin: -4px 0;" @click.stop="documentsExpanded = true"><v-icon color="white">expand_more</v-icon></v-btn>
                    <v-btn v-if="documentsExpanded" small icon style="margin: -4px 0;" @click.stop="documentsExpanded = false"><v-icon color="white">expand_less</v-icon></v-btn>
                    <template v-if="project.documentURL">
                      <v-menu bottom left @click.native.stop>
                        <v-btn small icon slot="activator" style="margin: -4px 0;"><v-icon color="white">more_vert</v-icon></v-btn>
                        <v-list class="pt-0 pb-0">
                          <v-list-tile :href="project.documentURL" target="_blank">
                            <v-list-tile-action><v-icon>open_in_new</v-icon></v-list-tile-action>
                            <v-list-tile-title>{{ $t('view_documents_in_sharepoint') }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-card-title>
                  <template v-if="$vuetify.breakpoint.lgAndUp">
                    <v-expand-transition>
                      <div v-show="documentsExpanded">
                        <v-card-text  class="pl-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
                          <document-tree container-type="Project" :container-id="id" :loading="loading" />
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </template>
                  <template v-if="$vuetify.breakpoint.mdAndDown">
                    <v-card-text class="pl-0 pr-0 pb-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
                      <v-subheader @click="documentsExpanded = !documentsExpanded">
                        <span>{{ $t('projects.documents') }}</span>

                        <v-spacer></v-spacer>
                        <v-btn v-if="!documentsExpanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="documentsExpanded = true"><v-icon color="grey" data-cy="project-show-documents">expand_more</v-icon></v-btn>
                        <v-btn v-if="documentsExpanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="documentsExpanded = false"><v-icon color="grey" data-cy="project-hide-documents">expand_less</v-icon></v-btn>
                        <template v-if="project.documentURL">
                          <v-menu bottom left @click.native.stop>
                            <v-btn small icon slot="activator" style="margin: -4px -4px -4px 5px;"><v-icon color="grey">more_vert</v-icon></v-btn>
                            <v-list class="pt-0 pb-0">
                              <v-list-tile :href="project.documentURL" target="_blank">
                                <v-list-tile-action><v-icon>open_in_new</v-icon></v-list-tile-action>
                                <v-list-tile-title>{{ $t('view_documents_in_sharepoint') }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-subheader>
                    </v-card-text>
                    <v-expand-transition>
                      <div v-show="documentsExpanded">
                        <v-card-text class="pl-0 pt-0">
                          <document-tree container-type="Project" :container-id="id" :loading="loading" />
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                    <v-divider v-if="$vuetify.breakpoint.mdAndDown"></v-divider>
                  </template>
                </v-card>

                <v-card :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp}" data-cy="project-delivery-lots">
                  <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text">
                    <span>{{ $t('projects.delivery_lots') }}</span>
                    <v-spacer></v-spacer>
                    <template v-if="($isAdmin || $isTeamLeader)">
                      <v-btn :disabled="allDeliveryLotsUnacceptable" v-if="!receiveWorkMode" color="white" outline small style="text-transform: none; margin: -4px 0;" @click="setReceiveWorksMode">{{ $t('projects.accepting_works') }}</v-btn>
                    </template>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-subheader v-if="$vuetify.breakpoint.mdAndDown" class="white justify-space-between">
                      <span>{{ $t('projects.delivery_lots') }}</span>
                      <v-spacer></v-spacer>
                      <template v-if="($isAdmin || $isTeamLeader)">
                        <v-btn :disabled="allDeliveryLotsUnacceptable" v-if="!receiveWorkMode" color="primary" outline small style="text-transform: none; margin: -4px 0;" @click="setReceiveWorksMode">{{ $t('projects.accepting_works') }}</v-btn>
                      </template>
                    </v-subheader>

                    <v-container fluid grid-list-xs pa-3>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            :placeholder="$t('delivery_lot.filter')"
                            v-model="searchPhrase"
                            single-line
                            full-width
                            outline
                            hide-details
                            color="grey darken-3"
                          >
                            <v-icon style="cursor: pointer;" slot="append">filter_list</v-icon>
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-divider></v-divider>

                    <div style="position: relative;">
                      <div v-if="workAcceptingIsLoading" class="table-disabled"></div>
                      <v-data-table :items="filteredDeliveryLots"
                                    ref="deliveryLotsDataTable"
                                    id="deliveryLotsDataTable"
                                    :headers-length="2"
                                    hide-headers
                                    hide-actions
                                    :no-data-text="$t('projects.no_delivery_lots')"
                                    item-key="id">
                        <template slot="items" slot-scope="props">
                          <tr class="delivery-row-lot" v-touch:tap="handleDeliveryLotTap(props)" v-touch:longtap="handleDeliveryLotLongTap(props)" :key="props.item.id" :id="'deliveryLot_' + props.item.id" :data-cy="`delivery-lot-item_${filteredDeliveryLots.indexOf(props.item)}`">
                            <td class="subheading pl-3 pr-3 pt-3 pb-3" style="line-height: 1.2; height: 70px;" >

                              <router-link :to="deliveryLotRoute(props)" exact-path exact style="text-decoration:none; color: inherit;"  v-if="!receiveWorkMode">
                                <v-layout :pl-2="$vuetify.breakpoint.lgAndUp">
                                  <div>
                                    {{ props.item.name }}
                                    <br />
                                    <span class="grey--text caption">
                                      <template v-if="props.item.deliveryDate">{{ $t('projects.delivery') + ' ' + weekday(props.item.deliveryDate) + ' ' + $formatDateNoTime(props.item.deliveryDate) }} ({{ getWeek(props.item.deliveryDate) }}) &bull; </template>
                                      <template v-if="!$isCustomer">{{ $t('projects.installation') + ' ' + weekday(props.item.installationStartDate) + ' ' + $formatDateNoTime(props.item.installationStartDate) }} &bull; </template>{{ props.item.frameEstimation }} {{ $t('projects.delivery_lot_frames')}}
                                      <template v-if="$isPuustelliUser || $isAdmin"> &bull; {{ getStatusDesc(props.item) }}</template>
                                    </span>
                                    <template v-if="displayDeliveryLotAddress(props.item)"><br /><span class="grey--text caption">{{ props.item.streetAddress }}, {{ props.item.city }} {{ props.item.postalCode }}</span></template>

                                    <template v-if="props.item.deliveryExtraInfo"><br /><span class="grey--text caption">{{ props.item.deliveryExtraInfo }}</span></template>

                                    <template v-if="isAcceptHandOverPossible(props.item)"><br /><span class="font-weight-bold caption">{{ $t('projects.waiting_accept_hand_over_delivery') }}</span></template>
                                  </div>
                                </v-layout>
                              </router-link>
                              <template v-else>
                                <v-layout :pl-2="$vuetify.breakpoint.lgAndUp">
                                  <div v-if="receiveWorkMode && ($isAdmin || $isTeamLeader)" style="padding-top: 2px; padding-right: 16px;">
                                    <div style="width: 25px;">
                                      <template v-if="props.item.loading">
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                          :width="2"
                                          :size="18"
                                        ></v-progress-circular>
                                      </template>
                                      <template v-if="!props.item.received && !props.item.loading && $hasMatchingGroups(props.item.installationGroupId)">
                                        <v-icon v-if="deliveryLotsToSetReceived.findIndex(r => r === props.item.id) > -1" color="primary">check_box</v-icon>
                                        <v-icon v-else :disabled="deliveryLotIsPrediction(props.item)" color="primary">check_box_outline_blank</v-icon>
                                      </template>
                                      <template v-if="props.item.received && !props.item.loading || !$hasMatchingGroups(props.item.installationGroupId)">
                                        <v-icon disabled color="primary">check_box</v-icon>
                                      </template>
                                    </div>
                                  </div>
                                  <div>

                                    {{ props.item.name }}
                                    <br />
                                    <span class="grey--text caption">
                                      <template v-if="props.item.deliveryDate">{{ $t('projects.delivery') + ' ' + weekday(props.item.deliveryDate) + ' ' + $formatDateNoTime(props.item.deliveryDate) }} &bull; </template>
                                      <template v-if="!$isCustomer">{{ $t('projects.installation') + ' ' + weekday(props.item.installationStartDate) + ' ' + $formatDateNoTime(props.item.installationStartDate) }} &bull; </template>{{ props.item.frameEstimation }} {{ $t('projects.delivery_lot_frames')}}
                                      <template v-if="$isPuustelliUser || $isAdmin"> &bull; {{ getStatusDesc(props.item) }}</template>
                                    </span>
                                    <template v-if="displayDeliveryLotAddress(props.item)"><br /><span class="grey--text caption">{{ props.item.streetAddress }}, {{ props.item.city }} {{ props.item.postalCode }}</span></template>

                                    <template v-if="props.item.deliveryExtraInfo"><br /><span class="grey--text caption">{{ props.item.deliveryExtraInfo }}</span></template>

                                  </div>

                                </v-layout>
                              </template>
                            </td>
                            <td class="text-xs-right" style="width: 120px;">
                              <v-icon v-if="!$isCustomer" left size="22">{{ getStatusIcon(props.item) }}</v-icon>
                              <v-badge right overlap color="grey lighten-1">
                                <template slot="badge">
                                  <span>{{ props.item.apartmentCount }}</span>
                                </template>
                                <v-icon size="18" style="padding-bottom: 2px;">fas fa-door-open</v-icon>
                              </v-badge>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <project-ready-dialog ref="projectReadyDialog" @project-updated="projectStatusUpdated"></project-ready-dialog>

    <edit-tyonaarays-dialog
      v-if="($isPuustelliUser || $isAdmin)"
      ref="editTyomaaraysDialog"
      :projectDTO="projectDTO"
    />

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import dayjs from 'dayjs';
  import frendsApi from '../frendsApi';
  import parameters from '../parameters.js';
  import moment from 'moment';
  import DocumentTree from "../components/DocumentTree.vue";
  import ProjectDetailsCard from "@/components/SingleProjectView/ProjectDetailsCard";
  import ProjectReadyDialog from "@/components/SingleProjectView/ProjectReadyDialog";
  import EditTyonaaraysDialog from "../components/SingleTyomaaraysView/EditTyomaaraysDialog.vue";

  const isoWeek = require('dayjs/plugin/isoWeek')
  dayjs.extend(isoWeek)

  export default {
    name: "SingleProjectView",
    components: {
      EditTyonaaraysDialog,
      ProjectReadyDialog,
      ProjectDetailsCard,
      DocumentTree: DocumentTree,
    },
    props: {
      id: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        workAcceptingIsLoading: false,
        searchPhrase: '',
        receiveWorkMode: false,
        deliveryLotsToSetReceived: [],
        updatingStartMeetingStatus: false,
        reclamationRows: [],
        documentsExpanded: false,
        updatingProjectStatus: false,
      }
    },
    computed: {
      showOpenInDynamicsLink() {
        return (this.$isPuustelliUser || this.$isAdmin) && this.project != null && this.$dynamicsProjectUrlConfigured;
      },
      dynamicsUrl() {
        return this.$dynamicsProjectUrl(this.project.id);
      },
      canSetProjectReady() {
        return (this.$isPuustelliUser || this.$isAdmin) && this.project && this.project.projectStatus !== 100000004;
      },
      systemData() {
        return this.$store.state.systemData;
      },
      allDeliveryLotsAccepted() {
        const firstLotWhichIsNotReceived = this.deliveryLots.findIndex(lot => lot.received === false);
        return (firstLotWhichIsNotReceived === -1);
      },
      allDeliveryLotsArePredictions() {
        const firstLotWhichIsNotPrediction = this.deliveryLots.findIndex(lot => lot.statusCode !== 100000001 && lot.statusCode !== 100000000);
        return (firstLotWhichIsNotPrediction === -1);
      },
      allDeliveryLotsUnacceptable() {
        if (this.allDeliveryLotsAccepted) return true;
        return this.allDeliveryLotsArePredictions;
      },
      unfinishedTasks() {
        return this.tasks.filter(task => task.statecode !== 1 && task.statuscode !== 5);
      },
      project() {
        return this.projectDTO.project;
      },
      projectDTO() {
        return this.$store.state.projectDTO;
      },
      filteredDeliveryLots() {
        let deliveryLots = this.deliveryLots;

        if (this.searchPhrase == null || this.searchPhrase.length === 0) {
          return deliveryLots;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return deliveryLots.filter(p => {
          let found = [];
          const lowerSearchedString =
            (p.clientName !== null ? p.clientName.toLowerCase() : '') +
            (p.city !== null ? p.city.toLowerCase() : '') +
            (p.name !== null ? p.name.toLowerCase() : '') +
            (p.postalCode !== null ? p.postalCode.toLowerCase() : '') +
            (p.streetAddress !== null ? p.streetAddress.toLowerCase() : '') +
            (p.statusDescription !== null ? p.statusDescription.toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      },
      deliveryLots() {
        let lots = this.projectDTO.deliveryLots;
        if (lots) {
          lots.sort((a, b) => new Date(a.installationStartDate) - new Date(b.installationStartDate));
          return lots;
        }

        return [];
      },

      tasks() {
        if (!this.$isCustomer) {
          return this.sortTasksByScheduledEnd(this.projectDTO.inspectionTasks);
        }
        return [];
      },
    },
    methods: {
      async createTyomaarays() {
        // TODO: projektiid ja muita tietoja?
        await this.$refs.editTyomaaraysDialog.open({});
      },
      weekday(date) {
        return this.$t(`projects.week_days.${dayjs(date).isoWeekday()}`);
      },
      onScroll(e) {
        this.$store.commit('setProjectViewScrollY', window.scrollY);
      },
      getWeek(date) {
        return `${this.$t('projects.week')} ${moment(date).isoWeek()}`;
      },
      projectStatusUpdated() {
        this.project.projectStatus = 100000004;
        this.reloadProject();
      },
      deliveryLotRoute(props) {
        const deliveryLotId = props.item.id;
        const ret = {name: 'deliverylot', params: {projectId: this.project.id, deliveryLotId: deliveryLotId}};
        return ret;
      },
      setProjectReady() {
        this.$refs.projectReadyDialog.openDialog(this.project);
      },
      displayDeliveryLotAddress(deliveryLot) {
        return !!(deliveryLot.streetAddress && deliveryLot.streetAddress.length > 0 && deliveryLot.streetAddress !== this.project.streetAddress);
      },

      deliveryLotIsPrediction(lot) {
        return lot.statusCode === 100000001 || lot.statusCode === 100000000;
      },
      formatDeliveryWeek(deliveryWeek) {
        deliveryWeek = deliveryWeek.split(".");
        return deliveryWeek.join("/");
      },
      setReceiveWorksMode() {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        this.receiveWorkMode = true;
        const deliveryLotsToSetReceived = [];
        this.deliveryLots.forEach(dl => {
          if (!dl.received && dl.statusCode > 100000001 && dl.statusCode < 100000007) {
            if (this.$hasMatchingGroups(dl.installationGroupId)) {
              deliveryLotsToSetReceived.push(dl.id);
            }
          }
        });
        this.deliveryLotsToSetReceived = deliveryLotsToSetReceived;
        this.$vuetify.goTo('#deliveryLotsDataTable', {
          duration: 200,
          offset: 0,
          easing: 'easeInOutCubic',
        });
      },
      handleDeliveryLotLongTap(props) {
        const _this = this;
        return function() {
          if (!_this.$isAdmin && !_this.$isTeamLeader) return;
          _this.receiveWorkMode = true;
          _this.selectDeliveryLotToBeReceived(props.item);
        }
      },
      handleDeliveryLotTap(props) {
        const _this = this;

        return function(e) {
          const btnclass = 'deliveryLotButton';
          if (e.target.classList.contains(btnclass) || e.target.parentElement.classList.contains(btnclass)) {
            return;
          }

          if (_this.receiveWorkMode) {
            if (!_this.$isAdmin && !_this.$isTeamLeader) return;
            _this.selectDeliveryLotToBeReceived(props.item);
          } else {
            // do nothing
          }
        }
      },
      resetReceivedDeliveryLotsList() {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        this.deliveryLotsToSetReceived = [];
        this.receiveWorkMode = false;
      },
      selectDeliveryLotToBeReceived(item) {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        if (item.statusCode === 100000001 || item.statusCode === 100000000) return;

        const lotIndex = this.deliveryLots.findIndex(lot => lot.id === item.id);
        if (this.deliveryLots[lotIndex].received) return;
        const dlIndex = this.deliveryLotsToSetReceived.findIndex(r => r === item.id);
        if (dlIndex > -1) {
          this.deliveryLotsToSetReceived.splice(dlIndex, 1);
          if (this.deliveryLotsToSetReceived.length === 0) {
            this.receiveWorkMode = false;
          }
        } else {
          this.deliveryLotsToSetReceived.push(item.id);
        }
      },
      getStatusDesc(lot) {
        const statusCode = lot.statusCode;
        const status = this.systemData.deliveryLotStatuses.find(s => s.key === statusCode);
        return status.value;
      },
      getStatusIcon(lot) {
        if (lot.received) {
          return parameters.deliveryLotStatuses[lot.statusCode].icon;
        } else {
          return parameters.deliveryLotStatuses[lot.statusCode].notReceivedIcon;
        }
      },
      markSelectedDeliveryLotsReceived() {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        this.workAcceptingIsLoading = true;
        let promises = [];
        this.deliveryLotsToSetReceived.forEach(lotId => {
          const lotIndex = this.deliveryLots.findIndex(dl => dl.id === lotId);
          if (lotIndex > -1) {
            let deliveryLot = this.deliveryLots[lotIndex];
            deliveryLot.loading = true;
            this.deliveryLots.splice(lotIndex, 1, deliveryLot);
          }
          promises.push(this.markDeliveryLotAsReceived(lotId));
        });
        Promise.all(promises).then(() => {
          this.workAcceptingIsLoading = false;
          this.resetReceivedDeliveryLotsList();
        }).catch(error => {
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('projects.error_when_accepting_works'),
              timeOut: 4000,
              showButton: false,
            });
          this.workAcceptingIsLoading = false;
          this.deliveryLots.forEach(lot => {
            lot.loading = false;
          });
          console.log(error);
        });
      },
      markDeliveryLotAsReceived(lotId) {
        if (!this.$isAdmin && !this.$isTeamLeader) return;
        const deliveryLotResponse = axios.post(`/api/DeliveryLot/${lotId}/received`).then(res => {
          const deliveryLot = res.data;
          const lotIndex = this.deliveryLots.findIndex(dl => dl.id === deliveryLot.id);
          if (lotIndex > -1) {
            this.deliveryLots.splice(lotIndex, 1, deliveryLot);
          }
        });
        return deliveryLotResponse;
      },
      sortTasksByScheduledEnd(tasks) {
        if (!tasks) return null;
        return tasks.sort((a, b) => {
          const am = moment(a.scheduledEnd);
          const bm = moment(b.scheduledEnd);
          if (am.isBefore(bm)) {
            return -1;
          }

          if (am.isAfter(bm)) {
            return 1;
          }

          return 0;
        });
      },
      async reloadProjectReclamations() {
        if (!this.$showReclamations) {
          return;
        }
        try {
          const rivit = await frendsApi.getReklamaatioRivit(this.id, null, null, null, this.$kielinro);
          this.reclamationRows = rivit;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('reclamation.failed_to_fetch_reclamation_rows'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadProjectReclamations();
              }
            });
        }
      },
      async reloadProject() {
        if (!this.projectDTO.project || this.projectDTO.project.id !== this.id) {
          this.loading = true;
          await this.$store.dispatch('reloadProjectDTO', this.id);
          this.loading = false;
        } else {
          this.subtleLoading = true;
          this.loading = false;
          await this.$store.dispatch('reloadProjectDTO', this.id);
          this.subtleLoading = false;
        }
      },
      isAcceptHandOverPossible(deliveryLot) {
        return !(this.$isTeamMember || this.$isTeamLeader) && deliveryLot && deliveryLot.statusCode === 100000009;
      },
    },
    watch: {
      id () {
        this.reloadProject();
      }
    },
    mounted() {
      this.reloadProject();
      this.reloadProjectReclamations();
      if (this.$store.state.projectViewScrollY > 0) {
        this.$vuetify.goTo(this.$store.state.projectViewScrollY, {
          duration: 200,
          easing: 'easeInOutCubic',
          offset: this.$vuetify.breakpoint.mdAndUp ? -128 : -43
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .delivery-row-lot {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .table-disabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(255,255,255,0.5);
  }
</style>

<style lang="scss">

  .project {

    .topbar-search {
      .v-input__slot {
        padding: 0 !important;
      }
    }

    .v-toolbar__extension {
      background-color: #b30000 !important;
      border-color: #b30000 !important;
    }

    .v-list--dense {
      .contact-persons-header {
        .v-list__tile {
          height: 60px;
        }
      }
    }

    .contact-persons-header {
      .v-list__tile {
        height: 62px;
      }
    }

    .contact-persons-list {
      &:after,
      &:before {
        display: none !important;
      }
    }

    @media screen and (min-width: 1264px) {
      .v-toolbar__extension {
        background-color: #fff !important;
        border-color: #fff !important;
      }
    }
  }

</style>
