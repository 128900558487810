<template>
  <v-list-tile
    class="tyomaaraysrivit-list"
    style="height: auto !important; max-height: unset !important;"
    :class="'status-color-' + row.rekl_statusvari"
    avatar
    :style="{opacity: parseInt(row.rekl_statusnro, 10) === 9 ? '0.65' : '1'}"
  >
    <div class="status-badge"></div>
    <v-list-tile-avatar>
      <v-progress-circular indeterminate v-if="row.updatingStatus" size="18" width="2" color="primary"></v-progress-circular>
      <v-icon v-else-if="parseInt(row.rekl_statusnro, 10) === 9" color="success">done_outline</v-icon>
      <template v-else-if="row.rekl_rivitunniste">
        <v-layout justify-center style="margin: 0;">
          <v-checkbox
            color="primary"
            @click.stop.prevent="row.selected = !row.selected"
            :disabled="rowSelectionDisabled"
            v-model="row.selected"
            hide-details
            style="margin-left: 8px; margin-top: 0;"
          ></v-checkbox>
        </v-layout>
      </template>
    </v-list-tile-avatar>
    <v-list-tile-content style="cursor: pointer;" @click="rowClicked">
      <v-list-tile-title class="font-weight-bold" style="white-space: normal; height: auto; line-height: 20px;">{{ row.tuoteselite ? row.tuoteselite : $t('reclamation.no_product') }}</v-list-tile-title>
      <v-list-tile-sub-title class="caption text--primary">
          <v-layout row wrap no-gutters class="my-0">
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.apartment') }}:</strong> {{ apartmentName ? apartmentName : $t('reclamation.no_apartment') }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.type') }}:</strong> {{ row.rekl_tyyppiselite ? row.rekl_tyyppiselite : $t('tyomaaraykset.not_related_to_reclamations')}}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.order_number') }}:</strong> {{ row.rekl_tilausnro ? row.rekl_tilausnro : $t('reclamation.no_order_number') }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4>
              <strong>{{ $t('reclamation.status') }}:</strong> {{ row.rekl_statusteksti ? row.rekl_statusteksti : '' }}
              <template v-if="row.rekl_rivinstatuspvm">
                ({{ $formatDateNoTime(row.rekl_rivinstatuspvm) }})
              </template>
            </v-flex>
            <v-flex class="py-0" xs12 sm6 md4 v-if="row.rekl_jakelupvm"><strong>{{ $t('reclamation.delivery') }}:</strong> {{$formatDateIncludeWeekday(row.rekl_jakelupvm) }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4 v-else-if="row.rekl_jakeluarvio"><strong>{{ $t('reclamation.delivery_estimate') }}:</strong> {{ row.rekl_jakeluarvio }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4 v-else><strong>{{ $t('reclamation.delivery') }}:</strong> {{ $t('reclamation.no_delivery_date') }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.created') }}:</strong> {{ $formatDateNoTime(row.lisatty) }}</v-flex>
            <!--
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.rekltoimeranimi') }}:</strong> {{ row.rekltoimeranimi ? row.rekltoimeranimi : '' }}</v-flex>
            <v-flex class="py-0" xs12 sm6 md4><strong>{{ $t('reclamation.rekltoimeranro') }}:</strong> {{ row.rekltoimeranro ? row.rekltoimeranro : '' }}</v-flex>
            -->
            <v-flex class="py-0" xs12 sm6 md4 v-if="vuositarkastus"><strong>{{ $t('reclamation.vuositarkastus') }}</strong></v-flex>
          </v-layout>
      </v-list-tile-sub-title>
      <v-list-tile-sub-title class="caption mt-2">{{ row.rekl_selite }}</v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action>
      <v-btn v-if="$isPuustelliUser || $isAdmin" icon @click.stop.prevent="deleteTyomaaraysRivi">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>

export default {
  props: ['row', 'updatingStatuses'],
  name: "TyomaaraysRiviListItem",
  computed: {
    apartmentName() {
      return this.row.huonenimi;
    },
    vuositarkastus() {
      return this.row.vuositarkastus === "true";
    },
    rowSelectionDisabled() {
      const row = this.row;
      const rowStatus = parseInt(row.rekl_statusnro, 10);

      if (this.updatingStatuses) return true;

      if (rowStatus === 4 || rowStatus === 6 || rowStatus === 7) {
        if (this.selectedReclamationStatus === 4 || this.selectedReclamationStatus === 6) {
          return !(rowStatus === 4 || rowStatus === 6);
        }
        if (this.selectedReclamationStatus === 7) {
          return rowStatus !== 7;
        }
        return false;
      }
      return true;
    }
  },
  methods: {
    rowClicked() {
      this.$emit('row-clicked', this.row);
    },
    deleteTyomaaraysRivi() {
      this.$emit('delete-clicked', this.row);
    }
  },
}
</script>

<style lang="scss">
  .tyomaaraysrivit-list {
    .v-list__tile {
      height: auto !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
</style>
