<template>
  <v-card data-cy="delivery-lot-reports" :class="{'elevation-0': $vuetify.breakpoint.mdAndDown, 'grey lighten-5': $vuetify.breakpoint.mdAndDown, 'mt-3': $vuetify.breakpoint.lgAndUp} ">
    <v-card-title v-if="$vuetify.breakpoint.lgAndUp" class="title grey white--text" @click="expanded = !expanded">
      <span>{{ $t('delivery_lot.reports.title') }}</span>
      <v-spacer></v-spacer>
      <v-btn v-if="!expanded" small icon style="margin: -4px 0;" @click.stop="expanded = true"><v-icon color="white" data-cy="expand-apartment-list">expand_more</v-icon></v-btn>
      <v-btn v-if="expanded" small icon style="margin: -4px 0;" @click.stop="expanded = false"><v-icon color="white">expand_less</v-icon></v-btn>
    </v-card-title>
    <template v-if="$vuetify.breakpoint.lgAndUp">
      <v-expand-transition>
        <div v-show="expanded">
          <v-card-text  class="pl-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
            <v-container fluid grid-list-md pt-0 pb-0>
              <v-layout row wrap class="pa-0">
                <v-flex xs12 class="pa-0 ma-0" v-if="showReports">
                  <v-list class="pa-0 ma-0">
                    <report-row icon="far fa-file-pdf" :name="$t('delivery_lot.reports.package_list')" @click="openPackageReport" :loading="openingPackageReport"></report-row>
                  </v-list>
                </v-flex>
                <v-flex xs12 v-else>
                  <v-icon size="16" color="grey">info</v-icon> {{ $t('delivery_lot.reports.no_reports') }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </div>
      </v-expand-transition>
    </template>
    <template v-if="$vuetify.breakpoint.mdAndDown">
      <v-card-text class="pl-0 pr-0 pb-0" :class="{'pt-0': $vuetify.breakpoint.mdAndDown}">
        <v-subheader @click="expanded = !expanded">
          <span>{{ $t('delivery_lot.reports.title') }}</span>

          <v-spacer></v-spacer>
          <v-btn v-if="!expanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="expanded = true"><v-icon color="grey" data-cy="expand-apartment-list">expand_more</v-icon></v-btn>
          <v-btn v-if="expanded" small icon style="margin: -4px -5px -4px 0;" @click.stop="expanded = false"><v-icon color="grey">expand_less</v-icon></v-btn>
        </v-subheader>
      </v-card-text>
      <v-expand-transition>
        <div v-show="expanded">
          <v-card-text class="pl-0 pt-0">
            <v-container fluid grid-list-md pt-2 pb-2>
              <v-layout row wrap>
                <v-flex xs12 v-if="showReports">
                  <v-list class="pa-0 ma-0 grey">
                    <report-row icon="far fa-file-pdf" :name="$t('delivery_lot.reports.package_list')" @click="openPackageReport" :loading="openingPackageReport"></report-row>
                  </v-list>
                </v-flex>
                <v-flex xs12 v-else>
                  <v-icon size="16" color="grey">info</v-icon> {{ $t('delivery_lot.reports.no_reports') }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </div>
      </v-expand-transition>
      <v-divider v-if="$vuetify.breakpoint.mdAndDown"></v-divider>
    </template>
  </v-card>
</template>

<script>
import frendsApi from "@/frendsApi";
import { saveAs } from 'file-saver';
import ReportRow from "@/components/SingleDeliveryLotView/ReportRow";

export default {
  name: "Reports",
  props: ['deliveryLot'],
  components: {ReportRow},
  data() {
    return {
      openingPackageReport: false,
      expanded: false
    }
  },
  computed: {
    showReports() {
      return this.deliveryLot.puusnetDeliveryNumber != null;
    },
  },
  methods: {
    async openPackageReport() {
      this.openingPackageReport = true;
      const blob = await frendsApi.getToimitusEraPakkaukset(this.deliveryLot.puusnetDeliveryNumber);
      const filename = `pakkaukset_${this.deliveryLot.puusnetDeliveryNumber}.pdf`;
      this.openingPackageReport = false;
      saveAs(blob, filename);
    },
  }
}
</script>

<style scoped>

</style>
