<template>
  <div>
    <v-treeview
      v-if="resolvedDocumentTree.length > 0"
      v-model="documentTree"
      :open="openFolders"
      :items="resolvedDocumentTree"
      item-key="id"
      :load-children="fetchChildren"
      open-on-click
      class="pl-2"
    >
      <template v-slot:prepend="{ item, open }">
                      <span class="ml-1">
                        <v-icon size="20" v-if="!item.type">
                          {{ open ? 'fas fa-folder-open' : 'fas fa-folder' }}
                        </v-icon>
                        <v-icon size="20" v-else-if="fileDownloadsInProgress[item.name] === true">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                        <v-icon size="20" v-else>
                          {{ fileIcons[item.type] ? fileIcons[item.type] : 'far fa-file' }}
                        </v-icon>
                      </span>
      </template>
      <template v-slot:label="{ item }">
                      <span @click="downloadFile(item)">
                        {{ item.name }}
                      </span>
      </template>
    </v-treeview>
    <template v-if="resolvedDocumentTree.length < 1 && !loading">
      <v-container fluid grid-list-md pt-2 pb-2>
        <v-layout row wrap>
          <v-flex xs12><v-icon size="16" color="grey">info</v-icon> {{ containerType === 'DeliveryLot' ? $t('delivery_lot.no_documents') : $t('projects.no_documents') }}</v-flex>
        </v-layout>
      </v-container>
    </template>
<!--    <v-snackbar multi-line :value="fileDownloadsInProgress.length > 0" :timeout="0">-->
<!--      <v-flex xs12 class="pa-0 ma-0">-->
<!--        <v-progress-linear class="mt-1" height="2" indeterminate></v-progress-linear>-->
<!--        <div style="max-width: 100%; min-width: 100%;" class="text-truncate">{{ $t('loading') }} {{ fileDownloadsInProgress.length > 1 ? `${fileDownloadsInProgress.length} ${$t('files')}`   : `${$t('file')} ${fileDownloadsInProgress[0]}` }}</div>-->
<!--      </v-flex>-->
<!--    </v-snackbar>-->
  </div>
</template>

<script>
  import axios from 'axios';
  import api from "../api";

  export default {
    name: "DocumentTree",
    props: {
      containerType: {
        type: [String],
        default: '', // always DeliveryLot or Project
      },
      containerId: {
        type: [String],
        default: '',
      },
    },
    data() {
      return {
        loading: false,
        openingFile: false,
        documents: [],
        documentTree: [],
        openFolders: [],
        fileDownloadsInProgress: {},
        fileIcons: {
          "application/pdf": 'far fa-file-pdf',
          "application/msword": 'far fa-file-word',
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 'far fa-file-word',
          "application/vnd.oasis.opendocument.text": 'far fa-file-word',
          "video/ogg": 'far fa-file-video',
          "video/mpeg": 'far fa-file-video',
          "video/x-msvideo": 'far fa-file-video',
          "video/mp2t": 'far fa-file-video',
          "video/webm": 'far fa-file-video',
          "video/3gpp": 'far fa-file-video',
          "video/3gpp2": 'far fa-file-video',
          "application/vnd.ms-powerpoint": 'far fa-file-powerpoint',
          "application/vnd.openxmlformats-officedocument.presentationml.presentation": 'far fa-file-powerpoint',
          "application/vnd.oasis.opendocument.presentation": 'far fa-file-powerpoint',
          "image/png": 'far fa-file-image',
          "image/bmp": 'far fa-file-image',
          "image/gif": 'far fa-file-image',
          "image/vnd.microsoft.icon": 'far fa-file-image',
          "image/jpeg": 'far fa-file-image',
          "image/svg+xml": 'far fa-file-image',
          "image/tiff": 'far fa-file-image',
          "image/webp": 'far fa-file-image',
          "application/vnd.ms-excel": 'far fa-file-excel',
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 'far fa-file-excel',
          "application/vnd.oasis.opendocument.spreadsheet": 'far fa-file-excel',
          "text/csv": 'far fa-file-csv',
          "application/php": 'far fa-file-code',
          "text/javascript": 'far fa-file-code',
          "application/json": 'far fa-file-code',
          "application/ld+json": 'far fa-file-code',
          "application/xml": 'far fa-file-code',
          "text/xml": 'far fa-file-code',
          "text/css": 'far fa-file-code',
          "audio/aac": 'far fa-file-audio',
          "audio/midi": 'far fa-file-audio',
          "audio/x-midi": 'far fa-file-audio',
          "audio/mpeg": 'far fa-file-audio',
          "audio/ogg": 'far fa-file-audio',
          "audio/opus": 'far fa-file-audio',
          "audio/wav": 'far fa-file-audio',
          "audio/webm": 'far fa-file-audio',
          "audio/3gpp": 'far fa-file-audio',
          "audio/3gpp2": 'far fa-file-audio',
          "application/x-freearc": 'far fa-file-archive',
          "application/x-bzip": 'far fa-file-archive',
          "application/x-bzip2": 'far fa-file-archive',
          "application/gzip": 'far fa-file-archive',
          "application/java-archive": 'far fa-file-archive',
          "application/vnd.rar": 'far fa-file-archive',
          "application/x-tar": 'far fa-file-archive',
          "application/zip": 'far fa-file-archive',
          "application/x-7z-compressed": 'far fa-file-archive',
        },
      }
    },
    methods: {
      async fetchChildren(folder) {
        this.loading = true;
        if (this.containerType === 'Project') {
          try {
            const docs = await api.getProjectFolderContent(this.containerId, folder.id);
            folder.children = docs;
          } catch (e) {
            console.log(e);
            this.$store.dispatch('showNotification',
              {
                color: 'error',
                message: this.$t('delivery_lot.failed_to_load_documents'),
                timeOut: 4000,
                showButton: false,
              });
          }
        } else {
          try {
            const docs = await api.getDeliveryLotFolderContent(this.containerId, folder.id);
            folder.children = docs;
          } catch (e) {
            console.log(e);
            this.$store.dispatch('showNotification',
              {
                color: 'error',
                message: this.$t('delivery_lot.failed_to_load_documents'),
                timeOut: 4000,
                showButton: false,
              });
          }
        }
        this.loading = false;
      },
      async downloadFile(file) {
        if (this.openingFile === true) {
          return;
        }
        if (!file.size) {
          return;
        }

        this.$set(this.fileDownloadsInProgress, file.name, true);

        this.openingFile = true;
        try {
          const document = await api.getDocument(this.containerType, this.containerId, file.id);
          window.location = `/api/document/${document.key}`;
        } catch (error) {
          console.log(error);
        }

        this.fileDownloadsInProgress[file.name] = false;
        this.openingFile = false;
      },
      async reloadDocumentRoot() {
        this.loading = true;
        if (this.containerType === 'Project') {
          try {
            const docs = await api.getProjectRootDocuments(this.containerId)
            this.documents = docs;
          } catch (e) {
            console.log(e);
            this.$store.dispatch('showNotification',
              {
                color: 'error',
                message: this.$t('delivery_lot.failed_to_load_documents'),
                timeOut: 4000,
                showButton: false,
              });
          }
        } else {
          try {
            const docs = await api.getDeliveryLotRootDocuments(this.containerId)
            this.documents = docs;
          } catch (e) {
            console.log(e);
            this.$store.dispatch('showNotification',
              {
                color: 'error',
                message: this.$t('delivery_lot.failed_to_load_documents'),
                timeOut: 4000,
                showButton: false,
              });
          }
        }
        this.loading = false;
      }
    },
    mounted() {
      this.reloadDocumentRoot();
    },
    computed: {
      resolvedDocumentTree() {
        const documents = this.documents;
        return documents;
      },
    },
  }
</script>

<style scoped>

</style>
