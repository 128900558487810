<template>
  <v-card flat tile class="d-flex text-xs-center" :elevation="2" style="position: relative; cursor: pointer; width: 100%">
    <template v-if="isImageFile">
      <v-img
        :src="src"
        :loading="removingFile"
        @click="openFullImage()"
        aspect-ratio="1"
        class="grey lighten-2"
        style="border: 1px solid #ccc;"
      >
        <template v-slot:placeholder>
          <v-layout
            fill-height
            align-center
            justify-center
            ma-0
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
    </template>
    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-layout v-on="on" class="ma-0" style="width: 100%" align-center justify-center fill-height
                    @click="downloadFile">
            <div style="width: 90%">
              <v-icon size="36" color="primary" v-if="uploadedFile">mdi-file-download</v-icon>
              <v-icon size="36" color="primary" v-else>mdi-file</v-icon>
              <br/>

              <div class="primary--text text-uppercase font-weight-bold caption text-truncate" style="width: 100%">
                {{ filename }}
              </div>


            </div>
          </v-layout>
        </template>
        <div>{{ filename }}</div>
      </v-tooltip>
    </template>
    <v-btn data-cy="delete-image-button" v-if="allowDelete" :disabled="removingFile" :loading="removingFile" @click="deleteImage(img.fileid)" icon
           style="position: absolute; top: -2px; right: -2px;">
      <v-icon size="30" color="error">delete</v-icon>
    </v-btn>
    <div class="hidden-xs-only image-date white--text caption">
      {{ $formatDate(img.modified) }}
    </div>
  </v-card>
</template>

<script>
import MimeTypes from 'mime-types';

export default {
  props: {
    img: {
      type: Object,
    },
    removingFile: {
      type: Boolean,
    },
    allowDelete: {
      type: Boolean,
      default: true
    }
  },
  name: 'FileCard',
  computed: {
    filename() {
      if (this.uploadedFile) {
        return this.img.tiedostonimi;
      } else {
        return this.img.name;
      }
    },
    uploadedFile() {
      return this.img.type == null;
    },
    isImageFile() {
      if (this.filename.endsWith('jfif')) {
      return true;
      } else if (this.img.type) {
        return this.img.type.startsWith('image/');
      } else {
        let lookup = MimeTypes.lookup(this.filename);
        return lookup.startsWith('image/');
      }
    },
    src() {
      if (this.img.thumb_url) {
        return this.img.thumb_url;
      }
      if (this.img.dataUrl) {
        return this.img.dataUrl;
      } else {
        return this.img.public_url;
      }
    },
    mimetype() {
      return MimeTypes.lookup(this.filename);
    }
  },
  methods: {
    downloadFile() {
      window.open(this.src, '_blank');
    },
    deleteImage() {
      this.$emit('delete-image', this.img);
    },
    openFullImage() {
      this.$emit('open-image', this.img);
    },
  },

}
</script>

<style lang="scss" scoped>
.image-date {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
}
</style>
