<template>
  <v-list-tile
    class="section-to-print auto-height my-3"
    avatar
    style="page-break-inside: avoid"
  >
    <v-list-tile-content v-if="reclamationRow">
      <v-list-tile-title>{{ reclamationRow.malliselite ? reclamationRow.rekl_tuoteselite : $t('reclamation.no_product') }}</v-list-tile-title>
      <v-list-tile-sub-title>
        <v-layout row wrap>
          <v-flex xs6 md4>{{ $t('reclamation.apartment') }}: {{ reclamationRow.apartment ? reclamationRow.apartment.name : $t('reclamation.no_apartment') }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.type') }}: {{ reclamationRow.rekl_tyyppiselite }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.order_number') }}: {{ reclamationRow.rekl_tilausnro ? reclamationRow.rekl_tilausnro : $t('reclamation.no_order_number') }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.status') }}: {{ reclamationRow.rekl_statusteksti ? reclamationRow.rekl_statusteksti : '' }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.delivery') }}: {{ reclamationRow.rekl_jakelupvm ? $formatDateIncludeWeekday(reclamationRow.rekl_jakelupvm) : $t('reclamation.no_delivery_date') }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.created') }}: {{ $formatDateNoTime(reclamationRow.rekl_rivinperustamispvm) }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.rekltoimeranro') }}: {{ reclamationRow.rekltoimeranro ? reclamationRow.rekltoimeranro : '' }}</v-flex>
          <v-flex xs6 md4>{{ $t('reclamation.rekltoimeranimi') }}: {{ reclamationRow.rekltoimeranimi ? reclamationRow.rekltoimeranimi : '' }}</v-flex>
        </v-layout>
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
export default {
  props: ['reclamationRow'],
  name: "PrintReclamationListItem",
  computed: {},
  methods: {},
}
</script>

<style>

.auto-height * {
  height: auto !important;
}
.web-pr-2 {
  padding-right: 8px;
}
.web-pl-2 {
  padding-left: 8px;
}
.web-mt-3 {
  margin-top: 12px;
}
.web-mb-3 {
  margin-bottom: 12px;
}

</style>

<style scoped>

@media print {
  .web-mt-3 {
    margin-top: 0px;
  }
  .web-mb-3 {
    margin-bottom: 0px;
  }
  .web-pr-2 {
    padding-right: 0px !important;
  }
  .web-pl-2 {
    padding-left: 0px !important;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    margin: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page { margin: 0 0 0 0; }
  body  { margin: 1.6cm; }
}

</style>
