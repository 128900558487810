const CALENDAR_TASKS_KEY = "jehu-calendar-tasks";
const CALENDAR_DELIVERYLOTS_KEY = "jehu-calendar-deliverylots";
const ALL_PROJECTS_KEY = "jehu-projects-all";
const OWN_PROJECTS_KEY = "jehu-projects-own";
const CALENDAR_TYOMAARAYKSET_KEY = 'jehu-calendar-tyomaaraykset';

function stringHash(key) {
  const str = typeof key == "string" ? key : JSON.stringify(key);
  let hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function setDataToCache(localStorageKey, objectValidKey, data) {
  const objectData = {
    key: stringHash(objectValidKey), data
  }
  localStorage.setItem(localStorageKey, JSON.stringify(objectData));
}

function getDataFromCache(localStorageKey, objectValidKey, defaultValue) {
  const asJson = localStorage.getItem(localStorageKey);
  if (!asJson) {
    return defaultValue;
  }

  try {
    const asObject = JSON.parse(asJson);
    if (asObject.key === stringHash(objectValidKey)) {
      if (asObject.data) {
        return asObject.data;
      }
    }
    localStorage.removeItem(localStorageKey);
  } catch (error) {
    console.log('invalid json', { error });
  }
  return defaultValue;
}

export default {
  clear() {
    localStorage.removeItem(CALENDAR_TASKS_KEY);
    localStorage.removeItem(CALENDAR_DELIVERYLOTS_KEY);
    localStorage.removeItem(ALL_PROJECTS_KEY);
    localStorage.removeItem(OWN_PROJECTS_KEY);
    localStorage.removeItem(CALENDAR_TYOMAARAYKSET_KEY);
  },
  getDeliveryLotsForInstallationGroups(installationGroupIds) {
    const key = installationGroupIds.join('');
    return getDataFromCache(CALENDAR_DELIVERYLOTS_KEY, key, null);
  },
  setDeliveryLotsForInstallationGroups(installationGroupIds, deliveryLots) {
    const key = installationGroupIds.join('');
    setDataToCache(CALENDAR_DELIVERYLOTS_KEY, key, deliveryLots);
  },
  getTyomaarayksetForInstallationGroups(installationGroupIds) {
    const key = installationGroupIds.join('');
    return getDataFromCache(CALENDAR_TYOMAARAYKSET_KEY, key, null);
  },
  setTyomaarayksetForInstallationGroups(installationGroupIds, tyomaaraykset) {
    const key = installationGroupIds.join('');
    setDataToCache(CALENDAR_TYOMAARAYKSET_KEY, key, tyomaaraykset);
  },
  getTasksForInstallationGroups(installationGroupIds) {
    const key = installationGroupIds.join('');
    return getDataFromCache(CALENDAR_TASKS_KEY, key, null);
  },
  setTasksForInstallationGroups(installationGroupIds, tasks) {
    const key = installationGroupIds.join('');
    setDataToCache(CALENDAR_TASKS_KEY, key, tasks);
  },
  getAllProjects(installationGroupIds) {
    const key = installationGroupIds.join('');
    return getDataFromCache(ALL_PROJECTS_KEY, key, []);
  },
  setAllProjects(installationGroupIds, projects) {
    const key = installationGroupIds.join('');
    return setDataToCache(ALL_PROJECTS_KEY, key, projects);
  },
  getOwnProjects(user) {
    return getDataFromCache(OWN_PROJECTS_KEY, user, []);
  },
  setOwnProjects(user, projects) {
    return setDataToCache(OWN_PROJECTS_KEY, user, projects);
  },
}
