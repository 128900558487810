<template>
  <v-navigation-drawer
    v-model="showDrawer"
    light
    clipped
    right
    app
    :style="{zIndex: $vuetify.breakpoint.lgAndUp ? '1' : '6'}"
  >
    <v-container fluid :class="noPadding ? 'pa-0' : ''">
      <slot></slot>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      noPadding: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDrawer: false
      }
    },
    watch: {
      value(newValue, oldValue) {
        this.showDrawer = newValue;
      },
      showDrawer(newValue) {
        this.$emit('input', newValue);
      }
    },
    created() {
      this.showDrawer = this.value;
    }
  }
</script>

<style scoped>

</style>
