<template>
  <v-btn icon @click="goBack">
    <v-icon>arrow_back</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "BackButton",
    props: ['fallback', 'fallbackParams'],
    data() {
      return {}
    },
    methods: {
      goBack() {
        const fallBackCounter = this.$store.state.fallBackRouteCounter;

        if (this.isChildPage) {
          if (this.$store.state.previousRouteName !== null) {
            this.$store.commit('setFallBackRouteCounter', 0);
            this.$router.back();
          } else {
            this.goToFallBack();
          }
        } else {
          if ((this.isPreviousRouteChildPage && fallBackCounter > 0) || this.$store.state.previousRouteName === null) {
            this.goToFallBack();
          } else {
            this.$store.commit('setFallBackRouteCounter', 0);
            this.$router.back();
          }
        }
        this.$emit('back');
      },
      goToFallBack() {
        let routerFallback = {};
        routerFallback.name = this.fallback;
        if (this.fallbackParams) routerFallback.params = this.fallbackParams;
        const fallBackCounter = this.$store.state.fallBackRouteCounter;
        this.$store.commit('setFallBackRouteCounter', fallBackCounter + 1);
        this.$router.push(routerFallback);
      },
    },
    computed: {
      isChildPage() {
        const mainPages = ['reclamations', 'projects', 'tasks', 'calendar'];
        return !mainPages.includes(this.$route.name);
      },
      isPreviousRouteChildPage() {
        if (this.$store.state.previousRouteName === null) return false;
        const prevRouteName = this.$store.state.previousRouteName;
        if (this.$route.name === 'reclamations') {
          return prevRouteName === 'reclamationrow';
        }
        if (this.$route.name === 'projects') {
          return prevRouteName === 'project' || prevRouteName === 'room' || prevRouteName === 'deliverylot';
        }
        if (this.$route.name === 'tasks') {
          return prevRouteName === 'task'
        }
        return false;
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
