<template>
  <v-container v-if="!$isCustomer" fluid fill-height ma-0 pa-0 class="calendar" id="scroll-target">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-toolbar color="primary" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0;" @click="scrollToCurrentWeek">
        <span class="title text-capitalize">{{ currentDateString }} </span><span
        class="title red--text text--lighten-3 font-weight-bold">{{ $t('week') }} {{ currentWeekString }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn dark icon @click="toggleFilterPanel">
        <v-icon>filter_list</v-icon>
      </v-btn>

    </v-toolbar>

    <hb-loading-indicator v-if="loading" fill-height align-middle/>

    <v-fade-transition>
      <v-layout row wrap v-if="!loading">
        <v-flex xs12 class="calendar-holder">
          <v-container fluid grid-list-md v-if="deliveryLots.length === 0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-alert :value="true" color="grey" icon="warning">
                  <div>{{ $t('calendar.no_delivery_lots_found') }}</div>
                  <v-btn class="ml-0 mt-3" outline :to="{name: 'installationGroups'}" color="white">
                    <v-icon left size="20">people_outline</v-icon>
                    {{ $t('installation_groups.title') }}
                  </v-btn>
                </v-alert>
              </v-flex>
            </v-layout>
          </v-container>
          <v-timeline v-if="deliveryLots.length > 0" dense align-top
                      class="pt-0 fixed-height-content ml-0">
            <recycle-scroller ref="scroller" :item-size="$vuetify.breakpoint.mdAndUp ? 64 : 52"
                              :items="calendarItems"
                              class="scroller fixed-height-content" key-field="key"
                              @scroll.native="onScroll">
              <template v-slot="{ item }">
                <calendar-timeline-item :item="item"
                                        :project-colors="projectColors"></calendar-timeline-item>
              </template>
            </recycle-scroller>
          </v-timeline>
        </v-flex>
      </v-layout>
    </v-fade-transition>

    <hb-filter-drawer v-model="showFilterPanel">
      <v-subheader class="subheading pa-0">{{ $t('calendar.filter.show_in_calendar') }}:</v-subheader>
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.deliveryLots"
            :label="$t('projects.delivery_lots')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.taskTypes[100000001]"
            :label="$t('tasks.filter_types.100000001')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.taskTypes[100000011]"
            :label="$t('tasks.filter_types.100000011')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            @change="storeFilters"
            v-model="objectFilters.showTyomaaraykset"
            :label="$t('tyomaaraykset.title')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            @change="storeFilters"
            v-if="$isPuustelliUser"
            v-model="objectFilters.own"
            :label="$t('calendar.filter.own')"
            hide-details
          ></v-checkbox>
        </v-flex>
      </v-layout>

      <template v-if="objectFilters.deliveryLots">
        <v-subheader class="subheading mt-4 pa-0">{{ $t('calendar.filter.show_delivery_lots_in_calendar') }}:
        </v-subheader>
        <v-layout row wrap>
          <v-flex xs12>
            <template v-for="filter in deliveryLotFilterOptions[0]">
              <v-checkbox
                @change="storeFilters"
                :key="filter.descKey"
                v-model="filter.value"
                :label="$t(filter.descKey)"
                hide-details
              ></v-checkbox>
            </template>
          </v-flex>
          <v-flex xs12>
            <v-divider class="mt-3 mb-1 pa-0"></v-divider>
            <template v-for="filter in deliveryLotFilterOptions[1]">
              <v-checkbox
                @change="storeFilters"
                :key="filter.descKey"
                v-model="filter.value"
                :label="$t(filter.descKey)"
                hide-details
              ></v-checkbox>
            </template>
          </v-flex>
        </v-layout>
      </template>
    </hb-filter-drawer>

  </v-container>
</template>

<script>
  import moment from 'moment';

  import api from "@/api";
  import dayjs from "dayjs";
  import ApiCacheService from "@/api-cache.service";
  import i18n from "@/i18n";
  import CalendarTimelineItem from "@/components/Calendar/CalendarTimelineItem";
  import frendsApi from "@/frendsApi";

  export default {
    name: "CalendarView",
    components: {CalendarTimelineItem},
    data() {
      return {
        loadingDeliveryLots: true,
        loadingTasks: true,
        loadingTyomaaraykset: true,
        tasks: null,
        deliveryLots: null,
        tyomaaraykset: null,
        objectFilters: {
          deliveryLots: true,
          tasks: true,
          taskTypes: {100000011: false, 100000001: false},
          own: false,
          showTyomaaraykset: true,
        },
        deliveryLotFilterOptions: {
          0: [
            {
              id: 0,
              value: true, descKey: 'calendar.filter.expecteds', filter: (lot) => {
                return lot.statusCode === 100000001 || lot.statusCode === 100000000;
              }
            },
            {
              id: 1,
              value: true, descKey: 'calendar.filter.actives', filter: (lot) => {
                return lot.statusCode > 100000001 && lot.statusCode < 100000007;
              }
            },
            {
              id: 2,
              value: true, descKey: 'calendar.filter.handovers', filter: (lot) => {
                return lot.statusCode === 100000009;
              }
            },
          ],
          1: [
            {
              id: 3,
              value: true, descKey: 'calendar.filter.receiveds', filter: (lot) => {
                return lot.received;
              }
            },
            {
              id: 4,
              value: true, descKey: 'calendar.filter.unreceiveds', filter: (lot) => {
                return !lot.received;
              }
            },
          ],
        },
        colors: [
          "pink",
          "light-green",
          "cyan",
          "deep-purple",
          "indigo",
          "amber",
          "light-blue",
          "teal",
          "blue",
          "lime",
          "purple",
          "red",
        ],
        showFilterPanel: false,
      }
    },
    methods: {
      onScroll(e) {
        this.$store.state.calendarScrollY = {
          taskCount: this.tasks.length,
          lotCount: this.deliveryLots.length,
          y: e.target.scrollTop
        };
      },
      storeFilters() {
        const filters = {
          objectFilters: this.objectFilters,
          deliveryLotFilterOptions0: this.deliveryLotFilterOptions["0"].map(o => {
            return {
              id: o.id,
              value: o.value,
            }
          }),
          deliveryLotFilterOptions1:this.deliveryLotFilterOptions["1"].map(o => {
            return {
              id: o.id,
              value: o.value,
            }
          }),
        };
        localStorage.setItem("calendar-filters", JSON.stringify(filters));
      },
      toggleFilterPanel() {
        this.showFilterPanel = !this.showFilterPanel;
      },
      scrollToCurrentWeek() {
        setTimeout(() => {
          if (this.$refs.scroller) {
            const index = this.calendarItems.findIndex(ci => ci.isCurrentWeek && ci.showWeekNumber);
            const position = (this.$vuetify.breakpoint.mdAndUp ? 64 : 52) * (index - 1);
            this.$refs.scroller.scrollToPosition(position);
          }
        });
      },
      async reloadDeliveryLots() {
        this.loadingDeliveryLots = true;
        try {
          const installationGroupIds = this.installationGroupIds;

          if (installationGroupIds.length === 0) {
            return [];
          }

          const deliveryLots = await api.getDeliveryLotsForInstallationGroups(installationGroupIds);
          let scroll = this.deliveryLots == null && this.tasks != null;
          ApiCacheService.setDeliveryLotsForInstallationGroups(installationGroupIds, deliveryLots);
          this.deliveryLots = deliveryLots;
          if (scroll) {
            this.scrollToCurrentWeek();
          }
        } catch (err) {
          console.log(err);
          context.dispatch('showNotification', {
            color: 'error',
            message: i18n.t('projects.error_loading_projects'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              this.reloadDeliveryLots()
            }
          });
        }

        this.loadingDeliveryLots = false;
      },
      checkDeliveryFilters(lot) {
        let show = false;
        this.deliveryLotFilterOptions[1].forEach(f => {
          if (f.value && f.filter(lot)) {
            this.deliveryLotFilterOptions[0].forEach(f2 => {
              if (f2.value && f2.filter(lot)) show = true;
            });
          }
        });
        return show;
      },
      async reloadTasksForUser() {
        this.loadingTasks = true;
        try {

          const tasks = await api.reloadTasksForUser(this.installationGroupIds, dayjs().subtract(3, 'months').toDate());
          ApiCacheService.setTasksForInstallationGroups(this.installationGroupIds, tasks);
          const scrollToCurrentWeek = this.tasks == null && this.deliveryLots != null; // jos taskeja ldataan ekan kerran, siirrytään nykyviikkoon
          this.tasks = tasks;
          if (scrollToCurrentWeek) {
            this.scrollToCurrentWeek();
          }
        } catch (error) {
          this.$store.dispatch('showNotification', {
            color: 'error',
            message: this.$t('tasks.failed_to_fetch_tasks'),
            timeOut: 10000,
            showButton: false,
            buttonText: null,
            callback: () => {}
          });
        }
        this.loadingTasks = false;
      },
      loadFilters(storedFilters) {
        const filters = JSON.parse(storedFilters);
        if (filters == null || filters.objectFilters == null) {
          return;
        }

        if (!this.$isPuustelliUser) {
          filters.objectFilters.own = false;
        }

        Object.assign(this.objectFilters, filters.objectFilters);
        if (filters.deliveryLotFilterOptions0 == null) {
          return;
        }
        filters.deliveryLotFilterOptions0.forEach(o => {
          const foundOption = this.deliveryLotFilterOptions["0"].find(opt => opt.id === o.id);
          if (foundOption != null) {
            foundOption.value = o.value;
          }
        });
        filters.deliveryLotFilterOptions1.forEach(o => {
          const foundOption = this.deliveryLotFilterOptions["1"].find(opt => opt.id === o.id);
          if (foundOption != null) {
            foundOption.value = o.value;
          }
        });
      },
      getDataFromCache() {
        this.tasks = ApiCacheService.getTasksForInstallationGroups(this.installationGroupIds);
        this.deliveryLots = ApiCacheService.getDeliveryLotsForInstallationGroups(this.installationGroupIds);
        this.tyomaaraykset = ApiCacheService.getTyomaarayksetForInstallationGroups(this.installationGroupIds);
      },
      async reloadTyomaaraykset() {
        this.loadingTyomaaraykset = true;
        try {
          const installationGroupIds = this.installationGroupIds;
          const tyomaaraykset = await frendsApi.getTyomaaraykset(this.$kielinro, installationGroupIds, null, null, dayjs().subtract(3, 'months').toDate(), null, false);
          ApiCacheService.setTyomaarayksetForInstallationGroups(installationGroupIds, tyomaaraykset);
          this.tyomaaraykset = tyomaaraykset;
        } catch (error) {
          console.log(error);
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: this.$t('tyomaaraykset.failed_to_fetch'),
              timeOut: 10000,
              showButton: true,
              buttonText: this.$t('try_again'),
              callback: () => {
                this.reloadTyomaaraykset(id);
              }
            });
        }
        this.loadingTyomaaraykset = false;
      },
    },
    computed: {
      calendarItems() {
        const items = [];

        const indexMoment = moment().subtract(12, "weeks");
        const todaysWeek = moment().format('w');

        for (let i = 0; i < 52; i += 1) {
          const weekNumber = indexMoment.startOf('w').format('w');
          const year = indexMoment.format('YYYY');
          const dateKeyPart = `${weekNumber}.${year}`;
          const isCurrentWeek = todaysWeek === weekNumber;

          const weekItem = {
            key: 'week-' + dateKeyPart,
            type: 'week',
            year,
            showWeekNumber: false,
            datesFormatted: `${indexMoment.startOf('isoWeek').format('D.M')} – ${indexMoment.endOf('isoWeek').format('D.M.YYYY')}`,
          }
          items.push(weekItem);

          const lots = this.deliveryLotsGroupedByWeek[dateKeyPart] || [];
          lots.forEach((l, index) => {
            const lot = {
              ...l,
              key: `lot-${l.id}-${dateKeyPart}`,
              showWeekNumber: index === 0,
              weekNumber,
              isCurrentWeek,
              year,
              type: 'deliverylot'
            }
            items.push(lot);
          })

          const tasks = this.tasksGroupedByWeek[dateKeyPart] || [];

          tasks.forEach((t, index) => {
            const task = {
              ...t,
              key: `task-${t.activityId}-${dateKeyPart}`,
              showWeekNumber: index === 0 && lots.length === 0,
              weekNumber,
              isCurrentWeek,
              year,
              type: 'task'
            }
            items.push(task);
          })

          const tyomaaraykset = this.tyomaarayksetGroupedByWeek[dateKeyPart] || [];

          tyomaaraykset.forEach((t, index) => {
            const tyomaarays = {
              ...t,
              key: `tyomaarays-${t.tyomaaraysid}-${dateKeyPart}`,
              showWeekNumber: index === 0 && lots.length === 0 && tasks.length === 0,
              weekNumber,
              isCurrentWeek,
              year,
              type: 'tyomaarays'
            }
            items.push(tyomaarays);
          })

          if (lots.length === 0 && tasks.length === 0 && tyomaaraykset.length === 0) {
            items.push({
              type: 'empty',
              showWeekNumber: true,
              isCurrentWeek,
              key: 'empty-' + dateKeyPart,
              weekNumber,
              year,
            })
          }

          indexMoment.add(1, 'weeks');
        }
        return items;
      },
      loading() {
        if (this.loadingTasks || this.loadingDeliveryLots || this.loadingTyomaaraykset) {
          return this.deliveryLots == null || this.tasks == null || this.tyomaaraykset == null;
        }
        return false;
      },
      subtleLoading() {
        if (this.loadingTasks || this.loadingDeliveryLots) {
          return this.deliveryLots != null && this.tasks != null;
        }
        return false;
      },
      installationGroupIds() {
        const installationGroupIds = this.$store.state.installationGroups.filter(g => g.selected).map(group => group.id);
        return installationGroupIds;
      },
      projectColors() {
        if (this.deliveryLots.length === 0) return [];

        let colorIndex = 0;
        const projectColors = [];
        this.deliveryLots.forEach(l => {
          const projectHasColor = projectColors.findIndex(pc => pc.projectId === l.projectId) > -1;
          if (!projectHasColor) {
            projectColors.push({
              projectId: l.projectId,
              color: this.colors[colorIndex],
            })
          }
          colorIndex++;
          if (colorIndex >= this.colors.length) {
            colorIndex = 0;
          }
        });
        return projectColors;
      },
      deliveryLotsGroupedByWeek() {
        const lots = this.filteredDeliveryLots;

        const deliveryLots = {};

        lots.forEach((dl) => {
          const startMoment = moment(dl.startDate);
          const endMoment = moment(dl.endDate);
          let currentMoment = moment(startMoment);
          while (currentMoment.isBefore(endMoment)) {
            const weekNumber = currentMoment.format('w');
            const year = currentMoment.format('YYYY');
            const key = `${weekNumber}.${year}`;
            if (!(key in deliveryLots)) {
              deliveryLots[key] = [];
            }
            deliveryLots[key].push(dl);
            currentMoment = currentMoment.add(1, 'week');
          }
        })
        return deliveryLots;
      },

      filteredDeliveryLots() {
        if (!this.deliveryLots) {
          return [];
        }

        if (!this.objectFilters.deliveryLots) {
          return [];
        }

        return this.deliveryLots.filter(dl => {
          if (this.objectFilters.own) {
            const userId = this.$store.state.auth.jehuUser.id;
            const ownDeliveryLot = dl.managerId === userId || dl.customerRepId === userId || dl.ownerId  === userId || dl.secretaryId === userId;
            if (!ownDeliveryLot) {
              return false;
            }
          }

          let ret = false;
          this.deliveryLotFilterOptions[1].forEach(f => {
            if (f.value && f.filter(dl)) {
              this.deliveryLotFilterOptions[0].forEach(f2 => {
                if (f2.value && f2.filter(dl)) ret = true;
              });
            }
          });
          return ret;
        });
      },
      filteredTasks() {
        if (!this.tasks) {
          return [];
        }
        let tasks = this.tasks;

        if (this.objectFilters.own) {
          const userId = this.$store.state.auth.jehuUser.id;
          tasks = tasks.filter(task => (task.isDeliveryLotTask && task.deliveryLot.managerId === userId) || (!task.isDeliveryLotTask && task.project.managerId === userId));
        }

        tasks = tasks.filter(task => {
          return this.objectFilters.taskTypes[task.typeCode];
        });

        tasks.sort((a, b) => {
          a = a.scheduledEnd;
          b = b.scheduledEnd;
          return b > a ? -1 : b < a ? 1 : 0;
        });

        return tasks;
      },
      tasksGroupedByWeek() {
        let tasks = this.filteredTasks;
        let tasksGroupedByWeek = {};

        tasks.forEach(task => {
          const taskDueDate = moment(task.scheduledEnd).startOf('w');
          const weekNumber = taskDueDate.format('w');
          const year = taskDueDate.format('YYYY');
          const key = `${weekNumber}.${year}`;
          if (!tasksGroupedByWeek[key])
            tasksGroupedByWeek[key] = [];
          tasksGroupedByWeek[key].push(task);
        });
        return tasksGroupedByWeek;
      },
      filteredTyomaaraykset() {
        if (!this.tyomaaraykset || !this.objectFilters.showTyomaaraykset) {
          return [];
        }
        let tyomaaraykset = this.tyomaaraykset;

        if (this.objectFilters.own) {
          const email = this.$store.state.auth.jehuUser.email;
          tyomaaraykset = tyomaaraykset.filter(t => ("" + t.puustellivastaavaemail).toLowerCase() === ("" + email).toLowerCase());
        }

        tyomaaraykset.sort((a, b) => {
          a = a.tyopvm;
          b = b.tyopvm;
          return b > a ? -1 : b < a ? 1 : 0;
        });

        return tyomaaraykset;
      },
      tyomaarayksetGroupedByWeek() {
        let tyomaaraykset = this.filteredTyomaaraykset;
        let tyomaarayksetGroupedByWeek = {};

        tyomaaraykset.forEach(tyomaarays => {
          const tyomaaraysDueDate = moment(tyomaarays.tyopvm).startOf('w');
          const weekNumber = tyomaaraysDueDate.format('w');
          const year = tyomaaraysDueDate.format('YYYY');
          const key = `${weekNumber}.${year}`;
          if (!tyomaarayksetGroupedByWeek[key])
            tyomaarayksetGroupedByWeek[key] = [];
          tyomaarayksetGroupedByWeek[key].push(tyomaarays);
        });
        return tyomaarayksetGroupedByWeek;
      },
      currentDateString() {
        return moment().locale(this.$locale).format('dddd D.M')
      },
      currentWeekString() {
        return moment().locale(this.$locale).format('w');
      },
    },
    async mounted() {
      // For puustelliusers, set own to true, rest of the users dont even see the selection so false is required
      this.objectFilters.own = !!this.$isPuustelliUser;

      const storedFilters = localStorage.getItem("calendar-filters");
      if (storedFilters != null) {
        this.loadFilters(storedFilters);
      }
      if (this.$isCustomer) {
        this.$router.push({name: 'projects'});
      }

      this.getDataFromCache();

      // Jos tuli taskeja cachesta niin scrollataan nykyviikkoon
      if (this.tasks != null && this.deliveryLots != null) {
        this.$nextTick(() => {
          // scroll to initial position
          if (this.$store.state.calendarScrollY != null) {
            const scrollY = this.$store.state.calendarScrollY;
            if (this.tasks && this.deliveryLots && this.tasks.length === scrollY.taskCount && this.deliveryLots.length === scrollY.lotCount) {
              setTimeout(() => {
                this.$refs.scroller.$el.scrollTop = this.$store.state.calendarScrollY.y
                this.$store.state.calendarScrollY.scrollY = 0;
              })
            } else {
              this.scrollToCurrentWeek();
            }
          } else {
            this.scrollToCurrentWeek();
          }
        });
      }
      this.reloadDeliveryLots();
      this.reloadTasksForUser();
      this.reloadTyomaaraykset();
    },
  }
</script>

<style lang="scss">
.calendar-custom-tile {
  .v-list__tile {
    padding-right: 5px;

    &:hover {
      background: none !important;
    }

    .v-list__tile__avatar {
      min-width: 48px;
    }

    .v-list__tile__title {
      line-height: initial;
      height: auto;
    }
  }
}

@media only screen and (min-width: 960px) {
  .calendar-custom-tile {
    .v-list__tile {
      padding-right: 8px;
    }
  }

  .v-toolbar {
    padding-right: 0 !important;
  }
}

.v-timeline--dense::before {
  left: 34px;
}
</style>

<style lang="scss" scoped>
   @media only screen and (max-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 112px);
    }
  }

  @media only screen and (min-width: 600px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 112px);
    }
  }

  @media only screen and (min-width: 960px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 120px);
    }
  }

  @media only screen and (min-width: 1265px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }

  @media only screen and (min-width: 1300px) {
    .fixed-height-content {
      margin-bottom: 0;
      height: calc(100vh - 65px);
    }
  }
</style>
