<template>
  <v-list-tile-content>
    <v-list-tile-title :class="item.highlight ? 'font-weight-bold primary--text text--darken-3' : 'font-weight-medium'">
      <span :style="{paddingLeft: indent * indentValue + 'px'}">{{ item.tuoteselite }}</span>
    </v-list-tile-title>
    <v-list-tile-sub-title>
      <span v-if="$vuetify.breakpoint.xsOnly" class="unit-details caption grey--text" :style="{paddingLeft: indent * indentValue + 'px'}">
        {{ item.vari }} {{ item.vari && item.valmiste ? '&bull; ' + item.valmiste : item.valmiste ? item.valmiste : ''}} {{ item.m1 ? '&bull; ' + item.m1 + '*' + item.m2 + '*' + item.m3 : ''}} {{ item.katisyys ? '&bull; ' + item.katisyys : '' }}
      </span>
      <div v-if="$vuetify.breakpoint.smAndUp" class="unit-details caption grey--text">
        <v-layout row wrap>
          <v-flex sm3>
            <span :style="{paddingLeft: indent * indentValue + 'px'}">{{ item.vari ? item.vari : '-'}}</span>
          </v-flex>
          <v-flex sm3>
            {{ item.valmiste ? item.valmiste : '-'}}
          </v-flex>
          <v-flex sm3>
            {{ item.m1 ? item.m1 + '*' + item.m2 + '*' + item.m3 : '-'}}
          </v-flex>
          <v-flex sm3>
            {{ item.katisyys ? item.katisyys : '-' }}
          </v-flex>
        </v-layout>
      </div>
    </v-list-tile-sub-title>
  </v-list-tile-content>
</template>

<script>
  export default {
    name: "UnitListItemContent",
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      indent: {
        type: Number,
        default: 0,
      },
      indentValue: {
        type: Number,
        default: 15,
      }
    },
  }
</script>

<style scoped>

</style>
