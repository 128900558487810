/* eslint-disable no-param-reassign */
import Vue from 'vue';

import { databaseEnabledPromise, default as database } from "@/database";

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      methods: {
        $removeCurlyBrackets(value) {
          if (value && value.startsWith('{')) {
            value = value.substring(1, value.length - 1).toLowerCase();
          }
          return value;
        },
        $selectInputValue(e) {
          const input = e.target;
          input.setSelectionRange(0, input.value.length);
        },
        $roundNumber(value, precision) {
          const multiplier = Math.pow(10, precision || 0);
          return Math.round(value * multiplier) / multiplier;
        },
        $dynamicsProjectUrl(id) {
          return process.env.VUE_APP_DynamicsProjektiUrl.replace('%id%', id);
        },
        $dynamicsDeliveryLotUrl(id) {
          return process.env.VUE_APP_DynamicsToimituseraUrl.replace('%id%', id);
        },
        async $isDbSupported() {
          return await databaseEnabledPromise;
        },
        $makeDialogsMovable() { // Kun dialogilla on content-class="movable-dialog" niin dialogia voi liikuttaa tarttumalla toolbariin. Toolbarille pitää antaa "move-attach" class, jotta kursori muuttuu indikoimaan liikuttamista.
          const d = {};
          document.addEventListener("mousedown", e => {
            if (this.$vuetify.breakpoint.xsOnly) return;
            const closestDialog = e.target.closest(".v-dialog.v-dialog--active.movable-dialog");
            if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-toolbar__content")) { // element which can be used to move element
              d.el = closestDialog; // element which should be moved
              d.mouseStartX = e.clientX;
              d.mouseStartY = e.clientY;
              d.elStartX = d.el.getBoundingClientRect().left;
              d.elStartY = d.el.getBoundingClientRect().top;
              d.el.style.position = "fixed";
              d.el.style.margin = 0;
              d.oldTransition = d.el.style.transition;
              d.el.style.transition = "none"
            }
          });
          document.addEventListener("mousemove", e => {
            if (this.$vuetify.breakpoint.xsOnly) return;
            if (d.el === undefined) return;
            d.el.style.left = Math.min(
              Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
              window.innerWidth - d.el.getBoundingClientRect().width
            ) + "px";
            d.el.style.top = Math.min(
              Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
              window.innerHeight - d.el.getBoundingClientRect().height
            ) + "px";
          });
          document.addEventListener("mouseup", () => {
            if (this.$vuetify.breakpoint.xsOnly) return;
            if (d.el === undefined) return;
            d.el.style.transition = d.oldTransition;
            d.el = undefined
          });
          if (!this.$vuetify.breakpoint.xsOnly) {
            setInterval(() => { // prevent out of bounds
              const dialog = document.querySelector(".v-dialog.v-dialog--active");
              if (dialog === null) return;
              dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
              dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
            }, 100);
          }
        },
        $makeDialogsResizable() {

        },
      },
      computed: {
        $dynamicsProjectUrlConfigured() {
          return 'VUE_APP_DynamicsProjektiUrl' in process.env;
        },
        $dynamicsDeliveryLotUrlConfigured() {
          return 'VUE_APP_DynamicsToimituseraUrl' in process.env;
        },
        $db() {
          return database;
        },
      },
    });
  },
};

export default HelperPlugin;
